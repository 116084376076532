<template lang="">
  <div  v-if="adresses.length > 0" class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
    
       <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0">
         
      <h4 class="fw-700 mb-0 font-14 text-grey-900">Suggested adresses <span v-if="isAdressesReady">  ({{totalResults}}) </span></h4>
      </div>
      <template v-if="isAdressesReady">
        <div class="chat-left scroll-bar pt-2">
      <div v-for="adresse in adresses" class="card-body d-flex pt-2 ps-2 pe-2 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-2">
          
        
        <figure class="avatar me-2 mb-0">
          <router-link :to="{name :'feedadresse', params: { id : adresse.id } }" >
         <img  :src="`${assetsBaseUrl}${adresse.logo}`" alt="image" class="shadow-sm rounded-circle w60"> 
         </router-link>
        </figure>
        
        
              <h4  class="fw-700 text-grey-900 font-xssss mt-1 link-user elipsis">
              <router-link v-tooltip.top-start="'Visit profil'" :to="{name :'feedadresse', params: { id : adresse.id } }" >{{adresse.rs}}
                </router-link>
                <!-- <div class="star w-50 d-block text-left mt-0 font-xssss">
              <h6 class="font-xssss text-grey-500 fw-600 mt-0 mb-0 elipsis">
                {{adresse.adresse}}
              </h6>
              </div> -->
              <div class="star d-block w-100 text-left mt-0">
                <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
                <div class="rating-color d-i-b" v-html="drawStarsYellow(adresse.rateRound)"></div>
                <div class="d-i-b rating-grey" v-html="drawStarsGrey(adresse.rateRound)"></div>
                </router-link>
              
              </div>
               <div class="clearfix"></div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
              <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
              <div class="couts-color d-i-b" v-html="drawDollarsGreen(adresse.coutsRound)"></div>
              <div class="d-i-b rating-grey" v-html="drawDollarsGrey(adresse.coutsRound)"></div>
              </router-link>
              </div>

              </h4>
              
             
         
        
        
       </div>
             <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-2
            mb-1
            mt-1
        "
    >
        
No other suggestions
    </div>
      <Loader v-show="isVisible && !done"/> 
          <div v-if="adresses.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>  
              
      </div>
      <router-link  to="/suggestedAdresses" class="fw-600 ms-auto font-14 text-primary pe-3 pb-1 show-all" v-if="adresses.length > 0">See all</router-link>
    </template>
    <template v-else>
      <div class="pb-4 pt-4 border-top-xs">
      <Loader></Loader>
      </div>

    </template>
  </div>
 
</template>
<script>
import Loader from "../../layouts/loader/loader.vue"
export default {
  name: "amis",
   components:{
    Loader,
    
  },
  data(){
    return{
      adresses:[],
      isAdressesReady:false,
      page:1,
      lastPage:1,
      done:false,
      //fav ref
      //addFriendRef:[],
      isVisible: false,
      totalResults:0


    }
  }, 
  methods:{
     visibilityChanged (isVisible) {
         //this.addFriendRef=[];
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
          this.isVisible = isVisible;
    
            this.page++;
            this.getAdresses();
     
      },
    drawStarsYellow(count){
          return `<i class="fa fa-star rating-color"></i>`.repeat(count)
      },
    drawStarsGrey(count){
          return `<i class="fa fa-star"></i>`.repeat(5-count)
      },
    drawDollarsGreen(count){
          return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count)
      },
    drawDollarsGrey(count){
          return `<i class="fas fa-dollar-sign"></i>`.repeat(5-count)
      },
    getAdresses(){
      //this.isAdressesReady=false;
      
      this.axios.get(`/suggedtedAdresse?page=${this.page}`)
      .then(res=>{
        this.adresses.push(...res.data.adresses.data);
        //this.friends = res.data.amis.data;
       
        this.lastPage = res.data.adresses.last_page;
        this.totalResults = res.data.adresses.total;
        //
       // this.adresses = res.data.adresses;
        this.isAdressesReady=true;
        //console.log(this.adresses)
    })
    },

  }, 
  mounted(){
    this.getAdresses();

  },
  computed: {
          currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.rounded-circle{
 border-radius:10px !important
}
.show-all{
  &:hover{
    text-decoration: underline !important;
  }
  color:#2e58a6 !important
}
.link-user a{
  color:#000 !important;

&:hover{
  color:#2e58a6 !important;
  text-decoration: underline !important
}
}
.btn-round-sm{
  border-radius:5px !important
}
.container-adrs-inf{
  padding: 0rem 1rem 0.5rem !important
}
.rating-color {
    color: #fbc634 !important
}
.rating-grey{
  color:#d2e3ff !important
}
.couts-color{
  color:rgb(16, 92, 16)
}
.w-img{
  width:100% !important;
  height:90px !important;
  vertical-align:bottom !important;
  border-radius: 10px
  //text-align:center;
  //left:20%;
}
.elipsis{
  white-space:nowrap;
  text-overflow:ellipsis;
   overflow: hidden;
}
.d-i-b{
  display:inline-block !important
}
.w60{
  width:60px !important
} 
.chat-left {
    height: calc(100vh - 400px) !important;
}
</style>
