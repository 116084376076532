<template lang="">
    <div class="middle-wrap">
                <div class="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                    
                    <div class="card-body p-lg-5 p-4 w-100 border-0">
                        <div class="row">
                            <div class="col-lg-12">
                                <h4 class="mb-4 font-xxl fw-700 mont-font mb-lg-5 mb-4 font-md-xs">Settings</h4>
                                <div class="nav-caption fw-600 font-xssss text-grey-500 mb-2">Genaral</div>
                                <ul class="list-inline mb-4">
                                    <li class="list-inline-item d-block  me-0">
                                        <router-link :to="{ name : 'infocompte'}" class="pt-2 pb-2 d-flex align-items-center">
                                            <i class="btn-round-md bg-blue-gradiant text-white feather-home font-md me-3"></i>
                                            <h4 class="fw-600 font-xsss mb-0 mt-0">Account Info</h4>
                                            <i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i>
                                        </router-link>
                                    </li>
                                </ul>

                                <div class="nav-caption fw-600 font-xsss text-grey-500 mb-2">Account</div>
                                <ul class="list-inline mb-4">
                                    <li class="list-inline-item d-block  me-0"><router-link to="/motdepasse" class="pt-2 pb-2 d-flex align-items-center"><i class="btn-round-md bg-blue-gradiant text-white feather-inbox font-md me-3"></i> <h4 class="fw-600 font-xsss mb-0 mt-0">Password</h4><i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></router-link></li>
                                    
                                </ul>

                                <div class="nav-caption fw-600 font-xsss text-grey-500 mb-2">Others</div>
                                <ul class="list-inline">
                                    <li class="list-inline-item d-block border-bottom me-0"><router-link to="/notifications" class="pt-2 pb-2 d-flex align-items-center"><i class="btn-round-md bg-gold-gradiant text-white feather-bell font-md me-3"></i> <h4 class="fw-600 font-xsss mb-0 mt-0">Notifications</h4><i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></router-link></li>
                                    <li class="list-inline-item d-block me-0"><a role="button" @click.prevent="logout" class="pt-2 pb-2 d-flex align-items-center"><i class="btn-round-md bg-red-gradiant text-white feather-lock font-md me-3"></i> <h4 class="fw-600 font-xsss mb-0 mt-0">Logout</h4><i class="ti-angle-right font-xsss text-grey-500 ms-auto mt-3"></i></a></li>
                                     
                                </ul>
                            </div>
                        </div>


                    </div>
                </div>
                
            </div>
</template>
<script>
export default {
  methods: {
    logout() {
      this.axios
        .get("logout")
        .then((res) => {
          localStorage.removeItem("token");
          this.$router.push("/");
          // console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-round-md{
  border-radius: 10px !important;
}
</style>