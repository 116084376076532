<template>
  <portal to="modal">
    <div class="modall">
      <div class="wrapper col-md-6 col-sm-12">
        <div class="modal-container">
          <div
            @click.self.stop.prevent="handleClose"
            class="absolute inset-0 bg-gray-500 opacity-75"
          ></div>
        </div>

        <div
          class="z-10 rounded-modal bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="flex items-center justify-between text-center">
            <h3
              class="pt-2 text-lg modal-header-txt text-gray-900 fw-600"
              id="modal-headline"
            >
              Share on social media
            </h3>
            <i class="feather-x-square pt-0" @click.prevent="handleClose"></i>
          </div>
          <div
            class=" w-100 rounded-xxl border-0 ps-4 pe-4 mb-3"
          >
            <div class="card-body pt-3 ps-2 pe-2 pb-3 border-top-xs bor-0">
              <!-- :url="`https://www.trippybook.com/showPost/${postId}/${mediaId}`" -->
              <div class="row">
                <div class="col-md-4">
                  <ShareNetwork
                    @close="closeModal"
                    title="Trippybook"
                    class="fb-container"
                    style="
                       {
                        color: #fff;
                      }
                    "
                    network="facebook"
                    :url="`https://www.trippybook.com/showPost/${postId}/${mediaId}`"
                    :quote="textPubToShare"
                  >
                    <i class="fab fah fa-lg fa-facebook-f"></i>
                    <span>Facebook</span>
                  </ShareNetwork>
                </div>

                <div class="col-md-4">
                  <ShareNetwork
                    @close="closeModal"
                    title="Trippybook"
                    class="twitter-container"
                    style="
                       {
                        color: #fff;
                      }
                    "
                    network="twitter"
                    url="https://www.trippybook.com/feeds"
                    :quote="textPubToShare"
                  >
                    <i class="fab fah fa-lg fa-twitter"></i>
                    <span>Twitter</span>
                  </ShareNetwork>
                </div>

                <div class="col-md-4">
                  <ShareNetwork
                    @close="closeModal"
                    title="Trippybook"
                    class="whatsapp-container"
                    style="
                       {
                        color: #fff;
                      }
                    "
                    network="whatsapp"
                    url="https://www.trippybook.com/feeds"
                    :quote="textPubToShare"
                  >
                    <i class="fab fah fa-lg fa-whatsapp"></i>
                    <span>Whatsapp</span>
                  </ShareNetwork>
                </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <ShareNetwork
                      @close="closeModal"
                      title="Trippybook"
                      class="skype-container"
                      style="
                         {
                          color: #fff;
                        }
                      "
                      network="skype"
                      url="https://www.trippybook.com/feeds"
                      :quote="textPubToShare"
                    >
                      <i class="fab fah fa-lg fa-skype"></i>
                      <span>Skype</span>
                    </ShareNetwork>
                  </div>

                  <!-- <div class="col-md-4">
                         <ShareNetwork
                            style="{color: #fff }"
                            network="messenger"
                            url="https://www.trippybook.com/feeds"
                            :quote="textPubToShare" 
                                 
                          > 
                           <i class="fab fah fa-lg fa-facebook-messenger"></i>
                          <span>Messenger</span>
 
                        </ShareNetwork>
                        </div> -->

                  <div class="col-md-4">
                    <ShareNetwork
                      @close="closeModal"
                      title="Trippybook"
                      class="telegram-container"
                      style="
                         {
                          color: #fff;
                        }
                      "
                      network="telegram"
                      url="https://www.trippybook.com/feeds"
                      :quote="textPubToShare"
                    >
                      <i class="fab fah fa-lg fa-telegram-plane"></i>
                      <span>Telegram</span>
                    </ShareNetwork>
                  </div>
                </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { Tabs, Tab } from "vue3-tabs-component";
import jaimeListAll from "../../pages/Jaimes/allReactions.vue";
import Loader from "../../layouts/loader/loaderImage.vue";
import Skeleton from "../../layouts/skeleton/SkeletonComment.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  name: "BaseModal",
  components: {
    Loader,
    jaimeListAll,
    Tabs,
    Tab,
    Skeleton,
  },
  props: {
    postId: Number,
    mediaId: Number,
    textPubToShare: String,
  },

  data() {
    return {
      //-------(Arrays)-----
      friends: [],
      //-------(Ready)-----
      isFriendsReady: false,
      //-------(Total)-----
      totalFriends: 0,
      //-------(Pagination)-----
      page: 1,
      lastpage: 1,
      done: false,
      isVisible: false,
      //---------(refs)---------
    };
  },

  created() {
    document.addEventListener("keyup", this.onClose);

    this.token = localStorage.getItem("token");
  },

  mounted() {},

  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },

  methods: {
    handleClose() {
      this.$emit("close");
    },
    closeModal() {
      this.$emit("close");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
    baseUrl() {
      return this.$store.state.baseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.em,
.em-svg {
  height: 1em;
  width: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}
.em---1,
.em---1,
.em-thumbsup {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
}
.em---1.em-svg,
.em---1.em-svg,
.em-thumbsup.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44d.svg"),
    none;
}
.em--1,
.em-thumbsdown {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
}
.em--1.em-svg,
.em-thumbsdown.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44e.svg"),
    none;
}
.em-heart {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
}
.em-heart.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/2764.svg"),
    none;
}
.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;
  }
}
.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.wrapper {
  // width: 40%;
  margin: 10% auto;
  border-radius: 10px;
}
.modal-container {
  margin: 30px 50px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}
.modal-header-txt {
  display: inline-block;
}
.feather-x-square {
  float: right;
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }
  // float:right;
  font-size: 32px;
}
.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}
.loader {
  height: 50px !important;
}
.d-i-b,
.tabs-component-tabs {
  display: inline-block !important;
}
.is-active {
  border-bottom: 2px#333 solid !important;
  color: #2e58a6 !important;
  text-decoration: underline !important;
  font-size: 32px;
}
.chat-left {
  height: calc(100vh - 300px) !important;
}
.close-btn {
  top: -410px !important;
  right: -23px !important;
}
.rounded-circle {
  border-radius: 10px !important;
}
.btn-round-sm {
  border-radius: 5px !important;
}
.btn-add,
.btn-decline {
  border-radius: 5px !important;
  transition: background-color 0.4s ease-in-out;
  &:hover {
    cursor: pointer;

    color: #fff !important;
  }
}
.btn-add {
  &:hover {
    background-color: #2e58a6 !important;
  }
}
.btn-decline {
  &:hover {
    background-color: #f00 !important;
  }
}
.icon-reaction {
  bottom: -3px;
  right: -3px;
}
.red {
  color: #f00 !important;
}
.blue {
  color: #2e58a6 !important;
}
.orange {
  color: rgb(233, 113, 15) !important;
}
.all-count {
  top: -55px;
  left: 44px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}
.likes-count {
  top: -55px;
  left: 138px;
  font-size: 11px !important;
  // color:#2e58a6 !important;
}
.loves-count {
  top: -55px;
  left: 237px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}
.dislikes-count {
  top: -55px;
  left: 350px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}
.hover-item {
  &:hover {
    border-bottom: 1px#2e58a6 solid !important;
    color: #2e58a6 !important;
  }
}
.modal-header-txt {
  text-align: center !important;
}
.ms-10 {
  padding-left: 10em;
}
// .social-container{
//   padding:5px;
//   border-radius: 5px;

// }
.fb-container {
  background-color: rgb(24, 119, 242) !important;
}
.twitter-container {
  background-color: rgb(29, 161, 242) !important;
}
.skype-container {
  background-color: rgb(0, 114, 198) !important;
}
.telegram-container {
  background-color: rgb(74, 118, 168) !important;
}
.whatsapp-container {
  background-color: rgb(37, 211, 102) !important;
}

a {
  flex: none;
  color: #ffffff;
  background-color: #333;
  border-radius: 3px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 10px 10px 0;
  text-decoration: none;
}

.fah {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px;
  flex: 0 1 auto;
}
a span {
  padding: 0 10px;
  flex: 1 1;
  font-weight: 500;
}
.rounded-modal {
  border-radius: 10px;
}
@media screen and (max-width: 989px) {
  .modal-container {
    margin: 0px 10px;
    padding: 0px 20px;
  }
}
@media (max-width: 767px) {

  .bg-greylight{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
 .card-m{
   padding-left:0px !important;
    padding-right:0px !important;

   
 }
    .modal-container {
    margin: 0px 10px !important;
    
  }
   .wrapper {
  margin: 0 auto;
  height: 100% !important;
  }
  .rounded-modal {
    border-radius: 0px;
    width:100%;
    height: 100% !important;
  }
  .card-footer{
    position: relative;
    bottom: 0;
    border-top:0 ;
    
  }
  .container-publish{
    background: none;

  }
  .card-mm{
       padding-left:0px !important;
    padding-right:0px !important;
  }
}
</style>
