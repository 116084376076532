<template lang="">
    <div class="row">
        <div class="col-xl-8 col-xxl-8 col-lg-8">
            <CreateFeed></CreateFeed>
            
                
                 <div class="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3 mt-2">
                <Videos />
                </div>
            
        </div>
          <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0">
      <SuggestedAmis />
      
      <SuggestedPages />
    </div>
    </div>
</template>

<script>
import SuggestedAmis from "../Feeds/suggestedAmis.vue";
import SuggestedPages from "../Feeds/suggestedPages.vue";
import CreateFeed from "../Feeds/CreateFeed.vue";

import Videos from "./Videos.vue";
export default {
    name: "photos",
    components: {
      
        Videos,
        SuggestedAmis,
        SuggestedPages,
        CreateFeed
    },
};
</script>
<style lang=""></style>
