<template lang="">
    <div
        class="dropdown-menu dropdown-menu-end pe-4 ps-4 pt-2 pb-2 rounded-3 border-0 shadow-lg"
       
      >
        
             

  <template v-if="isNotifiReady">

      <div class="border-bottom-xs">
        <h4  class="fw-700 font-xss mb-2 d-i-b">Notifications ({{notifCount}})</h4>
        <a v-if="notifCount > 0" @click="markNotificationsAsRead" class="fw-600 ms-auto font-14 text-primary ps-4 pb-1 show-all float-right mark-as-read" >Mark all as read </a>
        
      </div>
       <div class="chat-left scroll-bar pt-2 w-100">
       
        <div v-for="(notif,index) in notifications" :key="notif.id" class="card bg-transparent-card w-100 border-0 mt-1 pt-1 notif-row" :class="notif.read_at == null ? 'unread':''" :ref="setNotifRef"
         @click.self="markNotificationAsRead(notif.id,index,notif.type,notif.data.visiteur.id)">
          <img
            :src="`${assetsBaseUrl}${notif.data.visiteur.photo}`"
            alt="user"
            class="w45 position-absolute left-0 rounded-3"
          />
           
            <span class="position-absolute icon-notif" v-if="notif.type == 'App\\Notifications\\commentNotification'">
            <i class="fas fa-comment"></i>
            </span>
            <span class="position-absolute icon-notif" v-else-if="notif.type == 'App\\Notifications\\storyNotification'">
            <i class="fas fa-photo-video"></i>
            </span>
            <span class="position-absolute icon-notif" v-else-if="notif.type == 'App\\Notifications\\postNotification'">
            <i class="fas fa-photo-video"></i>
            </span>
            <span class="position-absolute icon-notif" v-else-if="notif.type == 'App\\Notifications\\tagNotification'">
              <i class="fas fa-tag"></i>
            </span>
            <span class="position-absolute icon-notif" v-else-if="notif.type == 'App\\Notifications\\friendNotification'">
               <!-- <i class="fas fa-user-plus"></i>  -->
               <!-- if status = 0  -->
                
                
               <template v-if="notif.friendStatus == false">
               <div :ref="setBtnToggleFriendRef">
                

               <a v-tooltip.top-start="'Accept'" class="btn-accept btn-round-sm bg-white text-grey-900 fas fa-check font-xss mt-2 ms-5 me-1" @click.self="confirmFriend(notif.data.visiteur.id,index)"></a>
               <a v-tooltip.top-start="'Decline'" class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss mt-2" @click.self="declineFriend(notif.data.visiteur.id,index)"></a>
               </div>
               </template>
            </span>
            <span v-else-if="notif.type == 'App\\Notifications\\jaimeNotification'" class="position-absolute icon-notif">
              <i v-if="notif.data.jaime.reaction_type == 2 " class="fas fa-heart"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 5" class="fas fa-frown"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 4" class="fas fa-laugh"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 1" class="fas fa-thumbs-up"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 7" class="fas fa-thumbs-down"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 6" class="fas fa-angry"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 3" class="fas fa-surprise"></i>
            </span>
            <div class="ps-13">
          <h5 class="font-xsss text-grey-900 mb-1 mt-0 fw-700 d-block">
           {{notif.data.visiteur.prenom}} {{notif.data.visiteur.nom}}
            <span class="text-grey-600 font-xsssss fw-600 float-right mt-1 ms-2 me-1">
              {{ $filters.timeAgo(notif.created_at)}}</span
            >
          </h5>
          <h6 class="text-grey-500 fw-500 font-xssss lh-4">
            {{notif.data.message}}
          </h6>
          </div>
          
        </div>
         

        <div v-if="done"
        class="
            
            w-100
            text-center
            border-0
            p-2
            mb-1
            mt-1
            
        "
    >
        
           No other notifications
    </div>
    <div>
      <Loader class="mt-2" v-show="isVisible && !done"/> 
      </div>
        <div v-if="notifications.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div> 
        <div v-else class="card-body d-flex pt-2 ps-4 pe-4 pb-0 bor-0 col-xl-12 col-xxl-12 col-lg-12 text-center"> No results found</div> 
  </div>
  <router-link v-if="notifications.length > 5" to="/notifications" class="fw-600 ms-auto font-14 text-primary ps-4 pb-1 show-all float-right" >See all </router-link>
        
</template>
<template v-else>
<div class="border-bottom-xs">
        <h4  class="fw-700 font-xss mb-2 d-i-b">Notifications</h4>
        
      </div>
            <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 col-xl-12 col-xxl-8 col-lg-12">
             
             
                <Loader class="mt-1 mb-1"></Loader>
             </div>
</template>


  </div>
</template>
<script>
//import Pusher from 'pusher-js';
import Loader from "../loader/loader.vue";
import SkeletonComment from "../skeleton/SkeletonComment.vue";
export default {
  name: "vueNavBar",
  components: {
    Loader,
    SkeletonComment

  },
  data() {
    return {
      isNotifiReady: false,
      notifications: [],
      notifCount: 0,
      page: 1,
      lastPage: 1,
      done: false,
      isVisible: false,
      refreshNotif: false,
      notifRef: [],
      btnToggleFriendRef: [],
    };
  },
  mounted() {
    this.$store.dispatch("getuser");
    this.getNotifications();

    //this.subscribe()

  },
  // beforeUpdate(){
  //   this.notifRef = [];  
  //    let a = new Set(this.notifRef);
  //    this.notifRef = [...a];

  // },
  methods: {

    confirmFriend(id, i) {
      this.btnToggleFriendRef[i].remove();
      this.axios.post('amis/confimer', {
        visiteur_id: id
      }).then(res => {

      })

    },
    declineFriend(id, i) {
      this.btnToggleFriendRef[i].remove();
      this.axios.post('/amis/decline', {
        visiteur_id: id
      }).then(res => {

      })



    },

    setBtnToggleFriendRef(el) {
      if (el && !this.btnToggleFriendRef.includes(el)) {
        this.btnToggleFriendRef.push(el)
      }

    },
    setNotifRef(el) {
      if (el && !this.notifRef.includes(el)) {
        this.notifRef.push(el)
      }

    },
    visibilityChanged(isVisible) {
      this.notifRef = [];


      if (!isVisible) { return }
      if (this.page >= this.lastPage) { return this.done = true }
      this.isVisible = isVisible;

      this.page++;
      this.getNotifications();
      let a = new Set(this.notifRef);
      this.notifRef = [...a];


    },
    markNotificationsAsRead() {
      this.notifRef = [];
      this.axios.get('markNotificationsAsRead').then(res => {
        this.$emit('markAsReady');
        //this.getNotifications();
        this.notifRef.forEach(el => {
          el.classList.remove('unread');
        })
      })
    },
    markNotificationAsRead(notifId, i, type, userID) {
      //console.log(`${notifId} ${i} ${type} ${userID}`);
      if (type == 'App\\Notifications\\friendNotification') {
        this.$router.push(`/feedvisiteur/${userID}`)

      }

      this.notifRef = [];
      this.axios.post('markNotificationAsRead', {
        notif_ID: notifId
      }).then(res => {
        this.$emit('markOneAsReady', notifId);
        //this.getNotifications();
        this.notifRef[i].classList.remove('unread');

      })

    },
    getNotifications() {
      this.axios.get(`/notifications?page=${this.page}`).then(res => {
        this.notifications.push(...res.data.notifications.data);
        this.notifCount = res.data.unreadNotifCount;
        this.lastPage = res.data.notifications.last_page;
        this.isNotifiReady = true;
        //console.log(res.data)

      })
    },
    //  subscribe () {
    //    //Pusher.logToConsole = true;
    //   let pusher = new Pusher('80bcf26201ca31d02cc5', { cluster: 'mt1' })
    //   pusher.subscribe('trippybookNotifications')
    //   pusher.bind('BroadcastNotificationCreated', data => {
    //     //console.log('ooooooooooo'+JSON.stringify(data));
    //     this.notifications.unshift(JSON.stringify(data))
    //   })
    // },
    // initPusher(){
    // //Pusher.logToConsole = true;

    // var pusher = new Pusher('80bcf26201ca31d02cc5', {
    //   cluster: 'mt1'
    // });

    // var channel = pusher.subscribe('trippybookNotifications');
    // channel.bind('BroadcastNotificationCreated', function(data) {
    //   // app.messages.push(JSON.stringify(data));
    //   //console.log('ooooooooooo'+JSON.stringify(data));
    //   this.notifications.push(JSON.stringify(data));
    // });

    // },

  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-round-lg {
  line-height: 50px !important;
}

a {
  text-decoration: none !important;
}

.router-link-active.router-link-exact-active {
  i {
    color: #124698 !important;
    background-color: #d2e3ff !important;
    border-color: #bcd5fe !important;
  }
}

.btn-round-lg,
.rounded-xl,
.rounded-circle {
  border-radius: 10px !important;
}

.w400 {
  width: 40% !important;
}

.nav-header {
  z-index: 99 !important;
}

.right-search {
  &:hover {
    cursor: pointer;
    color: #2e58a6 !important;
  }

  left: 315px !important;
}

.btn-round-md {
  border-radius: 10px;
}

.dot-count {
  width: fit-content !important;
  height: 15px !important;
  min-width: 15px !important;
  color: #fff;
  font-weight: 700;
}

.d-i-b {
  display: inline-block;
}

.show-all {
  &:hover {
    text-decoration: underline !important;
  }

  color: #2e58a6 !important;
}

.unread {
  background-color: #efefef;
}

.border-bottom-xs {
  border-bottom: 1px #efefef solid;
}

.notif-row {
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer !important;
    background-color: #cfcfcf !important;
  }
}

.icon-notif {
  z-index: 9;
  bottom: 0;
  right: 5px;
}

.fa-comment,
.fa-photo-video,
.fa-tag,
.fa-user-plus {
  color: #2e58a6;
}

.fa-heart {
  color: red;
}

.fa-laugh,
.fa-frown,
.fa-angry,
.fa-surprise {
  color: rgb(223, 184, 12);
}

.fa-thumbs-up {
  color: #00f;
}

.fa-thumbs-down {
  color: rgb(255, 102, 0);
}

.w45 {
  width: 45px;
}

.dropdown-menu {
  display: inline-block;
  right: 10px;
  min-width: 20rem !important;
}

.scroll-bar {
  overflow-x: hidden;
}

.chat-left {
  height: calc(100vh - 400px) !important;
}

.mark-as-read {
  cursor: pointer
}

.btn-round-sm,
.btn-add,
.btn-accept,
.btn-decline {
  border-radius: 5px !important;
  height: 25px;
  width: 25px;
  line-height: 25px !important;
  margin-bottom: 5px;
  text-align: center;
  padding-bottom: 10px;
  transition: background-color .4s ease-in-out;

  &:hover {
    cursor: pointer;

    color: #fff !important;
  }
}

.btn-add {

  background-color: #2e58a6 !important;

}

.btn-decline {


  background-color: #f00 !important;

}

.btn-accept {

  background-color: rgb(43, 110, 43) !important;

}</style>
      