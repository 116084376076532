<template lang="">
  <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
    
       <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0">
         
      <h4 class="fw-700 mb-0 font-14 text-grey-900">Friends <span v-if="isFriendsReady">  ({{friends.length}}) </span></h4>
      </div>
      <template v-if="isFriendsReady">
      <div v-for="friend in friends" class="card-body d-flex pt-2 ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-2">
          
        <figure class="avatar me-2 mb-0" v-if="friend.sender.id != currentUser.id">
           <router-link v-tooltip.left-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.sender.id } }" >
         <img  :src="`${assetsBaseUrl}${friend.sender.photo}`" alt="image" class="shadow-sm rounded-circle w45"> 
         </router-link>
        </figure>
        <figure class="avatar me-2 mb-0" v-else>
          <router-link v-tooltip.left-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.receiver.id } }" >
         <img  :src="`${assetsBaseUrl}${friend.receiver.photo}`" alt="image" class="shadow-sm rounded-circle w45"> 
         </router-link>
        </figure>
        <template  v-if="friend.sender.id != currentUser.id">
          <h4  class="fw-700 text-grey-900 font-xssss mt-1 link-user">
              <router-link v-tooltip.top-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.sender.id } }" >{{friend.sender.nom}}
                </router-link>
                <span v-if="friend.mutualFriend > 0" class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{friend.mutualFriend}} mutual friends</span>
              </h4>
             
        </template>
        <template v-else>
              <h4  class="fw-700 text-grey-900 font-xssss mt-1 link-user">
              <router-link v-tooltip.top-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.receiver.id } }" >{{friend.receiver.nom}}
                </router-link>
                <span v-if="friend.mutualFriend > 0" class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{friend.mutualFriend}} mutual friends</span>
              </h4>
         
        </template>
        
       </div>
      
              
      
      <a href="#" class="fw-600 ms-auto font-14 text-primary pe-3 pb-1 show-all" v-if="friends.length > 3">Show all</a>
    </template>
    <template v-else>
      <div class="pb-4 pt-4 border-top-xs">
      <Loader></Loader>
      </div>

    </template>
  </div>
 
</template>
<script>
import Loader from "../../layouts/loader/loader.vue"
export default {
  name: "amis",
   components:{
    Loader,
    
  },
  data(){
    return{
      friends:[],
      isFriendsReady:false


    }
  }, 
  methods:{
    getFriends(){
      this.isFriendsReady=false;
      this.axios.get('/amis')
      .then(res=>{
        this.friends = res.data.amis;
        this.isFriendsReady=true;
        console.log(this.friends)
    })
    },

  }, 
  
  mounted(){
    this.getFriends();

  },
  computed: {
          currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.rounded-circle{
 border-radius:10px !important
}
.show-all{
  &:hover{
    text-decoration: underline !important;
  }
  color:#2e58a6 !important
}
.link-user a{
  color:#000 !important;

&:hover{
  color:#2e58a6 !important;
  text-decoration: underline !important
}
}
</style>
