<template lang="">
    <template v-if="isReady">
     <template v-if="!adresse.google_place_id && adresse.google_place_id == null">
        <router-link :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }" class="card w200 h100 d-block border-0 shadow-xss overflow-hidden cursor-pointer">
       <img :src="`${assetsBaseUrl}${adresse.logo}`" alt="image" class="img-adrs avatar">
        </router-link>
       </template>
       <template v-else>
            <router-link :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }" class="card w200 h100 d-block border-0 shadow-xss overflow-hidden cursor-pointer">
       <img :src="`${assetsBaseUrl}${adresse.logo}`" alt="image" class="img-adrs avatar">
        </router-link>
       </template>
        
        <div class="p-1 infAdresse">
             <h4 class="fw-700 font-xss mb-0 adress-txt">
                 <router-link :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }" >
                 {{adresse.rs}}
                 </router-link>
                 </h4>
             
             <div class="star d-block text-left mt-0 mb-1 col-md-8 w-100 ps-4">
                 
               <div class="star d-block w-100 text-left mt-0">
                <router-link class="ms-4" :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
                    <div class="rating-color d-i-b" v-html="drawStarsYellow(roundSumRate)"></div>
                    <div class="d-i-b rating-grey" v-html="drawStarsGrey(roundSumRate)"></div>
                </router-link>
              
              </div>
              <router-link class="ms-4" :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
              <div class="couts-color d-i-b ms-2" v-html="drawDollarsGreen(roundSumCouts)"></div>
              <div class="d-i-b rating-grey ms-2" v-html="drawDollarsGrey(roundSumCouts)"></div>
              </router-link>
              </div>
              <!-- <div class="text-left mt-0 mb-1 col-md-4
              "> {{adresse.categorie.nom_categorie}}
              </div> -->
             
            
            <div class="fw-500 font-xssss text-grey-500 mt-0 w-100 col-md-12 adress-txt">{{adresse.adresse}}</div> 
            
            <div class="fw-500 font-xssss text-grey-500 mt-0">{{adresse.tel}}</div>
        </div>
           </template>                      
</template>
<script>

export default {
    data(){
        return {
            roundSumRate:0,
            roundSumCouts:0,
            isReady:false,
            adresse:{} 
        }
    },
    props:{
        adresseId:Number,
    },
    mounted(){
        this.getAdresse();
    },
    methods:{
            drawStarsYellow(count){
            return `<i class="fa fa-star rating-color"></i>`.repeat(count)
            },
            drawStarsGrey(count){
                return `<i class="fa fa-star"></i>`.repeat(5-count)
            },
            drawDollarsGreen(count){
                return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count)
            },
            drawDollarsGrey(count){
                return `<i class="fas fa-dollar-sign"></i>`.repeat(5-count)
            },
            getAdresse(){
            this.axios.get(`adresse/${this.adresseId}`).then(res=>{
                this.adresse = res.data.adresse ;
                this.isReady = true;
                this.roundSumRate = res.data.roundSumRate;
                this.roundSumCouts = res.data.roundSumCouts;
                //console.log(res.data);
            }).catch(error=>{

            })
        }

    },
      computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
      }

    
    
}

</script>
<style lang="scss" scoped>
.gm-style-iw-d{
    
    overflow: hidden  !important;

    max-height:150px !important;
}
.avatar {
    width:100% !important;
    overflow: hidden  !important;
}
.image-container{
    max-height: 150px !important;
}
.img-adrs{
   width:100% !important;
   height:100% !important;
   overflow:hidden !important;

}
.gm-style {
    padding:0 !important
}
.gm-ui-hover-effect{
    color:white  !important
}
.gm-style-iw-c{
   padding: 0 !important;
}
.infAdresse{
    text-align: center;
}
.img-adrs{
    overflow:hidden
}
.rate-total-count{
    transition: all .4s ease-in-out;
    &:hover{
        cursor:pointer;
        color:#2e58a6;
        font-weight: bold;
    }
}
.adress-txt{
    inline-size: 180px !important;
    overflow-wrap: break-word !important;
    text-align: center;
    margin: 0 auto !important;
}
.rating-color {
    color: #fbc634 !important
}
.rating-grey{
  color:#d2e3ff !important
}
.d-i-b {
    display:inline-block !important
  }
  .infAdresse h4 a{
      color:black !important;
      transition: all .4s ease-in-out;
      &:hover{
          color:#2e58a6 !important;
      }

  }
.ms-4 {
    margin-left: 2.25rem !important;
}
</style>