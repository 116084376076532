<template lang="">
    <template v-if="isAdressesReady">
         <template v-if="adresses.length == 0">
          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1
            "
          >
           <div class="card-body pt-4 pb-4 border-0 rounded-3">
            No result found
             
             
              <div class="clearfix"></div>
             
            </div>
            <div class="card-image w-100 mb-3 border-0">
              <a href="#"
              class="position-relative d-block w-100 border-0">
                 
              </a>
            </div>
           
            
         </div> 
        </template>  
   <div v-for="adresse in adresses" :key="keyAdresse" class="col-lg-12 col-md-12 col-sm-6 pe-2 ps-2 adr">

          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              
              rounded-3
              overflow-hidden
              me-1
              col-lg-3
              d-i-b
            "
          >
            <div class=" mb-1 mt-1 d-i-b col-md-3 border-0 ">
              <router-link  :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }"
              class="position-relative d-block">
                <img
                  :src="`${assetsBaseUrl}${adresse.logo}`"
                  alt="image"
                  class="w-img"
                />
              </router-link>
            </div>

            <div class="card-body pt-2 d-i-b col-md-8">
              <h4 class="fw-700 font-xss mt-0 lh-28 mb-0">
                <router-link :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }" class="text-dark text-grey-900">{{adresse.rs}}</router-link>
              </h4>
              <h6 class="font-xsssss text-grey-500 fw-600 mt-0 mb-2">
                {{adresse.adresse}}
              </h6>
              <div class="star d-block w-100 text-left mt-0">
                <img
                  :src="`${assetsBaseUrl}images/star.png`"
                  alt="star"
                  class="w15  float-left"
                />
                <img
                  :src="`${assetsBaseUrl}images/star.png`"
                  alt="star"
                  class="w15  float-left"
                />
                <img
                  :src="`${assetsBaseUrl}images/star.png`"
                  alt="star"
                  class="w15  float-left"
                />
                <img
                  :src="`${assetsBaseUrl}images/star.png`"
                  alt="star"
                  class="w15  float-left"
                />
                <img
                  :src="`${assetsBaseUrl}images/star-disable.png`"
                  alt="star"
                  class="w15 me-1 float-left"
                />
              </div>
               <div class="clearfix"></div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
              
              <i class="fas fa-dollar-sign font-xssss"></i> -
              <i class="fas fa-dollar-sign font-xssss"></i>
              <i class="fas fa-dollar-sign font-xssss"></i>
              
              </div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
                <template v-for="serv in adresse.services">

                  <span v-html="serv.icone"></span>
                  
                </template>
              </div>

             
             
            </div>  
         </div> 


        </div>
  </template>
        <template v-else>
          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1
            "
          >
            <div class="card-image w-100 mb-3">
              <a href="#"
              class="position-relative d-block w-100">
                 <Skeleton></Skeleton>
              </a>
            </div>
            <div class="card-body pt-0">
             <Loader></Loader>
             
             
              <div class="clearfix"></div>
             
            </div>
            
         </div> 
        </template>
</template>

<script>

import Loader from "../../../layouts/loader/loader.vue"
import Skeleton from "../../../layouts/skeleton/SkeletonAdresse.vue"
export default {
  name: "MesAdrresses",
  components: {
 
    Loader,
    Skeleton
    
    
  },
  data() {
    return {
      isShowModal: false,
      isAdressesReady: false,
      adresses: [],
      keyAdresse:true,
    };
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted(){
    this.getMesAdresses();
  },

  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    
  },

  methods: {
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
    getMesAdresses() {
      this.axios.get(`mesAdresses`).then((res) => {
        this.adresses = res.data.adresses;
        this.isAdressesReady = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.right-15 {
  right: 4px !important;
}
.bottom-15 {
  bottom: 0px !important;
}
.addAdresseBtn {
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
  &:hover {
    cursor: pointer;
    color: blue;
  }
}
.router-link-active.router-link-exact-active {
  
  &:hover{
       border-bottom: 2px#666 solid !important;
    color: #000 !important;

  }
    
    border-bottom: 2px#333 solid !important;
    color: #000 !important;
   
}
.nav-tabs li a {
    &:hover{
        border-bottom: 2px#aaa solid !important;
    color: #000 !important;
    }
    transition: all 0.1s ease-in-out !important;
 }
 .w-img{
  width:100% !important;
  vertical-align:bottom !important;
  //text-align:center;
  //left:20%;
}
.icon-tab{
  display: inline-block;
}
.d-i-b {
    display:inline-block !important
  }

</style>
