<template lang="">

    
<link href="https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" rel="stylesheet" />
         <!-- -----------------------------(Visitor Reviews)---------------------------------- -->



                                   
    <div class="row p-4">
    <div class="col-md-4">
    <div class="card p-3 review-visitor">
        <div class="justify-content-between align-items-center">
            <h5 v-if="!isGoogleRated" @click="getRates(0)"  v-tooltip.top-start="'Show all'" class="review-count">{{ratesAll.length}} Visitor Reviews</h5>
             <h5 v-else @click="getRates(0)"  v-tooltip.top-start="'Show all'" class="review-count">{{ratesAll.length + 1}} Visitor Reviews</h5>
            <div v-if="rates.length > 0 || isGoogleRated" class="ratings mt-2"> 
            <div class="rating-color d-i-b" v-html="drawStarsYellow(noteTotale)"></div>
            <div class="d-i-b grey-star" v-html="drawStarsGrey(noteTotale)"></div> {{noteTotale}}/5 </div>
            
        </div>
       
        <div v-if="ratesAll.length > 0" class="justify-content-between align-items-center">
            <!-- <h5 class="review-stat d-i-b">1</h5> -->
            <div class="small-ratings d-i-b"> 
            <i class="fa fa-star rating-color"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i> <span v-if="one.length > 0" @click="getRates(1)" v-tooltip.right-start="'Show all'" class="review-detail-count">({{one.length}})</span>  
            <span v-else class="">({{one.length}}) </span> 
            </div>
        </div>
       
        <div v-if="ratesAll.length > 0" class="justify-content-between align-items-center">
            <!-- <h5 class="review-stat d-i-b">2</h5> -->
            <div class="small-ratings d-i-b"> 
            <i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star"></i><i class="fa fa-star"></i><i class="fa fa-star"></i> <span v-if="two.length > 0" @click="getRates(2)" v-tooltip.right-start="'Show all'" class="review-detail-count">({{two.length}})</span>
             <span v-else class="">({{two.length}})</span>   
            </div>
        </div>
        
        <div v-if="ratesAll.length > 0" class="justify-content-between align-items-center">
            <!-- <h5 class="review-stat d-i-b">3</h5> -->
            <div class="small-ratings d-i-b"> 
            <i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star"></i><i class="fa fa-star"></i> <span v-if="three.length > 0" @click="getRates(3)" v-tooltip.right-start="'Show all'" class="review-detail-count">({{three.length}})</span>
            <span v-else class="">({{three.length}})</span>     
            </div>
        </div>
         <div v-if="ratesAll.length > 0" class="justify-content-between align-items-center">
            <!-- <h5 class="review-stat d-i-b">4</h5> -->
            <div class="small-ratings d-i-b"> 
            <i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star"></i> <span v-if="four.length > 0" @click="getRates(4)" v-tooltip.right-start="'Show all'" class="review-detail-count">({{four.length}})</span>
            <span v-else class="">({{four.length}})</span>   
            </div>
        </div> 
        <div v-if="ratesAll.length > 0" class="justify-content-between align-items-center">
            <!-- <h5 class="review-stat d-i-b">5</h5> -->
            <div class="small-ratings d-i-b"> 
            <i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i><i class="fa fa-star rating-color"></i> <span v-if="five.length > 0" @click="getRates(5)" v-tooltip.right-start="'Show all'" class="review-detail-count">({{five.length}})</span> 
            <span v-else class="">({{five.length}})</span>  
            </div>
        </div>
    </div>
    </div>

    




         <!-- ------------------------------(rate us)--------------------------------- -->
                               
        <div class="card-body ps-2 mt-0 col-md-8 d-flex">
            <div class="row">
        <div class="col-md-6">
        <span class="rate-us" > Rate us <span ref="rateUs">*</span></span>        
        <div class="rate-comment-flex">
        <star-rating 
             @update:rating ="setRating"
             v-bind:increment="1"
             v-bind:max-rating="5"
             inactive-color="#d8d8d8"
             active-color="#ffd055"
             :show-rating="false"
             v-bind:star-size="20"
             :clearable="true"
             :rating="currentRate"
              v-tooltip.top-start="'*****'">
        </star-rating>
        <!-- <button @click="setRating(0)">Reset Rating</button> -->
        
        </div>

        
        </div>
        <div  class="col-md-6">
        <span class="rate-us"> Costs</span> 
        <div>
            <!-- <i class="fas fa-dollar-sign"></i><i class="fas fa-dollar-sign"></i><i class="fas fa-dollar-sign"></i><i class="fas fa-dollar-sign"></i><i class="fas fa-dollar-sign"></i> -->

            <select v-tooltip.top-start="'............'" class="fa form-control input-comment" v-model.number="couts">
                <option value="0" disabled>.....</option>
                 <option value="1" :selected="`${couts}` == 1 ? 'selected':''">&#xf155;</option>
                  <option value="2" :selected="`${couts}` == 2 ? 'selected':''">&#xf155; &#xf155;</option>
                   <option value="3" :selected="`${couts}` == 3 ? 'selected':''">&#xf155; &#xf155; &#xf155;</option>
                    <option value="4" :selected="`${couts}` == 4 ? 'selected':''">&#xf155; &#xf155; &#xf155; &#xf155;</option>
                     <option value="5" :selected="`${couts}` == 5 ? 'selected':''">&#xf155; &#xf155; &#xf155; &#xf155; &#xf155;</option>
              
            </select>
            </div>
        </div>
       </div>
        <div  class="input-comment-rate mt-3">
            <input class="form-control " type="text" placeholder="Write a comment" v-model.trim="commentRate">
        </div>
        <div  class="col-md-2 mt-1 div-send">
        <a @click="addRate" class="text-center p-2 lh-24 ms-1 ls-3 d-inline-block bg-grey font-xssss fw-700 ls-lg button-send" ref="buttonSend">
            <span v-if="!isRated">Send</span>
            <span v-else>Edit</span>
         </a>
        </div>
        <div class="comments mt-2 pt-1">
            <span class="comments-count"  v-tooltip.bottom-start="'Show all'">
             Reviews ({{rates.length}})
            </span>
            <ul v-if="isRateReady" class="email-message mt-2">
            <li v-for="rate in rates" class="rate-row">
             <div class="d-flex comment-flex p-1">
                <div class="email-user">                                
                    <img :src="`${assetsBaseUrl}${rate.visiteur.photo}`" alt="user" class="w35 me-2 tof-profil">      
                </div>
                <h6 class="font-xssss text-grey-900 text-grey-900 mb-0 mt-0 fw-700 pt-2 me-1 user-name">{{rate.visiteur.nom}}</h6>
                <div class="email-subject text-grey-900 text-dark fw-600 font-xssss pt-1 me-1 user-name">
                    
                    <div class="rating-color d-i-b" v-html="drawStarsYellow(rate.note)"></div>
                    <div class="d-i-b" v-html="drawStarsGrey(rate.note)"></div>

                    
                                                
                </div>
                <div class="email-subject text-grey-900 text-dark fw-600 font-xssss pt-1 me-1 user-name ">
                     <div class="dollars d-i-b" v-html="drawDollarsGreen(rate.couts)"></div>
                    <div class="d-i-b" v-html="drawDollarsGrey(rate.couts)"></div>
                </div>
                <div class="email-time text-grey-500 fw-600 font-xssss pt-1">
                     {{ $filters.timeAgo(rate.updated_at)}}
                   
                    </div>
                </div>
               <div class="d-flex comment-flex p-1">                         
              
               <div v-if="rate.comment_avis != null" class="email-text text-grey-500 fw-600 font-xssss col-md-12 ms-5">
                   {{rate.comment_avis}}
                </div>
                 </div>
                </li>                        
                

            </ul>
            <ul v-else>
                    <Loader></Loader>
            </ul>

            </div>





        </div>

</div>

     
</template>
<script>
import StarRating from "vue-star-rating";
import Loader from "../../../layouts/loader/loader.vue";
export default {
  components: {
    StarRating,
    Loader,
  },
  data() {
    return {
      rateGoogle: 0,
      isGoogleRated: false,
      rating: 0,
      isRated: false,
      commentRate: null,
      rates: [],
      ratesAll: [],
      couts: 0,
      isRateReady: false,
      noteTotale: 0,
      one: [],
      two: [],
      three: [],
      four: [],
      five: [],
      currentUserRate: [],
      currentRate: 0,
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted() {
    this.$nextTick(() => {
      this.getRates(0);

      //console.log(this.currentRate);
    });
  },
  methods: {
    drawStarsYellow(count) {
      return `<i class="fa fa-star rating-color"></i>`.repeat(count);
    },
    drawStarsGrey(count) {
      return `<i class="fa fa-star"></i>`.repeat(5 - count);
    },
    drawDollarsGreen(count) {
      return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count);
    },
    drawDollarsGrey(count) {
      return `<i class="fas fa-dollar-sign"></i>`.repeat(5 - count);
    },

    setRating(rating) {
      this.$refs.rateUs.classList.remove("required");
      this.rating = rating;
      //   this.isRated = true;
      if (rating == 0) {
        this.isRated = false;
        //delete rate
      }
    },
    addRate() {
      if (
        this.$refs.buttonSend.hasAttribute("data") ||
        this.currentUserRate != false
      ) {
        if (this.rating == 0) {
          this.rating = this.currentUserRate;
        }

        this.axios
          .put("rate", {
            visiteurId: this.currentUser.id,
            adresseId: this.$route.params.id,
            commentRate: this.commentRate,
            note: this.rating,
            couts: this.couts,
          })
          .then((res) => {
            this.getRates();
          });
      } else {
        if (this.rating == 0 || !this.rating) {
          this.$refs.rateUs.classList.add("required");
        } else {
          this.isRated = true;
          this.$refs.buttonSend.setAttribute("data", "toBeUpdated");

          this.axios
            .post("rate", {
              visiteurId: this.currentUser.id,
              adresseId: this.$route.params.id,
              commentRate: this.commentRate,
              note: this.rating,
              couts: this.couts,
            })
            .then((res) => {
              this.getRates();
            });
        }
      }
    },
    getRates(type) {
      this.isRateReady = false;
      this.axios
        .get(`ratesByAdress/${this.$route.params.id}`)
        .then((res) => {
          this.ratesAll = res.data.avis;
          //console.log(res.data);
          switch (type) {
            case 1:
              this.rates = res.data.one;
              break;
            case 2:
              this.rates = res.data.two;
              break;
            case 3:
              this.rates = res.data.three;
              break;
            case 4:
              this.rates = res.data.four;
              break;
            case 5:
              this.rates = res.data.five;
              break;
            default:
              this.rates = res.data.avis;
          }

          this.noteTotale = res.data.noteTotal;
          this.isRateReady = true;
          this.one = res.data.one;
          this.two = res.data.two;
          this.three = res.data.three;
          this.four = res.data.four;
          this.five = res.data.five;
          this.isRated = res.data.isRated;
          this.currentUserRate = res.data.currentUserRate;
          this.commentRate = res.data.currentUserRate.comment_avis;
          // this.rating = res.data.currentUserRate.note;
          this.couts = res.data.currentUserRate.couts;
          this.currentRate = res.data.currentUserRate.note;
          this.isGoogleRated = res.data.isGoogleRated;
          this.rateGoogle = res.data.rateGoogle;
          //console.log(res.data);
          //console.log(res.data.currentUserRate.note);
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.ratings {
  font-size: 18px !important;
}

.ratings i {
  color: #cecece;
}

.rating-color {
  color: #fbc634 !important;
}

.review-count {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #2e58a6 !important;
  }
  transition: all 0.4s ease-in-out;
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 15px !important;
}

.small-ratings i {
  color: #cecece;
  font-size: 12px;
}

.review-stat {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 2px;
}
.review-visitor {
  //min-width: 180px !important;
  border-radius: 10px !important;
}
.d-i-b {
  display: inline-block !important;
}
.ratings i {
  font-size: 20px;
}
.d-flex {
  flex-direction: column;
}
.rate-us {
  color: black;
  font-weight: bold;
}
.comments {
  border-top: 1px solid #d8d8d8;
}
.comments-count {
  color: black !important;
  transition: all 0.4s ease-in-out;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #2e58a6 !important;
  }
}
.rate-comment-flex {
  display: flex;
  flex-direction: row !important;
}
.form-control {
  border-radius: 10px !important;

  &:focus {
    border-color: #2e58a6 !important;
  }
}
.review-detail-count {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    color: #2e58a6 !important;
  }
  transition: all 0.4s ease-in-out;
}
.tof-profil {
  border-radius: 10px;
}
.comment-flex {
  flex-direction: row !important;
  transition: all 0.4s ease-in-out;
  border-radius: 10px;
}
.email-subject {
  color: #cecece !important;
}
.grey-star {
  color: #cecece !important;
}
.button-send {
  border-radius: 10px;
  color: #000;
  border: 1px solid #2e58a600;
  transition: all 0.4s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #2e58a6;
    border: 1px solid #2e58a6;
  }
}
.div-send {
  align-self: flex-end;
}
.fa {
  font-family: "FontAwesome", "sans-serif";
}
.user-name {
  width: 25%;
}
.input-comment {
  height: 40px;
}
.rate-row {
  border-bottom: 1px solid #d8d8d87a;
}
.dollars {
  color: #498a14d5;
}
.required {
  color: red;
}
</style>