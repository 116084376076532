<template lang="">
  <div>
    <!-- navigation top-->
    <div class="nav-header bg-white shadow-xs border-0">
      <div class="nav-top ">
         <router-link to="/feeds" class=" me-1" >
          <!-- <img :src="`${assetsBaseUrl}LogoTrippy/logo.png`" class="display1-size w-100-m">   -->
          <div class="logo-nav-bar display1-size w-100-m w-100"></div> 
        </router-link>
        <router-link to="/feeds" class="mob-menu ms-auto chat-active-btn me-1"
          ><i
            class="feather-home text-grey-900 font-sm btn-round-md bg-greylight ic-m"
          ></i
        ></router-link>

        <a @click="toggleShearchMobile" class="mob-menu me-1 "
          >  <i v-tooltip.bottom-start="'Notifications'" class="feather-search text-grey-900 font-sm btn-round-md bg-greylight ic-m"></i
      ></a>
 
      <form ref="searchMobile" @submit.prevent="findFriendAdresses" action="#" class="float-left header-search mob-menu search-mobile hide-search">
                <div class="form-group mb-0 icon-input">
                    
                    <input ref="searchMobileInput" v-model.trim="NavBarSearchKey" @keyup.enter="findFriendAdresses" type="text" placeholder="Find friends, adresses.." class="bg-greylight border-0 lh-32 pt-2 pb-2 ps-3 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg">
                    <i @click="findFriendAdresses"  v-tooltip.bottom-start="'Search'" class="feather-search font-sm text-grey-400 right-search"></i>
                </div>
        </form>
  

      <!-- <router-link to="/adressesaproximite" class="mob-menu me-1 "
          >  <i v-tooltip.bottom-start="'Notifications'" class="feather-bell text-grey-900 font-sm btn-round-md bg-greylight"></i
      ></router-link> -->
      
      <router-link to="/notifications" class="mob-menu chat-active-btn me-1"
          ><i
            class="feather-bell text-grey-900 font-sm btn-round-md bg-greylight ic-m"
          ></i
        ></router-link>
      <!-- <a
        @click="toggleModalNotifications"
        class="mob-menu me-1"
        ><i v-tooltip.bottom-start="'Notifications'" class="feather-bell text-grey-900 font-sm btn-round-md bg-greylight ic-m"></i
      ></a>  -->



      
        <router-link to="/adressesaproximite" class="mob-menu me-1"
          ><i
            class="
              feather-globe
              text-grey-900
              font-sm
              btn-round-md
              bg-greylight
              ic-m
            "
          ></i
        ></router-link>
        <router-link :to="{ name: 'feedvisiteur', params: { id: `${currentUser.id}` } }" class="me-2 menu-search-icon mob-menu"
          ><i
            class="
              feather-user
              text-grey-900
              font-sm
              btn-round-md
              bg-greylight
              ic-m
            "
          ></i
        ></router-link>
        <button class="nav-menu me-0"></button>
      </div>

      
        <form @submit.prevent="findFriendAdresses" action="#" class="float-left header-search">
                <div class="form-group mb-0 icon-input">
                    
                    <input v-model.trim="NavBarSearchKey" @keyup.enter="findFriendAdresses" type="text" placeholder="Find friends, adresses.." class="bg-greylight border-0 lh-32 pt-2 pb-2 ps-3 pe-3 font-xssss fw-500 rounded-xl w350 theme-dark-bg">
                    <i @click="findFriendAdresses"  v-tooltip.bottom-start="'Search'" class="feather-search font-sm text-grey-400 right-search"></i>
                </div>
            </form>
      
      <router-link
        to="/feeds"
        title="fil d'actualite"
        class="p-2 text-center ms-3 menu-icon center-menu-icon"
        ><i v-tooltip.bottom-start="'Feeds'"
          class="
            feather-home
            font-lg
            bg-greylight
            btn-round-lg
            theme-dark-bg
            text-grey-500
          "
        ></i
      ></router-link>
      <router-link
        :to="{ name: 'photos', params: { id: `${currentUser.id}` } }"
        
        class="p-2 text-center ms-0 menu-icon center-menu-icon"
        ><i v-tooltip.bottom-start="'My photos'"
          class="
            feather-image
            font-lg
            bg-greylight
            btn-round-lg
            theme-dark-bg
            text-grey-500
          "
        ></i
      ></router-link>
      <router-link
        :to="{ name: 'videos', params: { id: `${currentUser.id}` } }"
        
        class="p-2 text-center ms-0 menu-icon center-menu-icon"
        ><i v-tooltip.bottom-start="'My videos'"
          class="
            feather-video
            font-lg
            bg-greylight
            btn-round-lg
            theme-dark-bg
            text-grey-500
          "
        ></i
      ></router-link>
      <router-link
        to="/adressesaproximite"
        class="p-2 text-center ms-0 menu-icon center-menu-icon"
        ><i v-tooltip.bottom-start="'Nearby addresses'"
          class="
            feather-globe
            font-lg
            bg-greylight
            btn-round-lg
            theme-dark-bg
            text-grey-500
          "
        ></i
      ></router-link>
      <router-link
        :to="{ name: 'feedvisiteur', params: { id: `${currentUser.id}` } }"
       
        class="p-2 text-center ms-0 menu-icon center-menu-icon"
        ><i v-tooltip.bottom-start="'My profile'"
          class="
            feather-user
            font-lg
            bg-greylight
            btn-round-lg
            theme-dark-bg
            text-grey-500
          "
        ></i
      ></router-link>
<div class="p-2 text-center ms-auto menu-icon">


     
    <v-badge :content="notifCount" color="warning" :offset-x="9" :offset-y="9">
       <a
        @click="toggleModalNotifications"
        class="p-2 text-center ms-auto menu-icon btn-show-notif"
        ><i v-tooltip.bottom-start="'Notifications'" class="feather-bell font-xl text-current"></i
      >
   </a> 
</v-badge>

 

      <!-- **************************************************************** -->

  <Notifications v-if="isShowModalNotifications" @markAsReady="markNotificationsAsRead"
    @markOneAsReady="markNotificationAsRead"></Notifications>
  </div>
  
      

      <router-link :to="{ name: 'feedvisiteur', params: { id: `${currentUser.id}` } }" id="profile" class="p-0 ms-3 menu-icon">
        <img v-tooltip.bottom-start="'My profile'"
          v-if="currentUser.photo"
          :src="`${assetsBaseUrl}${currentUser.photo}`"
          alt="user"
          class="w40 mt--1 rounded-circle"
      /></router-link>
    </div>

    <!-- navigation top -->
  </div>
</template>
<script>
//import Pusher from 'pusher-js';
import Notifications from '../layouts/notifications/notificationModal.vue'
export default {
  name: "vueNavBar",
  components:{
    Notifications
  },
  data() {
    return {
      NavBarSearchKey: "",
      lastPageAdresse: 1,
      lastPagePeople: 1,
      pageAdresse: 1,
      pagePeople: 1,
      notifications:[],
      notifCount:0,
      isShowModalNotifications:false
      
    };
  },
  mounted() {
    this.$store.dispatch("getuser");
    this.getNotifications();
  
    //this.subscribe()
   
  },
  methods: {
    toggleShearchMobile() {
      this.$refs.searchMobile.classList.toggle('hide-search');
      //console.log(this.$refs.searchMobileInput);
      this.$refs.searchMobileInput.focus();
      
    },
     markNotificationsAsRead(){
        this.axios.get('markNotificationsAsRead').then(res => {
         
          this.getNotifications();
         
        })
      
      },
      markNotificationAsRead(notifId){
         this.axios.post('markNotificationAsRead',{
          notif_ID:notifId
        }).then(res => {
         
          this.getNotifications();
         
        })
      
      },
     toggleModalNotifications(){

      this.isShowModalNotifications = !this.isShowModalNotifications;
      

    },
    getNotifications(){
      this.axios.get(`/notifications`).then(res => {
        
      
        this.notifCount = res.data.unreadNotifCount;
      

      })
    },
    //  subscribe () {
    //    Pusher.logToConsole = true;
    //   let pusher = new Pusher('80bcf26201ca31d02cc5', { cluster: 'mt1' })
    //   pusher.subscribe('trippybookNotifications')
    //   pusher.bind('BroadcastNotificationCreated', data => {
    //     console.log('ooooooooooo'+JSON.stringify(data));
    //     this.notifications.unshift(JSON.stringify(data))
    //   })
    // },
    // initPusher(){
    // Pusher.logToConsole = true;

    // var pusher = new Pusher('80bcf26201ca31d02cc5', {
    //   cluster: 'mt1'
    // });

    // var channel = pusher.subscribe('trippybookNotifications');
    // channel.bind('BroadcastNotificationCreated', function(data) {
    //   // app.messages.push(JSON.stringify(data));
    //   console.log('ooooooooooo'+JSON.stringify(data));
    //   this.notifications.push(JSON.stringify(data));
    // });

    // },
    findFriendAdresses() {
      this.$refs.searchMobile.classList.add('hide-search');
      this.$nextTick(() => {
        if (this.NavBarSearchKey != "") {
          this.$store.dispatch("NavBarSearchKey", this.NavBarSearchKey);

          this.$store.dispatch("getSearchResult", {
            NavBarSearchKey: this.NavBarSearchKey,
            pageAdresse: this.pageAdresse,
            pagePeople: this.pagePeople,
          });
          this.$router.push("/searchResultAll");
        }
      });
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    // notifCount() {
    //   return this.$store.state.notifCount;
    // },
    // notifications() {
    //   return this.$store.state.notifications;
    // },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-round-lg {
  line-height: 50px !important;
}
a {
  text-decoration: none !important;
}

.router-link-active.router-link-exact-active {
  i {
    color: #124698 !important;
    background-color: #d2e3ff !important;
    border-color: #bcd5fe !important;
  }
}
.btn-round-lg,
.rounded-xl,
.rounded-circle {
  border-radius: 10px !important;
}
.w400 {
  width: 40% !important;
}
.nav-header {
  z-index: 99 !important;
}
.right-search {
  &:hover {
    cursor: pointer;
    color: #2e58a6 !important;
  }
  left: 315px !important;
}
.btn-round-md {
  border-radius: 10px;
}
.dot-count {
  width: fit-content !important;
  height: 15px !important;
  min-width: 15px !important;
  color: #fff;
  font-weight: 700;
}
.d-i-b {
  display: inline-block;
}
.show-all {
  &:hover {
    text-decoration: underline !important;
  }
  color: #2e58a6 !important;
}
.unread {
  background-color: #efefef;
}
.border-bottom-xs {
  border-bottom: 1px #efefef solid;
}
.notif-row {
  transition: all 0.2s ease-in-out;
  &:hover {
    cursor: pointer !important;
    background-color: #cfcfcf !important;
  }
}
.icon-notif {
  z-index: 9;
  bottom: 0;
  right: 5px;
}

.fa-comment,
.fa-photo-video,
.fa-tag,
.fa-user-plus {
  color: #2e58a6;
}
.fa-heart {
  color: red;
}
.fa-laugh,
.fa-frown,
.fa-angry,
.fa-surprise {
  color: rgb(223, 184, 12);
}
.fa-thumbs-up {
  color: #00f;
}
.fa-thumbs-down {
  color: rgb(255, 102, 0);
}
.w45 {
  width: 45px;
}
.btn-show-notif{
  cursor:pointer
}
.logo-nav-bar{
  background: url('https://ressources.trippybook.com/assets/LogoTrippy/logo.png') no-repeat center;
}
.display1-size{
  height: 90px;
}
@media (max-width: 767px) {


  .hide-search{
    // display : none !important;
    visibility: hidden !important;
  }
.search-mobile{
  position: absolute;
  top:100%
}
.ic-m{

    width: 35px;
    height: 35px;    
    line-height: 35px !important;
    background-color : transparent !important
   

  }
.w-100-m{
  width:60px !important
}

.logo-nav-bar{
  background: url('https://ressources.trippybook.com/assets/LogoTrippy/logo45.png') no-repeat center;
  height: 50px;
  width:60px 
}
.display1-size{
  height: 50px;
}
}
</style>
      