<template lang="">
<ModalMutualFriend v-if="isShowModalMutualFriend" @close="toggleModalCloseMutualFriend" :friendId="friendId"></ModalMutualFriend>
<div v-if="!isFriendsReady">

  <Loader></Loader>
</div>  

<div v-else>

          
     
            <template v-if="friends.length">
             
                  <h2>Profiles ({{searchResults.visiteurs.total}}) : </h2>
                  <div class="chat-left scroll-bar pt-2 w-100">
                   <div class="row">
                      <div v-for="(friend,index) in friends" class="card-body d-flex pt-2 ps-4 pe-1 pb-0 border-top-xs bor-0 col-xl-6 col-xxl-4 col-lg-6 col-md-6 bg-greylight mb-1 rounded-3" :ref="setFriendRef">
                      
                       <router-link :to="{name :'feedvisiteur', params: { id : friend.id } }" >
               
                    <figure class="avatar me-3">
                      <img :src="`${assetsBaseUrl}${friend.photo}`" alt="image" class="shadow-sm rounded-circle w45 fluid-image">
                    </figure>
                  </router-link>
                      <h4 class="fw-700 text-grey-900 font-xssss mt-1 link-user">
                  <router-link  :to="{name :'feedvisiteur', params: { id : friend.id } }" >{{friend.prenom}} {{friend.nom}}
                  </router-link>
                  <template v-if="!friend.isMyProfil">

                 
                  <span @click="toggleModalMutualFriend(friend.id)" v-if="friend.mutualFriend > 0"  class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500 get-mutual-friend">{{friend.mutualFriend}}  mutual friends</span>
                   <span  v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">no mutual friends</span>
                   </template>
                   <template v-else>
                      <span  class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profile</span>
                   </template>
                  </h4>
                 

                  <template v-if="!friend.isMyProfil">
<!-- is not my profil -->

                    <template v-if="!friend.isFriend">
<!-- is not friend -->                 
                    <template  v-if="friend.isRequestSent == false">
<!-- is request false -->    
                          <!-- v-tooltip.top-start="{content: () => asyncMethod(true)}"           -->
                       <a  @click="addFriend(friend.id,index)" class="ms-auto btn-add add-friend btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss me-2 mt-2" ></a>

                    </template>
<!-- else is request false -->                      
                     <template v-else-if="friend.isRequestSent == 'sender'">
                        
                       <a  @click="confirmFriend(friend.id,index)" class="btn-accept btn-round-sm bg-white text-grey-900 fas fa-check font-xss mt-2 ms-5 me-1" href="#"></a>
                       <a  @click="declineFriend(friend.id,index)" class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss mt-2" href="#"></a>
                       
                     </template>

                      <template v-else-if="friend.isRequestSent == 'receiver'">
                          <a  @click="removeFriend(friend.id,index)" class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss mt-2 ms-6" href="#"></a>
                       </template>

                  </template>  
<!-- is friend -->
                  <template v-else>
                    <div class="position-relative ms-auto ">
                   <a class="p-1 lh-20 mb-1 me-3 bg-greylight text-grey-800 text-center font-xssss fw-600 rounded-md is-friend">Friend</a>
                   </div>
                  </template>

                 
                  </template>
                  
<!-- is  my profil -->
                 

                      </div> 
                      </div>

      <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-2
            mb-1
            mt-1
            ms-3
            centerr-flex
        "
    >
        
           No other profiles
    </div>
    <div>
      <Loader v-show="isVisible && !done"/> 
      </div>
        <div v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div> 
        <!-- <div v-else class="card-body d-flex pt-2 ps-4 pe-4 pb-0 border-top-xs bor-0 col-xl-6 col-xxl-4 col-lg-6"> No results found</div>  -->

                      </div>
                   
            </template> 
            <template v-else>
              No result found
            </template> 
           
        
  </div>     
</template>
<script>
import { useToast } from 'vue-toastification';
const toast = useToast()
import Loader from "../../layouts/loader/loader.vue";
import ModalMutualFriend from "../../layouts/modal/modalMutualFriend.vue";

export default {
  components:{
    Loader,
    ModalMutualFriend
  },
    data(){
        return{
            NavBarSearchKey:'',
            results:[],
            friends:[],
            adresses:[],
            isFollowed:false,
            toggleFavRef:[],
            addFriendRef:[],
            isShowModalMutualFriend:false,
            friendId:null,
            page:1,
            lastPage:1,
            done:false,
            isVisible: false,
            totalResults:0,
            isFriendsReady:false,
           
        }

  },
      destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
    mounted(){
     
        

        this.NavBarSearchKey = this.$store.state.NavBarSearchKey
        this.getSearchResultsPage();

        

    },
    beforeUpdate(){
      this.NavBarSearchKey = this.$store.state.NavBarSearchKey;
    },
  
      
    methods:{
       visibilityChanged (isVisible) {
         this.addFriendRef = [];
    
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
          this.isVisible = isVisible;
            this.page++;
            this.getSearchResultsPage();    
      },
      getSearchResultsPage(){
        this.axios.post(`searchNavBarVisiteur?page=${this.page}`, {
                    searchFilter: this.$store.state.NavBarSearchKey             
                }).then(res=>{
                   this.friends.push(...res.data.visiteurs.data);  
                    this.lastPage = res.data.visiteurs.last_page;
                    this.totalResults = res.data.visiteurs.total;
                    this.isFriendsReady = true;
                     //console.log(this.friends);

                })
      },
      toggleModalCloseMutualFriend(id){

      this.isShowModalMutualFriend = false;  

    },

    toggleModalMutualFriend(id){

      this.isShowModalMutualFriend = !this.isShowModalMutualFriend;
      this.friendId = id;

    },
      asyncMethod(text){
        if(text){
        return 'Add friend' ;
        }else{
        return 'Cancel' ;

        }
        },

      
      //Fav-------------------------------------------------------
     
       setFriendRef(el){
         
        if (el && !this.addFriendRef.includes(el)) {
          this.addFriendRef.push(el);
        }
      },
      
    
  
    toggleFavorite(id,i){
                   
      //isFavorite = true
      if(this.toggleFavRef[i].firstElementChild.classList.contains("btn-remove-fav")){
        this.toggleFavRef[i].firstElementChild.classList.remove('btn-remove-fav');
        this.toggleFavRef[i].firstElementChild.classList.add('btn-add-fav');

        this.removeFavorite(id);   

      }else{
        this.toggleFavRef[i].firstElementChild.classList.remove('btn-add-fav');
        this.toggleFavRef[i].firstElementChild.classList.add('btn-remove-fav');

        this.addFavorite(id);

      }

    },

    removeFavorite(id){
      this.axios.post(`favoris/delete`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,     
      }).then(res=> {
       
        
        
      });
    },
     addFavorite(id){    
      this.axios.post(`/favoris/add`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
         
        
        
      });

    },
      //friend-------------------------------------------------
       addFriend(id,i){
         if( this.addFriendRef[i].lastElementChild.hasAttribute('cancel-data')){
          this.removeFriend(id,i)
         

         }else{
       // this.asyncMethod(false);
        //this.addFriendRef[i].lastElementChild.tooltip.content ='lool'
        //this.addFriendRef[i].lastElementChild.tooltip.content = 'Cancel';
         console.log(this.addFriendRef[i].lastElementChild);
        this.addFriendRef[i].lastElementChild.setAttribute('cancel-data' ,"cancel" );
        this.addFriendRef[i].lastElementChild.classList.remove('fa-user-plus','v-popper--has-tooltip','btn-add');
        this.addFriendRef[i].lastElementChild.classList.add('fa-times','btn-decline');
     
      this.axios.post('amis/ajouterAmis',{
        visiteur_id:this.currentUser.id,
        receiver_id:id


      }).then(res=>{
        //this.getFriendStatus();
       

      })
         }
    },
     getFriendStatus(){
      this.axios.post('/amis/friendStatus',{
        visiteur_id:this.$route.params.id
      }).then(res=>
      {
        this.friendStatus = res.data.friendStatus;
         this.senderID = res.data.sender;
          this.receiverID = res.data.receiver;
        // console.log(this.friendStatus);
        // console.log(this.senderID);
        // console.log(this.receiverID);
      }).catch(err=>{
        this.friendStatus = false;

      })
   
    },
     confirmFriend(id,i){

            this.addFriendRef[i].children[2].remove();
            this.addFriendRef[i].lastElementChild.remove();

      console.log(this.addFriendRef[i]);
        this.axios.post('amis/confimer',{
        visiteur_id:id,
      }).then(res=>{
        //this.getFriendStatus();
      })
      

      
        



    },
    declineFriend(id,i){
       
      if(this.addFriendRef[i].lastElementChild.classList.contains("btn-decline")){
           
            this.addFriendRef[i].children[2].remove();
            this.addFriendRef[i].lastElementChild.remove();
            //console.log(this.addFriendRef[i].children[2]);
            // this.addFriendRef[i].lastElementChild.classList.add('fa-user-plus','btn-add', 'btn-add-decline');
            // this.addFriendRef[i].lastElementChild.classList.remove('fa-times','btn-decline');
           
      console.log(this.addFriendRef[i]);
       this.axios.post('amis/supprimerAmis',{
        visiteur_id:id,
      }).then(res=>{
        //this.getFriendStatus();
      })
      }
      

    },
    removeFriend(id,i){
       if( this.addFriendRef[i].lastElementChild.classList.contains('btn-decline')){

            console.log(this.addFriendRef[i]);
            this.addFriendRef[i].lastElementChild.removeAttribute('cancel-data');
            this.addFriendRef[i].lastElementChild.classList.add('fa-user-plus','btn-add');
            this.addFriendRef[i].lastElementChild.classList.remove('fa-times','btn-decline');
      

       this.axios.post('amis/supprimerAmis',{
        visiteur_id:id,
        


      }).then(res=>{
        //this.getFriendStatus();

      })
        
       
       }else{

          this.addFriend(id,i);
         

              
       }

    },
    
    //Adresse--------------------------------------------------------------------
      follow(){
      this.isFollowerListReady = false;
       this.isFollowed = true;
      this.axios.post(`/follow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:this.adresseId,
        
      }).then(res=> {
        //this.getFollowersList();

      
        
      });
    },

     unfollow(){
       this.isFollowerListReady = false;
       this.isFollowed = false;
      this.axios.post(`/unfollow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:this.adresseId,
        
      }).then(res=> {
       // this.getFollowersList();
        
      });
    },
    //--------------------------------------------------------------------------------
     drawStarsYellow(count){
          return `<i class="fa fa-star rating-color"></i>`.repeat(count)
      },
    drawStarsGrey(count){
          return `<i class="fa fa-star"></i>`.repeat(5-count)
      },
    drawDollarsGreen(count){
          return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count)
      },
    drawDollarsGrey(count){
          return `<i class="fas fa-dollar-sign"></i>`.repeat(5-count)
      },
    //-------------------------------------------------------------------------------
      getSearchResults(){
        this.axios.post('searchNavBar',{
          searchFilter:this.NavBarSearchKey
        }).then(res=>{
          this.results = res.data;
          this.friends = res.data.visiteurs;
          this.adresses = res.data.adresses;

        })
      },
    },
     computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     currentUser() {
            return this.$store.state.currentUser;
        },
    searchResults() {
            return this.$store.state.searchResults;
        },
    isReady() {
            return this.$store.state.searchResultsIsReady;
        },
       
       
  },
};
</script>
<style lang="scss" scoped>
.centerr-flex{
  justify-content: center;
}
.card{
  flex-direction: row !important
}
.rounded-circle{
 border-radius:10px !important
}
.btn-unfollow, .btn-follow{
  
  bottom:0px !important;
  right:0px !important;
  // background-color: #2e58a6 !important;
  color:#000 !important;
  // font-size:16px !important
}
.btn-unfollow {
  border-radius: 5px;
  &:hover{
      cursor:pointer;
     box-shadow: 1px 1px 2px #8888884d inset ;
     
     

  }
}
.btn-follow{
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color:#fff !important;
   &:hover{
      cursor:pointer;
     box-shadow: 1px 1px 2px #222 inset ;
     
     

  }
}
.top-0 {
    top: -100px !important;
}
.rounded-3{
  border-radius: 5px !important;
  border-right: #fff 2px solid ;
}
.btn-round-sm, .btn-add , .btn-accept, .btn-decline{
  border-radius:5px !important;
  transition: background-color .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
    color:#fff !important;
  }
}
.btn-add{
  &:hover{
background-color: #2e58a6 !important;
  }
}
.btn-decline{
  
  &:hover{
background-color: #f00 !important;
  }
}
.btn-accept{
  &:hover{
background-color: rgb(43, 110, 43) !important;
  }
}
.see-all-btn{
  &:hover{
    cursor:pointer;
    text-decoration:underline !important;
  }
}
.ms-6 {
    margin-left: 5rem !important;
}
.container-adrs-inf{
  padding: 0rem 1rem 0.5rem !important
}
.rating-color {
    color: #fbc634 !important
}
.rating-grey{
  color:#d2e3ff !important
}
.couts-color{
  color:rgb(16, 92, 16)
}
 .btn-remove-fav{
   &:hover{
     cursor:pointer;

     color:#bbb !important
   }
  
   transition: all .4s ease-in-out;
   z-index:5;
   color:#f00;
  
 }

  .btn-add-fav{
   &:hover{
     cursor:pointer;
     color:#f00  !important;

     
   }
  
   transition: all .4s ease-in-out;
   z-index:5;
   
   color:#bbb !important;
   
 }
 .btn-toggle-fav{
   left:-15px !important;
   top:-9px !important;
 }
 .w-img{
  width:100% !important;
  height:90px !important;
  vertical-align:bottom !important;
  border-radius: 10px
  //text-align:center;
  //left:20%;
}
.elipsis{
  white-space:nowrap;
  text-overflow:ellipsis;
}
// .btn-followw{
  // bottom:0px !important;
  // right:0px !important
// }
.is-friend{
    bottom:-8px !important;
  // right:-120px !important;
  background: #fff !important;
  border-radius:5px;
}
.get-mutual-friend{
  &:hover{
    cursor:pointer;
    text-decoration:underline
  }
 

}
.scroll-bar{
   overflow-x: hidden;
 }
//   .chat-left {
//     height: calc(100vh - 400px) !important;
// }
</style>