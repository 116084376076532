<template>
  <portal to="modal">
    <transition name="fade">
      <div class="modall">
        <div class="wrapper col-md-5 col-sm-12">
          <div class="modal-container">
            <div @click.self.stop.prevent="handleClose" class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>



          <div
            class="z-10 bg-white rounded-modal overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
            role="dialog" aria-modal="true" aria-labelledby="modal-headline">
            <div class="bg-white pb-2 sm:p-5 sm:pb-5">
              <div class="sm:flex sm:items-start">
                <div class="w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div class="flex items-center justify-between bg-gray-500 pt-2">
                    <h3 class="pt-2 text-lg modal-header-txt text-gray-900 fw-600" id="modal-headline">Highlight
                      story<span v-if="selectedIDs.length > 0"> ({{ selectedIDs.length }})</span> </h3>


                    <i class="feather-x-square pt-1" @click.prevent="handleClose">

                    </i>

                    <hr>
                  </div>



                  <div class="card w-100  border-0 ps-4 pe-4 mb-2 story-container">

                    <template v-if="!isStoriesReady">
                      <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 col-xl-12 col-xxl-8 col-lg-12">
                        <Loader></Loader>
                      </div>
                    </template>
                    <template v-else>
                      <input v-if="stories.length" type="text" placeholder="Write a title..."
                        class="bg-greylight border-0 lh-32 pt-2 pb-2 ps-3 pe-3 font-xssss fw-500 input-search-adress mb-2 theme-dark-bg"
                        v-model="titreStory" maxlength="10">

                      <div class="chat-left scroll-bar pe-2 ps-2">

                        <div class="row">
                          <div v-for="(story, index) in stories" class="col-md-3 col-xss-6 story" :ref="setStoryRef">
                            <div @click="selectStory(story.id, index)"
                              class="card h150 d-block border-0 shadow-xss rounded-3 overflow-hidden mb-3">
                              <img :src="`${assetsBaseUrl}${story.src}`" class="image-fit float-right w-100 h200"
                                v-if="story.type == 1">
                              <video loop v-else class="float-right w-100 h200 video-fit" muted>
                                <source :src="`${assetsBaseUrl}${story.src}`" type="video/mp4" />
                              </video>


                              <div class="card-body d-block w-100 position-absolute bottom-0 text-center">
                                <figure :ref="setStoryCheckRef"
                                  class="hide avatar ms-auto me-auto mb-0 position-relative w50 z-index-1"><i
                                    class="fad fa-check-square fa-4x"></i></figure>
                                <div class="clearfix"></div>
                                <div class="datetime-story">
                                  <h4 class="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-0">{{
                                    $filters.getMonth(story.datetime) }}</h4>
                                  <h4 class="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-0 mb-0">{{
                                    $filters.getDay(story.datetime) }}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <!--*******************(infiniteScroll)********************* -->
                        <div v-if="done" class="
              card
              w-100
              text-center
              shadow-xss
              rounded-xxl
              border-0
              p-2
              mb-1
              mt-1
              ms-3
          ">

                          No other stories
                        </div>
                        <div>
                          <Loader v-show="isVisible && !done" />
                        </div>
                        <div v-if="stories.length" v-observe-visibility="{ callback: visibilityChanged, throttle: 500, }">
                        </div>
                        <div v-else class="empty-story card-body d-flex pt-2 ps-4 pe-4 pb-0 border-top-xs bor-0">
                          <div class="mt-3">
                            <i class="fad fa-history fa-5x"></i>
                          </div>
                          <div class=""> Add to your story</div>

                        </div>
                        <!-- *********************************************-->
                      </div>
                    </template>

                  </div>
                </div>
              </div>
            </div>
            <div v-if="stories.length"
              class="card-footer px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse container-publish">
              <slot name="footer"></slot>
              <span class="mt-0 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto spanBtn">
                <button @click="addHighlightPost" id="btnCmnt" :disabled="isDisabled" type="button"
                  class="btnPub inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">Publish</button>



              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>

import Loader from '../loader/loaderImage.vue';
export default {
  components: {
    Loader
  },
  data() {
    return {

      stories: [],
      isStoriesReady: false,
      page: 1,
      lastPage: 1,
      done: false,
      isVisible: false,
      //----(refs)--
      storyCheckRef: [],
      storyRef: [],
      selectedIDs: [],
      titreStory: '',

    }
  },

  name: "modalStoryHighlight",
  props: {


  },
  mounted() {
    this.getStories();

  },
  created() {
    document.addEventListener("keyup", this.onClose);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    //-----------------------------(refs)--------------------------
    setStoryCheckRef(el) {
      if (el && !this.storyCheckRef.includes(el)) {
        this.storyCheckRef.push(el);
      }
    },
    setStoryRef(el) {
      if (el && !this.storyRef.includes(el)) {
        this.storyRef.push(el);
      }
    },
    //------------------------------------------------------------
    addHighlightPost() {
      this.axios.post('storeHighlight', {
        mediaIDs: this.selectedIDs,
        titreStory: this.titreStory

      }).then(res => {

        this.handleClose();
        this.handleRefreshStoryHighlight();

      })
    },
    selectStory(id, i) {
      //add
      if (this.storyCheckRef[i].classList.contains('hide')) {
        this.storyCheckRef[i].classList.remove('hide');
        this.storyCheckRef[i].classList.add('show');
        this.selectedIDs.push(id);
        //remove
      } else {

        this.selectedIDs.splice(this.selectedIDs.indexOf(id), 1);
        this.storyCheckRef[i].classList.add('hide');
        this.storyCheckRef[i].classList.remove('show');

      }

    },

    getStories() {
      this.axios.get(`allMyStories?page=${this.page}`)
        .then(res => {
          this.stories.push(...res.data.stories.data);
          this.isStoriesReady = true;
          this.lastPage = res.data.stories.last_page;
          this.totalResults = res.data.stories.total;
          console.log(this.stories);

        })

    },
    visibilityChanged(isVisible) {
      this.storyCheckRef = [];
      this.storyRef = [];
      if (!isVisible) { return }
      if (this.page >= this.lastPage) { return this.done = true }
      this.isVisible = isVisible;
      this.page++;
      this.getStories();

    },
    handleClose() {

      this.$emit("close");

    },
    handleRefreshStoryHighlight() {
      this.$emit("refreshStoryHighlight");

    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },


  },
  computed: {
    isDisabled() {
      if (this.selectedIDs.length > 0) {
        return false
      } else {
        return true
      }

    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },


  },
};
</script>

<style lang="scss" scoped>
.btnPub {
  transition: all .4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;

  }
}

.spanBtn {
  display: inline-block;
  width: 100%;


}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;


}

.wrapper {
  //width: 40%;
  margin: 0 auto;
  border-radius: 10px;

}

.modal-container {

  margin: 0px 50px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;

}

.modal-header-txt {
  display: inline-block;
}

.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all .4s ease-in-out;
  }

  float:right;
  font-size: 32px;
}

.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;



}

.loader {
  height: 50px !important
}

.required {
  border: 1px solid rgba(206, 36, 36, 0.815);
}

.cancel {
  display: none !important
}

.invalid {
  border: 2px red solid
}

.rounded-modal {
  border-radius: 10px
}


@media (max-width: 767px) {

  .modal-container {
    margin: -20px 10px !important;

  }

  .wrapper {
    margin: 0 auto;
    height: 100% !important;
  }

  .rounded-modal {
    border-radius: 0px;
    width: 100%;
    height: 100% !important;
  }

  .card-footer {
    position: relative;
    bottom: 0;
    border-top: 0;

  }

  .container-publish {
    background: none;
    // position: absolute;
    // bottom: 10px;
    // width: 100%;
  }
}

.chat-left {
  height: calc(100vh - 300px) !important;

}

.scroll-bar {
  overflow-x: hidden;

}

.fa-check-square {
  color: #127728;

}

.story {
  overflow: hidden;
  opacity: 0.8;
  transition: all .2s ease-in-out;

  &:hover {
    cursor: pointer;
    //transform: scale(1.01);
    opacity: 1;

  }

}

.story-container {
  min-height: 330px;
}

.show {
  display: block !important;
}

.hide {
  display: none !important;

}

.empty-story {
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.input-search-adress {
  border-radius: 10px !important
}

.owl-stage {
  width: 100% !important
}

.video-fit,
.image-fit {
  object-fit: cover !important;
}

.datetime-story {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

.bg-gray-500 {
  background-color: rgba(0, 0, 0, 0.03);
}</style>