<template lang="">
  
  <ModalShowStory @refreshStoryHighlight="refreshStoryHighlight" v-if="isStoriesShowModal" @closeStory="toggleShowModalClose" :indexSelected="indexSelected" :userID="userID">
      
  </ModalShowStory>

  <ModalStoryHighlight
    v-if="isShowModal"
    @close="toggleModalClose"
    @refreshStoryHighlight="refreshStoryHighlight"
  >
  </ModalStoryHighlight>
  <div class="card w-100 shadow-xss rounded-xxl border-0 p-2 mb-1 mt-1">
    <div class="card-body p-0 d-block mb-0 style2 chatlist">
      
           
            
            <Splide
                :options="{
                  rewind: true,
                  perPage: 3,
                  autoWidth: true,
                  arrows: false,
                  pagination: false,
                  wheel: false,
                  isNavigation: false,
                  drag:'free',
                  
                }"
              >
              <SplideSlide v-if="isMyProfile">
              <div class="item no-ouline me-1">
               
              <div
                  class="card w-100 border-0 shadow-none bg-transparent bg-transparent-card owl-item"
                >
                  <a
                    @click="toggleModal"
                    data-target="#Modalstries"
                    data-toggle="modal"
                    class="w-100 btn-round-lg add-story-btn"
                    ><i class="feather-plus text-grey-600 font-md"></i>
                    <span
                      class="d-block fw-600 font-xssss text-grey-600 text-dark mt-0"
                      >My story</span
                    ></a
                  >
                </div>
                </div>
              </SplideSlide>
              <template v-if="!isHighlightStoryReady">
              <div
                
                class="owl-item active"
                style="width: auto; margin-right: 7px"
              >
                <div class="item no-ouline">
                  <div
                    class="card w-100 border-0 shadow-none bg-transparent bg-transparent-card btn-round-lg pt-2"
                  >
              
                
              <Loader/>
              </div></div></div>
              
              

              </template>
              <template v-else>
  <SplideSlide v-for="(storie, index) in stories" class="me-1" :class="loading && clickedStoryID == storie.id ? 'opacity-str' : ''">
  
    <div class="item no-ouline" @click="toggleShowModal(index,storie.id)">
      <div class="card bg-transparent border-0 shadow-none">
        <div class="card-image text-center splide-btn">
          <a class="h-story"
            ><img
              v-if="storie.medias[storie.medias.length - 1].type == 1"
              :src="`${assetsBaseUrl}${
                storie.medias[storie.medias.length - 1].src
              }`"
              alt="image"
              class="d-inline p-0 btn-round-lg"
            />
            <video v-else class="d-inline p-0 btn-round-lg image-fit">
              <source
                :src="`${assetsBaseUrl}${
                  storie.medias[storie.medias.length - 1].src
                }`"
                type="video/mp4"
              />
            </video>
          </a>
        </div>
      </div>
    </div>
    
    <span
      class="d-block fw-600 font-xssss text-grey-600 text-dark titre-story"
      >{{ storie.titre }}</span
    >
  </SplideSlide>
</template>
              
              </Splide>

            
            
             
            
            
          </div>
        </div>
    
    
  
</template>
<script>
import ModalStoryHighlight from "../../../layouts/modal/modalStoryHighlight.vue";
import Loader from "../../../layouts/loader/loaderImage.vue";
import ModalShowStory from "../../../layouts/modal/modalShowStoryHighlight.vue";
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import LoaderStory from "../../../layouts/loader/loaderStoryCheck.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  components: {
    ModalStoryHighlight,
    Loader,
    ModalShowStory,
    Splide,
    SplideSlide,
    LoaderStory
  },
  data() {
    return {
      isShowModal: false,
      isHighlightStoryReady: false,
      stories: [],
      //---------------
      isStoriesShowModal: false,
      indexSelected: 0,
      clickedStoryID : '',
    };
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted() {
    this.getStoryHighlight();
    this.userID = this.$route.params.id;
  },

  methods: {
    toggleShowModal(i,id) {
      // this.isStoriesShowModal = !this.isStoriesShowModal;
      // this.indexSelected = id;

      //---------------------------
         //this.clickedStoryID = idStory;
        this.clickedStoryID = id;
      document.body.style.cursor = "wait";
      this.$emit('removeListnerKEyUp');
     
    
      
      this.loading = true;
      

      this.axios.post('/checkStorieExist', {
        storie_id: id
      }).then(res => {

        this.loading = false;
        document.body.style.cursor = "default";

        if (res.data.exist == false) {
          this.refreshStoryHighlight();
          toast.error("This story is no longer available ", {
            position: "bottom-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        } else {
          this.isStoriesShowModal = !this.isStoriesShowModal;
          this.indexSelected = i;
        }
      });
    },

    toggleShowModalClose() {
      this.isStoriesShowModal = false;
      this.loading = false;
    },

    //----------------------
    refreshStoryHighlight() {
      this.getStoryHighlight();
    },

    getStoryHighlight() {
      this.isHighlightStoryReady = false;
      this.axios
        .post("getStoryHighlight", {
          userID: this.$route.params.id,
        })
        .then((res) => {
          this.isHighlightStoryReady = true;
          //console.log(res.data.stories)
          this.stories = res.data.stories;
        });
    },

    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
    toggleModalClose() {
      this.isShowModal = false;
      this.loading = false;
    },
  },
  computed: {
    isMyProfile() {
      if (this.currentUser.id == this.$route.params.id) {
        return true;
      } else {
        return false;
      }
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.opacity-str{
  opacity: 0.5;
}
.btn-round-lg,
.item.no-ouline {
  border-radius: 15px !important;
}
.add-story-btn,
.splide-btn {
  &:hover {
    cursor: pointer;
  }
}
.owl-carousel.overflow-visible .owl-item {
  opacity: 0.5 !important;
  &:hover {
    opacity: 1 !important;
  }
}

.chatlist.style2 .item:before {
  //background-color: #2e58a6;

  border-radius: 15px !important;
}
// .element-splide {
//   background: linear-gradient(to right, red, orange);
// }

.chatlist.style2 .item {
  img {
    border-radius: 15px !important;
  }
}
.owl-item {
  &:hover {
    cursor: pointer;
  }
}
.video-fit,
.image-fit {
  object-fit: cover !important;
}
.is-active {
  border-bottom: 0 !important;
}
.item {
  height: 55px !important;
}
.chatlist.style2 .item.no-ouline {
  line-height: 51px !important;
}
.titre-story {
  z-index: 999999;
  margin-top: 0.15rem !important;
}
</style>
