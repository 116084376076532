<template>
  <portal to="modal">
    <transition name="fade">
      <div class="modall">
        <div class="wrapper col-md-5 col-sm-12">
          <div class="modal-container">
            <div
              @click.self.stop.prevent="handleClose"
              class="absolute inset-0 bg-gray-500 opacity-75"
            ></div>
          </div>
          <div
            v-if="isUploading"
            class="uploading z-11 bg-white overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          ></div>

          <div
            class="z-10 bg-white rounded-modal overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div class="bg-white pb-2 sm:p-5 sm:pb-5">
              <div class="sm:flex sm:items-start">
                <div class="w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div class="flex items-center justify-between card-footer border-bottom-xs">
                    <h3
                      class="pt-2 text-lg modal-header-txt text-gray-800 fw-700 "
                      id="modal-headline"
                    >
                      Add Story
                    </h3>

                    <i
                      v-if="!isUploading"
                      class="feather-x-square"
                      @click.prevent="handleClose"
                    >
                    </i>
                   
                  </div>
                   <hr />
                  <div class="" :class="{ scrollable: scrollable }">
                    <div
                      class="mt-m card w-100 rounded-xxl border-0 ps-4 pe-4 mb-3"
                    >
                      <div class="card-body p-0 mt-2 position-relative">
                        <figure
                          class="avatar position-absolute ms-2 mt-1 b-2 top-5"
                        >
                          <img
                            :src="`${assetsBaseUrl}${currentUser.photo}`"
                            alt="image"
                            class="shadow-sm rounded-circle w30"
                          />
                        </figure>
                        <textarea
                          name="message"
                          class="h100 bor-0 w-100 rounded-xxl p-2 ps-10 font-xssss text-grey-500 fw-500 border-light-md theme-dark-bg"
                          cols="30"
                          rows="10"
                          :placeholder="`what's new ${currentUser.nom} ? `"
                          v-model="texteCom"
                          ref="refTexteCome"
                          :disabled="isUploading"
                        ></textarea>
                      </div>

                      <div
                        ref="dropZoneRef"
                        method="POST"
                        enctype="multipart/form-data"
                        id="myCommentDropzone"
                        class="dropzone mt-2"
                      >
                        <div class="fallback">
                          <input name="file" type="file" />
                        </div>
                      </div>
                    </div>

                    <slot />
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-footer bg-gray-50 px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse container-publish"
              :class="isUploading ? 'loader' : ''"
            >
              <slot name="footer"></slot>
              <span
                class="mt-0 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto spanBtn"
              >
                <button
                  v-if="!isUploading"
                  id="btnCmnt"
                  type="button"
                  class="btnPub inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                >
                  Publish
                </button>
                <template class="" v-else> <Loader></Loader> </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { Dropzone } from "dropzone";
import Loader from "../loader/loaderImage.vue";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      texteCom: "",
      commentable_type: "Story",

      myDropzone: "",
      isUploading: false,
      refTexteCome: "",
      isRequired: false,
    };
  },

  name: "BaseModal",
  props: {
    title: {
      type: String,
      required: true,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    setTimeout(() => {
      this.dropInit(), 500;
    });
  },
  created() {
    document.addEventListener("keyup", this.onClose);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    testeVide() {
      if (this.texteCom != "") {
        this.isRequired = false;
      }
    },
    handleClose() {
      this.$emit("close");
    },
    refreshStory(index) {
      this.$emit("refreshStory");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27 && this.isUploading == false) {
        this.handleClose();
      }
    },
    dropInit() {
      
      Dropzone.autoDiscover = false;
      Dropzone.options.myCommentDropzone = {
        paramName: "file", 
        maxFilesize: 25, // MB
        url: `${this.apiBaseUrl}storie`,
        addRemoveLinks: true,
        uploadMultiple: false,
        withCredentials: true,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        autoProcessQueue: false,
        maxFiles: 1,
        parallelUploads: 1,
        acceptedFiles: "image/*, video/*",
        dictRemoveFile: "Supprimer",
        dictCancelUploadConfirmation:
          "Voulez-vous vraiment annuler ce téléchargement ?",
        dictDefaultMessage: "Drop an image/video here to upload",
        dictCancelUpload: "",
        dictInvalidFileType: "This form only accepts images",
        dictFileTooBig: `the file size is too large.  {{filesize}} the max accepted is {{maxFilesize}} `,
      };

      var myDropzone = new Dropzone("#myCommentDropzone", {
        parallelUploads: 22,
        init: () => {
          var myDropzone = Dropzone.forElement("#myCommentDropzone");

          document.getElementById("btnCmnt").addEventListener("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (myDropzone.getQueuedFiles().length == 0) {
              this.$refs.dropZoneRef.classList.add("invalid");
            } else {
              myDropzone.processQueue();
            }
          });

          myDropzone.on("sending", (data, xhr, formData) => {
            this.isUploading = true;

            formData.append("visiteur_id", this.currentUser.id);
            formData.append("texte", this.texteCom);
          });
          myDropzone.on("success", (data, xhr, formData) => {
            this.handleClose();
            this.refreshStory();
            //this.$router.go();
          });
        },
      });
    },

    addStory() {
      this.axios
        .post("/storie", {
          visiteur_id: this.currentUser.id,
          texte: this.texteCom,
        })

        .then((res) => {
          if (res.data.success) {
            this.handleClose();
            
          }
        })
        .catch((error) => {});
    },
    publierPub() {
      alert("Commenter");
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/dropzone.min.css";
.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;
  
  

  &:hover {
    background-color: #1259cc !important;
  }
}
.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.wrapper {
  //width: 40%;
  margin: 0 auto;
  border-radius: 10px;
}
.modal-container {
  margin: 0px 50px;
  padding: 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}
.modal-header-txt {
  display: inline-block;
}
.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }
  float: right;
  font-size: 32px;
}
.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}
.loader {
  height: 50px !important;
}
.required {
  border: 1px solid rgba(206, 36, 36, 0.815);
}
.cancel {
  display: none !important;
}
.invalid {
  border: 2px red solid;
}
.rounded-modal {
  border-radius: 10px;
  width:100%;
 
}
@media (min-width: 989px) {
  .modal-container {
    margin: 40px 10px;
    padding: 0px 20px;
  }
  }
 
@media (max-width: 767px) {
  // .mt-m{
  //   margin-top: 10% ;

  // }
   .modal-container {
    margin: -40px 10px !important;
    
  }
   .wrapper {
  margin: 0 auto;
  height: 100% !important;
  }
  .rounded-modal {
    border-radius: 0px;
    width:100%;
    height: 100% !important;
  }
  .card-footer{
    position: relative;
    bottom: 0;
    border-top:0 ;
    
  }
  .container-publish{
    background: none;
 
  }
}


.media-fit {
  object-fit: contain !important;
}
hr{
  margin:0 0 20px 0
}
.dropzone{ 
  border-radius: 15px;
  background-color: #eff3fa;
}
</style>
