<template lang="">
        <div class="col-xl-12 col-xxl-8 col-lg-12 pe-0">
          <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">

            
                
            <template v-if="isFavoritesReady">

                <div  class="card-body d-flex pt-0 ps-4 pe-4 pb-0  bor-0 col-xl-6 col-xxl-4 col-lg-6">


                <div class="col-xl-12  chat-left scroll-bar">
                             <div class="card shadow-xss w-100 d-block d-flex border-0 p-1 mb-1">
                                <div class="card-body d-flex align-items-center p-0">
                                    
                                    <div class="search-form-2 ms-auto">
                                        <i class="ti-search font-xss"></i>
                                        <input type="text" class="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" placeholder="Search here."
                                        v-model.trim="searchKey">
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="row ps-2 pe-2">
                              <template v-if="isFavoritesReady && favorites.length == 0">
                                <div class="col-lg-12 col-md-12 col-sm-12 mb-3 pe-2 ps-2">
                                No results found
                                </div>
                              </template>
                                <div :ref="setAdresseFavorites" v-for="(fol,index) in filtredResults" class="col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2">
                                    <div class="card w-100 p-0 hover-card shadow-xss border-0  overflow-hidden me-1">
                                       
                                        <div class="card-image w-100 mb-3 ">
                                          <!-- <a @click="removeFavorite(fol.adresse.id)" class="position-absolute p-2 lh-20  bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-md btn-add-friend" ref="btnAddFriend"><i class="far fa-heart"></i></a> -->
                                          <a  v-tooltip.top-start="'Remove from favorites'" @click="removeFavorite(fol.adresse.id,index)" class="position-absolute btn-add-friend btn-add-fav d-lg-block bg-grey btn-round-lg ms-2 rounded-3 text-white-700"><i class="fas fa-heart font-md"></i></a>
                                            <router-link :to="{ name : 'feedadresse' , params : { id : `${fol.adresse.id}`}}"  class="position-relative d-block"><img :src="`${assetsBaseUrl}${fol.adresse.logo}`" alt="image" class="w-100"></router-link >
                                        </div>
                                        <div class="card-body pt-0">
                                            
                                            <h4 class="fw-700 font-xss mt-0 lh-28 mb-0">
                                              <router-link :to="{ name : 'feedadresse' , params : { id : `${fol.adresse.id}`}}" class="text-dark text-grey-900">{{fol.adresse.rs}}</router-link></h4>
                                            <h6 class="font-xsssss text-grey-500 fw-600 mt-0 mb-2"> {{fol.adresse.adresse}}</h6>
                                            <div class="star d-block w-100 text-left mt-0">
                                                
                                            </div>
                                            <div class="clearfix"></div>
                                             
                                             
                                            
                                            
                                        </div>
                                    </div>
                                </div>

                                
                                   
                                
                                
                            </div>

        <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-2
            mb-1
            mt-1
            ms-3
        "
    >
        
           No other adresses
    </div>
    <div>
      <Loader v-show="isVisible && !done"/> 
      </div>
        <div v-if="filtredResults.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div> 
        <div v-else class="card-body d-flex pt-2 ps-4 pe-4 pb-0 border-top-xs bor-0 col-xl-6 col-xxl-4 col-lg-6"> No results found</div> 
                        </div>




              </div>

            </template>
            <template v-else>

            <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 bor-0 col-xl-12 col-xxl-8 col-lg-12">
                <Loader></Loader>
            </div>
            

          </template>

          </div>
        </div>
</template>
<script>
import Loader from "../../../layouts/loader/loader.vue"
export default {
   components:{
    Loader,
    
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
    mounted() {
      this.getFavorites();
   
  },

  data(){
    return {
      favorites:[],
      isFavoritesReady:false,
      searchKey:'',
      searchkeyArray:[],
      page:1,
      lastPage:1,
      done:false,
      isVisible: false,
      totalResults:0,
      adressesFovorites:[]
       
    }
  },
   
  
  methods: {
     setAdresseFavorites(el){
        if (el && !this.adressesFovorites.includes(el)) {
          this.adressesFovorites.push(el)
        }
      },
     visibilityChanged (isVisible) {
    
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
          this.isVisible = isVisible;
    
            this.page++;
            this.getFavorites();
 
      },
    getFavorites(){
      //this.isFavoritesReady = false;
     
      this.axios.post(`favorisByUser?page=${this.page}`,{
        visiteurId:this.$route.params.id
      })
      .then(res=>{
        this.favorites.push(...res.data.favoris.data);
        this.isFavoritesReady=true;
        this.lastPage = res.data.favoris.last_page;
        this.totalResults = res.data.favoris.total;


      })
    },

     removeFavorite(id,i){
      this.adressesFovorites[i].remove();
      this.axios.post(`favoris/delete`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
        //this.getFavorites();
        
      });
    },
      
  },
  computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    filtredResults() {
        return this.favorites.filter(fav => (
        (fav.adresse.adresse && fav.adresse.adresse.toLowerCase().includes(this.searchKey.toLowerCase())) ||
        (fav.adresse.rs && fav.adresse.rs.toLowerCase().includes(this.searchKey.toLowerCase())) ||
        this.favorites
      ));
    },
  }
}
</script>
<style lang="scss" scoped>
.card{
  flex-direction: row !important
}
.rounded-circle{
 border-radius:10px !important
}
.scroll-bar{
  overflow-x:hidden !important
}
.hover-card{
  flex-direction: column !important;
}
 .btn-add-friend{
   &:hover{
     cursor:pointer;
     box-shadow: 2px 2px 2px #8888884d ;
     //background-color: #2e58a6 !important;
     color:#999 !important
   }
  
   transition: all .4s ease-in-out;
   z-index:99999;
   color:#f00;
   right:0
 }
 .rounded-3{
   border-radius:0px !important
 }

</style>