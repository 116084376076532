<template lang="">
    <!-- loader wrapper -->
    <template  class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1 col-md-6
            ">
    <div class="preloader-wrap">
        <div class="box shimmer">
            <div class="lines ">
                <div class="line s_shimmer"></div>
               
                
                
            </div>
        </div>
      
    </div>
    </template>
     
    <!-- loader wrapper -->
</template>
<script>
export default {
    name: "skeletonComment",
};
</script>
<style lang="scss" scoped>
.preloader-wrap {
    display: block !important;
}

.line{
    width: 100px !important; 
    height: 300px !important;
}


</style>
