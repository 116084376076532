<template>
  <portal to="modal">
    <div class="modall">
      <div class="wrapper col-md-5 col-sm-12">
        <div class="modal-container">
          <div @click.self.stop.prevent="handleClose" class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>

        <div class="z-10 bg-white rounded-modal overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="position-relative">
            <i class="feather-x-square pt-1 float-right position-absolute close-btn" @click.prevent="handleClose"></i>

            <div class="card w-100 border-0 ps-4 pe-4 mb-3 card-mm">
              <div v-if="
                !isAllReactionsReady ||
                !isLikesReady ||
                !isLovesReady ||
                !isDislikesReady
              ">
                <div class="card-body pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 card-m">
                  <div class="chat-left scroll-bar pt-2 w-100">

                    <Skeleton></Skeleton>
                  </div>
                </div>
              </div>
              <tabs v-else @changed="tabChanged" @clicked="tabClicked"
                :options="{ useUrlFragment: false, defaultTabHash: 'All' }" class=""
                nav-class="nav nav-tabs h55 d-flex product-info-tab border-bottom-0 ps-4"
                nav-item-class="list-inline-item me-5 hover-item"
                nav-item-link-class="fw-700 font-xsss text-grey-500 pt-3 pb-4 ls-1 d-inline-block">
                <tab v-if="isAllReactionsReady" name="All" id="All"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                                                                                                                                                                            > (${totalReactions})</span>`">
                  <div class="card-body pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 card-m">
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <div v-if="
                        !isAllReactionsReady ||
                        !isLikesReady ||
                        !isLovesReady ||
                        !isDislikesReady
                      ">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <div v-else v-for="(jaime, index) in jaimes"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1">
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link v-tooltip.left-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: jaime.visiteur.id },
                          }">
                            <img :src="`${assetsBaseUrl}${jaime.visiteur.photo}`" alt="image"
                              class="shadow-sm rounded-circle w45" />
                          </router-link>
                          <i v-if="jaime.reaction_type == 1" class="em em-thumbsup position-absolute icon-reaction"></i>
                          <i v-else-if="jaime.reaction_type == 2" class="em em-heart position-absolute icon-reaction"></i>
                          <i v-else-if="jaime.reaction_type == 7"
                            class="em em-thumbsdown position-absolute icon-reaction"></i>
                        </figure>

                        <h4 class="fw-700 text-grey-900 font-xssss mt-2 link-user">
                          <router-link v-tooltip.top-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: jaime.visiteur.id },
                          }">{{ jaime.visiteur.prenom }}
                            {{ jaime.visiteur.nom }}
                          </router-link>
                          <span v-if="!jaime.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{ jaime.visiteur.mutualFriend }}
                            mutual
                            friends</span>
                          <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profil</span>
                        </h4>

                        <a :class="`add-btn-${jaime.visiteur.id}`" v-if="
                          !jaime.visiteur.isFriend &&
                          !jaime.visiteur.isMyProfil &&
                          !jaime.visiteur.isRequestSent
                        " @click="addFriend(jaime.visiteur.id, index, null)"
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"></a>

                        <a cancel-data="cancel" :class="`add-btn-${jaime.visiteur.id}`"
                          v-else-if="jaime.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(jaime.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"></a>
                      </div>

                      <div v-if="doneAll" class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1">
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleAll && !doneAll" />
                      <div v-if="jaimes.length" v-observe-visibility="{
                        callback: visibilityChanged,
                        throttle: 500,
                      }"></div>
                    </div>
                  </div>
                </tab>

                <tab v-if="isLikesReady && totalLikes > 0" class="position-relative"
                  prefix="<i class='fas fa-thumbs-up fa-1-5x  pe-1 blue'></i>"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                                                                                                                                                                                    >(${totalLikes})</span>`"
                  name="" id="Like">
                  <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 card-m">
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isLikesReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div v-else v-for="(like, index) in Likes"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1">
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link v-tooltip.left-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: like.visiteur.id },
                          }">
                            <img :src="`${assetsBaseUrl}${like.visiteur.photo}`" alt="image"
                              class="shadow-sm rounded-circle w45" />
                          </router-link>
                          <i class="em em-thumbsup position-absolute icon-reaction"></i>
                        </figure>

                        <h4 class="fw-700 text-grey-900 font-xssss mt-2 link-user">
                          <router-link v-tooltip.top-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: like.visiteur.id },
                          }">{{ like.visiteur.prenom }} {{ like.visiteur.nom }}
                          </router-link>
                          <span v-if="!like.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{ like.visiteur.mutualFriend }}
                            mutual
                            friends</span>
                          <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profil</span>
                        </h4>

                        <a :class="`add-btn-${like.visiteur.id}`" v-if="
                          !like.visiteur.isFriend &&
                          !like.visiteur.isMyProfil &&
                          !like.visiteur.isRequestSent
                        " @click="
  addFriend(
    like.visiteur.id,
    index,
    like.reaction_type
  )
" class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"></a>
                        <a cancel-data="cancel" :class="`add-btn-${like.visiteur.id}`"
                          v-else-if="like.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(like.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"></a>
                      </div>

                      <div v-if="doneLikes" class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1">
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleLikes && !doneLikes" />
                      <div v-if="Likes.length" v-observe-visibility="{
                        callback: visibilityChangedLikes,
                        throttle: 500,
                      }"></div>
                    </div>
                  </div>
                </tab>

                <tab v-if="isLovesReady && totalLoves > 0" name="" id="Love"
                  prefix="<i class='fas fa-heart fa-1-5x  pe-1 red'></i>"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                                                                                                                                                                                    >(${totalLoves})</span>`">
                  <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 card-m">
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isLovesReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div v-else v-for="(love, index) in Loves"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1">
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link v-tooltip.left-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: love.visiteur.id },
                          }">
                            <img :src="`${assetsBaseUrl}${love.visiteur.photo}`" alt="image"
                              class="shadow-sm rounded-circle w45" />
                          </router-link>

                          <i class="em em-heart position-absolute icon-reaction"></i>
                        </figure>

                        <h4 class="fw-700 text-grey-900 font-xssss mt-2 link-user">
                          <router-link v-tooltip.top-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: love.visiteur.id },
                          }">{{ love.visiteur.prenom }} {{ love.visiteur.nom }}
                          </router-link>
                          <span v-if="!love.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{ love.visiteur.mutualFriend }}
                            mutual
                            friends</span>
                          <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profil</span>
                        </h4>

                        <a :class="`add-btn-${love.visiteur.id}`" v-if="
                          !love.visiteur.isFriend &&
                          !love.visiteur.isMyProfil &&
                          !love.visiteur.isRequestSent
                        " @click="
  addFriend(
    love.visiteur.id,
    index,
    love.reaction_type
  )
" class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"></a>
                        <a cancel-data="cancel" :class="`add-btn-${love.visiteur.id}`"
                          v-else-if="love.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(love.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"></a>
                      </div>

                      <div v-if="doneLoves" class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1">
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleLoves && !doneLoves" />
                      <div v-if="Loves.length" v-observe-visibility="{
                        callback: visibilityChangedLoves,
                        throttle: 500,
                      }"></div>
                    </div>
                  </div>
                </tab>
                <tab v-if="isDislikesReady && totalDislike > 0"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                                                                                                                                                                          >(${totalDislike})</span>`"
                  prefix="<i class='fas fa-thumbs-down fa-1-5x  pe-1 orange'></i>" name="" id="Dislike">
                  <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 card-m">
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isDislikesReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div v-else v-for="(Dislike, index) in Dislikes"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1">
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link v-tooltip.left-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: Dislike.visiteur.id },
                          }">
                            <img :src="`${assetsBaseUrl}${Dislike.visiteur.photo}`" alt="image"
                              class="shadow-sm rounded-circle w45" />
                          </router-link>

                          <i class="em em-thumbsdown position-absolute icon-reaction"></i>
                        </figure>

                        <h4 class="fw-700 text-grey-900 font-xssss mt-2 link-user">
                          <router-link v-tooltip.top-start="'Show profil'" :to="{
                            name: 'feedvisiteur',
                            params: { id: Dislike.visiteur.id },
                          }">{{ Dislike.visiteur.prenom }} {{ Dislike.visiteur.nom }}
                          </router-link>
                          <span v-if="!Dislike.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{ Dislike.visiteur.mutualFriend }}
                            mutual
                            friends</span>
                          <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profil</span>
                        </h4>

                        <a :class="`add-btn-${Dislike.visiteur.id}`" v-if="
                          !Dislike.visiteur.isFriend &&
                          !Dislike.visiteur.isMyProfil &&
                          !Dislike.visiteur.isRequestSent
                        " @click="
  addFriend(
    Dislike.visiteur.id,
    index,
    Dislike.reaction_type
  )
" class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"></a>
                        <a cancel-data="cancel" :class="`add-btn-${Dislike.visiteur.id}`" v-else-if="
                          Dislike.visiteur.isRequestSent == 'receiver'
                        " @click="addFriend(Dislike.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"></a>
                      </div>

                      <div v-if="doneDislikes" class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1">
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleDislikes && !doneDislikes" />
                      <div v-if="Dislikes.length" v-observe-visibility="{
                        callback: visibilityChangedDislikes,
                        throttle: 500,
                      }"></div>
                    </div>
                  </div>
                </tab>
              </tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { Tabs, Tab } from "vue3-tabs-component";
import jaimeListAll from "../../pages/Jaimes/allReactions.vue";
import Loader from "../loader/loaderImage.vue";
import Skeleton from "../skeleton/SkeletonComment.vue";

export default {
  name: "BaseModal",
  components: {
    Loader,
    jaimeListAll,
    Tabs,
    Tab,
    Skeleton,
  },

  data() {
    return {
      textPub: "",
      token: "",
      myDropzone: "",
      isUploading: false,
      //-------(Arrays)-----
      jaimes: [],
      Likes: [],
      Loves: [],
      Dislikes: [],
      hahas: [],
      sads: [],
      Angrys: [],
      //-------(Ready)-----
      isAllReactionsReady: false,
      isLikesReady: false,
      isLovesReady: false,
      isDislikesReady: false,
      isHahasReady: false,
      isSadsReady: false,
      isAngrysReady: false,
      //-------(Total)-----
      totalReactions: 0,
      totalLikes: 0,
      totalLoves: 0,
      totalDislike: 0,
      totalHahas: 0,
      totalSads: 0,
      totalAngrys: 0,
      //-------(Pagination)-----
      //current
      pageAll: 1,
      pageLoves: 1,
      pageDislike: 1,
      pageLikes: 1,
      pageHahas: 1,
      pageSads: 1,
      pageAngrys: 1,
      //last
      lastPageAll: 1,
      lastPageLikes: 1,
      lastPageLoves: 1,
      lastpageDislike: 1,
      lastPageHahas: 1,
      lastPageSads: 1,
      lastpageAngrys: 1,
      //done
      doneAll: false,
      doneDislikes: false,
      doneLikes: false,
      doneLoves: false,
      doneHahas: false,
      doneSads: false,
      doneAngrys: false,
      //visible
      isVisibleLikes: false,
      isVisibleLoves: false,
      isVisibleAll: false,
      isVisibleDislikes: false,
      isVisibleHahas: false,
      isVisibleSads: false,
      isVisibleAngrys: false,
    };
  },
  props: {
    postId: Number,
  },
  created() {
    document.addEventListener("keyup", this.onClose);

    this.token = localStorage.getItem("token");
  },

  mounted() {
    this.getReactionsList();
    this.getReactionsListByType("1");
    this.getReactionsListByType("2");
    this.getReactionsListByType("7");
  },

  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },

  methods: {
    //------------------(AddFriend)-------------------

    removeFriend(id, i, type) {
      let btns = document.getElementsByClassName("add-btn-" + id);

      if (btns[0].classList.contains("btn-decline")) {
        for (let j = 0; j < btns.length; j++) {
          btns[j].removeAttribute("cancel-data");
          btns[j].classList.add("fa-user-plus", "btn-add");
          btns[j].classList.remove("fa-times", "btn-decline");
        }

        this.axios
          .post("amis/supprimerAmis", {
            visiteur_id: id,
          })
          .then((res) => {
            //this.getFriendStatus();
          });
      } else {
        this.addFriend(id, i, type);
      }
    },
    addFriend(id, i, type) {
      let btns = document.getElementsByClassName("add-btn-" + id);

      if (btns[0].hasAttribute("cancel-data")) {
        this.removeFriend(id, i, type);
      } else {
        //console.log(this.addFriendALLRef[i]);
        for (let j = 0; j < btns.length; j++) {
          btns[j].setAttribute("cancel-data", "cancel");
          btns[j].classList.remove(
            "fa-user-plus",
            "v-popper--has-tooltip",
            "btn-add"
          );
          btns[j].classList.add("fa-times", "btn-decline");
        }

        this.axios
          .post("amis/ajouterAmis", {
            visiteur_id: this.currentUser.id,
            receiver_id: id,
          })
          .then((res) => {
            //this.getFriendStatus();
          });
      }

      //----------------------------------
    },

    //------------------------------------------

    visibilityChanged(isVisibleAll) {
      //this.addFriendRef=[];

      if (!isVisibleAll) {
        return;
      }
      if (this.pageAll >= this.lastPageAll) {
        return (this.doneAll = true);
      }
      this.isVisibleAll = isVisibleAll;

      this.pageAll++;
      this.getReactionsList();
    },
    visibilityChangedLikes(isVisibleLikes) {
      //this.addFriendRef=[];

      if (!isVisibleLikes) {
        return;
      }
      if (this.pageLikes >= this.lastPageLikes) {
        return (this.doneLikes = true);
      }
      this.isVisibleLikes = isVisibleLikes;

      this.pageLikes++;
      this.getReactionsListByType("1");
    },
    visibilityChangedLoves(isVisibleLoves) {
      //this.addFriendRef=[];

      if (!isVisibleLoves) {
        return;
      }
      if (this.pageLoves >= this.lastPageLoves) {
        return (this.doneLoves = true);
      }
      this.isVisibleLoves = isVisibleLoves;

      this.pageLoves++;
      this.getReactionsListByType("2");
    },
    visibilityChangedDislikes(isVisibleDislikes) {
      //this.addFriendRef=[];

      if (!isVisibleDislikes) {
        return;
      }
      if (this.pageDislike >= this.lastpageDislike) {
        return (this.doneDislikes = true);
      }
      this.isVisibleDislikes = isVisibleDislikes;

      this.pageDislike++;
      this.getReactionsListByType("3");
    },
    getReactionsList() {
      this.axios
        .post(`reactionsMedia?page=${this.pageAll}`, {
          postID: this.postId,
        })
        .then((res) => {
          this.jaimes.push(...res.data.reactions.data);

          this.isAllReactionsReady = true;
          this.totalReactions = res.data.reactions.total;
          this.lastPageAll = res.data.reactions.last_page;
          console.log(this.jaimes);
        });
    },

    getReactionsListByType(type) {
      switch (type) {
        case "1":
          this.axios
            .post(`getMediaJaimeMediaListByReactType?page=${this.pageLikes}`, {
              postID: this.postId,
              ReactionType: type,
            })
            .then((res) => {
              this.Likes.push(...res.data.reactions.data);
              this.isLikesReady = true;
              this.totalLikes = res.data.reactions.total;
              this.lastPageLikes = res.data.reactions.last_page;
            });
          break;
        case "2":
          this.axios
            .post(`getJaimeListByReactType?page=${this.pageLoves}`, {
              postID: this.postId,
              ReactionType: type,
            })
            .then((res) => {
              this.Loves.push(...res.data.reactions.data);

              this.isLovesReady = true;
              this.totalLoves = res.data.reactions.total;
              this.lastPageLoves = res.data.reactions.last_page;
            });
          break;

        case "7":
          this.axios
            .post(`getJaimeListByReactType?page=${this.pageDislike}`, {
              postID: this.postId,
              ReactionType: type,
            })
            .then((res) => {
              // console.log(res.data.reactions.data);
              this.Dislikes.push(...res.data.reactions.data);

              this.isDislikesReady = true;
              this.totalDislike = res.data.reactions.total;
              this.lastpageDislike = res.data.reactions.last_page;
            });
          break;
      }
    },
    tabClicked(selectedTab) {
      //console.log("Current tab re-clicked:" + selectedTab.tab.name);
    },
    tabChanged(selectedTab) { },

    handleClose() {
      this.$emit("close");
    },
    handleRefresh() {
      this.$emit("refreshLogo");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.em,
.em-svg {
  height: 1em;
  width: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}

.em---1,
.em---1,
.em-thumbsup {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
}

.em---1.em-svg,
.em---1.em-svg,
.em-thumbsup.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44d.svg"),
    none;
}

.em--1,
.em-thumbsdown {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
}

.em--1.em-svg,
.em-thumbsdown.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44e.svg"),
    none;
}

.em-heart {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
}

.em-astonished {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f632.png");
}

.em-heart.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/2764.svg"),
    none;
}

.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;
  }
}

.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  //width: 40%;
  margin: 0 auto;
  border-radius: 10px;
}

.modal-container {
  margin: 30px 50px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}

.modal-header-txt {
  display: inline-block;
}

.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }

  float: right;
  font-size: 32px;
}

.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}

.loader {
  height: 50px !important;
}

.d-i-b,
.tabs-component-tabs {
  display: inline-block !important;
}

.is-active {
  border-bottom: 2px#333 solid !important;
  color: #2e58a6 !important;
  text-decoration: underline !important;
  font-size: 32px;
}

.chat-left {
  height: calc(100vh - 300px) !important;
}

.close-btn {
  top: 0px !important;
  right: 0px !important;
  z-index: 9999;
}

.rounded-circle {
  border-radius: 10px !important;
}

.btn-round-sm {
  border-radius: 5px !important;
}

.btn-add,
.btn-decline {
  border-radius: 5px !important;
  transition: all 0.1s ease-in-out;

  &:hover {
    cursor: pointer;

    color: #fff !important;
  }
}

.btn-add {
  &:hover {
    background-color: #2e58a6 !important;
  }
}

.btn-decline {
  &:hover {
    background-color: #f00 !important;
  }
}

.icon-reaction {
  bottom: -3px;
  right: -3px;
}

.red {
  color: #f00 !important;
}

.blue {
  color: #2e58a6 !important;
}

.orange {
  color: rgb(233, 113, 15) !important;
}

.all-count {
  top: -55px;
  left: 44px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}

.likes-count {
  top: -55px;
  left: 138px;
  font-size: 11px !important;
  // color:#2e58a6 !important;
}

.loves-count {
  top: -55px;
  left: 237px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}

.Dislikes-count {
  top: -55px;
  left: 350px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}

.hover-item {
  &:hover {
    border-bottom: 1px#2e58a6 solid !important;
    color: #2e58a6 !important;
  }
}

.rounded-modal {
  border-radius: 10px;
}

.yellow {
  color: #f0f331 !important;
}

.fa-1-5x {
  font-size: 1.5em !important;
}


@media (max-width: 767px) {

  .bg-greylight {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .card-m {
    padding-left: 0px !important;
    padding-right: 0px !important;


  }

  .modal-container {
    margin: -20px 10px !important;

  }

  .wrapper {
    margin: 0 auto;
    height: 100% !important;
  }

  .rounded-modal {
    border-radius: 0px;
    width: 100%;
    height: 100% !important;
  }

  .card-footer {
    position: relative;
    bottom: 0;
    border-top: 0;

  }

  .container-publish {
    background: none;
    // position: absolute;
    // bottom: 10px;
    // width: 100%;
  }

  .card-mm {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.pb-4 {
  padding-bottom: 17px !important;
}
</style>
