<template lang=""> 
  <template v-if="!isReady" >
        <Loader/>   
        <Skeleton />
  </template>

  

   <ModalJaimeCommentList v-if="isShowModalJaimeComment" @close="toggleModalJaimeCommentClose" :commentID="commentIdToJaimeList"></ModalJaimeCommentList>

   <ModalSocialMediaShare v-if="isShowModalShare" @close="toggleModalCloseShare" :postId="postIdToShare" :mediaId="mediaIdToShare" :textPubToShare="textPubToShare">
      
  </ModalSocialMediaShare>
 
   <ModalComment v-if="isShowModal" @close="toggleModalClose" scrollable title="Commentaire" :postId="postIdToCmt" :index="indexCmntedPubMedia" @refreshComment="refreshComment">
      
  </ModalComment>
  <ModalJaimeList v-if="isShowModalJaime" @close="toggleModalCloseJaime" :postId="postIdToJaimeList"></ModalJaimeList>

 <LightBox v-if="lightBox" @close="toggleLightBoxClose" :postId="postId" :mediaId="mediaId" :mediaPos="mediaPos" >
      
  </LightBox>
   
  <div v-if="isReady"
        class="
            ">
        <div v-if="feeds.length" id="data-wrapper">
            <template v-for="(x,index) in feeds" :key="x.id"> 
 
<!-- ----------------------------------------------(Debut Publication)------------------------------------->
                      <div :ref="setPubRef" class="card w-100 shadow-xss rounded-xxl border-0 p-3 mb-3" v-if="!x.isSignaled">
                     <!-- Test si proprietaire ou pas -->  
                           <div class="card-body p-0 d-flex">
                            <router-link :to="{ name : 'feedadresse' , params : { id : `${x.adresse.id}`}}">
                            <figure class="avatar me-1">
                                    <img :src="`${assetsBaseUrl}${x.adresse.logo}`" alt="image" class="shadow-md rounded-circle w45">
                               
                            </figure>
                        </router-link>
                           <h4 class="fw-700 text-grey-900 font-14 d-flex adrs-user">
                                <router-link :to="{ name : 'feedadresse' , params : { id : `${x.adresse.id}`}}">{{x.adresse.rs}} 


                                <template  v-if="feeds">
                          <span class="font-14 fw-500 mt-1 lh-3 text-grey-500" :ref="setVisibilityRef">
                          <template v-if="x.visiteur_id == currentUser.id" >
                          <i 
                          :class="x.visibility == 0 ? 'fa fa-globe-americas' :'fa fa-user-friends' "
                          id="dropdownMenu2" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="change-visibility btn-tgl-visib"></i>
                          
                         <div class="dropdown-menu dropdown-menu-start rounded-xl border-0 shadow-lg position-absolute" aria-labelledby="dropdownMenu2">
                           <div class="v-element v-top" @click.prevent ="changeVisibilty(x.id ,0 , x.visibility , index)" >
                          <i 
                          class="fa fa-globe-americas change-visibility "></i> Public
                          </div>
                          <div class="v-element v-bottom" @click.prevent ="changeVisibilty(x.id, 1 ,x.visibility , index)" >
                          <i 
                          class="fa fa-user-friends change-visibility " ></i> Amis
                          </div>
                            </div>  
  
                          </template>
                             
                               
                             
                            
                            
                              <template v-else>
                                
                          <i  v-if="x.visibility == 0" class="fa fa-globe-americas"></i>
                          
                        
                        <i click="" v-else class="fa fa-user-friends"></i>
                              </template>   
                              
    
                          </span>
                          </template>
                                
                                
                                </router-link>
                          <template v-if="x.visiteur.id != x.adresse.visiteur_id">
                            
                           <h4 class="fw-700 text-grey-900 font-14 d-inline-block user-pub">
                            <i class="fas fa-user"></i>
                                <router-link  :to="{name :'feedvisiteur', params: { id : x.visiteur_id } }">{{x.visiteur.prenom}} {{x.visiteur.nom}}             
                                </router-link>
                            </h4>
                        </template>       
                        
                      
                      <!-- Test si proprietaire ou pas -->      
                        <!-- <i class="fas fa-angle-right font-14 mt-0  me-1 ms-1"></i> -->
                        
                      
                          <!-- firas-->
                      
                                
                        
                        </h4>
                       
                          <template v-if="x.visiteur.id != x.adresse.visiteur_id">
                        
                        <div class="justify-self-end"><i class="far fa-calendar"></i> {{ $filters.timeAgo(x.datetime)}}</div> 
                                   
                        </template> 
                        <template v-else>
                         <div class="justify-self-end"> <i class="far fa-calendar"></i> {{ $filters.timeAgo(x.datetime)}} </div> 
                        </template>
                       
    <!-- ---------------------------------Drop Down menu Posts (à fixer) ------------------------------------------  -->
                            <a href="#" class="ms-2" id="dropdownMenu7" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ti-more-alt text-grey-900 font-14"></i></a>
                            <div class="dropdown-menu dropdown-menu-start dropdown-pub rounded-xxl border-0 shadow-lg" aria-labelledby="dropdownMenu7">
                            <h4 class="v-top">
                            <router-link :to="{ name: 'showPost', params: { id: `${x.id}` , mediaID: `${x.medias[0].id }` } }"  class="fw-600 text-grey-900 font-14 mt-0 v-top" role="button">Show</router-link></h4>
                            <h4 v-if="!isProp(x.visiteur_id,currentUser.id)" @click="showAlertShare(x.id,x.visiteur_id,x.medias[0].id,x.text_pub)" class="fw-600 text-grey-900 font-14 mt-0" role="button">Share</h4>        
                                
                            <h4 @click="showAlertDeletePub(x.id,index)" v-if="isProp(x.visiteur_id,currentUser.id)" role="button" class="fw-600 mb-0 text-grey-900 font-14 mt-0 v-bottom" >Delete</h4>
                            <h4 @click="showAlertReport(x.id,index)" v-if="!isProp(x.visiteur_id,currentUser.id)" class="fw-600 text-grey-900 font-14 mt-0 v-bottom" role="button">Report</h4>  
                            
                                
                            </div>

                        </div>
                        
    <!-- ----------------------------------Fin Drop Down ----------------------------------- --> 
                        <div class="card-body p-0 me-lg-5">
                            <!-- <p class="fw-500 text-grey-500 lh-26 font-14 w-100">{{x.text_pub}} <a href="#" class="fw-600 text-primary ms-2">Voir plus</a></p> -->
                        </div>
                        <div class="card-body d-block p-0">
                           <div class=""> 
<!-- **********************************Debut Container images Dynamic ***************************-->
 
<div class="row"> <!-- Row 1 Start -->


  <div :class="[x.medias.length == 1 ? 'col-xs-12 col-sm-12' : 'col-xs-6 col-sm-6','p-1 p-m-0']">
      <a role="button" class="position-relative d-block">
        <img @click="toggleLightBox(x.id,x.medias[0].id,0)" v-if="x.medias[0].type == '1'" :src="`${assetsBaseUrl}${x.medias[0].src}`"  :class="[x.medias.length == 1 ? 'h-1' : x.medias.length == 2 || x.medias.length == 3 ?  'h-2' :  x.medias.length == 4 ?  'h-2' : x.medias.length >= 5 ? 'h-3':'' ,'rounded-3 w-100 media-fit']" alt="image">
         <video v-else controls class="d-block w-100 blur-up lazyload media-fit" :class="[x.medias.length == 1 ? 'h-1' : x.medias.length == 2 || x.medias.length == 3 ?  'h-2' :  x.medias.length == 4 ?  'h-2' : x.medias.length >= 5 ? 'h-3' :'' ,'rounded-3 w-100']">   
                                        <source :src="`${assetsBaseUrl}${x.medias[0].src}`"
                                        type="video/mp4" >
                                        Sorry, your browser doesn't support embedded videos.
        </video>
     </a>
  </div>


  <div v-if="x.medias.length > 1" class="col-xs-6 col-sm-6 p-1 p-m-0" >
      <a  role="button"  class="position-relative d-block">
        <img @click="toggleLightBox(x.id,x.medias[1].id,1)" v-if="x.medias[1].type == '1'" :src="`${assetsBaseUrl}${x.medias[1].src}`"  :class="[x.medias.length == 2 || x.medias.length == 3 ?  'h-2' : x.medias.length == 4 ?  'h-2' :  x.medias.length >= 5 ? 'h-3':'' ,'rounded-3 w-100 media-fit']" alt="image">
         <video v-else controls class="d-block blur-up lazyload media-fit" type="video/mp4" :class="[x.medias.length == 1 ? 'h-1' : x.medias.length == 2 || x.medias.length == 3 ?  'h-2' : x.medias.length == 4 ?  'h-2' :  x.medias.length >= 5 ? 'h-3': '' ,'rounded-3 w-100']">   
                                        <source :src="`${assetsBaseUrl}${x.medias[1].src}`"
                                        >
                                        Sorry, your browser doesn't support embedded videos.
        </video>
     </a>
  </div>


</div> <!-- Row 1 End -->


<div v-if="x.medias.length>2"  class="row"> <!-- Row 2 Start -->


  <div v-if="x.medias.length > 2"  :class="[x.medias.length == 3 ? 'col-xs-12 col-sm-12' : x.medias.length == 4 ?  'col-xs-6 col-sm-6' : 'col-xs-4 col-sm-4' ,'p-1 p-m-0']">
      <a role="button" class="position-relative d-block">
        <img @click="toggleLightBox(x.id,x.medias[2].id,2)" v-if="x.medias[2].type == '1'" :src="`${assetsBaseUrl}${x.medias[2].src}`" :class="[x.medias.length == 3 ?  'h-3' :   x.medias.length == 4 ?  'h-2' : x.medias.length >= 5 ? 'h-5' :'' ,'rounded-3 w-100 media-fit']" alt="image">
          <video v-else controls class="d-block blur-up lazyload media-fit" type="video/mp4" :class="[x.medias.length == 3 ?  'h-3' :   x.medias.length == 4 ?  'h-2' :  x.medias.length >= 5 ? 'h-5' :'','rounded-3 w-100']" >   
                                        <source :src="`${assetsBaseUrl}${x.medias[2].src}`"
                                        >
                                        Sorry, your browser doesn't support embedded videos.
        </video>
     </a>
  </div>


  <div v-if="x.medias.length >= 4"    :class="[x.medias.length == 4 ?  'col-xs-6 col-sm-6' : 'col-xs-4 col-sm-4' ,'p-1 p-m-0']">
      <a  role="button"  class="position-relative d-block">
        <img @click="toggleLightBox(x.id,x.medias[3].id,3)" v-if="x.medias[3].type == '1'" :src="`${assetsBaseUrl}${x.medias[3].src}`"  :class="[x.medias.length == 4 ?  'h-2' : x.medias.length >= 5 ? 'h-5' :'' ,'rounded-3 w-100 media-fit']" alt="image">
          <video v-else controls class="d-block blur-up lazyload media-fit" type="video/mp4" :class="[x.medias.length == 4 ?  'h-2' : x.medias.length >= 5 ? 'h-5' :'','rounded-3 w-100']" >   
                                        <source :src="`${assetsBaseUrl}${x.medias[3].src}`"
                                        >
                                        Sorry, your browser doesn't support embedded videos.
        </video>
     </a>
  </div>

  <div v-if="x.medias.length >= 5"  class="col-xs-4 col-sm-4 p-1 p-m-0">
      <a role="button"   class="position-relative d-block">
        <img @click="toggleLightBox(x.id,x.medias[4].id,4)" v-if="x.medias[4].type == '1'"  :src="`${assetsBaseUrl}${x.medias[4].src}`"  class="rounded-3 w-100 h-5 media-fit" alt="image">
          <video v-else controls class="d-block blur-up lazyload rounded-3 w-100 h-5 media-fit" type="video/mp4">   
                                        <source :src="`${assetsBaseUrl}${x.medias[4].src}`"
                                        >
                                        Sorry, your browser doesn't support embedded videos.
        </video>
        <span @click="toggleLightBox(x.id,x.medias[4].id,4)" v-if="x.medias.length > 5" class="img-count  font-sm text-white ls-3 fw-600 img-count-m">
                                            <b> +{{x.medias.length-5}}</b>
       </span>
     </a>
  </div>


</div> <!-- Row 2 End -->
<div class="position-relative jaime-comment">
  <div class="card-body d-flex mt-0 btn-jaime-list" >
    <template v-if="isJaimeGroupReady ">
                            <a v-tooltip.top-start="'Show all'" @click="toggleModalJaime(x.id,index)" v-if="jaimesGroup[index]" class="emoji-bttn d-flex align-items-center fw-400 text-grey-900 text-dark lh-20 font-10 me-2">
                            
                            <i v-if="jaimesGroup[index].hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="jaimesGroup[index].hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="jaimesGroup[index].hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="jaimesGroup[index].hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="jaimesGroup[index].hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="jaimesGroup[index].hasOwnProperty('6')" class="em em-angry"></i>
                               <i v-if="jaimesGroup[index].hasOwnProperty('7')" class="em em-thumbsdown"></i> 
                            </a>
                            <a v-tooltip.top-start="'Show all'"   @click="toggleModalJaime(x.id,index)" v-else class="emoji-bttn">
                                <i v-if="x.jaimesCountByType.hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="x.jaimesCountByType.hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="x.jaimesCountByType.hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="x.jaimesCountByType.hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="x.jaimesCountByType.hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="x.jaimesCountByType.hasOwnProperty('6')" class="em em-angry"></i> 
                              <i v-if="x.jaimesCountByType.hasOwnProperty('7')" class="em em-thumbsdown"></i> 

                            </a>
       </template>
       <template v-else>
         <a class="emoji-bttn d-flex align-items-center fw-400 text-grey-900 text-dark lh-20 font-10 me-2">
         <loaderJaime></loaderJaime>
         </a>
       </template>                           
                             <span v-show="(jaimesGroup[index] || x.jaimes.length >= 1 ) " >
                               <template v-if="x.jaimes"><span :ref="setJaimeCountRef"  @click="toggleModalJaime(x.id,index)" class="fw-700 font-xssss text-grey-500 ms-1">{{ x.jaimes.length}}</span> </template> 
                             </span>
  
                            <div @mouseover="hoverTwo" @mouseleave="unhover(index)" class="emoji-wrap hidden" :ref="setSocialactRef">
                                <ul class="emojis list-inline mb-0">
                                    <li role="button" @click="jaime(currentUser.id,x.id,index)" class="emoji list-inline-item" title="Like"><i class="em em---1"></i> </li>
                                    <li role="button" @click="jadore(currentUser.id,x.id,index)" class="emoji list-inline-item" title="Love"><i class="em em-heart"></i></li>

                                    <li role="button" @click="dislike(currentUser.id,x.id,index)" class="emoji list-inline-item" title="Dislike"><i class="em em-thumbsdown"></i></li>

                                </ul>
                            </div> 
                            
                            <a @click="showComments(x.id,index)" class="ms-auto align-items-center font-xxs fw-700 text-grey-900 text-dark lh-26 font-14 comment-count" :ref="setCommentCountRef"   v-show="x.commentaires.length >= 1">{{x.commentaires.length }} Comments</a>  
            </div>                                  
                             
                            
                           <!-- <i class="feather-message-circle text-dark text-grey-900 btn-round-sm font-lg"></i> -->
                        </div>
                        <div class="social-actions ps-auto-m">
                            <div @mouseleave="unhoverr(index)" @click="deletReaction(index,currentUser.id,x.id,x.jaimes.length)" @touchstart="hover(index,$event)" @mouseover.self="hover(index,$event)" class="p-2 lh-28  text-grey-800 text-center font-xsss fw-700 col-3 d-inline-block social-action" :class="x.react ? 'socialClicked' : ''" :ref="setBtnReactRef">
                             
                             
                               <template v-if="x.react == false">
                              <i class="feather-thumbs-up  btn-round-sm"></i><span class="">Like</span>
                             </template>
                              
                              <template v-else-if="x.react == '1' ">
                              <i class="feather-thumbs-up  btn-round-sm socialClicked" style="color:blue !important"></i><span class="socialClicked" style="color:blue">Like</span>
                             </template>
                               <template v-else-if=" x.react == '2' ">
                              <i class="feather-heart btn-round-sm socialClicked" style="color:red !important"></i><span class="socialClicked" style="color:red">Love</span>
                             </template>
                               <template v-else-if="x.react == '3' ">
                              <i class="em em-astonished btn-round-sm socialClicked" style="color:rgb(247, 177, 37) !important"></i><span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>
                             </template>
                             <template v-else-if=" x.react == '4' ">
                              <i class="em em-rolling_on_the_floor_laughing btn-round-sm socialClicked" style="color:rgb(247, 177, 37) !important"></i><span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>
                             </template>
                             <template v-else-if=" x.react == '5' ">
                              <i class="em em-cry btn-round-sm socialClicked" style="color:rgb(247, 177, 37) !important"></i><span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>
                             </template>

                             <template v-else-if=" x.react == '6' ">
                              <i class="em em-angry btn-round-sm socialClicked" style="color:rgb(247, 177, 37) !important"></i><span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>
                             </template>
                              <template v-else-if=" x.react == '7' ">
                              <i class="em em-thumbsdown btn-round-sm socialClicked" style="color:rgb(233, 113, 15) !important"></i><span class="socialClicked" style="color:rgb(233, 113, 15)">Dislike</span>
                             </template>
                             
                             
                              
                              
                              
                              </div>

                            <div @click="focusComment(index)" class="p-2 lh-28  text-grey-800 text-center font-xsss fw-700 col-5 d-inline-block social-action" ><i class="feather-message-circle text-dark text-grey-900 btn-round-sm "></i>Comment</div>
                            <div v-if="!isProp(x.visiteur_id,currentUser.id)" @click="showAlertShare(x.id,x.visiteur_id,x.medias[0].id,x.text_pub)" class="p-2 lh-28  text-grey-800 text-center font-xsss fw-700 col-4 d-inline-block social-action" ><i class="feather-share-2 text-grey-900 text-dark btn-round-sm "></i>Share</div>
                           
                             <div v-else @click="toggleModalShare(x.id,x.medias[0].id,x.text_pub)" class="p-2 lh-28  text-grey-800 text-center font-xsss fw-700 col-4 d-inline-block social-action" ><i class="feather-share-2 text-grey-900 text-dark btn-round-sm "></i>Share</div>
                        </div>

                      
                        
                      <div class="comments-container hide" :ref="setCommentListRef">

                         <template v-if="!isCommentReady" >
                        <!-- <div class="preloader"></div> -->
                        <div class="mt-4">
                            <Loader/>
                        </div>   
                            <SkeletonComment />
                        </template> 
                        
                        <template v-if="isCommentReady" >
                        <a @click="getPrevComments(x.id,index)" v-if="this.pageComment < this.lastCommentPage" class="p-2 lh-28  text-grey-600 text-center font-xsss fw-500 comment-pagination">See previous comments</a>
                        <span class="float-right" v-if="this.lastCommentPage >= 2">{{this.pageComment}}/{{this.lastCommentPage}}</span>
                       <div class="fix-float"></div> 
                          <template v-if="!isPrevCommentReady" >
                            <Loader/>
                             <!-- <SkeletonComment />    -->
                          </template> 
                        
                            <div class="social-comment" v-for="(comment,indexComment) in comments[index]" :key="isCommentReady">

                              <div class="card-body d-block ms-3 comment-card "> 

                                <figure class="avatar position-absolute left-0 ms-2 mt-1">
                                  <img :src="`${assetsBaseUrl}${comment.visiteur.photo}`" alt="image" class="shadow-sm rounded-circle w35">
                                  </figure>
                                  <div class="chat p-2 bg-greylight rounded-xxl d-block text-left theme-dark-bg">
                                    <router-link v-tooltip.top-start="'Visit profile'" :to="{name :'feedvisiteur', params: { id : comment.visiteur_id } }" class="fw-600 text-grey-900">{{comment.visiteur.prenom}} {{comment.visiteur.nom}}
                                    
                                    </router-link>

                                   
                                    
                                    <a v-if="isCommentUser(currentUser.id,x.visiteur_id) || isCommentUser(comment.visiteur.id,currentUser.id)" href="#" class="delete-comment-btn float-right text-grey-800 font-xsss" id="dropdownMenu9" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ti-more-alt text-grey-900 font-14"></i></a>
                            <div v-if="isCommentUser(currentUser.id,x.visiteur_id)  || isCommentUser(comment.visiteur.id,currentUser.id)" class="dropdown-menu dropdown-menu-start rounded-cc border-0 shadow-lg edit-comment" aria-labelledby="dropdownMenu7">
                             <!-- @click.prevent="deleteComment(comment.id,x.id,index)" -->
                             <div @click="showAlertDeleteCom(comment.id,x.id,index)"
                                role="button"  class="del-up v-top card-body ">
                                    <!-- <i class="fa fa-trash-alt text-grey-500 me-3 font-lg"></i> -->
                                    <h4 class="fw-600 text-grey-900 font-14 d-i">
                                        
                                          Delete 
                                          <!-- <span class="d-block font-14 fw-500 lh-3 text-grey-500"></span> -->
                                          </h4>
                                </div>
                                <div v-if="isCommentUser(comment.visiteur.id,currentUser.id)"  @click.prevent="editComment(comment.id,index)" role="button"  class="del-up v-bottom card-body ">
                                    <!-- <i class="fa fa-edit text-grey-500 me-3 font-lg"></i> -->
                                    <h4 class="fw-600 text-grey-900 font-14 d-i">
                                        
                                          Edit 
                                          <!-- <span class="d-block font-14 fw-500 lh-3 text-grey-500"></span> -->
                                          </h4>
                                </div>
                             
                            </div>
                              
                   
                                  <p class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0">{{comment.texte_com}}</p>
                                  <div class="tags lh-28 ms-1 font-xssss fw-600 d-i-b" >
                                    <ul class="d-i-b me-1">
                                        <li v-for="tagAmis in comment.amis_tags" class="d-i-b  me-1">
                                          <router-link :to="{name :'feedvisiteur', params: { id : tagAmis.visiteur.id } }">
                                          @{{tagAmis.visiteur.prenom}}
                                          </router-link>
                                        </li>
                                    </ul>
                                      <ul  class="d-i-b">
                                        <li v-for="tagAdresse in comment.adresse_tags" class="d-i-b  me-1">
                                            <router-link :to="{name :'feedadresse', params: { id : tagAdresse.adresse.id } }">
                                          @{{tagAdresse.adresse.rs}}
                                          </router-link>
                                        </li> 
                                    </ul>
                                    </div>


         <div class="count-jaime-comment" >
           
              <template v-if="isJaimeGroupCommentReady[comment.id] == true ">
                  <div class="jaime-group-comment d-i-b">
                            <a v-tooltip.top-start="'Show all'" @click="toggleModalJaimeComment(comment.id,index)" v-if="jaimesGroupComment[comment.id]" class="emoji-bttn fw-400 text-grey-900 text-dark lh-20 font-10">
                            
                            <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('6')" class="em em-angry"></i>
                               <i v-if="jaimesGroupComment[comment.id].hasOwnProperty('7')" class="em em-thumbsdown"></i> 
                            </a>
                            <a v-tooltip.top-start="'Show all'"   @click="toggleModalJaimeComment(comment.id,index)" v-else class="emoji-bttn">
                                <i v-if="comment.countJaimeByComment.hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="comment.countJaimeByComment.hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="comment.countJaimeByComment.hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="comment.countJaimeByComment.hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="comment.countJaimeByComment.hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="comment.countJaimeByComment.hasOwnProperty('6')" class="em em-angry"></i> 
                              <i v-if="comment.countJaimeByComment.hasOwnProperty('7')" class="em em-thumbsdown"></i> 

                            </a>
        </div>                    
       </template>
       <template v-else>
         <a class="emoji-bttn fw-400 text-grey-900 text-dark lh-20 font-10 me-2 d-i-b loader-jaime-comment">
         <LoaderJaimeComment></LoaderJaimeComment>
         </a>
       </template> 
                                     
                                     <span class="comment-jaime-count" :id="'commentJaimeCount'+comment.id">{{ comment.jaimes.length}}</span> 
                                   </div>
<!-- ****************************************************************************** -->






<!-- **************************************************************************** -->
                                  </div>
                                  <div class="fix-float d-i-b"></div> 
                                   <img class="rounded-5 w-50 d-block mediaComment" v-if="comment.medias.length >= 1" :src="`${assetsBaseUrl}${comment.medias[0].src}`"> 




                                  
                           
                                  <div :id="'btnJaimeComment'+comment.id" @mouseleave="unhoverrComment(indexComment,comment.id)" @click="deletReactionComment(indexComment,currentUser.id,comment.id,x.jaimes.length)" @mouseover.self="hoverJaimeComment(indexComment,comment.id,$event)"
                                  @touchstart.self="hoverJaimeComment(indexComment,comment.id,$event)"  class="fw-400 text-grey-800 font-xssss mt-0 mb-1 jaimeComment d-i-b" :class="comment.react ? 'socialClicked' : ''">

                                  <template v-if="comment.react == false">
                              <span class="">Like</span>
                             </template>
                              
                              <template v-else-if=" comment.react == '1' ">
                              <span class="socialClicked" style="color:blue">Like</span>
                             </template>
                               <template v-else-if="comment.react == '2' ">
                             <span class="socialClicked" style="color:red">Love</span>
                             </template>
                               <template v-else-if=" comment.react == '3' ">
                             <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>
                             </template>
                             <template v-else-if="comment.react == '4' ">
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>
                             </template>
                             <template v-else-if="comment.react == '5' ">
                            <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>
                             </template>

                             <template v-else-if="comment.react == '6' ">
                             <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>
                             </template>
                             
                            
                                 
                            </div>
                             <span v-if="comment.updated_at != comment.created_at " class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0 datetimeComment">updated {{ $filters.timeAgo(comment.updated_at) }}</span>
                                  <span v-else class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0 datetimeComment">{{ $filters.timeAgo(comment.created_at) }}</span>
                             <div class="position-relative">
                                    <div :id="'commentJaimeTooltip'+comment.id" @mouseover="hoverTwoComment" @mouseleave="unhoverComment(indexComment,comment.id)" class="emoji-wrap-comment emoji-wrap emoji-wrap-comment hidden" >
                                    <ul class="emojis list-inline mb-0 emoji-wrap-comment">
                                    <li role="button" @click="jaimeComment(currentUser.id,comment.id,indexComment,index)" class="jaimeComBtn emoji list-inline-item" title="Love"><i class="em em---1"></i> </li>
                                    <li role="button" @click="jadoreComment(currentUser.id,comment.id,indexComment,index)" class="emoji list-inline-item" title="Love"><i class="em em-heart"></i></li>
                                    <li  @click="hahaComment(currentUser.id,comment.id,indexComment,index)" role="button" class="emoji list-inline-item" title="Haha"><i class="em em-rolling_on_the_floor_laughing"></i> </li>
                                    <li @click="wouahComment(currentUser.id,comment.id,indexComment,index)" role="button" class="emoji list-inline-item" title="Wouah"><i class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i></li>
                                    <li  @click="tristeComment(currentUser.id,comment.id,indexComment,index)" role="button" class="emoji list-inline-item" title="Sad"><i class="em em-cry"></i></li>
                                    <li @click="grrrComment(currentUser.id,comment.id,indexComment,index)"  role="button" class="emoji list-inline-item" title="Angry"><i class="em em-angry"></i> </li>
                                </ul>
                            </div> 


                                 
                                  
                                   </div>
                              </div>
                        
                            </div>
                            
                            
                             </template> 
                      </div>
                       <div class="mt-1 tags lh-28  font-xssss fw-600 hide" :ref="setTaggedAreaRef">
                         <ul class="d-i-b me-1">
                           <li class="d-i-b  me-1" v-for="friend in taggedFriends">@{{friend.prenom}} <i @click="deleteTagFriend(friend.id,index)" class="fas fa-times delete-tag-btn"></i></li>
                         </ul>
                          <ul  class="d-i-b">
                           <li class="d-i-b  me-1" v-for="adresse in taggedAdresses">@{{adresse.rs}} <i @click="deleteTagAdresse(adresse.id,index)" class="fas fa-times delete-tag-btn"></i></li>
                         </ul>
                         </div>
                        <div class="card-body p-0 mt-0 position-relative">
                            <figure class="avatar position-absolute ms-2 mt-1 top-5"><img :src="`${assetsBaseUrl}${currentUser.photo}`" alt="image" class="shadow-sm rounded-circle w30">
                           </figure>
                            
                            <div>
                             
                            <input @blur="unfocusComment(index)" type="text" @keyup="showAnnuler(index,$event) , checkvide($event) , showtTooltipTagFriend($event,index)" @keyup.esc="cancelComment(index)" @keyup.enter="commenter(x.id,currentUser.id,index,$event)" :ref="setCommentRef"  name="message" class="h60 bor-0 w-100 rounded-xxl p-2 ps-10 font-14 text-grey-500 fw-500 border-light-md theme-dark-bg" cols="30" rows="1" placeholder="Écrivez un commentaire..." autocomplete="off" required>  
                            <i @click="toggleModal(x.id,index) , getindexCmntedPubMedia(index)" class="feather-camera text-grey-900 text-dark btn-round-sm"></i>  
                             <!-- <i @click="showtTooltipTagFriend($event,index)" class="fad fa-tags text-grey-900 text-dark btn-round-sm"></i> -->
                            </div>
                            <ul class="autocomplete-results hide rounded-xxl" :ref="setTooltipTagFriend">
                              <div class="form-group mb-0 icon-input-tag">
                              <input :ref="setInputTagFriend" @keyup="findTag($event,index)" class="h60 bor-0 w-100 rounded-xxl p-2 ps-5 font-14 text-grey-500 fw-500 border-light-md-tag theme-dark-bg" type="text" placeholder="Find friend, adress.."> 
                              <i class="feather-search font-sm text-grey-400 right-search"></i>
                              </div>
                            <li v-for="(tagFriend,indexResult) in tagFriendsList[index]" class="autocomplete-result d-flex elipsis">
                              <template v-if="indexResult < 5">
                                <!-- firend -->
                                <div v-if="tagFriend.hasOwnProperty('photo')" class="d-flex" @click="tagFriends(index,tagFriend.id,tagFriend.prenom,'user')">
                               <figure class="avatar me-2 mb-0"> 
                                <img  :src="`${assetsBaseUrl}${tagFriend.photo}`" alt="image" class="shadow-sm rounded-circle w45">   
                              </figure>
                               <!-- d-flex friends-tag -->
                              <div class="d-flex friends-tag">
                              <div class="d-flex">
                              <h4  class="fw-700 text-grey-900 font-xssss  link-user">
                              <span> {{tagFriend.prenom}} </span></h4>
                              <h4  class="fw-700 text-grey-900 font-xssss ms-1 link-user">
                              <span> {{tagFriend.nom}} </span>
                              </h4>
                              </div>
                             
                              <div>
                              <span v-if="tagFriend.mutualFriend > 0" class="d-block font-xssss fw-500 lh-3 text-grey-500 get-mutual-friend">{{tagFriend.mutualFriend}} mutual friends</span>
                              <span v-else class="d-block font-xssss fw-500 lh-3 text-grey-500">no mutual friends</span>
                             </div>
                              </div>
                              </div>
                              <!-- adresse -->
                               <div v-if="tagFriend.hasOwnProperty('logo')" class="d-flex" @click="tagFriends(index,tagFriend.id,tagFriend.rs,'adress')">
                               <figure class="avatar me-2 mb-0"> 
                                <img  :src="`${assetsBaseUrl}${tagFriend.logo}`" alt="image" class="shadow-sm rounded-circle w45">   
                              </figure>
                               <!-- d-flex friends-tag -->
                              <div class="d-flex friends-tag">
                              <div class="d-flex">
                              <h4  class="fw-700 text-grey-900 font-xssss  link-user">
                              <span> {{tagFriend.rs}} </span></h4>
                              
                              </div>
                             
                              <div>
                              <span  class="d-block font-xssss fw-500 lh-3 text-grey-500 get-mutual-friend">{{tagFriend.adresse}}</span>
                              
                             </div>
                              </div>
                              </div>
                             </template>
                              </li>
                              
                            </ul>  
                            <span :ref="setAnnulerCommentBtnRef" class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0 cancelComment hide">Press escape to  <a class="annulerCommentBtn" @click="cancelComment(index)">cancel</a> and @ to <a class="annulerCommentBtn" @click="showtTooltipTagFriend($event,index)">tag</a></span>  

                            
                                           
                        </div>

<!-- ****************************************Fin Container images Dynamic ***********************-->

                         </div>
                        </div>
                     </div>
<!-- ------------------------------------------------Fin Tof ---------------------------------------->    

      </template> <!-- Fin For -->
           
              <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-4
            mb-3
            mt-3
        "
    >
        
No other feeds
    </div>
        </div> <!-- Fin data-wrapper -->
        <div v-if="feeds.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>
        <div v-else class="text-center  p-4">No feeds found</div>
  </div>
  
   
 <Loader v-show="isVisible && !done"/>                    
</template>
<script>
import Skeleton from "../../../layouts/skeleton/Skeleton.vue";
import SkeletonComment from "../../../layouts/skeleton/SkeletonComment.vue";
import Loader from "../../../layouts/loader/loader.vue";
import { watchEffect } from "vue";
import ModalComment from "../../../layouts/modal/modalComment.vue";
import LightBox from "../../../layouts/lightBox.vue";
import loaderJaime from "../../../layouts/loader/loaderJaime.vue";
import LoaderJaimeComment from "../../../layouts/loader/loaderJaimeComment.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
import ModalJaimeList from "../../../layouts/modal/modalJaimeList.vue";
import ModalSocialMediaShare from "../../../layouts/modal/modalSocialMediaShare.vue";
import ModalJaimeCommentList from "../../../layouts/modal/modalJaimeCommentList.vue";
const controller = new AbortController();
export default {
  name: "FeedList",
  components: {
    Skeleton,
    Loader,
    SkeletonComment,
    ModalComment,
    LightBox,
    loaderJaime,
    ModalJaimeList,
    ModalSocialMediaShare,
    LoaderJaimeComment,
    ModalJaimeCommentList,
  },
  setup() {
    watchEffect(() => { }, {
      flush: "post",
    });
  },
  /* ------------------------------------------------- (DATA)--------------------------------- */
  watch: {
    // watching top-level property
    currentIndexPostToCmt(newVal, oldVal) {
      if (newVal != oldVal) {
        this.taggedFriends = [];
        this.taggedAdresses = [];
      }
    },
    // profilID(newprofilID, oldprofilID) {
    //   if (newprofilID != oldprofilID) {
    //     this.getFeeds();
    //   }
    // },
    "$route.params.id": {
      handler: function (value) {
        this.getFeeds();
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      profilID: null,
      //tag---------------
      taggedFriends: [],
      taggedAdresses: [],
      currentIndexPostToCmt: 0,
      currentCmtLength: 0,
      //----------------------

      isShowModalJaimeComment: false,
      commentIdToJaimeList: null,
      jaimesGroupComment: [],
      isJaimeGroupCommentReady: [],
      tagFriendsList: [],
      //searchTagFriend---------------
      tagSearchKeyNom: null,
      tagSearchKeyPrenom: null,
      //-----------------------------
      feeds: [],
      jaimes: [],
      comments: [],
      isReady: false,
      isShowModal: false,
      isShowModalShare: false,
      isShowModalJaime: false,
      lightBox: false,
      postId: Number,
      mediaId: Number,
      mediaPos: Number,
      postIdToCmt: Number,
      postIdToJaimeList: Number,
      indexCmntedPubMedia: Number,
      commentRefreshKey: false,
      isJaimeGroupReady: true,
      //-------------PAGE-------------
      lastPage: 1,
      lastCommentPage: 1,
      page: 1,
      pageComment: 1,
      isPrevCommentReady: true,

      //-------------REFS-------------
      pubRef: [],
      socialactRef: [],
      commentRef: [],
      jaimesGroup: [],
      btnReactRef: [],
      jaimeCountRef: [],
      //---------------(comment)--------------
      commentCountRef: [],
      commentListRef: [],
      annulerCommentBtnRef: [],
      inputTagFriend: [],
      taggedAreaRef: [],
      //-------------------------------------
      visibilityRef: [],
      tooltipTagFriend: [],
      //--------React---------------
      socialClicked: false,
      isCommentReady: true,
      isVisible: false,
      done: false,
      isPhoto: "App\\Models\\Photo",
      isVideo: "App\\Models\\Video",
      excecuted: false,
      show: false,
      //------Hover-----------------
      hovering: false,
      hoveringTwo: false,
      showed: false,
      hoverTimer: "",
      //-------Hover comment-------------------
      hoveringComment: false,
      hoveringTwoComment: false,
      showedComment: false,
      hoverTimerComment: "",
      //--------Share-------------------
      postIdToShare: Number,
      mediaIdToShare: Number,
      textPubToShare: String,
    };
  },
  /* ------------------------------------------------- (LifeCycle Hooks)--------------------------------- */
  created() {
    document.addEventListener("keyup", this.onClose);
  },
  destroyed() {

    controller.abort()
  },
  mounted() {
    this.profilID = this.$route.params.id;
    this.getFeeds();
    this.$store.dispatch("getuser");
    this.checkHover();
    this.checkHoverComment();
  },

  /* ------------------------------------------------- (Computed)----------------------------------------------- */
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
  /*  -------------------------------------------------------(Methods) -------------------------------------------*/
  methods: {
    toggleModalJaimeComment(commentId, index) {
      this.isShowModalJaimeComment = !this.isShowModalJaimeComment;
      this.commentIdToJaimeList = commentId;
    },
    toggleModalJaimeCommentClose() {
      this.isShowModalJaimeComment = false;
    },

    signalerPost(postId, index) {
      this.axios
        .post("signaler", {
          visiteur_id: this.currentUser.id,
          signalable_id: postId,
          signalable_type: "App\\Models\\Publication",
        })
        .then((res) => { });
    },
    showAlertDeleteCom(commentId, pubId, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this comment",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteComment(commentId, pubId, index);
        }
      });
    },
    showAlertDeletePub(pubId, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePub(pubId, index);
        }
      });
    },
    showAlertShare(pubId, userId, mediaId, textPub) {
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            0: "Public",
            1: "Friends",
            2: "Social media",
          });
        }, 100);
      });

      const { value: visibility } = this.$swal({
        title: "Select visibility",
        input: "radio",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#2e58a6",
        confirmButtonText: "Share it",
        inputOptions: inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "You need to choose something!";
          } else if (value == "0") {
            this.axios
              .post("partage", {
                visiteur_id: userId,
                partageable_id: pubId,
                partageable_type: "App\\Models\\Publication",
                etat: "0",
              })
              .then((res) => {
                toast.success("Post shared successfully ", {
                  position: "bottom-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
          } else if (value == "1") {
            this.axios
              .post("partage", {
                visiteur_id: userId,
                partageable_id: pubId,
                partageable_type: "App\\Models\\Publication",
                etat: "1",
              })
              .then((res) => {
                toast.success("Post shared successfully ", {
                  position: "bottom-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
          } else if (value == "2") {
            this.toggleModalShare(pubId, mediaId, textPub);
          }
        },
      });
    },
    showAlertReport(postId, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to report this post",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, report it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.pubRef[index].remove();
          toast.error("Post reported ", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.signalerPost(postId, index);
        }
      });
    },
    changeVisibilty(postId, newVisi, oldVisi, i) {
      this.axios
        .post(`publication/${postId}`, { visibility: newVisi })
        .then((res) => {
          if (newVisi == 0) {
            this.visibilityRef[i].firstElementChild.classList.remove(
              "fa-user-friends"
            );
            this.visibilityRef[i].firstElementChild.classList.add(
              "fa-globe-americas"
            );
          } else {
            this.visibilityRef[i].firstElementChild.classList.add(
              "fa-user-friends"
            );
            this.visibilityRef[i].firstElementChild.classList.remove(
              "fa-globe-americas"
            );
          }
          toast.success("Visibility changed successfully ", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    checkvide(event) {
      if (event.target.value == "") {
        // return false
        //console.log('vide');
      } else {
        // return true
        //console.log('non svide');
      }
    },
    deletePub(postId, index) {
      this.pubRef[index].remove();
      toast.error("Post removed ", {
        position: "bottom-right",
        timeout: 3000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.axios.delete(`/publication/${postId}`).then((res) => { });
    },
    //------------(Share)------------------
    toggleModalShare(postId, mediaId, textPub) {
      this.isShowModalShare = !this.isShowModalShare;
      this.postIdToShare = postId;
      this.mediaIdToShare = mediaId;
      this.textPubToShare = textPub;
    },
    toggleModalCloseShare() {
      this.isShowModalShare = false;
    },

    //-------------------------------------

    toggleModal(postId, index) {
      this.isShowModal = !this.isShowModal;
      this.postIdToCmt = postId;
    },
    toggleModalClose() {
      this.isShowModal = false;
    },
    //----------------------------------------------------
    toggleModalJaime(postId, index) {
      this.isShowModalJaime = !this.isShowModalJaime;
      this.postIdToJaimeList = postId;
    },
    toggleModalCloseJaime() {
      this.isShowModalJaime = false;
    },
    //-----------------------------------------------------

    getindexCmntedPubMedia(index) {
      this.indexCmntedPubMedia = index;
    },

    toggleLightBox(postId, mediaId, mediaPos) {
      this.lightBox = !this.lightBox;
      this.postId = postId;
      this.mediaId = mediaId;
      this.mediaPos = mediaPos;
    },
    toggleLightBoxClose() {
      this.lightBox = false;
    },
    isProp(currentUserId, visiteurId) {
      if (currentUserId == visiteurId) {
        return true;
      } else {
        return false;
      }
    },

    checkHover() {
      setInterval(() => {
        if (this.hovering || this.hoveringTwo) {
          clearTimeout(this.hoverTimer);
        }
      }, 1000);
    },
    checkHoverComment() {
      setInterval(() => {
        if (this.hoveringComment || this.hoveringTwoComment) {
          clearTimeout(this.hoverTimerComment);
        }
      }, 1000);
    },

    /* --------------------------------------------------- (Feeds)----------------------------------------------- */
    getFeeds() {
      this.axios
        .get(`/publications/visiteur/${this.profilID}?page=${this.page}`,
          {
            signal: controller.signal
          })
        .then((res) => {
          this.feeds.push(...res.data.feeds.data);
          this.lastPage = res.data.feeds.last_page;
          this.isReady = true;
          this.lengthDebug = res.data.feeds.total;
        })
        .catch((err) => { });
    },
    visibilityChanged(isVisible) {
      this.socialactRef = [];
      this.commentRef = [];
      this.BtnReactRef = [];
      this.jaimeCountRef = [];
      this.commentCountRef = [];
      this.commentListRef = [];
      this.annulerCommentBtnRef = [];
      this.visibilityRef = [];
      this.pubRef = [];
      this.tooltipTagFriend = [];
      this.inputTagFriend = [];
      this.taggedAreaRef = [];
      if (!isVisible) {
        return;
      }
      if (this.page >= this.lastPage) {
        return (this.done = true);
      }
      this.isVisible = isVisible;

      this.page++;
      this.getFeeds();
      let a = new Set(this.socialactRef);
      this.socialactRef = [...a];
      let b = new Set(this.commentRef);
      this.socialactRef = [...b];
      let c = new Set(this.BtnReactRef);
      this.BtnReactRef = [...c];
      let d = new Set(this.jaimeCountRef);
      this.jaimeCountRef = [...d];
      let e = new Set(this.commentCountRef);
      this.commentCountRef = [...e];
      let f = new Set(this.annulerCommentBtnRef);
      this.annulerCommentBtnRef = [...f];
      let h = new Set(this.pubRef);
      this.pubRef = [...h];
      //console.log(this.BtnReactRef)
    },

    /* -----------------------------------------------Social Actions-------------------------------------------------------- */
    hover(i, event) {
      event.stopPropagation();
      this.hoverTimer = setTimeout(() => {
        this.hovering = true;
        this.socialactRef[i].classList.add("show");
        this.hoveringTwo = false;
      }, 700);
    },

    hoverJaimeComment(i, commentID, event) {
      let tooltip = document.getElementById("commentJaimeTooltip" + commentID);
      //console.log(i);
      event.stopPropagation();

      this.hoverTimerComment = setTimeout(() => {
        this.hoveringComment = true;
        //commentJaimeTooltip.classList.add('show');
        tooltip.classList.add("show");

        this.hoveringTwoComment = false;
      }, 700);
    },

    hoverTwo() {
      this.hoveringTwo = true;
      clearTimeout(this.hoverTimer);
    },
    hoverTwoComment() {
      this.hoveringTwoComment = true;
      clearTimeout(this.hoverTimerComment);
    },

    unhoverr(i) {
      this.hovering = false;

      if (!this.hoveringTwo) {
        clearTimeout(this.hoverTimer);
        clearTimeout(+this.hoverTimer - 1);
        clearTimeout(+this.hoverTimer + 1);
        setTimeout(() => {
          if (!this.hoveringTwo && !this.hovering) {
            this.socialactRef[i].classList.remove("show");
          }
        }, 700);
      }
    },
    unhoverrComment(i, commentID) {
      let tooltip = document.getElementById("commentJaimeTooltip" + commentID);
      this.hoveringComment = false;

      if (!this.hoveringTwoComment) {
        clearTimeout(this.hoverTimerComment);
        clearTimeout(+this.hoverTimerComment - 1);
        clearTimeout(+this.hoverTimerComment + 1);
        setTimeout(() => {
          if (!this.hoveringTwoComment && !this.hoveringComment) {
            tooltip.classList.remove("show");
          }
        }, 700);
      }
    },

    unhover(i) {
      this.hoveringTwo = false;
      setTimeout(() => {
        this.socialactRef[i].classList.remove("show");
      }, 700);
    },
    unhoverComment(i, commentID) {
      let tooltip = document.getElementById("commentJaimeTooltip" + commentID);

      this.hoveringTwoComment = false;
      setTimeout(() => {
        tooltip.classList.remove("show");
      }, 700);
    },
    /* ------------------------------------------(Life Cyle Hook)--------------------------------*/
    beforeUpdate() {
      this.profilID = this.$route.params.id;
      this.socialactRef = [];
      this.commentRef = [];
      this.BtnReactRef = [];
      this.jaimeCountRef = [];
      this.commentCountRef = [];
      this.commentListRef = [];
      this.annulerCommentBtnRef = [];
      this.tooltipTagFriend = [];
      this.inputTagFriend = [];
      this.taggedAreaRef = [];
    },

    /* ------------------------------------------(reference commentaire->jaime)--------------------------------*/

    setTooltipTagFriend(el) {
      if (el && !this.tooltipTagFriend.includes(el)) {
        this.tooltipTagFriend.push(el);
      }
    },

    setInputTagFriend(el) {
      if (el && !this.inputTagFriend.includes(el)) {
        this.inputTagFriend.push(el);
      }
    },

    setTaggedAreaRef(el) {
      if (el && !this.taggedAreaRef.includes(el)) {
        this.taggedAreaRef.push(el);
      }
    },

    /* ------------------------------------------(reference Publication)--------------------------------*/

    setPubRef(el) {
      if (el && !this.pubRef.includes(el)) {
        this.pubRef.push(el);
      }
    },
    setSocialactRef(el) {
      if (el && !this.socialactRef.includes(el)) {
        this.socialactRef.push(el);
      }
    },
    setCommentRef(el) {
      if (el && !this.commentRef.includes(el)) {
        this.commentRef.push(el);
      }
    },
    setBtnReactRef(el) {
      if (el && !this.btnReactRef.includes(el)) {
        this.btnReactRef.push(el);
      }
    },
    setJaimeCountRef(el) {
      if (el && !this.jaimeCountRef.includes(el)) {
        this.jaimeCountRef.push(el);
      }
    },
    setCommentCountRef(el) {
      if (el && !this.commentCountRef.includes(el)) {
        this.commentCountRef.push(el);
      }
    },

    setCommentListRef(el) {
      if (el && !this.commentListRef.includes(el)) {
        this.commentListRef.push(el);
      }
    },
    setAnnulerCommentBtnRef(el) {
      if (el && !this.annulerCommentBtnRef.includes(el)) {
        this.annulerCommentBtnRef.push(el);
      }
    },
    setVisibilityRef(el) {
      if (el && !this.visibilityRef.includes(el)) {
        this.visibilityRef.push(el);
      }
    },

    /* ------------------------------------------------------ (Commentaires)------------------------------------------------------- */
    focusComment(i) {
      this.commentRef[i].focus();
      this.commentRef[i].classList.add("comment-focus");
    },

    unfocusComment(i) {
      this.commentRef[i].classList.remove("comment-focus");
    },

    refreshComment() {
      //this.commentRefreshKey = !this.commentRefreshKey;
      this.commentCountRef[this.indexCmntedPubMedia].innerHTML =
        parseInt(this.commentCountRef[this.indexCmntedPubMedia].innerHTML) +
        1 +
        " Commentaires";

      this.commentCountRef[this.indexCmntedPubMedia].classList.add("show");
      this.getComments(
        this.postIdToCmt,
        "Publication",
        this.indexCmntedPubMedia
      );

      //this.commentRef[this.indexCmntedPubMedia].removeAttribute('disabled');
      // this.isCommentReady = true;
      //this.showComments(this.postIdToCmt,this.indexCmntedPubMedia);
    },
    commenter(postId, userId, i, event) {
      if (event.target.value != "") {
        this.taggedAreaRef[i].classList.remove("show");
        this.taggedAreaRef[i].classList.add("hide");

        //currentIndexPostToCmt:0,
        this.isCommentReady = false;
        this.commentRef[i].setAttribute("disabled", "disabled");
        this.unfocusComment(i);

        if (!this.commentRef[i].hasAttribute("data")) {
          this.axios
            .post("/commentaire", {
              reaction_type: 1,
              commentable_id: postId,
              commentable_type: "App\\Models\\Publication",
              visiteur_id: userId,
              texte_com: this.commentRef[i].value,
              taggedFriends: this.taggedFriends,
              taggedAdresses: this.taggedAdresses,
            })

            .then((res) => {
              if (res.data.success) {
                this.commentRef[i].value = "";
                this.showAnnuler(i);
                this.commentCountRef[i].innerHTML =
                  parseInt(this.commentCountRef[i].innerHTML) +
                  1 +
                  " Commentaires";
                this.commentCountRef[i].classList.add("show");
                this.pageComment = 1;
                this.getComments(postId, "Publication", i);
                this.commentRef[i].removeAttribute("disabled");
                this.commentListRef[i].classList.remove("hide");
                //  this.showComments(i)
              }
            })
            .catch((error) => { });
        } else {
          this.axios
            .put(`/commentaire/${this.commentRef[i].getAttribute("data-id")}`, {
              reaction_type: 1,
              commentable_id: postId,
              commentable_type: "App\\Models\\Publication",
              visiteur_id: userId,
              texte_com: this.commentRef[i].value,
            })
            .then((res) => {
              if (res.data.success) {
                this.commentRef[i].value = "";
                this.showAnnuler(i);
                this.commentRef[i].removeAttribute("data");
                this.commentRef[i].removeAttribute("disabled");
                this.commentCountRef[i].classList.add("show");
                this.pageComment = 1;
                this.getComments(postId, "Publication", i);
                this.commentListRef[i].classList.remove("hide");
                toast.success("Comment updated ", {
                  position: "bottom-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
            })
            .catch((error) => { });
        }
      }

      this.taggedFriends = [];
      this.taggedAdresses = [];
    },
    showComments(postId, i) {
      this.getComments(postId, "Publication", i);
      this.commentListRef[i].classList.toggle("hide");
      this.pageComment = 1;
      this.lastCommentPage = 1;
      this.isCommentReady = !this.isCommentReady;
    },

    deleteComment(commentId, postId, i) {
      this.isCommentReady = false;
      this.axios.delete(`/commentaire/${commentId}`).then((res) => {
        this.pageComment = 1;
        this.commentCountRef[i].innerHTML =
          parseInt(this.commentCountRef[i].innerHTML) - 1 + " Commentaires";
        this.getComments(postId, "Publication", i);
      });
    },
    editComment(commentId, i) {
      this.commentRef[i].focus();
      this.commentRef[i].setAttribute("disabled", "disabled");
      //get by id
      // open new input
      this.axios.get(`/commentaire/show/${commentId}`).then((res) => {
        //console.log(res.data.commentaire);
        this.commentRef[i].removeAttribute("disabled");
        this.commentRef[i].value = res.data.commentaire.texte_com;
        this.commentRef[i].setAttribute("data", "toBeUpdated");
        this.commentRef[i].setAttribute("data-id", res.data.commentaire.id);
        this.commentRef[i].focus();
      });
    },

    cancelComment(i) {
      this.commentRef[i].value = "";
      this.commentRef[i].removeAttribute("data");
      this.showAnnuler(i);
      this.unfocusComment(i);
    },
    getPrevComments(postId, i) {
      this.isPrevCommentReady = false;
      this.pageComment++;
      if (this.pageComment <= this.lastCommentPage) {
        this.axios
          .get(`/commentaire/${postId}/Publication?page=${this.pageComment}`)
          .then((res) => {
            this.lastCommentPage = res.data.commentaires.last_page;
            this.comments[i].unshift(
              ...res.data.commentaires.data.slice().reverse()
            );
            this.isCommentReady = true;

            this.isPrevCommentReady = true;
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },
    getComments(postId, commentableType, i) {
      this.axios
        .get(
          `/commentaire/${postId}/${commentableType}?page=${this.pageComment}`
        )
        .then((res) => {
          this.lastCommentPage = res.data.commentaires.last_page;
          this.comments[i] = res.data.commentaires.data.slice().reverse();
          this.isCommentReady = true;
          this.comments[i].forEach((ele) => {
            this.jaimesGroupComment[ele.id] = ele.countJaimeByComment;
            this.isJaimeGroupCommentReady[ele.id] = true;
          });
          console.log(res.data.commentaires.data);
          //  this.jaimesGroupComment[commentID]=res.data.countJaimeByComment;
          //  this.isJaimeGroupCommentReady[commentID]=true;
        })
        .catch((err) => { });
    },

    isCommentUser(commentUserId, currentUserId) {
      if (commentUserId == currentUserId) {
        return true;
      } else {
        return false;
      }
    },

    deleteTagFriend(id, i) {
      this.taggedFriends = this.taggedFriends.filter((friend) => {
        return id != friend.id;
      });
    },
    deleteTagAdresse(id, i) {
      this.taggedAdresses = this.taggedAdresses.filter((adresse) => {
        return id != adresse.id;
      });
    },

    tagFriends(i, id, prenom, type) {
      this.tooltipTagFriend[i].classList.add("hide");
      this.tooltipTagFriend[i].classList.remove("show");
      this.inputTagFriend[i].value = "";
      this.taggedAreaRef.forEach((el) => {
        el.classList.add("hide");
      });
      this.taggedAreaRef[i].classList.remove("hide");
      this.taggedAreaRef[i].classList.add("show");
      //this.commentRef[i].value = `${this.commentRef[i].value.replace("@"," ")}`;
      this.commentRef[i].value = this.commentRef[i].value.slice(
        0,
        this.currentCmtLength - 1
      );

      this.commentRef[this.currentIndexPostToCmt].focus();

      switch (type) {
        case "user":
          if (!this.taggedFriends.some((el) => el.id === id)) {
            this.taggedFriends.push({ id: id, prenom: prenom });
          }

          break;
        case "adress":
          if (!this.taggedAdresses.some((el) => el.id === id)) {
            this.taggedAdresses.push({ id: id, rs: prenom });
          }
          break;
      }
    },
    findTag(e, i) {
      console.log(this.inputTagFriend[i].value);
      let searchKeyArray = this.inputTagFriend[i].value.split(" ");
      this.axios
        .post("getTagFriends", {
          tagSearchKeyPrenom: this.inputTagFriend[i].value,
          searchKeyArray: searchKeyArray,
        })
        .then((res) => {
          this.tagFriendsList[i] = res.data.results;
        });
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleCloseTagTooltip();
      }
    },
    handleCloseTagTooltip() {
      //each
      this.tooltipTagFriend[this.currentIndexPostToCmt].classList.add("hide");
      this.tooltipTagFriend[this.currentIndexPostToCmt].classList.remove(
        "show"
      );
      this.commentRef[this.currentIndexPostToCmt].focus();
    },
    showtTooltipTagFriend(e, i) {
      this.currentIndexPostToCmt = i;
      this.currentCmtLength = this.commentRef[i].value.length;
      console.log(this.commentRef[i].value.length);

      if (e.keyCode === 48) {
        this.tooltipTagFriend[i].classList.remove("hide");
        this.tooltipTagFriend[i].classList.add("show");
        this.inputTagFriend[i].focus();
      } else {
        this.tooltipTagFriend[i].classList.add("hide");
        this.tooltipTagFriend[i].classList.remove("show");
      }

      //   let ArrayComment = e.target.value.split(' ');
      // let currentIndex = 0 ;
      // ArrayComment.forEach((element,index) => {
      //   if(element.startsWith('@')){
      //     currentIndex = index;
      //     if(ArrayComment[currentIndex].substring(1).length >= 1){
      //       if(ArrayComment[currentIndex]){
      //     this.tagSearchKeyPrenom = ArrayComment[currentIndex].substring(1);
      //       }else{
      //         this.tagSearchKeyPrenom = null ;
      //       }
      //     if(ArrayComment[currentIndex+1]){
      //     this.tagSearchKeyNom = ArrayComment[currentIndex+1];
      //     }else{
      //       this.tagSearchKeyNom = null ;
      //     }
      //     }
      //   }
      //    if(currentIndex < index-1){
      //    this.tooltipTagFriend[i].classList.add('hide');
      //    this.tooltipTagFriend[i].classList.remove('show');
      //    this.tagSearchKeyPrenom = null ;
      //    this.tagSearchKeyNom = null ;
      //  }else{
      //    this.tooltipTagFriend[i].classList.remove('hide');
      //    this.tooltipTagFriend[i].classList.add('show');
      //    if(ArrayComment[currentIndex].substring(1).length >= 1){
      //     this.tagSearchKeyPrenom = ArrayComment[currentIndex].substring(1);
      //     if(ArrayComment[currentIndex+1]){
      //     this.tagSearchKeyNom = ArrayComment[currentIndex+1];
      //     }
      //     }
      //  }
      //  });

      //  if(e.target.value.includes("@")){
      //  if(e.keyCode === 48){
      //    this.tooltipTagFriend[i].classList.remove('hide');
      //    this.tooltipTagFriend[i].classList.add('show');
      // }
      //  }else{
      //    this.tooltipTagFriend[i].classList.add('hide');
      //    this.tooltipTagFriend[i].classList.remove('show');

      //  }
      //  console.log(this.tagSearchKeyPrenom+' '+this.tagSearchKeyNom);
      // if(this.tagSearchKeyPrenom != null){
      // this.axios.post('getTagFriends',{
      //   tagSearchKeyPrenom:this.tagSearchKeyPrenom,
      //   tagSearchKeyNom:this.tagSearchKeyNom
      // }).then(res=>{
      //   this.tagFriendsList[i] = res.data.amis;
      //   //console.log(res.data)
      // })
      // }

      //  if(e.keyCode === 51){
      //   alert('tagAdresse')
      // }
    },
    showAnnuler(i, e) {
      // if(e.keyCode === 48){
      //   alert('tagFriend')
      // }
      //  if(e.keyCode === 51){
      //   alert('tagAdresse')
      // }
      if (!this.commentRef[i].value == "") {
        this.annulerCommentBtnRef[i].classList.remove("hide");
      } else {
        this.annulerCommentBtnRef[i].classList.add("hide");
      }
    },

    /* ------------------------------------------------------ (Jaimes) ------------------------------------------------------- */
    //   ReactedCheck(jaimes,currentUserId){

    //     for(var i = 0; i < jaimes.length; i++) {
    //     if (jaimes[i].visiteur_id == currentUserId) {
    //         return true;
    //     }else{
    //       return false
    //     }

    // }
    //   },
    ReactedCheckComment(jaimes, currentUserId) {
      for (var i = 0; i < jaimes.length; i++) {
        if (jaimes[i].visiteur_id == currentUserId) {
          return true;
        } else {
          return false;
        }
      }
    },

    //     ReactType(jaimes,currentUserId){

    //     for(var i = 0; i < jaimes.length; i++) {
    //     if (jaimes[i].visiteur_id == currentUserId) {
    //        return jaimes[i].reaction_type ;
    //     }else{
    //       return false
    //     }

    // }
    //   },

    ReactTypeComment(jaimes, currentUserId) {
      for (var i = 0; i < jaimes.length; i++) {
        if (jaimes[i].visiteur_id == currentUserId) {
          return jaimes[i].reaction_type;
        } else {
          return false;
        }
      }
    },
    //----------------------------------------------------------------------------------------------------------------------
    dislike(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="feather-thumbs-down btn-round-sm socialClicked" style="color:rgb(233, 113, 15)"></i>
    <span class="socialClicked" style="color:rgb(233, 113, 15)">Dislike</span>`;
        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;

        this.axios.post("/jaime", {
          reaction_type: "7",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="feather-thumbs-down btn-round-sm socialClicked" style="color:rgb(233, 113, 15)"></i>
        <span class="socialClicked" style="color:rgb(233, 113, 15)">Dislike</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "7",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },
    //-----------------------
    jaime(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="feather-thumbs-up btn-round-sm socialClicked" style="color:blue"></i>
    <span class="socialClicked" style="color:blue">Like</span>`;

        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;

        this.axios.post("/jaime", {
          reaction_type: "1",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="feather-thumbs-up btn-round-sm socialClicked" style="color:blue"></i>
        <span class="socialClicked" style="color:blue">Like</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "1",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },
    //----------------------------------------------------------------------------------------------------------------------
    jadore(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `<i class="feather-heart btn-round-sm socialClicked" style="color:red"></i>
    <span class="socialClicked" style="color:red">Love</span>`;
        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "2",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.btnReactRef[
          i
        ].innerHTML = `<i class="feather-heart btn-round-sm socialClicked" style="color:red"></i>
        <span class="socialClicked" style="color:red">Love</span>`;
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "2",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },

    //-----------------------------------------------------------------------------------------------------------------
    wouah(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f632.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "3",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f632.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "3",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },
    //-----------------------------------------------------------------------------------------------------------------
    haha(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f923.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "4",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f923.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime
            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "4",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },

    //----------------------------------------------------------------------------------------------------------------
    triste(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f622.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "5",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f622.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "5",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },
    //----------------------------------------------------------------------------------------------------------------
    grrr(UserId, postId, i) {
      this.jaimeCountRef[i].classList.remove("hide");
      this.jaimeCountRef[i].classList.add("show");
      this.socialactRef[i].classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.btnReactRef[i].classList.contains("socialClicked")) {
        this.socialactRef[i].setAttribute("data", "toBeUpdated");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="em socialClicked" style="color:rgb(233, 113, 15);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f620.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        this.jaimeCountRef[i].innerHTML =
          parseInt(this.jaimeCountRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "6",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Publication",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
            this.jaimesGroup[i] = res.data.countJaimeByType;
            this.isJaimeGroupReady = true;

          });
        })

      } else {
        this.socialactRef[i].classList.remove("show");
        this.btnReactRef[i].classList.add("socialClicked");
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="em socialClicked" style="color:rgb(233, 113, 15);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f620.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;"></i>
                              <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${postId}/Publication/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime
            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "6",
                jaimable_id: postId,
                jaimable_type: "App\\Models\\Publication",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                    this.jaimesGroup[i] = res.data.countJaimeByType;
                    this.isJaimeGroupReady = true;
                    //console.log(this.jaimesGroup[i])
                  });
                }
              });
          });
      }
    },
    //----------------------------------------------------------------------------------------------------------------

    deletReaction(i, userId, postId, jaimes) {
      //  if(!this.jaimesGroup[i]){
      //      this.jaimeCountRef[i].classList.remove('show');
      //      this.jaimeCountRef[i].classList.add('hide');

      //  }
      this.hovering = false;
      this.isJaimeGroupReady = false;
      this.socialactRef[i].removeAttribute("data");
      this.socialactRef[i].classList.remove("show");
      if (this.btnReactRef[i].classList.contains("socialClicked")) {
        this.btnReactRef[
          i
        ].innerHTML = `  <i class="feather-thumbs-up  text-grey-900 text-dark btn-round-sm"></i>
                              Like`;
        let j = 1;
        do {
          if (jaimes > 1) {
            this.jaimeCountRef[i].innerHTML =
              parseInt(this.jaimeCountRef[i].innerHTML) - 1;
            this.btnReactRef[i].classList.remove("socialClicked");
          } else {
            this.jaimeCountRef[i].innerHTML--;
            this.btnReactRef[i].classList.remove("socialClicked");
          }
          j++;
        } while (j == 0);
        this.btnReactRef[i].classList.remove("socialClicked");
        this.axios
          .post("/jaime/delete", {
            post_id: postId,
            visiteur_id: userId,
            jaimable_type: "App\\Models\\Publication",
          })
          .then((res) => {
            if (res.data.success) {
              this.axios.get(`/jaimes/bytype/${postId}`).then((res) => {
                this.jaimesGroup[i] = res.data.countJaimeByType;
                //console.log(this.jaimesGroup[i]);
                this.isJaimeGroupReady = true;
              });
            }
          })
          .catch((error) => { });
      } //else
      else {
        this.jaime(userId, postId, i, jaimes);
      }
    },
    //-----------------------------------------------------(JaimeComment)
    //  commentJaimeCount , commentJaimeTooltip , btnJaimeComment
    jaimeComment(UserId, commentID, indexComment, indexPub) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;

      commentJaimeCount.classList.remove("hide");
      commentJaimeCount.classList.add("show");
      commentJaimeTooltip.classList.remove("show");
      if (!btnJaimeComment.classList.contains("socialClicked")) {
        commentJaimeTooltip.setAttribute("data", "toBeUpdated");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = ` 
    <span class="socialClicked" style="color:blue">Like</span>`;
        commentJaimeCount.innerHTML = parseInt(commentJaimeCount.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "1",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
            this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
            this.isJaimeGroupCommentReady[commentID] = true;
          });
        })


      } else {
        commentJaimeTooltip.classList.remove("show");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = `
        <span class="socialClicked" style="color:blue">Like</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "1",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios
                    .get(`/jaimes/byComment/${commentID}`)
                    .then((res) => {
                      this.jaimesGroupComment[commentID] =
                        res.data.countJaimeByComment;
                      this.isJaimeGroupCommentReady[commentID] = true;
                    });
                }
              });
          });
      }
    },
    //-------------------------------------------------------------------------------------------------------------
    jadoreComment(UserId, commentID, i) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;
      commentJaimeCount.classList.remove("hide");
      commentJaimeCount.classList.add("show");
      commentJaimeTooltip.classList.remove("show");
      if (!btnJaimeComment.classList.contains("socialClicked")) {
        commentJaimeTooltip.setAttribute("data", "toBeUpdated");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = `
    <span class="socialClicked" style="color:red">Love</span>`;
        commentJaimeCount.innerHTML = parseInt(commentJaimeCount.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "2",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
            this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
            this.isJaimeGroupCommentReady[commentID] = true;
          });
        })

      } else {
        btnJaimeComment.innerHTML = `
        <span class="socialClicked" style="color:red">Love</span>`;
        commentJaimeTooltip.classList.remove("show");
        btnJaimeComment.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "2",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios
                    .get(`/jaimes/byComment/${commentID}`)
                    .then((res) => {
                      this.jaimesGroupComment[commentID] =
                        res.data.countJaimeByComment;
                      this.isJaimeGroupCommentReady[commentID] = true;
                    });
                }
              });
          });
      }
    },

    //-----------------------------------------------------------------------------------------------------------------
    wouahComment(UserId, commentID, i) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;
      commentJaimeCount.classList.remove("hide");
      commentJaimeCount.classList.add("show");
      commentJaimeTooltip.classList.remove("show");
      if (!btnJaimeComment.classList.contains("socialClicked")) {
        commentJaimeTooltip.setAttribute("data", "toBeUpdated");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = `
    <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        commentJaimeCount.innerHTML = parseInt(commentJaimeCount.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "3",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
            this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
            this.isJaimeGroupCommentReady[commentID] = true;
          });
        })

      } else {
        btnJaimeComment.innerHTML = `
        <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        commentJaimeTooltip.classList.remove("show");
        btnJaimeComment.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;

            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "3",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios
                    .get(`/jaimes/byComment/${commentID}`)
                    .then((res) => {
                      this.jaimesGroupComment[commentID] =
                        res.data.countJaimeByComment;
                      this.isJaimeGroupCommentReady[commentID] = true;
                    });
                }
              });
          });
      }
    },
    //-----------------------------------------------------------------------------------------------------------------
    hahaComment(UserId, commentID, i) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;
      commentJaimeCount.classList.remove("hide");
      commentJaimeCount.classList.add("show");
      commentJaimeTooltip.classList.remove("show");
      if (!btnJaimeComment.classList.contains("socialClicked")) {
        commentJaimeTooltip.setAttribute("data", "toBeUpdated");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = `
    <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        commentJaimeCount.innerHTML = parseInt(commentJaimeCount.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "4",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
            this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
            this.isJaimeGroupCommentReady[commentID] = true;
          });
        })

      } else {
        btnJaimeComment.innerHTML = `
        <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        commentJaimeTooltip.classList.remove("show");
        btnJaimeComment.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "4",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios
                    .get(`/jaimes/byComment/${commentID}`)
                    .then((res) => {
                      this.jaimesGroupComment[commentID] =
                        res.data.countJaimeByComment;
                      this.isJaimeGroupCommentReady[commentID] = true;
                    });
                }
              });
          });
      }
    },

    //----------------------------------------------------------------------------------------------------------------
    tristeComment(UserId, commentID, i) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;
      commentJaimeCount.classList.remove("hide");
      commentJaimeCount.classList.add("show");
      commentJaimeTooltip.classList.remove("show");
      if (!btnJaimeComment.classList.contains("socialClicked")) {
        commentJaimeTooltip.setAttribute("data", "toBeUpdated");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = `
    <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        commentJaimeCount.innerHTML = parseInt(commentJaimeCount.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "5",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
            this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
            this.isJaimeGroupCommentReady[commentID] = true;
          });
        })

      } else {
        btnJaimeComment.innerHTML = `
        <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        commentJaimeTooltip.classList.remove("show");
        btnJaimeComment.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "5",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios
                    .get(`/jaimes/byComment/${commentID}`)
                    .then((res) => {
                      this.jaimesGroupComment[commentID] =
                        res.data.countJaimeByComment;
                      this.isJaimeGroupCommentReady[commentID] = true;
                    });
                }
              });
          });
      }
    },
    //----------------------------------------------------------------------------------------------------------------
    grrrComment(UserId, commentID, i) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;
      commentJaimeCount.classList.remove("hide");
      commentJaimeCount.classList.add("show");
      commentJaimeTooltip.classList.remove("show");
      if (!btnJaimeComment.classList.contains("socialClicked")) {
        commentJaimeTooltip.setAttribute("data", "toBeUpdated");
        btnJaimeComment.classList.add("socialClicked");
        btnJaimeComment.innerHTML = `
    <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        commentJaimeCount.innerHTML = parseInt(commentJaimeCount.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "6",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        }).then(res => {
          this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
            this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
            this.isJaimeGroupCommentReady[commentID] = true;
          });
        })

      } else {
        btnJaimeComment.innerHTML = `
        <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        commentJaimeTooltip.classList.remove("show");
        btnJaimeComment.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "6",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios
                    .get(`/jaimes/byComment/${commentID}`)
                    .then((res) => {
                      this.jaimesGroupComment[commentID] =
                        res.data.countJaimeByComment;
                      this.isJaimeGroupCommentReady[commentID] = true;
                    });
                }
              });
          });
      }
    },
    //----------------------------------------------------------------------------------------------------------------

    deletReactionComment(i, userId, commentID, jaimes) {
      let commentJaimeTooltip = document.getElementById(
        "commentJaimeTooltip" + commentID
      );
      let commentJaimeCount = document.getElementById(
        "commentJaimeCount" + commentID
      );
      let btnJaimeComment = document.getElementById(
        "btnJaimeComment" + commentID
      );
      this.isJaimeGroupCommentReady[commentID] = false;

      if (!this.jaimesGroupComment[commentID]) {
        commentJaimeCount.classList.remove("show");
        commentJaimeCount.classList.add("hide");
      }
      this.hoveringComment = false;
      commentJaimeTooltip.removeAttribute("data");
      commentJaimeTooltip.classList.remove("show");
      if (btnJaimeComment.classList.contains("socialClicked")) {
        btnJaimeComment.innerHTML = `  
                              Like`;
        let j = 1;
        do {
          if (jaimes > 1) {
            commentJaimeCount.innerHTML =
              parseInt(commentJaimeCount.innerHTML) - 1;
            btnJaimeComment.classList.remove("socialClicked");
            j++;
          } else {
            commentJaimeCount.innerHTML--;
            btnJaimeComment.classList.remove("socialClicked");
          }
        } while (j == 0);
        btnJaimeComment.classList.remove("socialClicked");
        this.axios
          .post("/jaime/delete", {
            post_id: commentID,
            visiteur_id: userId,
            jaimable_type: "App\\Models\\Commentaire",
          })
          .then((res) => {
            if (res.data.success) {
              this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                this.jaimesGroupComment[commentID] =
                  res.data.countJaimeByComment;
                this.isJaimeGroupCommentReady[commentID] = true;
              });
            }
          })
          .catch((error) => { });
      } //else
      else {
        this.jaimeComment(userId, commentID, i, jaimes);
      }
    },
  },
};
</script>


   
<style lang="scss" scoped >
@import "../../../../assets/css/emoji.css";
@import "../../../../assets/css/bootstrap-datetimepicker.css";
@import "../../../../assets/css/video-player.css";

.preloader {
  left: 300px !important;
  top: 150px !important;
  background-position: 30% 5% !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.h-3 {
  height: 15em !important;
}

.h-2 {
  height: 12.5em !important;
}

.h-1 {
  height: 25em !important;
}

.h-5 {
  height: 8em !important;
}

.feather-thumbs-upp {
  background-color: blue;
}

.social-action {
  padding: 0px;
  font-size: 15px !important;

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }

  .feather-thumbs-up {
    background: none;
  }
}

.social-actions {
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: 0px 1px rgba(128, 128, 128, 0.2);

  user-select: none;
}

.jaime-comment {
  box-shadow: 0px 1px rgba(128, 128, 128, 0.2);
  padding-top: 1px;
  padding-bottom: 1px;
}

.emoji .em {
  opacity: 0.9;

  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}

.socialClicked {
  // color: blue !important
}

.jaime {
  color: blue !important;
}

.jadore {
  color: red !important;
}

.show {
  display: inline-block !important;
}

.hide {
  display: none !important;
}

.emoji-wrap {
  left: 30px;
  top: 15px;
}

.emoji-wrap-comment {
  left: 40px !important;
  top: -40px !important;
}

.comment-count {
  user-select: none;

  &:hover {
    cursor: pointer;
    color: #2e58a6 !important;
    text-decoration: underline !important;
  }
}

.feather-camera {
  position: absolute;
  right: 5px;
  top: 5px;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}

.fa-tags {
  position: absolute;
  right: 35px;
  top: 5px;

  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}

.jaimeComment {
  padding: 0px 10px;
  display: inline-block;
  font-weight: bold !important;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}

.delete-comment-btn {
  top: -10px;
  right: 0;
}

.del-up {
  padding: 2px 15px;
  transition: all 0.4s ease-in-out;

  h4 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  &:hover {
    background-color: #eee;
    box-shadow: 1px 1px #ddd inset;
    transition: all 0.3s ease-in-out;
  }

  &:hover h4 {
    color: #2e58a6 !important;
  }
}

.cancelComment {
  padding: 0px 8px;
}

.annulerCommentBtn,
.comment-pagination {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}

.fix-float {
  clear: both;
}

.comment-card {
  padding: 0 1em !important;
  padding-top: 5px !important;
}

.comments-container {
  padding-right: 0 !important;
}

video {
  object-fit: fill !important;
}

.mediaComment {
  border-radius: 10px;
  margin-top: 5px;
  border: 1px solid rgba(153, 153, 153, 0.3);
  //box-shadow: 1px 1px 1px rgba(153, 153, 153, 0.5);
}

.jaimeComBtn {
  font-weight: bold !important;
}

.emoji-wrap-comment {
  z-index: 99;
}

.count-jaime-comment {
  &:hover {
    cursor: pointer !important;
  }

  float: right;
  display: inline-block;
  background-color: #f5f5f5;
  width: fit-content;
  //min-width: 10%;
  border-radius: 10px;
  text-align: end;
  border: 2px solid #fff;
  padding: 0 2px !important;
  line-height: 1.2em;
  margin-top: 25px;
}

.rounded-circle {
  border-radius: 10px !important;
}

.comment-focus {
  border: 2px#5869b8b4 solid;
}

.position-absolute {
  position: absolute !important;
}

.change-visibility {
  transition: all 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
    color: #2e58a6;
  }
}

.edit-comment {
  padding: 0px !important;
  min-width: 6rem !important;
  text-align: center;
}

.rounded-cc {
  border-radius: 10px !important;
}

.btn-tgl-visib {
  display: inline-block !important;
}

.dropdown-menu {
  min-width: 40px !important;
  padding: 0px !important;
  border-radius: 10px !important;
}

.v-element {
  transition: all 0.2s ease-in-out;
  padding: 5px 10px;

  &:hover {
    cursor: pointer;
    background-color: #eee;
    color: #2e58a6 !important;
    box-shadow: 1px 1px #ccc inset;
  }
}

.dropdown-pub {
  min-width: 40px !important;
  padding: 0px !important;
  border-radius: 10px !important;

  //transform: translate(592px, 50px) !important;
  h4 {
    padding: 5px 15px;
    transition: all 0.2s ease-in-out;
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;

    &:hover {
      background-color: #eee;
      color: #2e58a6 !important;
      box-shadow: 1px 1px #ddd inset;

    }
  }
}

.v-top {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  margin-top: 0rem !important;
}

.v-top:hover {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;

}

.v-bottom:hover {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.v-bottom {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.chat a {
  transition: all 0.2s ease-in-out;

  &:hover {
    color: #2e58a6 !important;
    //text-decoration:underline !important;
  }
}

.btn-jaime-list {
  font-size: 12px !important;
  padding: 0.5rem 1rem !important;

  &:hover {
    cursor: pointer;
  }
}

.autocomplete-results {
  border-radius: 5px;
  width: 50%;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  // height: 150px;
  min-height: 1em;
  max-height: 22em;
  overflow: hidden;
  position: absolute;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px rgba(128, 128, 128, 0.3);
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 5px 2px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #eee;
}

.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.w-img {
  width: 50px !important;
  height: 50px !important;
  vertical-align: bottom !important;
  border-radius: 10px;
  //text-align:center;
  //left:20%;
}

.friends-tag {
  flex-direction: column;
}

.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.jaime-group-comment .em {
  height: 1em !important;
  width: 1em !important;
  margin-bottom: 2px;

  &:hover {
    cursor: pointer !important;
  }
}

.d-i-b {
  display: inline-block;
}

.comment-jaime-count {
  font-weight: 700;
  font-size: 10px;
  margin: 2px 0px 0px 2px;

  &:hover {
    cursor: pointer !important;
  }
}

.loader-jaime-comment {
  margin-top: 2px;
}

.ti-more-alt {
  &:hover {
    text-decoration: none !important;
  }
}

.form-group.icon-input-tag i {
  position: absolute;
  right: 12px;
  top: 12px;
}

.border-light-md-tag {
  border: 2px#5869b8b4 solid;
}

.tags {
  color: #2e58a6;

  &:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
  }
}

.delete-tag-btn {
  color: black !important;

  &:hover {
    color: red !important;
  }
}

.media-fit {
  object-fit: cover !important;
}

@media (max-width: 767px) {
  .social-actions {
    .social-action {
      padding: 0.25rem !important;
    }
  }

  .h-1 {
    height: 15em !important;
  }

  .h-3 {
    height: 10em !important;
  }

  .h-2 {
    height: 8em !important;
  }

  .row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .rounded-3 {
    border-radius: 3px !important;
  }

  .p-m-0 {
    padding: 1px !important;
  }

  .img-count-m {
    border-radius: 0;
  }
}

.user-pub a {
  color: #313538 !important;
  overflow-wrap: normal;
}

.user-pub {
  white-space: nowrap;
}

.adrs-user {
  flex-direction: column;
}

.justify-self-end {
  width: 155px;
  margin-left: auto;

  text-align: right;
  color: #aaa;
  font-weight: 600;
  font-size: 12px;
}

.fa-user-friends,
.fa-globe-americas {
  color: #aaa !important
}

.fa-user {

  font-size: 12px;
  margin-right: 3px
}</style>

