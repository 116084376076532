<template lang="">
    <template v-if="isDemandesReady">
        <div v-for="(adresse,index) in demandes" :key="adresse.id" class="col-lg-6 col-md-6 col-sm-6 mb-3 pe-2 ps-2">

          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1
            "
          >
            <div class="card-image w-100 mb-3">
              
                <img
                  :src="`${assetsBaseUrl}${adresse.logo}`"
                  alt="image"
                  class="w-100"
                />
              
            </div>
            <div class="card-body pt-0">
              <h4 class="fw-700 font-xss mt-0 lh-28 mb-0">
                 {{adresse.rs}}
              </h4>
              <h6 class="font-xsssss text-grey-500 fw-600 mt-0 mb-2">
                {{adresse.adresse}}
              </h6>
             
              <div class="clearfix"></div>
              <a class="position-absolute bottom-15 mb-2 right-15 me-5"
                >
                <i class="fas fa-edit btn-round-sm
                    bg-success-gradiant
                    text-white
                    font-sm"></i>
              </a>
              <a class="position-absolute bottom-15 mb-2 right-15"
                >
                <i class="fas fa-trash-alt btn-round-sm
                    bg-primary-gradiant
                    text-white
                    font-sm"></i>
              </a>  
             
            </div>
            
         </div> 
        </div>
        </template>
        <template v-else>
          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-5
              overflow-hidden
              me-1
            "
          >
            <div class="card-image w-100 mb-3">
              <a href="#"
              class="position-relative d-block w-100">
                 <Skeleton></Skeleton>
              </a>
            </div>
            <div class="card-body pt-0">
             <Loader></Loader>
             
             
              <div class="clearfix"></div>
             
            </div>
            
         </div> 
        </template>
</template>

<script>

import Loader from "../../../layouts/loader/loader.vue"
import Skeleton from "../../../layouts/skeleton/SkeletonAdresse.vue"
export default {
  name: "MesAdrresses",
  components: {
 
    Loader,
    Skeleton
    
    
  },
  data() {
    return {
      isShowModal: false,
      isDemandesReady: false,
      demandes: [],
    };
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted(){
    this.getMesDemandes();
  },

  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },

  methods: {
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
    getMesDemandes() {
      this.axios.get(`mesAdresses`).then((res) => {
        this.demandes = res.data.demandes;
        this.isDemandesReady = true;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.right-15 {
  right: 4px !important;
}
.bottom-15 {
  bottom: 0px !important;
}
.addAdresseBtn {
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s ease-in-out;
  &:hover {
    cursor: pointer;
    color: blue;
  }
}
.router-link-active.router-link-exact-active {
  
  &:hover{
       border-bottom: 2px#666 solid !important;
    color: #000 !important;

  }
    
    border-bottom: 2px#333 solid !important;
    color: #000 !important;
   
}
.nav-tabs li a {
    &:hover{
        border-bottom: 2px#aaa solid !important;
    color: #000 !important;
    
    }
    transition: all 0.1s ease-in-out !important;
 }
 .bg-primary-gradiant {
    
    background: linear-gradient(to right, #3f3b3b, #8b8282) !important;
   
    &:hover{
      background: linear-gradient(to right, #e42626, #f14949) !important;
      cursor:pointer;
       transition: all .4s ease-in-out;
    }
}
 .bg-success-gradiant {
    
    background: linear-gradient(to right, #3f3b3b, #8b8282) !important;
    transition: all .4s ease-in-out;
    &:hover{
      background: linear-gradient(to right, #2672e4, #6278dffa) !important;
      cursor:pointer;
    }
}
.me-5 {
    margin-right: 2.5rem !important;
}

</style>
