import { createApp } from 'vue';
import App from './component/App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment'
import store from './store';
import PortalVue from 'portal-vue';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import 'dropzone-vue/dist/dropzone-vue.common.css';
import VTooltip from 'v-tooltip';
import 'v-tooltip/dist/v-tooltip.css';
import '@fortawesome/fontawesome-free/css/all.css';
import Toast from "vue-toastification";
import VueObserveVisibility from 'vue3-observe-visibility';
import "vue-toastification/dist/index.css";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSocialSharing from 'vue-social-sharing';

import 'vuetify/styles'


import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
    components,
    directives,
})






//axios.defaults.baseURL = 'http://localhost:8000/api/visiteur/';
axios.defaults.baseURL = 'https://api.trippybook.com/api/visiteur/';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem("token");
axios.interceptors.request.use((config) => {
    if (localStorage.getItem("token")) {
        config.headers["Authorization"] = "Bearer " + localStorage.getItem("token")
    }
    return config
})
const app = createApp(App);

app.config.globalProperties.$filters = {
    timeAgo(date) {
        return moment(date).fromNow()
    },
    getMonth(date) {
        return moment(date).format('MMMM');

    },
    getDay(date) {
        return moment(date).format('DD');

    },
}

app.use(router);
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.use(store);
app.use(VueObserveVisibility);
app.use(PortalVue);
app.use(VTooltip);
app.use(Toast);
app.use(VueSweetalert2);
app.use(VueSocialSharing);





app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyBt0FzE7tTDwZuwkEpiMVXmxs9luDygliI',
        //key: 'AIzaSyB41DRUbKWJHPxaFjMAwdrzWzbVKartNGg',
        libraries: 'geometry,places',
    },
});
app.use(vuetify);
app.mount('#App');