<template lang="">


    <div class="form-group icon-tab col-md-12 p-3">
       Nearby addresses
   <label class="toggle ">
     <input type="checkbox" checked v-model="isAprox" @change="$emit('proxChanged')" @click="toggleAprox()" ref="toggleAproxInpt">
     <span class="toggle-icon"></span> 
    
     </label>
  
</div>



    <!-- ----------------------------------Filter------------------ -->
<div  class="filter  shadow-xss d-block p-2 border-0 mb-1" ref="filter">
 
  

 <div class="col-md-12 mb-1">
   <Multiselect @change="filterAdresse"
   ref="catRef"
  v-model="catID"
  mode="tags"
  :loading="!isCategorieReady"
  placeholder="By categories"
  :close-on-select="false"
  :searchable="true"
  :create-option="false"
  noResultsText=""
  :resolve-on-load="true"
  noOptionsText=""
  :options="
    categories
  "
/>
</div> 
 <div class="col-md-12 mb-1">
   <Multiselect @change="filterAdresse"
   ref="evalRef"
  v-model="evalsID"
  mode="single"
  placeholder="By evaluations"
  :close-on-select="true"
  :searchable="true"
  noResultsText="No result found"
  :options="
    evalsListes
  "
/>
</div> 
 <div class="col-md-12 mb-1">
   <Multiselect @change="filterAdresse"
   ref="costRef"
  v-model="coutsID"
  mode="single"
  autocomplete="off"
  placeholder="by costs"
  :close-on-select="true"
  :searchable="true" 
  noResultsText="No result found"
  :resolveOnLoad="false"
  :options="
    coutsListes
  "
/>
</div> 

<div class="col-md-12 mb-1 not-allowed" v-if="isAprox">
 
   <Multiselect @change="getVilles(),clearVille()"
  v-if="isAprox" v-tooltip.bottom-start="'Disable Nearby addresses'"
  ref="paysSelectRef"
  @clear="clearVille"
  v-model="paysID"
  autocomplete="off"
  :loading="!isPaysSelectReady"
  :disabled="isAprox"
  noOptionsText="Loading"
  placeholder="By country"
  :searchable="true"
  :resolveOnLoad="false"
  noResultsText="No result found"
  :options="
    pays
  "
/>

</div> 
<div class="col-md-12 mb-1 not-allowed" v-if="isAprox">
   <Multiselect 
   v-if="isAprox" v-tooltip.bottom-start="'Disable Nearby addresses'"
   @change="filterAdresse(),dragOverVille()"
  v-model="villeID"
  ref="villeSelectRef"

  noOptionsText="Please choose a country"
  placeholder="By city"
  :disabled="isAprox"
  :searchable="true"
  :loading="!isVillesSelectReady"
 
  noResultsText="No result found"
  
  :options="
    villes
  "
/>
</div> 
<div class="col-md-12 mb-1" v-if="!isAprox">
 
   <Multiselect @change="getVilles(),clearVille()"
  
  ref="paysSelectRef"
  @clear="clearVille"
  v-model="paysID"
  autocomplete="off"
  :loading="!isPaysSelectReady"
  :disabled="isAprox"
  noOptionsText="Loading"
  placeholder="By country"
  :searchable="true"
  :resolveOnLoad="false"
  noResultsText="No result found"
  :options="
    pays
  "
/>

</div> 
<div class="col-md-12 mb-1" v-if="!isAprox">
   <Multiselect 
   
   @change="filterAdresse(),dragOverVille()"
  v-model="villeID"
  ref="villeSelectRef"

  noOptionsText="Please choose a country"
  placeholder="By city"
  :disabled="isAprox"
  :searchable="true"
  :loading="!isVillesSelectReady"
 
  noResultsText="No result found"
  
  :options="
    villes
  "
/>
</div> 
<div class="col-md-12 mb-1 mt-1 text-right">
  <div class="bg-greylight btn-round-lg col-md-3" @click="clearAll">Clear all</div></div>

 <div class="col-md-12 mb-1">Please define the search radius [0.1-500]</div>
 <div class="range-slider col-md-12 position-relative"  >
    <span id="rs-bullet" class="rs-label position-absolute">{{range}} Km</span>
    <input id="rs-range-line" class="rs-range" type="range" step="0.5" min="0.1" max="500" v-model="range" @change="changeRange" @click="updateLatLng">
    
  
</div>





</div>


  <!-- </div>
  </div> -->
  
</template>
<script>
import Multiselect from '@vueform/multiselect'

import Loader from "./loader/loader.vue";
import Skeleton from "./skeleton/SkeletonAdresse.vue";
import Skeletonn from "./skeleton/SkeletonComment.vue";
import infoAdresse from "./infoAdresse.vue";

export default {
  components: {

    Loader,
    Skeletonn,
    Skeleton,
    infoAdresse,
    Multiselect,



  },
  props: ['pays', 'villes', 'isVillesSelectReady', 'isPaysSelectReady'],

  emits: ['proxChanged', 'filterAdresse', 'getPays', 'changeRange', 'getVilles', 'updateLatLng'
    , 'dragOverVille', 'toggleAprox', 'getIntervalAprox'],



  data() {

    return {
      searchFilter: '',
      latMin: 0,
      latMax: 0,
      lngMin: 0,
      lngMax: 0,
      latCercle: 0,
      lngCercle: 0,
      evalsListes: [
        { value: "1", label: "*" },
        { value: "2", label: "**" },
        { value: "3", label: "***" },
        { value: "4", label: "****" },
        { value: "5", label: "*****" },
      ],
      evalsID: null,
      coutsListes: [
        { value: "1", label: "$" },
        { value: "2", label: "$$" },
        { value: "3", label: "$$$" },
        { value: "4", label: "$$$$" },
        { value: "5", label: "$$$$$" },
      ],
      coutsID: null,
      valueCat: null,
      isAprox: true,
      range: 20,
      circleOptions: {
        strokeColor: "#124698 ",
        strokeOpacity: 0.9,
        strokeWeight: 2,
        fillColor: "#124698 ",
        fillOpacity: 0.15,
      },
      circleOptionsAprox: {
        strokeColor: "#f00 ",
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: "#f00 ",
        fillOpacity: 0.05,

      },
      adresses: [],
      isAdressesReady: false,
      isCategorieReady: false,
      isContinentReady: false,
      isPaysReady: false,
      //isVillesReady:false,
      // isVillesReady:false,
      continents: [],
      categories: [],

      // villes:[],  
      catID: [],
      //  pays:[],
      continentID: null,
      paysID: null,
      villeID: null,
      lat: 0,
      long: 0,
      zoom: 6,
      keyAdresse: false,
      centerCircle: { lat: 36, lng: 10 },

      //map-----------------------
      openedMarkerID: null,
      center: { lat: 36.173357, lng: 10.091831 },
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      markers: [
        {
          position:
          {
            lat: 36.173357, lng: 10.091831
          },

        },


      ],

      testBounds: [
        {
          position:
          {
            lat: 34, lng: 10
          },

        },


      ],
      adressesMap: [

      ],



    }
  },

  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },


  },

  mounted() {

    // this.getPosition();
    // setTimeout(()=>{this.getIntervalAprox(),450});    
    // this.filter();
    this.getCategories();
    this.getPays();

  },
  unmounted() {
    this.$nextTick(() => {
      this.$store.dispatch('catID', []);
      this.$store.dispatch('villeID', null);
      this.$store.dispatch('searchFilter', null);
      this.$store.dispatch('evalsID', []);
      this.$store.dispatch('coutsID', []);
      setTimeout(() => { this.$emit("filterAdresse"), 1000 })
    })
  },

  methods: {


    toggleAprox() {

      this.$emit('toggleAprox');
      //  if(!this.isAprox){
      // this.$refs.continentSelectRef.clear();
      // this.$refs.paysSelectRef.clear();
      // this.$refs.villeSelectRef.clear();
      // }
      if (!this.isAprox) {
        //this.$store.dispatch('continentID', null);
        this.$store.dispatch('paysID', null);
        this.$store.dispatch('villeID', null);
        //this.$store.dispatch('catID', null);

        //this.continentID = null;
        this.paysID = null;
        this.villeID = null;
        setTimeout(() => { this.$emit("filterAdresse"), 1000 })
      }

    },



    toggleAproxInpt() {

      this.$refs.toggleAproxInpt.checked = false;


    },


    radiusChanged(e) {
      //console.log(e)

    },
    // 


    clearVille() {
      if (this.villeID != null) {
        this.$refs.villeSelectRef.clear();
        //this.villeID = null;

        // this.$store.dispatch('villeID', null);
      }

    },

    clearVillePays() {
      if (this.paysID != null) {
        this.$refs.paysSelectRef.clear();

      }
      if (this.villeID != null) {
        this.$refs.villeSelectRef.clear();

      }



      //setTimeout(()=>{this.$emit("filterAdresse"),1000});
    },

    clearAll() {
      //this.$refs.continentSelectRef.clear();
      this.$refs.paysSelectRef.clear();
      this.$refs.villeSelectRef.clear();
      this.$refs.costRef.clear();
      this.$refs.evalRef.clear();
      this.$refs.catRef.clear();

      setTimeout(() => { this.$emit("filterAdresse"), 1000 });
    },



    openMarker(id) {
      this.openedMarkerID = id
    },
    //filtter---------------------------------------------------------------
    filterAdresse() {
      this.$nextTick(() => {
        this.$store.dispatch('catID', this.catID);
        this.$store.dispatch('villeID', this.villeID);
        this.$store.dispatch('searchFilter', this.searchFilter);
        this.$store.dispatch('evalsID', this.evalsID);
        this.$store.dispatch('coutsID', this.coutsID);





        setTimeout(() => { this.$emit("filterAdresse"), 1000 })
      })





    },

    //map---------------------------------------------------------------
    getPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.markers[0].position.lat = position.coords.latitude;
        this.markers[0].position.lng = position.coords.longitude;
        this.center.lat = position.coords.latitude;
        this.center.lng = position.coords.longitude;
        this.lat = position.coords.latitude;
        this.long = position.coords.longitude;
        this.latCercle = position.coords.latitude;
        this.lngCercle = position.coords.longitude;
      });
    },
    openInfoWindow(id) {
      alert(IDBKeyRange)
    },
    updateLatLng() {
      this.$emit("updateLatLng");
    },
    changeRange() {

      this.$nextTick(() => {
        this.$store.dispatch('range', this.range);
        this.$emit("updateLatLng");
        setTimeout(() => { this.$emit("changeRange"), 1000 })
      })


    },


    //fin map------------------------------------------------------------------
    filter() {
      var rangeSlider = document.getElementById("rs-range-line");
      var rangeBullet = document.getElementById("rs-bullet");

      rangeSlider.addEventListener("input", showSliderValue, false);

      function showSliderValue() {
        rangeBullet.innerHTML = rangeSlider.value + ' Km';
        var bulletPosition = (rangeSlider.value / rangeSlider.max);
        rangeBullet.style.left = (bulletPosition * 150) + "px";
      }
    },
    toggleFilter() {
      if (!this.$refs.filter.classList.contains('filter-open')) {
        this.$refs.filter.classList.add('filter-open');
        this.$refs.filter.classList.remove('filter-close');
      } else {
        this.$refs.filter.classList.add('filter-close');
        this.$refs.filter.classList.remove('filter-open');
      }

      this.$refs.btnToggleFilter.classList.toggle('active-filter')

    },
    getCategories() {
      this.axios.get('/categories').then(res => {
        ////console.log(res.data.categories);
        this.categories = res.data.categories;
        this.isCategorieReady = true;
        this.categories = [];

        res.data.categories.forEach((element, index) => {

          this.categories.push({ "value": element.id, "label": element.nom_categorie });
        });
        ////console.log(this.categories);
      });

    },
    getContinents() {
      this.axios.get('/continents').then(res => {

        this.isContinentReady = true;
        this.continents = [];
        res.data.continents.forEach((element, index) => {

          this.continents.push({ "value": element.id, "label": element.nom_continent });
        });
      });
    },
    getAdresses() {
      this.axios.get(`mesAdresses`).then((res) => {
        this.adresses = res.data.adresses;
        this.isAdressesReady = true;
      });
    },
    getPays() {
      this.$nextTick(() => {
        //this.$store.dispatch('continentID', this.continentID);
        setTimeout(() => { this.$emit("getPays"), 50 });


      });


    },
    getVilles() {
      this.$nextTick(() => {
        this.$store.dispatch('paysID', this.paysID);
        setTimeout(() => { this.$emit("getVilles"), 50 });


      });
    },

    dragOverVille() {
      this.$nextTick(() => {
        this.$store.dispatch('villeID', this.villeID);
        setTimeout(() => { this.$emit("dragOverVille"), 50 });


      });
    },

  }
};



</script>

<style lang="scss" scoped>
.btn-round-lg {
  border-radius: 2px;
  padding: 2px 10px;
  height: 30px;
  line-height: 25px;
  cursor: pointer;
  width: 80px;
  border: 1px solid #d1d5db;
  color: #808191;

  &:hover {
    color: #434343;


  }
}

.filter {}

.filter-open {


  transition: top .4s ease-in-out !important;


}

.filter-close {}

.active-filter {}

.btn-round-md {
  &:hover {
    background-color: #d2e3ff !important;
    transition: all .4s ease-in-out
  }
}

.d-i-b {
  display: inline-block !important
}

.card-image {
  overflow: visible !important;
  border: 1px solid black;
}

.chat-left {
  height: calc(100vh - 250px) !important;
}

//395
select.sort {
  border-radius: 7px !important;
  color: #000 !important;
  width: fit-content !important;
}

.form-control {
  line-height: 38px !important;
  height: 38px !important;

}

.box-minmax {

  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #000;

  span:first-child {
    margin-left: 10px;
  }
}

.range-slider {}

.rs-range {

  width: 100%;
  height: 3px;
  color: #000;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    box-shadow: none;
    background: #888;
    border-radius: 5px;
    border: 0px solid #010101;
  }

  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 15px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.rs-label {

  top: 50px;
  transform-origin: center center;
  display: block;
  width: 90px;
  height: 35px;
  background: transparent;
  border-radius: 50%;
  line-height: 20px;
  // text-align: center;
  font-weight: bold;
  //padding-top: 22px;
  box-sizing: border-box;
  //border: 2px solid #ddd;
  //margin-top: -20px;
  margin-left: 0px;
  left: attr(value);
  color: #000;
  font-style: normal;
  font-weight: 20px;
  line-height: normal;
  font-size: 12px;


}

.form-group,
.form-control {
  opacity: 1 !important;
  color: #000
}

.form-group {
  margin-left: 3px
}

.toggle input {
  background-color: #d2e3ff
}

.toggle input[type="checkbox"]:checked+.toggle-icon {
  background: #8ed45f;
  margin-left: 3px
}

.icon-tab {
  display: inline-block;
}

.vue-map-container {
  height: 480px !important;
}

.w-img {
  width: 100% !important;
  vertical-align: bottom !important;
  //text-align:center;
  //left:20%;
}

.scroll-bar {
  margin-left: -8px
}

.filter-control {
  visibility: visible !important;
  padding-left: 5px !important;


}

.you-are-here {
  border: 1px solid black;
  height: 100px;
  width: 100px;
}

.multiselect-tag {
  background: #bcd5fe !important;
}

.navigation .nav-caption {
  padding-left: 5px !important;
}

.search-form-2 i {
  top: 12px !important;
}

.not-allowed {
  &:hover {
    cursor: not-allowed !important
  }

}
</style>
<style src="@vueform/multiselect/themes/default.css"></style>