<template>
  <portal to="modal">
    <transition name="fade">
      <div class="modall">
        <div class="wrapper col-lg-6 col-md-9 col-sm-12">
          <div class="modal-container col-xs-12">
            <div @click.self.stop.prevent="handleClose" class="absolute inset-0 bg-gray-500 opacity-75"></div>

            <div class="
                  z-10
                  bg-white
                  rounded-modal
                  overflow-hidden
                  shadow-xl
                  transform
                  transition-all
                  sm:max-w-lg sm:w-full
                " role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="bg-white sm:p-5 sm:pb-5">
                <div class="sm:flex sm:items-start">
                  <div class="w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div class="flex items-center justify-between card-footer">
                      <h3 class="
                            pt-2
                            text-lg
                            modal-header-txt
                            text-gray-900
                            fw-600
                          " id="modal-headline">
                        {{ currentAdress.rs }}
                      </h3>


                      <!-- <template class="d-i-b" v-else>
                 <Loader></Loader>

               </template> -->
                      <i class="feather-x-square" @click.prevent="handleClose">
                      </i>

                    </div>
                    <hr />
                    <div class="" :class="{ scrollable: scrollable }">
                      <div class="
                            card
                            w-100
                            rounded-xxl
                            border-0
                            ps-4 pe-4
                            mb-3
                          ">

                        <OpenMap :currentAdress="currentAdress" />


                      </div>


                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
// import { DropZone } from 'dropzone-vue';
import { Dropzone } from "dropzone";
import Loader from "../../layouts/loader/loaderImage.vue";
import { useToast } from "vue-toastification";
import LoaderVisibility from "../loader/loaderLogin.vue";
const toast = useToast();
import Skeleton from "../../layouts/skeleton/SkeletonAdresseModal.vue";
import OpenMap from "../../pages/searchResults/openMap.vue"

export default {
  name: "BaseModal",
  components: {
    Loader,
    LoaderVisibility,
    Skeleton,
    OpenMap
  },

  data() {
    return {
      textPub: "",
      token: "",
      test: "firas",
      myDropzone: "",
      isUploading: false,
      defaultVisibility: 1,
      isDefaultVisibilityReady: false,
      adresse: [],
      isAdresseReady: false,
    };
  },
  props: ['currentAdress'],

  created() {
    document.addEventListener("keyup", this.onClose);

    this.token = localStorage.getItem("token");
  },

  mounted() {

  },

  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    getAdress() {
      this.axios.get(`adresse/${this.$route.params.id}`).then((res) => {
        this.adresse = res.data.adresse;
        this.isAdresseReady = true;
      });
    },

    getDefaultVisibility() {
      this.isDefaultVisibilityReady = false;

      this.axios.get("getDefaultVisibility").then((res) => {
        this.defaultVisibility = parseInt(
          res.data.visibility.default_visibility
        );
        this.isDefaultVisibilityReady = true;
        console.log(this.defaultVisibility);
      });
    },
    changeVisibility(visib) {
      this.axios
        .post("chaneDefaultVisibility", {
          visibility: visib,
        })
        .then((res) => {
          this.defaultVisibility = visib;
          toast.success("Visibility changed successfully ", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    dropInit() {
      //setTimeout(() => {,1000})
      Dropzone.autoDiscover = false;
      Dropzone.options.myAwesomeDropzone = {
        paramName: "file", // The name that will be used to transfer the file
        maxFilesize: 25, // MB
        url: `${this.apiBaseUrl}publication`,
        addRemoveLinks: true,
        uploadMultiple: true,
        withCredentials: true,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        autoProcessQueue: false,
        maxFiles: 8,
        parallelUploads: 22,
        acceptedFiles: "image/* , video/*",
        dictRemoveFile: "Delete",
        dictCancelUploadConfirmation:
          "Are you sure you want to cancel this download?",
        dictDefaultMessage: "Drop files here to upload",
        dictCancelUpload: "Cancel",
        dictInvalidFileType: "This form only accepts images/videos",
        dictFileTooBig: `The file size is too large. {{filesize}} the max accepted is {{maxFilesize}} `,
      };

      var myDropzone = new Dropzone("#myAwesomeDropzone", {
        parallelUploads: 22,
        init: () => {
          var myDropzone = Dropzone.forElement("#myAwesomeDropzone");

          document
            .getElementById("btnAddPub")
            .addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (myDropzone.files.length) {
                myDropzone.processQueue();
              } else {
                this.$refs.dropZoneRef.classList.add("invalid");
              }
            });

          myDropzone.on("sendingmultiple", (data, xhr, formData) => {
            this.isUploading = true;

            formData.append("adresse_id", this.adresseId);
            formData.append("visiteur_id", this.currentUser.id);
            formData.append("text_pub", this.textPub);
            formData.append("visibility", this.defaultVisibility);
          });
          myDropzone.on("successmultiple", (data, xhr, formData) => {
            this.handleRefresh();
            this.handleClose();
            toast.success("Post created ", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            //this.$router.go();
          });
          myDropzone.on("error", (file, data) => {
            this.isUploading = false;
          });
        },
      });
    },
    handleClose() {
      this.$emit("close");
    },
    handleRefresh() {
      this.$emit("refresh");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/dropzone.min.css";
.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;
  }
}
.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modall {
  position: fixed;
  z-index: 9998;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.wrapper {
  //width: 40%;
  margin: 10% auto;
  border-radius: 10px;
}
.modal-container {
  margin: 0px 50px;
  padding: 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}
.modal-header-txt {
  display: inline-block;
}
.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }
  float: right;
  font-size: 32px;
}
.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
  background-color: #eff3fa;
}
.loader {
  height: 50px !important;
}
// .h-text-area{
//   height: ;
// }
.invalid {
  border: 2px red solid;
}
.visibility-change {
  color: #212529;
  transition: all 0.3s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #2e58a6 !important;
  }
}
.dropdown-menu {
  min-width: 30px !important;
  padding: 0px !important;
  border-radius: 10px;
}
.v-element {
  transition: all 0.2s ease-in-out;
  h4,
  i {
    margin-top: 3px;
    padding-top: 3px !important;
  }
  h4 {
    padding-top: 0.3rem !important;
  }
  &:hover {
    cursor: pointer;
    background-color: #eee;
    box-shadow: 1px 1px #ccc inset;
  }
  &:hover h4 {
    color: #2e58a6 !important;
  }
  &:hover i {
    color: #2e58a6 !important;
  }
  i {
    text-align: center;
    padding: 4px 5px;
    margin-left: 2px;
    padding-bottom: 3px !important;
  }
}
.v-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.rounded-md {
  border-radius: 10px;
}
.adresse-span {
  font-weight: 300 !important;
  font-size: 12px !important;
}
.skeleton-block {
  padding: 0px !important;
}
.rounded-modal {
  border-radius: 10px;
}

@media(min-width: 989px) {
  .modal-container {
    margin: 40px 10px;
    padding: 0px 20px;
  }
}

@media (max-width: 767px) {
  // .mt-m{
  //   margin-top: 10% ;

  // }
  .chat-left{
    height: 100% !important;
  }
  .modal-container {
    margin: 0;
    padding: 0;
    border-radius: 0;
    height: 100%;
  }
   .wrapper {
  margin: 0 auto;
  height: 100% !important;
  }
  .rounded-modal {
    border-radius: 0px;
    width:100%;
    height: 100% !important;
  }
  .card-footer{
    position: relative;
    bottom: 0;
    border-top:0 ;
    
  }
  .container-publish{
    background: none;
    
  }
}
hr{
  margin:0 0 1px 0
}
.dropzone{ 
  border-radius: 15px;
}
@media (max-width: 768px) {
  .chat-left{
    height: 100% !important;
  }
}
</style>