<template lang="">
  <div class="middle-wrap">
    <div class="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
      <div class="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
        <router-link to="/parametres" class="d-inline-block mt-2"
          ><i class="ti-arrow-left font-sm text-white"></i
        ></router-link>
        <h4 class="font-xs text-white fw-600 ms-4 mb-0 mt-2">
          Account details
        </h4>
      </div>
      <div class="card-body p-lg-5 p-4 w-100 border-0">
        <div class="row justify-content-center">
          <div class="col-lg-4 text-center">
            <figure v-if="currentUser.photo" class="avatar ms-auto me-auto mb-0 mt-2 w100">
              <img
                :src="`${assetsBaseUrl}${currentUser.photo}`"
                alt="image"
                class="shadow-sm rounded-3 w-100"
              />
            </figure>
             <figure v-else class="avatar ms-auto me-auto mb-0 mt-2 w100">
              <Loader></Loader>
            </figure>
            <h2 class="fw-700 font-sm text-grey-900 mt-3">
             {{currentUser.nom}} {{currentUser.prenom}}
            </h2>
            <h4 class="text-grey-500 fw-500 mb-3 font-xsss mb-4">
              @{{currentUser.nom}} {{currentUser.prenom}}
            </h4>
          </div>
        </div>

        <form
          
          @submit.prevent="updateProfil()"
          
        >
        
          <div class="row">
            <div class="col-lg-6 mb-3">
              <div class="form-group">
                <label class="mont-font fw-600 font-xsss" for="nom"
                  >Last name <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="nom"
                  class="form-control"
                  id="nom"
                  name="nom"
                  required
                />
              </div>
            </div>

            <div class="col-lg-6 mb-3">
              <div class="form-group">
                <label class="mont-font fw-600 font-xsss" for="prenom"
                  >First name
                <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="prenom"
                  class="form-control"
                  id="prenom"
                  name="prenom"
                  required
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-6 mb-3">
              <div class="form-group">
                <label class="mont-font fw-600 font-xsss" for="email"
                  >Email <span class="text-danger">*</span></label
                >
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  id="email"
                  name="email"
                  required
                />
              </div>
            </div>

            <div class="col-lg-6 mb-3">
              <div class="form-group">
                <label class="mont-font fw-600 font-xsss" for="tel"
                  >Phone</label
                >
                <input
                  type="number"
                  v-model.number="tel"
                  class="form-control"
                  id="tel"
                  name="tel"
                  
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-lg-12 mb-3">
              <div class="form-group">
                <label class="mont-font fw-600 font-xsss" for="adresse"
                  >address</label
                >
                <input
                  type="text"
                  v-model="adresse                                                                                                                   "
                  class="form-control"
                  id="adresse"
                  name="adresse"
                />
              </div>
            </div>
          </div>

          <div class="row">
           

            <div class="col-lg-12">
              <input
                type="submit"
                role="button"
                value="Validate"
                class="
                  bg-current
                  text-center text-white
                  font-xsss
                  fw-600
                  p-3
                  w175
                  rounded-3
                  d-inline-block
                  float-right
                ">
                
              
            </div>
          </div>
        </form>
      </div>
    </div>
   
  </div>
</template>
<script>
import Loader from "../../layouts/loader/loader.vue";
import { useToast } from 'vue-toastification';
const toast = useToast()
export default {
  name: "InfoCompte",
  components:{
    Loader

  },
  data(){
    return{
      isUserReady:false,
      error:[],
      nom:"",
      prenom:"",
      email:"",
      tel:null,
      adresse:""

    }
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
   mounted(){
     this.nom = this.currentUser.nom;
     this.prenom = this.currentUser.prenom;
     this.email = this.currentUser.email;
     this.nom = this.currentUser.nom;
     this.tel = this.currentUser.tel;
     this.adresse = this.currentUser.adresse;   
     
   },
   methods:{

   },
  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    currentUser() {
            return this.$store.state.currentUser;
        },
  },
  methods:{
    updateProfil(){
      this.axios.post('/profil/update',{
      nom:this.nom,
      prenom:this.prenom,
      email:this.email,
      tel:this.tel,
      adresse:this.adresse

      }).then(res=>{
        console.log(res.data);
          toast.success("Updated successfully ", {
          position: "top-right",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        
      }).catch(error=>{
        console.log(error.response.data.errorsValidation);
       
              if(error.response.data.errorsValidation.email){
              toast.error("The email must be a valid email address.", {
              position: "top-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
            }
            
      })

    },

  },
 
};
</script>
<style lang="scss" scoped>
.bg-current {
  background-color: #2e58a6 !important;
}
</style>
