<template>
 <portal to="modal">
   
      <div
        class="modall"
      >
      <div class="wrapper col-md-6 col-sm-12">
        <div class="modal-container">
          <div
            @click.self.stop.prevent="handleClose"
            class="absolute inset-0 bg-gray-500 opacity-75"
          ></div>
        </div>

        <div
          class="z-10 bg-white rounded-modal overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <!-- <div class="bg-white px-4 pt-0 pb-0 sm:p-5 sm:pb-5">
           
                 
                 
              
                  
                </div> -->
                <div class="">
                    
                   <div class="flex items-center justify-between card-footer border-bottom-xs text-center">
                    <h3
                      class="pt-2 text-lg modal-header-txt text-gray-800 fw-700  "
                      id="modal-headline"
                    >
                      Mutual friends
                    </h3>

                    <i
                      v-if="!isUploading"
                      class="feather-x-square"
                      @click.prevent="handleClose"
                    >
                    </i>
                   
                  </div>
                 
               
                                  
                  <div class="card w-100 shadow-xss rounded-xxl border-0  mb-3">
                        
                   
                          <div class="card-body pt-3 pb-3 border-top-xs bor-0">
                            <div class="chat-left scroll-bar pt-2 w-100">
                           

        <div v-if="!isFriendsReady">
           <Loader></Loader>
          <Skeleton></Skeleton>
         
          
          
        </div>                      
        <div v-else  v-for="(friend,index) in friends" class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1">
          
        
        <figure class="avatar me-2 mb-1 pt-1 position-relative">
          <router-link v-tooltip.left-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.id } }" >
         <img  :src="`${assetsBaseUrl}${friend.photo}`" alt="image" class="shadow-sm rounded-circle w45"> 
         </router-link>
        
        </figure>
        
       
        
        
              <h4  class="fw-700 text-grey-900 font-xssss mt-2 link-user">
         
              <router-link v-tooltip.top-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.id } }" >{{friend.prenom}} {{friend.nom}}
                </router-link>
                <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">
                  {{friend.mutualFriend}} 
                  mutual friends</span>
                
                 <!-- <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profil</span> -->
              </h4>
               
              
              <!-- <a :ref="setAddFriendAllRef" v-if="!jaime.visiteur.isFriend && !jaime.visiteur.isMyProfil" @click="addFriend(jaime.visiteur.id,index,null)"  class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"></a>  -->
            
        
        
       </div>

       
       <div v-if="done"
                          class="
                              card
                              w-100
                              text-center
                              
                              rounded-xxl
                              border-0
                              p-2
                              mb-1
                              mt-1
                          "
                      >
                          
                  No other friends
                      </div>
                <Loader v-show="isVisible && !done"/> 
                <div v-if="friends.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div> 
   

                            </div>
                            
                 

                           </div>
    
                        
                               
                           
                  
     </div>
 
                 
                </div>
             
          
        </div>
      </div>
      </div>
 
  </portal>
</template>

<script>
import {Tabs, Tab} from 'vue3-tabs-component';
import jaimeListAll from "../../pages/Jaimes/allReactions.vue"; 
import Loader from '../../layouts/loader/loaderImage.vue';
import Skeleton from '../../layouts/skeleton/SkeletonComment.vue';
import { useToast } from 'vue-toastification';
const toast = useToast()

    
export default {
  
  name: "BaseModal",
  components:{
    Loader,
    jaimeListAll,
    Tabs,
    Tab,
    Skeleton

  },
 
  data(){
     return{
      
       //-------(Arrays)-----
       friends:[],
       //-------(Ready)-----
       isFriendsReady:false,
      
       //-------(Total)-----
      
       totalFriends:0,
       //-------(Pagination)-----
       page:1,
       lastpage:1,
      
       done:false,
       isVisible: false,
       
       //---------(refs)---------
      

   
     }
  },
  props: {
   
    friendId:Number,
  },
  created() {
    document.addEventListener("keyup", this.onClose);
     
   this.token = localStorage.getItem('token');
  
  },


  mounted(){
    this.getMutualFriends();
    
 
  },

  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
   watch: {
    
    
  },
  methods: {
    //-----------------------(Refs)---------------------
      setAddFriendAllRef(el){
         
        if (el && !this.addFriendAllRef.includes(el)) {
          this.addFriendAllRef.push(el);
        }
      },
        
    //------------------------------------------
    

     visibilityChanged (isVisible) {
         //this.addFriendRef=[];
            
        if(!isVisible){ return }
        if(this.page >= this.lastpage){return this.done = true}
          this.isVisible = isVisible;
    
            this.page++;
            this.getMutualFriends();
            
    
      },
    
      getMutualFriends(){
      this.axios.post(`getMutualFriends?page=${this.page}`,{
        friendId:this.friendId
      }).then(res=>{
        //console.log(res.data.reactions)
         this.friends.push(...res.data.amis.data);
        //this.jaimes = res.data.reactions.data;
        this.isFriendsReady = true;
        this.totalFriends = res.data.amis.total;
         this.lastpage = res.data.amis.last_page;

          
        })
          },

    
  
    handleClose() {
      this.$emit("close");
      
    },
     handleRefresh() {
      this.$emit("refreshLogo");
      
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
     
  },
      computed: {

       
        currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
    

    },
};
</script>

<style lang="scss" scoped>


.em,
.em-svg {
  height: 1em;
  width: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}
.em---1,
.em---1,
.em-thumbsup {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
}
.em---1.em-svg,
.em---1.em-svg,
.em-thumbsup.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44d.svg"),
    none;
}
.em--1,
.em-thumbsdown {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
}
.em--1.em-svg,
.em-thumbsdown.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44e.svg"),
    none;
}
.em-heart {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
}
.em-heart.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/2764.svg"),
    none;
}
.btnPub{
  transition: all .4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color:#fff;
  
  &:hover{
background-color: #1259cc !important;



  }
}
.spanBtn{
  display: inline-block;
  width: 100%;


}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modall{
   position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
 
}
.wrapper{
 // width: 40%;
  margin: 0 auto;
  border-radius: 10px;

}
.modal-container {

  margin: 30px 50px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
     overflow: auto;
}
.modal-header-txt{
  display: inline-block;
}
.feather-x-square{
  &:hover{
    cursor:pointer;
    color:red;
    transition: all .4s ease-in-out;
  }
  float:right;
  font-size: 32px;
}
.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
  
  
  
}
.loader{
  height:50px !important
}
.d-i-b, .tabs-component-tabs{
  display:inline-block !important;
}
.is-active{
 border-bottom: 2px#333 solid !important;
  color: #2e58a6 !important;
  text-decoration: underline !important;
  font-size:32px
   
}
.chat-left {
    height: calc(100vh - 300px) !important;
}
.close-btn{
  top:-415px !important;
  right:-23px !important
}
.rounded-circle{
 border-radius:10px !important
}
.btn-round-sm{
  border-radius:5px !important
}  
.btn-add , .btn-decline{
  border-radius:5px !important;
  transition: background-color .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
    color:#fff !important;
  }
}
.btn-add{
  &:hover{
background-color: #2e58a6 !important;
  }
}
.btn-decline{
  
  &:hover{
background-color: #f00 !important;
  }
}
.icon-reaction{
  bottom: -3px;
  right:-3px
  }
.red{
color:#f00 !important;
}
.blue{
color:#2e58a6 !important;
}
.orange{
color:rgb(233, 113, 15) !important;
}
.all-count{
  top:-55px;
  left:44px;
  font-size:11px !important;
  //color:#2e58a6 !important;
}
.likes-count{
  top:-55px;
  left:138px;
  font-size:11px !important;
 // color:#2e58a6 !important;
}
.loves-count{
   top:-55px;
  left:237px;
  font-size:11px !important;
  //color:#2e58a6 !important;

}
.dislikes-count{
  top:-55px;
  left:350px;
  font-size:11px !important;
  //color:#2e58a6 !important;

}
.hover-item{
  &:hover{
     border-bottom: 1px#2e58a6 solid !important;
    color: #2e58a6 !important;
  }
}
.modal-header-txt{
  text-align: center !important;
}
.ms-10{
  padding-left:10em
}
.rounded-modal{
  border-radius:10px
}
@media screen and (max-width: 989px) {
.modal-container {

  margin: 0px 10px;
  padding: 0px 20px;
  
}

}

@media (max-width: 767px) {

  .bg-greylight{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
 .card-m{
   padding-left:0px !important;
    padding-right:0px !important;

   
 }
    .modal-container {
    margin: 0px 10px !important;
    
  }
   .wrapper {
  margin: 0 auto;
  height: 100% !important;
  }
  .rounded-modal {
    border-radius: 0px;
    width:100%;
    height: 100% !important;
  }
  .card-footer{
    position: relative;
    bottom: 0;
    border-top:0 ;
    
  }
  .container-publish{
    background: none;
    // position: absolute;
    // bottom: 10px;
    // width: 100%;
  }
  .card-mm{
       padding-left:0px !important;
    padding-right:0px !important;
  }
}
</style>