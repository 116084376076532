<template lang="">
    <div class="row">
        <div class="col-xl-8 col-xxl-8 col-lg-8">
            <CreateFeed></CreateFeed>
          
                <!-- <div class="col-md-12">
                   
                </div> -->
                
                 <div class="card shadow-xss rounded-xxl border-0 p-4 mb-3 mt-1">
                <Photo />
                </div>
            
        </div>
        <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0">
      <SuggestedAmis />
      
      <SuggestedPages />
    </div>
    </div>
</template>

<script>
import CreateFeed from "../Feeds/CreateFeed.vue";
import Photo from "../Photos/Photo.vue";
import SuggestedAmis from "../Feeds/suggestedAmis.vue";
import SuggestedPages from "../Feeds/suggestedPages.vue";
export default {
    name: "photos",
    components: {
        CreateFeed,
        Photo,
         SuggestedAmis,
            SuggestedPages,
    },
};
</script>
<style lang=""></style>
