<template lang="">
  <vueNavBar />
  <vueSideBar :MenuFull="MenuFull" />
  <div ref="mainContent" class="main-content right-chat-active" :class="filterToggle? 'active-menu':''">
    <div class="middle-sidebar-bottom">
      <div class="middle-sidebar-left">
        <portal-target name="modal"/>
        <portal-target name="modalComment"/>
        <!-- <portal to="autocomplete"> -->
        <!-- <GMapAutocomplete
                                  ref="autocomplete"
                                    placeholder="This is a placeholder"
                                    @place_changed="setPlace"
                                    classname="bor-0 w-100 rounded-xxl p-2 ps-5 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                    
                                  >
         </GMapAutocomplete> -->
         <!-- </portal> -->
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import vueNavBar from "../layouts/navBar.vue";
import vueSideBar from "../layouts/sideBar.vue";


export default {
  name: "Home",
  data() {
    return {
      MenuFull:true,
    };
  },
  methods: {
    setPlace(place) {
       
      this.place = place ;
      
      console.log(this.place.geometry.location.lat());
      console.log(this.place.geometry.location.lng());
       
    },
  },

  components: {
    vueNavBar,
    vueSideBar,
  },
  beforeCreate() {
    if (!localStorage.getItem("token")) {
      this.$router.push("/");
    }
  },

  mounted() {
    this.$store.dispatch("getuser");
  },
  updated(){
    if(this.$store.state.filterToggle){
      this.MenuFull = true;
    }

  },
   


  computed: {
     isMapON() {
      return this.$route.name === 'adressesaproximite';
      
  },
    currentUser() {
      return this.$store.state.currentUser;
    },
     filterToggle(){
      return this.$store.state.filterToggle;
    }
  },
};
</script>
<style lang="scss">
a {
  text-decoration: none !important;
}
h4,
.h4 {
  font-size: 1rem !important;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease-in-out;
}
.main-content.menu-active{
  padding-left: 0 !important;
}

</style>
