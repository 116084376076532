<template lang="">

<ModalMutualFriend v-if="isShowModalMutualFriend" @close="toggleModalCloseMutualFriend" :friendId="friendId"></ModalMutualFriend>
<div v-if="!isReady">

  <Loader></Loader>
</div>  

<div v-else>

           <template v-if="!searchResults.visiteurs.data.length && !searchResults.adresses.data.length ">
             No result found
           </template>
     
            <template v-if="searchResults.visiteurs.data.length">
             
                  <h2>Profiles ({{searchResults.visiteurs.data.length}}) : <router-link to="/searchResultPeople" class="see-all-btn fw-600 text-primary font-xss float-right pe-3 pb-1 show-all" v-if="searchResults.visiteurs.total > 8">See all</router-link></h2>
                   <div class="row">
                      <div v-for="(friend,index) in searchResults.visiteurs.data" class="card-body d-flex pt-2 ps-4 pe-1 pb-0 border-top-xs bor-0 col-xl-6 col-xxl-4 col-lg-6 col-md-6 bg-greylight mb-1 rounded-3" :ref="setFriendRef">
                      <template v-if="index < 8">
                       <router-link :to="{name :'feedvisiteur', params: { id : friend.id } }" >
               
                    <figure class="avatar me-3">
                      <img :src="`${assetsBaseUrl}${friend.photo}`" alt="image" class="shadow-sm rounded-circle w45 fluid-image">
                    </figure>
                  </router-link>
                      <h4 class="fw-700 text-grey-900 font-xssss mt-1 link-user">
                  <router-link  :to="{name :'feedvisiteur', params: { id : friend.id } }" >{{friend.prenom}} {{friend.nom}}
                  </router-link>
                  <template v-if="!friend.isMyProfil">

                 
                  <span @click="toggleModalMutualFriend(friend.id)" v-if="friend.mutualFriend > 0"  class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500 get-mutual-friend">{{friend.mutualFriend}}  mutual friends</span>
                  <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">no mutual friends</span>
                   </template>
                   <template v-else>
                      <span  class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">Visit your profile</span>
                   </template>
                  </h4>
                  </template> 

                  <template v-if="!friend.isMyProfil">
<!-- is not my profil -->

                    <template v-if="!friend.isFriend">
<!-- is not friend -->                 
                    <template  v-if="friend.isRequestSent == false">
<!-- is request false -->    
                          <!-- v-tooltip.top-start="{content: () => asyncMethod(true)}"           -->
                       <a  @click="addFriend(friend.id,index)" class="btn-add add-friend btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2" ></a>

                    </template>
<!-- else is request false -->                      
                     <template v-else-if="friend.isRequestSent == 'sender'">
                        
                       <a  @click="confirmFriend(friend.id,index)" class="btn-accept btn-round-sm bg-white text-grey-900 fas fa-check font-xss mt-2 ms-5 me-1" href="#"></a>
                       <a  @click="declineFriend(friend.id,index)" class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss mt-2" href="#"></a>
                       
                     </template>

                      <template v-else-if="friend.isRequestSent == 'receiver'">
                          <a  @click="removeFriend(friend.id,index)" class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss mt-2 ms-6" href="#"></a>
                       </template>

                  </template>  
<!-- is friend -->
                  <template v-else>
                    <div class="position-relative ms-auto">
                   <a class=" me-3 p-1 lh-20 mb-1 bg-greylight text-grey-800 text-center font-xssss fw-600 rounded-md is-friend">Friend</a>
                   </div>
                  </template>

                 
                  </template>
                  
<!-- is  my profil -->
                  <template v-else>
                     <!-- <router-link :to="{name :'feedvisiteur', params: { id : currentUser.id } }" v-tooltip.top-start="'Show profile'" href="#" class="btn-round-sm bg-white text-grey-900 feather-chevron-right font-xss ms-auto mt-2"></router-link> -->

                  </template>

                      </div> </div>
                   
            </template>  
            <template v-if="searchResults.adresses.data.length"> 
              <div  class="pt-5 border-top-xs">
              <h2>Adresses ({{searchResults.adresses.total}}) : <router-link to="/searchResultAdresses" class="fw-600  text-primary float-right font-xss pe-3 pb-1 show-all see-all-btn" v-if="searchResults.adresses.total > 8">See all</router-link></h2>
              
              <div class="row">
              <div v-for="(adresse,indexx) in searchResults.adresses.data"  class="pe-2 ps-2 col-md-6 ">
              <template  v-if="indexx < 8">  
             <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss 
              rounded-3
              overflow-hidden
              me-1
              col-lg-3
              d-i-b
            "
          >
         
            <div class=" mb-1 mt-1 d-i-b col-md-4 border-0">
              <template v-if="!adresse.google_place_id && adresse.google_place_id == null">
              <router-link  :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }"
              class="position-relative d-block">
                <img 
                  :src="`${assetsBaseUrl}${adresse.logo}`"
                  alt="image"
                  class="w-img  ps-2"
                />
              </router-link>
              </template>
              <template v-else>
                  <router-link  :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }"
              class="position-relative d-block">
                <img v-if="adresse.google_place_id != null"
                  :src="`${assetsBaseUrl}${adresse.logo}`"
                  alt="image"
                  class="w-img  ps-1 media-fit"
                />
                <img v-else
                  :src="`${assetsBaseUrl}${adresse.logo}`"
                  alt="image"
                  class="w-img media-fit  ps-1"
                />
              </router-link>
              </template>
              
            </div>
             
            <div class="card-body pt-1 d-i-b col-md-8 container-adrs-inf">
              <h4 class="fw-700 font-xss mt-0 lh-28 mb-0 elipsis">
                <router-link :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }" class="text-dark text-grey-900 elipsis">{{adresse.rs}}</router-link>
              </h4>
              <h6 class="font-xssss text-grey-500 fw-600 mt-0 mb-0 elipsis">
                {{adresse.adresse}}
              </h6>
              <div class="star d-block w-100 text-left mt-0">
                <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
                <div class="rating-color d-i-b" v-html="drawStarsYellow(adresse.rateRound)"></div>
                <div class="d-i-b rating-grey" v-html="drawStarsGrey(adresse.rateRound)"></div>
                </router-link>
              
              </div>
               <div class="clearfix"></div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
              <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
              <div class="couts-color d-i-b" v-html="drawDollarsGreen(adresse.coutsRound)"></div>
              <div class="d-i-b rating-grey" v-html="drawDollarsGrey(adresse.coutsRound)"></div>
              </router-link>
              
              </div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
                
                <template v-for="serv in adresse.services">

                  <span v-html="serv.icone"></span>
                  
                </template>
              </div>
               <a :ref="setToggleFavRef" @click="toggleFavorite(adresse.id,indexx)"  class="position-absolute d-none  d-lg-block  btn-round-lg ms-2 text-white-700 btn-toggle-fav"><i class="fas fa-heart font-md" :class="adresse.isFavorite ? 'btn-remove-fav':'btn-add-fav'" ></i></a>


                 <!-- <a v-if="adresse.isFollowed" @click="unfollow(adresse.id,index)" class="position-absolute p-1 lh-20 mb-1 ms-1 bg-grey text-grey-800 text-center font-xssss fw-600  rounded-md btn-add-friend btn-unfollow" ref="btnAddFriend" ><i class="fas fa-user-times"></i> Unfollow</a>
                 <a v-if="!adresse.isFollowed" @click="unfollow(adresse.id,index)" class="position-absolute p-1 lh-20 mb-1 ms-1 bg-grey text-grey-800 text-center font-xssss fw-600  rounded-md btn-add-friend btn-follow" ref="btnAddFriend" ><i class="fas fa-user-plus"></i> Follow</a> -->

                 <!-- *****************************-->
                 <a @click="toggleFollow(adresse.id,indexx)" :class="adresse.isFollowed? 'btn-unfollow':'btn-follow'" class="position-absolute p-1 lh-20 mb-1 ms-1 bg-greylight text-grey-800 text-center font-xssss fw-600  rounded-md btn-add-friend" :ref="setBtntoggleFollow"><i :class="adresse.isFollowed? 'fas fa-user-times':'fas fa-user-plus'"></i> <span :ref="setSpantoggleFollow" v-text="adresse.isFollowed?'Unfollow':'Follow'"></span> </a>

               
                

             
             
            </div>  
         </div> 


       
            </template>                        
             </div>
             </div>
              </div>    
             </template>

             <!--******************************** (new pub) ******************************-->

             <template v-if="searchResults.publications.data.length"> 
              <div  class="pt-5 border-top-xs">
              <h2>Posts ({{searchResults.publications.total}}) : <router-link to="/searchResultAdresses" class="fw-600  text-primary float-right font-xss pe-3 pb-1 show-all see-all-btn" v-if="searchResults.publications.total > 8">See all</router-link></h2>
              
              <div class="row">
              <div v-for="(pub,indexx) in searchResults.publications.data"  class="pe-2 ps-2 col-md-6 ">
              <template  v-if="indexx < 8">  
             <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss 
              rounded-3
              overflow-hidden
              me-1
              col-lg-3
              d-i-b
            "
          >
         
            <div class=" mb-1 mt-1 d-i-b col-md-4 border-0">
              
              
              <div class="position-relative d-block">
              <template v-if="pub.medias[0].type == 1">
              <router-link :to="`/showPost/${pub.id}/${pub.medias[0].id}`">
              <img 
                  :src="`${assetsBaseUrl}${pub.medias[0].src}`"
                  alt="image"
                  class="w-img  ps-1 img-pub"
                />
              </router-link>
              

              </template>
              <template v-else>
                
                <router-link :to="`/showPost/${pub.id}/${pub.medias[0].id}`">
                 <img 
                  :src="`${assetsBaseUrl}images/videothumbnail.png`"
                  alt="image"
                  class="w-img  ps-1 img-pub"
                />
                </router-link>
              </template>
                
                </div>
              
              
             
              
            </div>
             
            <div class="card-body pt-1 d-i-b col-md-8 container-adrs-inf container-pub-inf">
              <h4 class="fw-700 font-xss mt-0 lh-28 mb-0 elipsis adrs-pub">
                <router-link :to="{ name: 'feedadresse', params: { id: `${pub.adresse.id}` } }" class="text-dark text-grey-900 elipsis">{{pub.adresse.rs}}</router-link>
              </h4>
              
              <h6 class="font-xssss text-grey-500 fw-600 mt-0 mb-0 elipsis">
                {{pub.text_pub}}
              </h6>
            </div>  

            

         </div> 


       
            </template>                        
             </div>
             </div>
              </div>    
             </template>



             <!-- ************************************************************************* -->
        
  </div>     
</template>
<script>
import { useToast } from 'vue-toastification';
const toast = useToast()
import Loader from "../../layouts/loader/loader.vue"
import ModalMutualFriend from "../../layouts/modal/modalMutualFriend.vue";
export default {
  components:{
    Loader,
    ModalMutualFriend
  },
    data(){
      return {
        videoRef: [],
          canvasRef:[],
           NavBarSearchKey:'',
            results:[],
            friends:[],
          adresses: [],
            publications:[],
            isFollowed:false,
            toggleFavRef:[],
            addFriendRef:[],
            btntoggleFollow:[],
           spantoggleFollow:[],
           isShowModalMutualFriend:false,
           friendId:null
        }

  },
    destroyed() {
      // this.NavBarSearchKey = this.$store.state.NavBarSearchKey;
      // this.getSearchResults();
      this.$store.dispatch("NavBarSearchKey", this.NavBarSearchKey);
     
    const controller = new AbortController();
    controller.abort()
 
    },
  mounted() {

    
     
      
         setTimeout(()=>{
           this.NavBarSearchKey = this.$store.state.NavBarSearchKey
          
        
             , 2000
         });
        
 

    },
  beforeUpdate() {
    if (this.NavBarSearchKey != '') {
      this.NavBarSearchKey = this.$store.state.NavBarSearchKey;
      this.getSearchResults()
    }
    },
    watch:{
       NavBarSearchKey(newNavBarSearchKey, oldNavBarSearchKey) {
      if (newNavBarSearchKey != oldNavBarSearchKey && this.NavBarSearchKey != '') {
        this.getSearchResults();
        
      }
    }

    },
  methods: {

      setVideoRef(el) {
      if (el && !this.videoRef.includes(el)) {
        this.videoRef.push(el);
      }
    },

    setCanvasRef(el) {
      if (el && !this.canvasRef.includes(el)) {
        this.canvasRef.push(el);
      }
    },

     thumbnail(){
    var canvas = document.getElementById('canvas');
    var video = document.getElementById('video');
    canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
},

     
      toggleModalCloseMutualFriend(id){

      this.isShowModalMutualFriend = false;  

    },

    toggleModalMutualFriend(id){

      this.isShowModalMutualFriend = !this.isShowModalMutualFriend;
      this.friendId = id;

    },
      asyncMethod(text){
        if(text){
        return 'Add friend' ;
        }else{
        return 'Cancel' ;

        }
        },

      
      //Fav-------------------------------------------------------
      setSpantoggleFollow(el){
           if (el && !this.spantoggleFollow.includes(el)) {
          this.spantoggleFollow.push(el);
        }
      },
      setToggleFavRef(el){
        if (el && !this.toggleFavRef.includes(el)) {
          this.toggleFavRef.push(el);
        }
      },
       setFriendRef(el){
         
        if (el && !this.addFriendRef.includes(el)) {
          this.addFriendRef.push(el);
        }
      },
      setBtntoggleFollow(el){
        if (el && !this.btntoggleFollow.includes(el)) {
          this.btntoggleFollow.push(el);
        }

      },
      
    
  
    toggleFavorite(id,i){
                   console.log(this.toggleFavRef[i]);
      //isFavorite = true
      if(this.toggleFavRef[i].firstElementChild.classList.contains("btn-remove-fav")){
        this.toggleFavRef[i].firstElementChild.classList.remove('btn-remove-fav');
        this.toggleFavRef[i].firstElementChild.classList.add('btn-add-fav');

        this.removeFavorite(id);

      }else{
        this.toggleFavRef[i].firstElementChild.classList.remove('btn-add-fav');
        this.toggleFavRef[i].firstElementChild.classList.add('btn-remove-fav');

        this.addFavorite(id);

      }

    },

    removeFavorite(id){
      this.axios.post(`favoris/delete`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,     
      }).then(res=> {
       
        
        
      });
    },
     addFavorite(id){    
      this.axios.post(`/favoris/add`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
         
        
        
      });

    },
      //friend-------------------------------------------------
       addFriend(id,i){
         if( this.addFriendRef[i].lastElementChild.hasAttribute('cancel-data')){
          this.removeFriend(id,i)
         

         }else{
         console.log(this.addFriendRef[i].lastElementChild);
        this.addFriendRef[i].lastElementChild.setAttribute('cancel-data' ,"cancel" );
        this.addFriendRef[i].lastElementChild.classList.remove('fa-user-plus','v-popper--has-tooltip','btn-add');
        this.addFriendRef[i].lastElementChild.classList.add('fa-times','btn-decline');

     
      this.axios.post('amis/ajouterAmis',{
        visiteur_id:this.currentUser.id,
        receiver_id:id


      }).then(res=>{
        //this.getFriendStatus();
       

      })
         }
    },
     getFriendStatus(){
      this.axios.post('/amis/friendStatus',{
        visiteur_id:this.$route.params.id
      }).then(res=>
      {
        this.friendStatus = res.data.friendStatus;
         this.senderID = res.data.sender;
          this.receiverID = res.data.receiver;
      
      }).catch(err=>{
        this.friendStatus = false;

      })
   
    },
     confirmFriend(id,i){

            this.addFriendRef[i].children[2].remove();
            this.addFriendRef[i].lastElementChild.remove();
          
      console.log(this.addFriendRef[i]);
        this.axios.post('amis/confimer',{
        visiteur_id:id,
      }).then(res=>{
        //this.getFriendStatus();
      })
      

      
        



    },
    declineFriend(id,i){
       
      if(this.addFriendRef[i].lastElementChild.classList.contains("btn-decline")){
           
            this.addFriendRef[i].children[2].remove();
            this.addFriendRef[i].lastElementChild.remove();

      console.log(this.addFriendRef[i]);
       this.axios.post('amis/supprimerAmis',{
        visiteur_id:id,
      }).then(res=>{
        //this.getFriendStatus();
      })
      }
      
    },
    removeFriend(id,i){
       if( this.addFriendRef[i].lastElementChild.classList.contains('btn-decline')){

            console.log(this.addFriendRef[i]);
            this.addFriendRef[i].lastElementChild.removeAttribute('cancel-data');
            this.addFriendRef[i].lastElementChild.classList.add('fa-user-plus','btn-add');
            this.addFriendRef[i].lastElementChild.classList.remove('fa-times','btn-decline');
       this.axios.post('amis/supprimerAmis',{
        visiteur_id:id,
        


      }).then(res=>{
        //this.getFriendStatus();

      })
        
       
       }else{

          this.addFriend(id,i);
         

              
       }

    },
    
    //Adresse--------------------------------------------------------------------
    toggleFollow(id,i){

       if( this.btntoggleFollow[i].classList.contains('btn-follow')){
         this.btntoggleFollow[i].classList.remove('btn-follow');
         this.btntoggleFollow[i].classList.add('btn-unfollow');
          this.btntoggleFollow[i].firstElementChild.classList.remove('fa-user-plus');
         this.btntoggleFollow[i].firstElementChild.classList.add('fa-user-times');
          this.spantoggleFollow[i].textContent ="Unfollow";


          this.axios.post(`/follow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
        

      
        
      });

       }else{

         this.spantoggleFollow[i].textContent = "Follow";
         this.btntoggleFollow[i].classList.remove('btn-unfollow');
        this.btntoggleFollow[i].classList.add('btn-follow');
         this.btntoggleFollow[i].firstElementChild.classList.add('fa-user-plus');
         this.btntoggleFollow[i].firstElementChild.classList.remove('fa-user-times');
         
         this.axios.post(`/unfollow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
       // this.getFollowersList();
        
      });

       }

     

    },

      follow(){
      this.isFollowerListReady = false;
       this.isFollowed = true;
      this.axios.post(`/follow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:this.adresseId,
        
      }).then(res=> {
        //this.getFollowersList();

      
        
      });
    },

     unfollow(){
       this.isFollowerListReady = false;
       this.isFollowed = false;
      this.axios.post(`/unfollow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:this.adresseId,
        
      }).then(res=> {
       // this.getFollowersList();
        
      });
    },
    //--------------------------------------------------------------------------------
     drawStarsYellow(count){
          return `<i class="fa fa-star rating-color"></i>`.repeat(count)
      },
    drawStarsGrey(count){
          return `<i class="fa fa-star"></i>`.repeat(5-count)
      },
    drawDollarsGreen(count){
          return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count)
      },
    drawDollarsGrey(count){
          return `<i class="fas fa-dollar-sign"></i>`.repeat(5-count)
      },
    //-------------------------------------------------------------------------------
      getSearchResults(){
        this.axios.post('searchNavBar',{
          searchFilter:this.NavBarSearchKey
        }).then(res=>{
          this.results = res.data;
          this.friends = res.data.visiteurs;
          this.adresses = res.data.adresses;
          this.publications = res.data.publications;
          

        })
      },
    },
     computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     currentUser() {
            return this.$store.state.currentUser;
        },
    searchResults() {
            return this.$store.state.searchResults;
        },
         isReady() {
            return this.$store.state.searchResultsIsReady;
        },
       
       
  },
};
</script>
<style lang="scss" scoped>

.container-pub-inf{

position: absolute;
top:5px;

}
.centerr-flex{
  justify-content: center;
}
.card{
  flex-direction: row !important
}
.rounded-circle{
 border-radius:10px !important
}
.btn-unfollow, .btn-follow{
  
  bottom:0px !important;
  right:0px !important;
  // background-color: #2e58a6 !important;
  color:#000 !important;
  // font-size:16px !important
}
.btn-unfollow {
  border-radius: 5px;
  &:hover{
      cursor:pointer;
     box-shadow: 1px 1px 2px #8888884d inset ;
     
     

  }
}
.btn-follow{
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color:#fff !important;
   &:hover{
      cursor:pointer;
     box-shadow: 1px 1px 2px #222 inset ;
     
     

  }
}
.top-0 {
    top: -100px !important;
}
.rounded-3{
  border-radius: 5px !important;
  border-right: #fff 2px solid ;
}
.btn-round-sm, .btn-add , .btn-accept, .btn-decline{
  border-radius:5px !important;
  transition: background-color .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
    color:#fff !important;
  }
}
.btn-add{
  &:hover{
background-color: #2e58a6 !important;
  }
}
.btn-decline{
  
  &:hover{
background-color: #f00 !important;
  }
}
.btn-accept{
  &:hover{
background-color: rgb(43, 110, 43) !important;
  }
}
.see-all-btn{
  &:hover{
    cursor:pointer;
    text-decoration:underline !important;
  }
}
.ms-6 {
    margin-left: 5rem !important;
}
.container-adrs-inf{
  padding: 0rem 1.5rem 0.5rem !important
}
.rating-color {
    color: #fbc634 !important
}
.rating-grey{
  color:#d2e3ff !important
}
.couts-color{
  color:rgb(16, 92, 16)
}
 .btn-remove-fav{
   &:hover{
     cursor:pointer;

     //color:#bbb !important
   }
  
   transition: all .4s ease-in-out;
   z-index:5;
   color:#f00;
  
 }

  .btn-add-fav{
   &:hover{
     cursor:pointer;
     //color:rgba(255,0,0,0.4)  !important;

     
   }
  
   transition: all .4s ease-in-out;
   z-index:5;
   
   color:#bbb !important;
   
 }
 .btn-toggle-fav{
   left:-15px !important;
   top:-9px !important;
 }
 .w-img{
  width:100% !important;
  height:90px !important;
  vertical-align:bottom !important;
  border-radius: 10px
  //text-align:center;
  //left:20%;
}
.elipsis{

   width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
// .btn-followw{
  // bottom:0px !important;
  // right:0px !important
// }
.is-friend{
  bottom:-8px !important;
  // right:-120px !important;
  background: rgba(255,255,255,0.7) !important;
  border-radius:5px;
}
.d-i-b{
  display:inline-block !important
}
.get-mutual-friend{
  &:hover{
    cursor:pointer;
    text-decoration:underline
  }
 

}

@media (max-width: 767px) {
 
.container-pub-inf{
padding: 0rem 0.5rem 0.5rem !important;
position: absolute;
top:5px;

}
.adrs-pub{
  width: 250px !important;

}
.elipsis{

   width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
}
.media-fit{
  object-fit: cover !important;
}
.img-pub{
  width: 120px !important;
  height: 100px !important;
}
</style>