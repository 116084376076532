<template lang="">
  <div class="row">
                <div class="col-xl-8 ">
                   <div class="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                   AmisList
                  </div>
                    
             </div>
              <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0">
                 
                  
                  <SuggestedPages />
              </div>
              
        
      </div>

</template>
<script>
import SuggestedAmis from "../Feeds/suggestedAmis.vue";
import SuggestedPages from "../Feeds/suggestedPages.vue";
export default {
  components:{
    SuggestedAmis,
    SuggestedPages

  },
    data(){
        return{
            NavBarSearchKey:'',
            results:[],
            friends:[],
            adresses:[]
        }

    },
    
    mounted(){
        this.NavBarSearchKey = this.$store.state.NavBarSearchKey;
        //this.getSearchResults()
        

    },
    beforeUpdate(){
      this.NavBarSearchKey = this.$store.state.NavBarSearchKey;
       //this.getSearchResults()
    },
    watch:{
       NavBarSearchKey(newNavBarSearchKey, oldNavBarSearchKey) {
      if (newNavBarSearchKey != oldNavBarSearchKey ) {
       // this.getSearchResults();
        
      }
    }

    },
    methods:{
      getSearchResults(){
        this.axios.post('searchNavBar',{
          searchFilter:this.NavBarSearchKey
        }).then(res=>{
          this.results = res.data;
          this.friends = res.data.visiteurs;
          this.adresses = res.data.adresses;

        })
      },
    },
     computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     currentUser() {
            return this.$store.state.currentUser;
        },

         searchResults() {
            return this.$store.state.searchResults;
        },
       
  },
};
</script>

<style lang="scss" scoped>
.btn-round-lg{
  border-radius: 10px !important;
}
.container-cover{
  transition: transform .4s ease-in-out;
  &:hover{
    cursor:pointer;
    opacity:0.7;
  }
  &:hover .update-cover{
    visibility: visible;
    transform: scale(2);

  }
}
.container-logo{
  transition: transform .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
  }
  &:hover .update-logo{
    visibility: visible;
    transform: scale(2);

  }
}
.update-logo{
  color:rgba(0, 0, 0, 0.3);
  transition: transform .4s ease-in-out;
  position:absolute !important;
  font-size:15px;
  visibility: hidden;
  top:30%;
  left:45%;

}
.update-cover{
  transition: transform .4s ease-in-out;
  position:absolute !important;
  visibility: hidden;
  color:rgba(0, 0, 0, 0.3);
  bottom:33%;
  left:48%;
  font-size:60px;
}
.addPubBtn span{
   user-select: none;
   transition: all .4s ease-in-out;
  &:hover{
    cursor:pointer;
   
    text-decoration: underline !important;
    
   
  }

}

.router-link-active.router-link-exact-active {
  
  &:hover{
       border-bottom: 2px#666 solid !important;
    color: #000 !important;

  }
    
    border-bottom: 2px#333 solid !important;
    color: #000 !important;
   
}
.nav-tabs li a {
    &:hover{
        border-bottom: 2px#aaa solid !important;
    color: #000 !important;
    }
    transition: all 0.1s ease-in-out !important;
 }
.bg-mini-gradiant{
  color:white !important
}
.btnAddFav{
  color:white !important
}
.btnSkeleton{
  width:80px
}
.btn-follow{
   background-color: #2e58a6 !important;
}
.btn-follow , .btn-unfollow {
  &:hover{
      cursor:pointer;
     box-shadow: 2px 2px 2px #8888884d ;
     background-color: #2e58a6 !important;
     color:#fff !important

  }
  
  transition: all .4s ease-in-out;
}
.btn-unfav{
  color:red;
  transition: color .4s ease-in-out;
  &:hover{
    color:rgba(255,0,0,0.5);
    cursor:pointer;
    color:#999 !important;

  }
}
.btn-add-fav{
  transition:all .4s ease-in-out;
  color:black !important;
  &:hover{
    color:red !important;
    cursor:pointer;
  }
}
.rounded-xl {
  border-radius:15px !important
}
.text-grey-500 {
    color: #adb5bd!important;
}
</style>