<template>
  <div class="aprox-container">
    <portal v-if="isTeleportOn" to="filter">
      <FilterAprox
        @proxChanged="proxChanged"
        @toggleAprox="toggleAprox"
        @filterAdresse="filterAdresse"
        @getPays="getPays"
        @changeRange="changeRange"
        :pays="pays"
        @getVilles="getVilles"
        :villes="villes"
        @updateLatLng="updateLatLng"
        @dragOverVille="dragOverVille"
        :isVillesSelectReady="isVillesSelectReady"
        :isPaysSelectReady="isPaysSelectReady"
        @getIntervalAprox="getIntervalAprox"
      ></FilterAprox>
    </portal>

    <!-- </portal> -->
  </div>

  <div class="row mtm">
    <div class="col-md-5 filter-resultat filter-resultat-hide" ref="MapResult">
      <div class="chat-left scroll-bar pt-2">
        <template v-if="isAdressesReady">
          <template v-if="adresses.length == 0 || filtredAdresse.length == 0">
            <div
              class="
                card
                w-100
                p-0
                hover-card
                shadow-xss
                border-0
                rounded-3
                overflow-hidden
                me-1
              "
            >
              <div class="card-body pt-4 pb-4 border-0 rounded-3 text-center">
                No result found

                <div class="clearfix"></div>
              </div>
              <div class="card-image w-100 mb-3 border-0">
                <a class="position-relative d-block w-100 border-0"> </a>
              </div>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(adresse, index) in filtredAdresse"
              :key="adresse.id"
              class="col-lg-12 col-md-12 col-sm-6 pe-2 ps-2 adr"
            >
              <div
                class="
                  card
                  w-100
                  p-0
                  hover-card
                  shadow-xss
                  rounded-3
                  overflow-hidden
                  me-1
                  col-lg-3
                  d-i-b
                "
              >
                <div class="mb-0 mt-1 d-i-b col-md-4 border-0 col-4">
                  <template
                    v-if="
                      !adresse.google_place_id &&
                      adresse.google_place_id == null
                    "
                  >
                    <router-link
                      :to="{
                        name: 'feedadresse',
                        params: { id: `${adresse.id}` },
                      }"
                      class="position-relative d-block"
                    >
                      <img
                        v-if="isAdressesReady && readyCheck"
                        :src="`${assetsBaseUrl}${adresse.logo}`"
                        alt="image"
                        class="w-img ps-2"
                      />
                    </router-link>
                  </template>
                  <template v-else>
                    <router-link
                      :to="{
                        name: 'feedadresse',
                        params: { id: `${adresse.id}` },
                      }"
                      class="position-relative d-block"
                    >
                      <img
                        v-if="
                          isAdressesReady &&
                          readyCheck &&
                          adresse.google_place_id != null
                        "
                        :src="`${assetsBaseUrl}${adresse.logo}`"
                        alt="image"
                        class="w-img ps-1"
                      />
                      <img
                        v-else
                        :src="`${assetsBaseUrl}${adresse.logo}`"
                        alt="image"
                        class="w-img ps-1"
                      />
                    </router-link>
                  </template>
                </div>

                <div
                  class="card-body pt-0 d-i-b col-md-8 col-8 container-adrs-inf"
                >
                  <h4 class="fw-700 font-xss mt-0 lh-28 mb-0 elipsis">
                    <router-link
                      :to="{
                        name: 'feedadresse',
                        params: { id: `${adresse.id}` },
                      }"
                      class="text-dark text-grey-900"
                      >{{ adresse.rs }}</router-link
                    >
                  </h4>
                  <h6 class="font-xssss text-grey-500 fw-600 mt-0 mb-0 elipsis">
                    {{ adresse.adresse }}
                  </h6>
                  <div class="star d-block w-100 text-left mt-0">
                    <router-link
                      :to="{
                        name: 'avisadresse',
                        params: { id: `${adresse.id}` },
                      }"
                    >
                      <div
                        class="rating-color d-i-b"
                        v-html="drawStarsYellow(adresse.rateRound)"
                      ></div>
                      <div
                        class="d-i-b rating-grey"
                        v-html="drawStarsGrey(adresse.rateRound)"
                      ></div>
                    </router-link>
                  </div>
                  <div class="clearfix"></div>
                  <div class="star w-100 d-block text-left mt-0 font-xssss">
                    <router-link
                      :to="{
                        name: 'avisadresse',
                        params: { id: `${adresse.id}` },
                      }"
                    >
                      <div
                        class="couts-color d-i-b"
                        v-html="drawDollarsGreen(adresse.coutsRound)"
                      ></div>
                      <div
                        class="d-i-b rating-grey"
                        v-html="drawDollarsGrey(adresse.coutsRound)"
                      ></div>
                    </router-link>
                  </div>
                  <div class="star w-100 d-block text-left mt-0 font-xssss">
                    <template v-for="serv in adresse.services">
                      <span v-html="serv.icone"></span>
                    </template>
                  </div>
                  <a
                    :ref="setToggleFavRef"
                    @click="toggleFavorite(adresse.id, index)"
                    class="
                      position-absolute
                      d-lg-block
                      btn-round-lg
                      ms-2
                      rounded-3
                      text-white-700
                      btn-toggle-fav
                    "
                    ><i
                      class="fas fa-heart font-md"
                      :class="
                        adresse.isFavorite ? 'btn-remove-fav' : 'btn-add-fav'
                      "
                    ></i
                  ></a>
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1
            "
          >
            <div class="card-body pt-4 pb-4 border-0 rounded-3">
              <Loader></Loader>

              <div class="clearfix"></div>
            </div>
            <!-- <div class="card-image w-100 mb-3 border-0">
              <a href="#"
              class="position-relative d-block w-100 border-0">
                 <Skeleton></Skeleton>
                 <Skeletonn></Skeletonn>
              </a>
            </div> -->
          </div>
        </template>
      </div>
    </div>
    <div class="col-xl-7 col-md-7 col-sm-12 ps-0 chat-left">
      <div ref="Gmap"
        class="
          card
          w-00
          border-0
          shadow-none
          rounded-3
          border-0
          mb-4
          overflow-hidden
          position-relative
          g-map-m
        "
      >
        <button
          class="show-filter"
          :class="isTeleportOn ? 'filter-on-text' : ''"
          @click="teleportFilter(isTeleportOn)"
        >
          <template v-if="!filterToggle">
            <span class="span-fa-filter"><i class="fas fa-filter"></i></span>
            <span class="text-show-filter">Show Filters</span>
          </template>
          <template v-else>
            <span class="span-fa-filter filter-on-icon"
              ><i class="fas fa-times"></i
            ></span>
            <span class="text-show-filter">Hide Filters</span>
          </template>
        </button>
        <GMapMap
          :options="options"
          :center="center"
          :zoom="zoom"
          ref="myMapRef"
          map-type-id="terrain"
          style="height: 90vh;"
        >
          <GMapCircle
            v-if="!isAprox"
            ref="cercleRef"
            :key="range"
            :radius="range * 1000"
            :center="{ lat: latCercle, lng: lngCercle }"
            :draggable="true"
            :options="circleOptions"
            @dragend="dragCircle($event)"
          />
          <GMapCircle
            v-if="isAprox"
            ref="cercleRefAprox"
            :key="range"
            :radius="range * 1000"
            :center="{ lat: lat, lng: long }"
            :options="circleOptionsAprox"
          ></GMapCircle>

          <GMapMarker
            v-if="isAprox"
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="false"
            :draggable="false"
            :icon="{
              url: 'https://ressources.trippybook.com/assets/images/you-are-here.png',
              scaledSize: { width: 40, height: 40 },
            }"
          />

          <GMapCluster
            :minimumClusterSize="2"
            :zoomOnClick="true"
            :styles="clusterIcon"
          >
            <GMapMarker
              :key="m.id"
              v-for="m in adressesMap"
              :position="m.position"
              :clickable="true"
              @click="openMarker(m.id)"
              :icon="{
                url: 'https://ressources.trippybook.com/assets/images/pin-map.png',
                scaledSize: { width: 30, height: 40 },
              }"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === m.id"
              >
                <div v-if="isAdressesReady">
                  <infoAdresse :adresseId="m.id"></infoAdresse>
                </div>
              </GMapInfoWindow>
            </GMapMarker>
          </GMapCluster>
        </GMapMap>
      </div>
    </div>
    <span class="btn-round-lg rounded-3 text-white-700  btn-round-sm-m toggle-map-result" @click="toggleMapResults">Show results</span>
  </div>
</template>
<script>
import Loader from "../../layouts/loader/loaderLogin.vue";
import Skeleton from "../../layouts/skeleton/SkeletonAdresse.vue";
import Skeletonn from "../../layouts/skeleton/SkeletonComment.vue";
import infoAdresse from "../../layouts/infoAdresse.vue";
import FilterAprox from "../../layouts/filterAdresse.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    Loader,
    Skeletonn,
    Skeleton,
    infoAdresse,

    FilterAprox,
  },
  emits: ["dragCircle", "drag", "dragend"],

  data() {
    return {
      isMapOn: true,
      toggleFavRef: [],
      filterOn: false,
      isPaysSelectReady: false,
      isVillesSelectReady: false,
      readyCheck: false,
      searchFilter: "",
      latCercleMoved: 0,
      lngCercleMoved: 0,
      isFilterOn: false,
      isTeleportOn: false,
      paysLat: 0,
      paysLng: 0,
      villeLat: 0,
      villeLng: 0,
      contLat: 0,
      contLng: 0,
      latMin: 0,
      latMax: 0,
      lngMin: 0,
      lngMax: 0,
      latCercle: 0,
      lngCercle: 0,

      evalsID: null,

      coutsID: null,
      valueCat: null,
      isAprox: true,
      range: 20,
      circleOptions: {
        strokeColor: "#124698 ",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#124698 ",
        fillOpacity: 0.1,
        zIndex: 99,
      },

      circleOptionsAprox: {
        strokeColor: "#f00 ",
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: "#f00 ",
        fillOpacity: 0.04,
      },
      clusterIcon: [
        {
          height: 50,
          width: 50,

          url: "https://ressources.trippybook.com/assets/images/cluster2.png",
          anchorIcon: [0, 0],
          anchorText: [8, -10],
          className: "cluster-custom",

          textColor: "#2e58a6",
          textSize: 15,
          textLineHeight: 15,
          fontWeight: "bold",
        },
      ],

      adresses: [],
      isAdressesReady: false,
      isCategorieReady: false,
      isContinentReady: false,
      isPaysReady: false,
      isVillesReady: false,
      continents: [],
      categories: [],
      pays: [],
      villes: [],
      catID: [],
      continentID: null,
      paysID: "",
      villeID: "",
      lat: 0,
      long: 0,
      zoom: 10,
      keyAdresse: false,
      centerCircle: { lat: 36, lng: 10 },

      //map-----------------------
      openedMarkerID: null,
      center: { lat: 36.173357, lng: 10.091831 },
      options: {
        zoomControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
      },
      markers: [
        {
          position: {
            lat: 36.173357,
            lng: 10.091831,
          },
        },
      ],

      testBounds: [
        {
          position: {
            lat: 34,
            lng: 10,
          },
        },
      ],
      adressesMap: [],
    };
  },
  // beforeUpdate(){
  //   this.toggleFavRef = [];

  // },
  computed: {
    filterToggle() {
      return this.$store.state.filterToggle;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    filtredAdresse() {
      this.toggleFavRef = [];

      return this.adresses
        .filter((adresse) => {
          if (this.evalsID != null) {
            return this.evalsID == adresse.rateRound;
          } else {
            return adresse;
          }
        })
        .filter((adresse) => {
          if (this.coutsID != null) {
            return this.coutsID == adresse.coutsRound;
          } else {
            return adresse;
          }
        });

      //}

      // else{
      //   return this.adresses.filter((adresse)=> {
      //     return adresse
      //   });
      // }
    },
  },
   destroyed() {
    const controller = new AbortController();
     controller.abort()
  },

  mounted() {
    const intervalPermission = setInterval(() => {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((permissionStatus) => {
          //console.log('geolocation permission state is ', permissionStatus.state);
          if (permissionStatus.state == "granted") {
            clearInterval(intervalPermission);
          }

          permissionStatus.onchange = () => {
            if (permissionStatus.state == "granted") {
              // this.$nextTick(() => {
              //   this.getPosition();
              //   this.filterAdresse();
              // });
              this.$router.go();
              clearInterval(intervalPermission);
            }
            //
          };
        });
    }, 500);

    this.getPosition();

    this.getCategories();
    //this.getContinents();

    this.$nextTick(() => {
      this.catID = this.$store.state.catID;
      this.villeID = this.$store.state.villeID;
      this.paysID = this.$store.state.paysID;
      this.evalsID = this.$store.state.evalsID;
      this.coutsID = this.$store.state.coutsID;
      //  this.searchFilter = this.$store.state.searchFilter;

      const interval = setInterval(() => {
        if (this.$refs.cercleRefAprox.$circleObject) {
          // //max
          // console.log(
          //   "max" +
          //     this.$refs.cercleRefAprox.$circleObject
          //       .getBounds()
          //       .getNorthEast()
          //       .lng()
          // );
          // //min
          // console.log(
          //   "min" +
          //     this.$refs.cercleRefAprox.$circleObject
          //       .getBounds()
          //       .getSouthWest()
          //       .lng()
          // );

          this.latMin = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getSouthWest()
            .lat();
          this.latMax = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getNorthEast()
            .lat();
          this.lngMin = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getSouthWest()
            .lng();
          this.lngMax = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getNorthEast()
            .lng();
          clearInterval(interval);
          this.filterOn = true;
        }
      }, 500);
    });
    const intervalFilter = setInterval(() => {
      if (this.filterOn) {
        this.filterAdresse();
        this.readyCheck = true;
        clearInterval(intervalFilter);
      }
    }, 10);
  },

  methods: {
    toggleMapResults(e) {
      
      
      
      if (this.isMapOn == true) {
        e.target.innerHTML = 'Back to map'
        this.$refs.Gmap.classList.add('g-map-hide-m');
        this.$refs.MapResult.classList.remove('filter-resultat-hide');
        console.log(document.getElementById('navigationRef'));
         document.getElementById('navigationRef').classList.remove('nav-active', 'active-menu');
      } else {

        e.target.innerHTML = 'Show results'
        this.$refs.MapResult.classList.add('filter-resultat-hide');
        this.$refs.Gmap.classList.remove('g-map-hide-m');
        
      }
      this.isMapOn =!this.isMapOn;

    },
    setToggleFavRef(el) {
      if (el && !this.toggleFavRef.includes(el)) {
        this.toggleFavRef.push(el);
      }
    },

    toggleFavorite(id, i) {
      //isFavorite = true
      if (
        this.toggleFavRef[i].firstElementChild.classList.contains(
          "btn-remove-fav"
        )
      ) {
        this.toggleFavRef[i].firstElementChild.classList.remove(
          "btn-remove-fav"
        );
        this.toggleFavRef[i].firstElementChild.classList.add("btn-add-fav");

        this.removeFavorite(id);
      } else {
        this.toggleFavRef[i].firstElementChild.classList.remove("btn-add-fav");
        this.toggleFavRef[i].firstElementChild.classList.add("btn-remove-fav");

        this.addFavorite(id);
      }
    },

    removeFavorite(id) {
      this.axios
        .post(`favoris/delete`, {
          visiteurId: this.currentUser.id,
          adresseId: id,
        })
        .then((res) => {});
    },
    addFavorite(id) {
      this.axios
        .post(`/favoris/add`, {
          visiteurId: this.currentUser.id,
          adresseId: id,
        })
        .then((res) => {});
    },
    drawStarsYellow(count) {
      return `<i class="fa fa-star rating-color"></i>`.repeat(count);
    },
    drawStarsGrey(count) {
      return `<i class="fa fa-star"></i>`.repeat(5 - count);
    },
    drawDollarsGreen(count) {
      return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count);
    },
    drawDollarsGrey(count) {
      return `<i class="fas fa-dollar-sign"></i>`.repeat(5 - count);
    },

    teleportFilter(val) {
      this.isAdressesReady = true;
      this.isTeleportOn = this.filterToggle;
      this.isTeleportOn = !this.isTeleportOn;
      // nav-active************************************************************
      this.$store.dispatch("filterToggle", this.isTeleportOn);

      
    },
    toggleAprox() {
      this.isAprox = !this.isAprox;

      this.$nextTick(() => {
        this.getPosition();
        this.getIntervalAprox();

        // setTimeout(()=>{this.filterAdresse(),500});
      });
    },

    proxChanged() {
      if (this.isAprox) {
        this.$nextTick(() => {
          this.getPosition();

          this.getIntervalAprox();
        });
      } else {
        this.getIntervalAprox();
      }

      setTimeout(() => {
        this.filterAdresse(), 500;
      });
    },
    radiusChanged(e) {
      //console.log(e)
    },
    getIntervalAprox() {
      const interval = setInterval(() => {
        if (this.$refs.cercleRefAprox) {
          // this.latMin = this.$refs.cercleRefAprox.$circleObject.getBounds().getSouthWest().lat();
          this.latMin = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getSouthWest()
            .lat();
          this.latMax = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getNorthEast()
            .lat();
          this.lngMin = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getSouthWest()
            .lng();
          this.lngMax = this.$refs.cercleRefAprox.$circleObject
            .getBounds()
            .getNorthEast()
            .lng();

          clearInterval(interval);
        }
      }, 10);
    },

    openMarker(id) {
      this.openedMarkerID = id;
    },
    //filtter---------------------------------------------------------------
    filterAdresse() {
      this.toggleFavRef = [];
      this.coutsID = this.$store.state.coutsID;
      this.evalsID = this.$store.state.evalsID;
      this.catID = this.$store.state.catID;
      this.villeID = this.$store.state.villeID;
      this.paysID = this.$store.state.paysID;
      this.searchFilter = this.$store.state.searchFilter;
      this.isAdressesReady = false;

      setTimeout(() => {
        this.axios
          .post(`mesAdressesFilter`, {
            categorieID: this.catID,
            latMin: this.latMin,
            latMax: this.latMax,
            lngMin: this.lngMin,
            lngMax: this.lngMax,
            villeID: this.villeID,
            evalsID: this.evalsID,
            coutsID: this.coutsID,
            continentID: this.continentID,
            paysID: this.paysID,
            searchFilter: this.searchFilter,
          })
          .then((res) => {
            this.$nextTick(() => {
              this.adresses = res.data.adresses;

              this.adressesMap = [];

              //console.log(res.data);
              if (this.adresses.length) {
                res.data.adresses.forEach((element, index) => {
                  this.adressesMap.push({
                    id: element.id,
                    position: { lat: element.lat, lng: element.long },
                  });
                });
              } else if (this.adresses.length == 1) {
                //console.log('lol');
                this.adressesMap.push({
                  id: this.adresses[0].id,
                  position: {
                    lat: this.adresses[0].lat,
                    lng: this.adresses[0].long,
                  },
                });
              }
            });

            this.isAdressesReady = true;
            this.keyAdresse = !this.keyAdresse;
          })
          .catch((error) => {}),
          1000;
      });
    },

    //map---------------------------------------------------------------
    getPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        setTimeout(() => {
          this.markers[0].position.lat = position.coords.latitude;
          this.markers[0].position.lng = position.coords.longitude;
          this.center.lat = position.coords.latitude;
          this.center.lng = position.coords.longitude;
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.latCercle = position.coords.latitude;
          this.lngCercle = position.coords.longitude;
          this.latCercleMoved = position.coords.latitude;
          this.lngCercleMoved = position.coords.longitude;
          console.log('getPosition');
          console.log(this.center.lat);
          console.log(this.center.lng)

            , 5000
        });
        
      });
      
      
    },
    openInfoWindow(id) {
      alert(IDBKeyRange);
    },
    updateLatLng() {
      this.latCercle = this.latCercleMoved;
      this.lngCercle = this.lngCercleMoved;
      console.log('hovred')
    },

    changeRange() {
      this.range = this.$store.state.range;
      if (!this.isAprox) {
        this.latMin = this.$refs.cercleRef.$circleObject
          .getBounds()
          .getSouthWest()
          .lat();
        this.latMax = this.$refs.cercleRef.$circleObject
          .getBounds()
          .getNorthEast()
          .lat();
        this.lngMin = this.$refs.cercleRef.$circleObject
          .getBounds()
          .getSouthWest()
          .lng();
        this.lngMax = this.$refs.cercleRef.$circleObject
          .getBounds()
          .getNorthEast()
          .lng();
      } else {
        this.latMin = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getSouthWest()
          .lat();
        this.latMax = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getNorthEast()
          .lat();
        this.lngMin = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getSouthWest()
          .lng();
        this.lngMax = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getNorthEast()
          .lng();
      }
      this.filterAdresse();
    },

    dragCircle(e) {
      if (!this.isAprox) {
        this.latCercleMoved = e.latLng.lat();
        this.lngCercleMoved = e.latLng.lng();

        this.$nextTick(() => {
          this.latMin = this.$refs.cercleRef.$circleObject
            .getBounds()
            .getSouthWest()
            .lat();
          this.latMax = this.$refs.cercleRef.$circleObject
            .getBounds()
            .getNorthEast()
            .lat();
          this.lngMin = this.$refs.cercleRef.$circleObject
            .getBounds()
            .getSouthWest()
            .lng();
          this.lngMax = this.$refs.cercleRef.$circleObject
            .getBounds()
            .getNorthEast()
            .lng();
        });
      } else {
        this.latMin = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getSouthWest()
          .lat();
        this.latMax = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getNorthEast()
          .lat();
        this.lngMin = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getSouthWest()
          .lng();
        this.lngMax = this.$refs.cercleRefAprox.$circleObject
          .getBounds()
          .getNorthEast()
          .lng();
      }
      this.filterAdresse();
    },

    // drag(e){
    //   //console.log('Lat : '+e.latLng.lat());
    //   //console.log('Lng : '+e.latLng.lng());
    //   this.center = {lat: e.latLng.lat(), lng: e.latLng.lng()}
    // },
    //fin map------------------------------------------------------------------
    filter() {
      var rangeSlider = document.getElementById("rs-range-line");
      var rangeBullet = document.getElementById("rs-bullet");

      rangeSlider.addEventListener("input", showSliderValue, false);

      function showSliderValue() {
        rangeBullet.innerHTML = rangeSlider.value + " Km";
        var bulletPosition = rangeSlider.value / rangeSlider.max;
        rangeBullet.style.left = bulletPosition * 350 + "px";
      }
    },
    toggleFilter() {
      if (!this.$refs.filter.classList.contains("filter-open")) {
        this.$refs.filter.classList.add("filter-open");
        this.$refs.filter.classList.remove("filter-close");
      } else {
        this.$refs.filter.classList.add("filter-close");
        this.$refs.filter.classList.remove("filter-open");
      }

      this.$refs.btnToggleFilter.classList.toggle("active-filter");
    },
    getCategories() {
      this.axios.get("/categories").then((res) => {
        ////console.log(res.data.categories);
        this.categories = res.data.categories;
        this.isCategorieReady = true;
        this.categories = [];

        res.data.categories.forEach((element, index) => {
          this.categories.push({
            value: element.id,
            label: element.nom_categorie,
          });
        });
        ////console.log(this.categories);
      });
    },
    getContinents() {
      this.axios.get("/continents").then((res) => {
        this.isContinentReady = true;
        this.continents = [];
        res.data.continents.forEach((element, index) => {
          this.continents.push({
            value: element.id,
            label: element.nom_continent,
          });
        });
      });
    },
    getAdresses() {
      this.axios.get(`mesAdresses`).then((res) => {
        this.adresses = res.data.adresses;
        this.isAdressesReady = true;
      });
    },
    getPays() {
      //this.continentID = this.$store.state.continentID;
      //this.clearAll();
      //this.isAprox = false;

      // this.axios
      //   .get(`continentby/${this.continentID}`)
      //   .then((res) => {
      //     // //console.log(res);
      //     this.contLat = res.data.continent.latitude;
      //     this.contLng = res.data.continent.longitude;

      //     this.zoom = 4;
      //     this.center = { lat: this.contLat, lng: this.contLng };
      //     this.latCercle = this.contLat;
      //     this.lngCercle = this.contLng;
      //   })
      //   .catch((error) => {});

      this.axios.get(`pays`).then((res) => {
        //this.pays = res.data.pays ;
        this.pays = [];
        // //console.log(res.data.pays)
        res.data.pays.forEach((element, index) => {
          this.pays.push({ value: element.id, label: element.nom_pays });
        });
        this.$store.dispatch("pays", this.pays);
        this.isPaysReady = true;
        this.isPaysSelectReady = true;
        this.villes = [];
        //this.isPaysSelectReady = true;
      });
    },

    getVilles() {
      this.paysID = this.$store.state.paysID;

      this.axios.get(`villes/${this.paysID}`).then((res) => {
        this.villes = [];
        if (res.data.villes) {
          res.data.villes.forEach((element, index) => {
            this.villes.push({ value: element.id, label: element.nom_ville });
          });
        }
        this.isVillesReady = true;
        this.isVillesSelectReady = true;
      });
      this.axios
        .get(`paysby/${this.paysID}`)
        .then((res) => {
          this.paysLat = res.data.pays.latitude;
          this.paysLng = res.data.pays.longitude;

          this.zoom = 6;
          this.center = { lat: this.paysLat, lng: this.paysLng };
          this.latCercle = this.paysLat;
          this.lngCercle = this.paysLng;
        })
        .catch((error) => {});
      //this.isVillesSelectReady = true;
    },

    dragOverVille() {
      this.villeID = this.$store.state.villeID;

      this.axios
        .get(`villeby/${this.villeID}`)
        .then((res) => {
          this.villeLat = res.data.ville.latitude;
          this.villeLng = res.data.ville.longitude;

          this.zoom = 8;
          this.center = { lat: this.villeLat, lng: this.villeLng };
          this.latCercle = this.villeLat;
          this.lngCercle = this.villeLng;
        })
        .catch((error) => {});
      this.getIntervalAprox();
      this.filterAdresse();
    },
  },
};
</script>
<style lang="scss" scoped>
.vue-map{
  width: 45vw !important
}
.filter-container {
  position: relative !important;
}

.filter {
  height: 265px;
  line-height: 50px;
  width: 100%;
  text-decoration: none;
  position: absolute;
  top: 70px;
  z-index: -1;
  transition: top background-color 0.4s ease-in-out !important;
  margin-bottom: 5px;
  //background-color: rgba(0, 0, 0, 0.9) !important;
  background-color: #ced4dae7 !important;
  margin-left: -25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.filter-open {
  z-index: 1;
  top: 93px;
  opacity: 1;

  transition: top 0.4s ease-in-out !important;
}
.filter-close {
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
}
.active-filter {
  color: #124698 !important;
  background-color: #d2e3ff !important;
  border-color: #2e58a6 !important;
}
.btn-round-md {
  &:hover {
    background-color: #d2e3ff !important;
    transition: all 0.4s ease-in-out;
  }
}
.d-i-b {
  display: inline-block !important;
}
.card-image {
  overflow: visible !important;
  border: 1px solid black;
}
.chat-left {
  height: calc(100vh - 125px) !important;
}
//395
select.sort {
  border-radius: 7px !important;
  color: #000 !important;
  width: fit-content !important;
}
.form-control {
  line-height: 38px !important;
  height: 38px !important;
}
.box-minmax {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #000;
  span:first-child {
    margin-left: 10px;
  }
}
.range-slider {
}
.rs-range {
  width: 100%;
  height: 3px;
  color: #000;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }
  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 15px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}
.rs-label {
  top: 50px;
  transform-origin: center center;
  display: block;
  width: 90px;
  height: 35px;
  background: transparent;
  border-radius: 50%;
  line-height: 20px;
  // text-align: center;
  font-weight: bold;
  //padding-top: 22px;
  box-sizing: border-box;
  //border: 2px solid #ddd;
  //margin-top: -20px;
  margin-left: 0px;
  left: attr(value);
  color: #000;
  font-style: normal;
  font-weight: 20px;
  line-height: normal;
  font-size: 12px;
}
.form-group,
.form-control {
  opacity: 1 !important;
  color: #000;
}
.form-group {
  margin-left: 3px;
}

.toggle input {
  background-color: #d2e3ff;
}
.toggle input[type="checkbox"]:checked + .toggle-icon {
  background: #2e58a6;
  margin-left: 3px;
}
.icon-tab {
  display: inline-block;
}
.vue-map-container {
  height: 540px !important;
}
.w-img {
  width: 100% !important;
  height: 90px !important;
  vertical-align: bottom !important;
  border-radius: 10px;
  //text-align:center;
  //left:20%;
}
.scroll-bar {
  // margin-left: -55px;
  overflow-x: hidden;
}
.filter-control {
  visibility: hidden !important;
  position: absolute !important;
}
.you-are-here {
  border: 1px solid black;
  height: 100px;
  width: 100px;
}

.text-show-filter {
  padding: 5px;
}
.show-filter {
  background-color: #2e58a6;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
  transition: background-color 0.4s ease-in-out;
  &:hover .span-fa-filter {
    background-color: #000;
  }
  &:hover {
    background-color: #555;
  }
  .filter-on-icon {
    background-color: #000;
  }
  .filter-on-text {
    background-color: #555 !important;
  }
}
.span-fa-filter {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 8px;
  transition: background-color 0.4s ease-in-out;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.fa-times {
  padding: 3px;
}
.cluster-custom {
  width: 30px !important;
  height: 30px !important;
  background-color: red !important;
}
.gm-style-iw-c {
  padding: 0 !important;
}
.container-adrs-inf {
  padding: 0rem 1rem 0.5rem !important;
}
.rating-color {
  color: #fbc634 !important;
}
.rating-grey {
  color: #d2e3ff !important;
}
.couts-color {
  color: rgb(16, 92, 16);
}
.btn-remove-fav {
  &:hover {
    cursor: pointer;

    //color:#999 !important
  }

  transition: all 0.4s ease-in-out;
  z-index: 5;
  color: #f00;
}

.btn-add-fav {
  &:hover {
    cursor: pointer;
    // color:#f00  !important;
  }

  transition: all 0.4s ease-in-out;
  z-index: 5;

  color: #999 !important;
}
.btn-toggle-fav {
  right: -10px !important;
  top: -10px !important;
}
.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.toggle-map-result{
  display: none;
}
@media (max-width: 768px) {

  .vue-map{
  width: 100vw !important
}
  .vue-map-container{
  height: 635px !important;
}
  .main-content {
    height: 100% !important;
    
  }
  .filter-resultat-hide{
   visibility: hidden;
   height:0px;
   position:absolute !important;
  //  top: 0
  }
  
  .toggle-map-result{
    display: block;
    z-index: 9999;
    position: fixed;
    bottom: 1px;
    background-color: #2e58a6;
    color: #fff;
    border-radius: 0rem !important;

  }
  .scroll-bar {
    margin-left: -15px;
  }
  .filter-resultat {
    order: 1;
    

  }
  .g-map-hide-m{
    visibility: hidden !important;
    position: absolute !important;
  }
  .g-map-m {
    height: 100%;
    
  }
  .chat-left {
    height: 100% !important;
    
  }
 
  .row > * {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}


</style>
