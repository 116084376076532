import { createWebHistory, createRouter } from 'vue-router'
import Home from '../component/pages/Home.vue';
import NotFound from '../component/layouts/NotFound.vue';
import Register from '../component/login/register.vue';
import Login from '../component/login/login.vue';
import FeedsContainer from '../component/pages/Feeds/FeedsContainer.vue';
import PhotosContainer from '../component/pages/Photos/PhotosContainer.vue';
import VideosContainer from '../component/pages/Videos/VideosContainer.vue';
import ProfilContainer from '../component/pages/profil/ProfilContainer.vue';
import AdressesContainer from '../component/pages/Adresses/AdressesContainer.vue';
import ParametresContainer from '../component/pages/Parametres/ParametresContainer.vue';
import Statistiques from '../component/pages/Statistiques/Statistiques.vue';
import AdressesAproximite from '../component/pages/Adresses/AdressesAproximite.vue';
import InfoCompte from '../component/pages/Parametres/InfoCompte.vue';
import MotDePasse from '../component/pages/Parametres/MotDePasse.vue';
import Notifications from '../component/layouts/notifications/notificationList.vue';
import VisiteurAdresses from '../component/pages/profil/visiteurAdresses.vue';
import FeedAdresse from '../component/pages/profil/Adresses/FeedAdresse.vue';
import AvisAdresse from '../component/pages/profil/Adresses/AvisAdresse.vue';
import AproposAdresse from '../component/pages/profil/Adresses/AproposAdresse.vue';
import VideosAdresse from '../component/pages/profil/Adresses/VideosAdresse.vue';
import PhotosAdresse from '../component/pages/profil/Adresses/PhotosAdresse.vue';

import feedvisiteur from '../component/pages/profil/Visiteur/feedvisiteur.vue';
import aproposvisiteur from '../component/pages/profil/Visiteur/aproposvisiteur.vue';
import videosvisiteur from '../component/pages/profil/Visiteur/videosvisiteur.vue';
import photosvisiteur from '../component/pages/profil/Visiteur/photosvisiteur.vue';
import MesAdressesActives from '../component/pages/profil/Adresses/MesAdressesActives.vue';
import MesDemandesAdresses from '../component/pages/profil/Adresses/MesDemandesAdresses.vue';
import followingvisiteur from '../component/pages/profil/Visiteur/followingvisiteur.vue';
import favoritesvisiteur from '../component/pages/profil/Visiteur/favoritesvisiteur.vue';

import searchResultContainer from "../component/pages/searchResults/searchResultContainer.vue";
import searchResultAll from "../component/pages/searchResults/searchResultAll.vue";
import searchResultPeople from "../component/pages/searchResults/searchResultPeople.vue";
import searchResultAdresses from "../component/pages/searchResults/searchResultAdresses.vue";
import searchResultpub from "../component/pages/searchResults/searchResultPub.vue";


import suggestedAmisList from "../component/pages/Feeds/suggestedAmisList.vue";
import SuggestedPagesList from "../component/pages/Feeds/SuggestedPagesList.vue";

import ModalJaimeList from "../component/layouts/modal/modalJaimeList.vue";
import jaimeListAll from "../component/pages/Jaimes/allReactions.vue";
import jaimeListLike from "../component/pages/Jaimes/like.vue";
import jaimeListLove from "../component/pages/Jaimes/love.vue";
import jaimeListDisike from "../component/pages/Jaimes/dislike.vue";
import PubShow from "../component/layouts/PubShow.vue";
//amis--------------------------------------------------------------------------------
import friendsContainer from "../component/pages/profil/Visiteur/friends/friendsContainer.vue";
import amisvisiteur from '../component/pages/profil/Visiteur/friends/amisvisiteur.vue';
import receivedRequests from '../component/pages/profil/Visiteur/friends/receivedRequests.vue';
import sentRequests from '../component/pages/profil/Visiteur/friends/sentRequests.vue';
import suggestedFriends from '../component/pages/profil/Visiteur/friends/suggestedFriends.vue';


export const routes = [

    {
        name: 'home', path: '/acceuil', component: Home,
        children: [
            { name: 'showPost', path: '/showPost/:id/:mediaID', component: PubShow },
            { name: 'feeds', path: '/feeds', component: FeedsContainer },
            { name: 'suggestedAmisList', path: '/suggestedFriends', component: suggestedAmisList },
            { name: 'SuggestedPagesList', path: '/suggestedAdresses', component: SuggestedPagesList },
            {
                name: 'searchResult', path: '/searchResult', component: searchResultContainer,
                children: [
                    { name: 'searchResultAll', path: '/searchResultAll', component: searchResultAll },
                    { name: 'searchResultPeople', path: '/searchResultPeople', component: searchResultPeople },
                    { name: 'searchResultAdresses', path: '/searchResultAdresses', component: searchResultAdresses },

                    { name: 'searchResultpub', path: '/searchResultpub', component: searchResultpub },



                ]
            },
            {
                name: 'jaimeList', path: '/jaimeList', component: ModalJaimeList,
                children: [
                    { name: 'jaimeListAll', path: '/jaimeListAll', component: jaimeListAll },
                    { name: 'jaimeListLike', path: '/jaimeListLike', component: jaimeListLike },
                    { name: 'jaimeListLove', path: '/jaimeListLove', component: jaimeListLove },
                    { name: 'jaimeListDisike', path: '/jaimeListDisike', component: jaimeListDisike },
                ]
            },
            { name: 'photos', path: '/photos/:id', component: PhotosContainer },
            { name: 'videos', path: '/videos/:id', component: VideosContainer },
            {
                name: 'profil', path: '/profil/:id', component: ProfilContainer,
                children: [
                    { name: 'feedvisiteur', path: '/feedvisiteur/:id', component: feedvisiteur },
                    { name: 'aproposvisiteur', path: '/aproposvisiteur/:id', component: aproposvisiteur },
                    { name: 'videosvisiteur', path: '/videosvisiteur/:id', component: videosvisiteur },
                    { name: 'photosvisiteur', path: '/photosvisiteur/:id', component: photosvisiteur },
                    { name: 'followingvisiteur', path: '/following/:id', component: followingvisiteur },
                    { name: 'favoritesvisiteur', path: '/favorites/:id', component: favoritesvisiteur },
                    {
                        name: 'amisvisiteur', path: '/friends/:id', component: friendsContainer,
                        children: [
                            { name: 'myamisvisiteur', path: '/myfriends/:id', component: amisvisiteur },
                            { name: 'receivedRequests', path: '/receivedRequests/:id', component: receivedRequests },
                            { name: 'sentRequests', path: '/sentRequests/:id', component: sentRequests },
                            { name: 'suggestedFriends', path: '/suggestedFriends/:id', component: suggestedFriends },







                        ]
                    },




                ]
            },
            {
                name: 'adresses', path: '/adresses', component: AdressesContainer,
                children: [
                    { name: 'mesadresses', path: '/mesadresses', component: MesAdressesActives },
                    { name: 'mesdemandes', path: '/mesdemandes', component: MesDemandesAdresses },


                ]
            },
            { name: 'parametres', path: '/parametres', component: ParametresContainer },
            { name: 'statistiques', path: '/statistiques', component: Statistiques },
            { name: 'adressesaproximite', path: '/adressesaproximite', component: AdressesAproximite },
            { name: 'infocompte', path: '/infocompte', component: InfoCompte },
            { name: 'motdepasse', path: '/motdepasse', component: MotDePasse },
            { name: 'notifications', path: '/notifications', component: Notifications },
            {
                name: 'visiteuradresses', path: '/visiteur/adresses/:id', component: VisiteurAdresses,
                children: [
                    { name: 'feedadresse', path: '/feedadresse/:id', component: FeedAdresse },
                    { name: 'avisadresse', path: '/avisadresse/:id', component: AvisAdresse },
                    { name: 'aproposadresse', path: '/aproposadresse/:id', component: AproposAdresse },
                    { name: 'videosadresse', path: '/videosadresse/:id', component: VideosAdresse },
                    { name: 'photosadresse', path: '/photosadresse/:id', component: PhotosAdresse },

                ]
            }

        ]

    },
    { name: 'register', path: '/inscription', component: Register },
    { name: 'login', path: '/', component: Login },
    { path: '/:catchAll(.*)', name: 'notFound', component: NotFound }

]
const router = createRouter({
    history: createWebHistory(),
    //base: 'http://localhost:3000/',
    base: 'https://www.trippybook.com/',
    routes: routes
})
router.beforeEach((to, from) => {
    const controller = new AbortController();
    controller.abort()
})
export default router
