<template lang="">
  <div class="row feed-body">
    <div class="col-xl-8 col-xxl-8 col-lg-8">
      <!-- <CreateFeed/> -->
      <Stories @removeListnerKEyUp="removeListnerKEyUp" @addListnerKEyUp="addListnerKEyUp"/>

  

      <FeedList ref="feedListRef"/>
    </div>
    <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0 hide-m">
      <SuggestedAmis />
      
      <SuggestedPages />
    </div>
  </div>
</template>
<script type="application/javascript" defer>
import FeedList from "./FeedList.vue";
import Stories from "./Stories.vue";
import CreateFeed from "./CreateFeed.vue";
import SuggestedAmis from "./suggestedAmis.vue";
import Amis from "./Amis.vue";
import SuggestedPages from "./suggestedPages.vue";
export default {
  data() {
    return {};
  },
  components: {
    FeedList,
    Stories,
    CreateFeed,
    SuggestedAmis,
    Amis,
    SuggestedPages,
  },
  methods: {
    removeListnerKEyUp() {
      this.$refs.feedListRef.removeListnerKEyUp()

    },

    addListnerKEyUp() {
      this.$refs.feedListRef.addListnerKEyUp()

    }

  },

  mounted() {
    // let scripts = [
    //   "https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.js",
    //   "Frontend/js/lightbox.js",
    //   "Frontend/js/publication.js",
    //   "Frontend/js/publication.js",
    //   "Frontend/js/signal.js",
    //   "Frontend/js/jaime.js",
    //   "Frontend/js/favoris.js",
    //   "Frontend/js/avis.js",
    //   "Frontend/js/amis.js",
    //   "Frontend/js/commentaire.js",
    //   "Frontend/js/partager.js",
    //   "Frontend/js/apexcharts.min.js",
    //   "Frontend/js/video-player.js",
    //   "Frontend/js/jquery.easypiechart.min.js",
    //   "Frontend/js/theme.js",
    //   "https://cdn.tiny.cloud/1/no-api-key/tinymce/5/tinymce.min.js",
    //   "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.8.0/js/bootstrap-datepicker.min.js",
    //   "https://maps.google.com/maps/api/js?sensor=false&libraries=geometry&v=3.22&key=AIzaSyBt0FzE7tTDwZuwkEpiMVXmxs9luDygliI",
    // ];
    // scripts.forEach((script) => {
    //   let el = document.querySelector('script[src="' + script + '"]');
    //   if (!el) {
    //     let tag = document.createElement("script");
    //     tag.setAttribute("src", script);
    //     tag.defer = true;
    //     document.head.appendChild(tag);
    //   }
    // });
  },
};
</script>

<style lang="scss" scoped>
h4,
.h4 {
  font-size: 1rem !important;
}

@import "../../../assets/css/emoji.css";
@import "../../../assets/css/video-player.css";
@import "../../../assets/css/bootstrap-datetimepicker.css";
@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/bootstrap-datepicker/1.8.0/css/bootstrap-datepicker.min.css";
@import "../../../assets/css/lightbox.css";
@import "https://cdnjs.cloudflare.com/ajax/libs/toastr.js/latest/toastr.min.css";

@media (max-width: 767px) {
  .row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .hide-m {
    display: none !important;
  }
}
</style>
