<template lang="">
 <ModalMutualFriend v-if="isShowModalMutualFriend" @close="toggleModalCloseMutualFriend" :friendId="friendId"></ModalMutualFriend>
        <div class="col-xl-12 col-xxl-8 col-lg-12 pe-0">

          <div class="search-form-2 ms-auto pe-2 ps-2 col-md-4 pb-1">
                                 <i class="ti-search font-xss"></i>
                                 <input type="text" class="form-control text-grey-500 mb-0 bg-greylight theme-dark-bg border-0" v-model.trim="searchKey" placeholder="Search..">
          </div>
          <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
           <div class="chat-left scroll-bar pt-2 w-100">
             <div class="row">
                                
                               
                                

        <template v-if="isFriendsReady">
         <div v-for="friend in filtredResults" class="card-body d-flex pt-2 ps-4 pe-4 pb-0 border-top-xs bor-0 col-xl-6 col-xxl-4 col-lg-6">
           <template v-if="friend.id != currentUser.id">
         <router-link :to="{name :'feedvisiteur', params: { id : friend.id } }" >
               
            <figure class="avatar me-3">
              <img :src="`${assetsBaseUrl}${friend.photo}`" alt="image" class="shadow-sm rounded-circle w45">
            </figure>
          </router-link>
              <h4 class="fw-700 text-grey-900 font-xssss mt-1 link-user">
          <router-link  :to="{name :'feedvisiteur', params: { id : friend.id } }" >{{friend.prenom}} {{friend.nom}} 
          </router-link>
          <span  v-if="friend.mutualFriend > 0" @click="toggleModalMutualFriend(friend.id)" class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500 btn-add-friend">{{friend.mutualFriend}} mutual friends</span>
          <span  v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">no mutual friends</span>
          </h4>
          </template>
          <template v-else>

            <router-link :to="{name :'feedvisiteur', params: { id : friend.id } }" >
               
            <figure class="avatar me-3">
              <img :src="`${assetsBaseUrl}${friend.photo}`" alt="image" class="shadow-sm rounded-circle w45">
            </figure>
          </router-link>
              <h4 class="fw-700 text-grey-900 font-xssss mt-1 link-user">
          <router-link  :to="{name :'feedvisiteur', params: { id : friend.id } }" >{{friend.nom}}
          </router-link>
          <span  v-if="friend.mutualFriend > 0" @click="toggleModalMutualFriend(friend.id)" class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500 btn-add-friend">{{friend.mutualFriend}} mutual friends</span>
          <span  v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">no mutual friends</span>
          </h4>

          </template>
          
          </div>
                 <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-2
            mb-1
            mt-1
            ms-3
        "
    >
        
           No other friends
    </div>
    <div>
      <Loader v-show="isVisible && !done"/> 
      </div>
        <div v-if="friends.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div> 
          
          
          </template>

         
          <template v-else>
            <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 col-xl-12 col-xxl-8 col-lg-12">
                <Loader></Loader>
            </div>
            

          </template>
 </div> </div>

          </div>
        </div>
</template>
<script>
import Loader from "../../../../layouts/loader/loader.vue";
import ModalMutualFriend from "../../../../layouts/modal/modalMutualFriend.vue";
export default {
   components:{
    Loader,
    ModalMutualFriend
    
  },
   

  data(){
    return {
      friends:[],
      isFriendsReady:false,
      searchKey:'',
      page:1,
      lastPage:1,
      done:false,
      addFriendRef:[],
      isVisible: false,
      totalResults:0,
      btnShowMutualFriendRef:[],
      tooltipMutualFriendRef:[],
      isShowModalMutualFriend:false,
      friendId:null,
      totalResults:0,
    }
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
   mounted() {
     this.getFriends();
   
  },
   
  
  methods: {
      toggleModalCloseMutualFriend(id){

      this.isShowModalMutualFriend = false;  

    },

    toggleModalMutualFriend(id){

      this.isShowModalMutualFriend = !this.isShowModalMutualFriend;
      this.friendId = id;

    },

     visibilityChanged (isVisible) {
    
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
          this.isVisible = isVisible;
    
            this.page++;
            this.getFriends();
            

  
         
      },

    getFriends(){
  
        //******************************************* */
      //this.isFriendsReady=false;
      this.axios.get(`/amisSuggested?page=${this.page}`)
      .then(res=>{
         this.friends.push(...res.data.amis.data);
        this.isFriendsReady=true;
        this.lastPage = res.data.amis.last_page;
        this.totalResults = res.data.amis.total;
        console.log(res)
    })
    },
    

   
  },
  computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
            return this.$store.state.assetsBaseUrl;
    },
        filtredResults(){
        return this.friends.filter(friend => ( (
         friend.nom.toLowerCase().includes(this.searchKey.toLowerCase()) ) ||
         (
         friend.prenom.toLowerCase().includes(this.searchKey.toLowerCase()) )
         ));
        }
  }
}
</script>
<style lang="scss" scoped>
.card{
  flex-direction: row !important
}
.rounded-circle{
 border-radius:10px !important
}

  .btn-add-friend{
   &:hover{
     cursor:pointer;
      text-decoration: underline !important
   }
 }
 .scroll-bar{
   overflow-x: hidden;
 }
 .chat-left {
    height: calc(100vh - 300px) !important;
}
    
</style>