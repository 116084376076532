<template lang="">
  <div class="row">
                <div class="col-xl-8 ">
                  <div class="card w-100 shadow-xss rounded-xxl border-0 p-4 mb-3">
                   
                   
        <template v-if="isAdressesReady">
        <div class="chat-left scroll-bar pt-2">
         <div class="row">  
      <div v-for="adresse in adresses" class="card-body d-flex pt-2 ps-2 pe-2 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-2 col-md-6">
          
        
        <figure class="avatar me-2 mb-0">
          <router-link :to="{name :'feedadresse', params: { id : adresse.id } }" >
         <img  :src="`${assetsBaseUrl}${adresse.logo}`" alt="image" class="shadow-sm rounded-circle w60"> 
         </router-link>
        </figure>
        
        
              <h4  class="fw-700 text-grey-900 font-xssss mt-1 link-user elipsis">
              <router-link v-tooltip.top-start="'Visit profil'" :to="{name :'feedadresse', params: { id : adresse.id } }" >{{adresse.rs}}
                </router-link>
              
              <div class="star d-block w-100 text-left mt-0">
                <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
                <div class="rating-color d-i-b" v-html="drawStarsYellow(adresse.rateRound)"></div>
                <div class="d-i-b rating-grey" v-html="drawStarsGrey(adresse.rateRound)"></div>
                </router-link>
              
              </div>
               <div class="clearfix"></div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
              <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
              <div class="couts-color d-i-b" v-html="drawDollarsGreen(adresse.coutsRound)"></div>
              <div class="d-i-b rating-grey" v-html="drawDollarsGrey(adresse.coutsRound)"></div>
              </router-link>
              </div>

              </h4>
            
         
         
        
        
       </div> </div>
       
             <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-2
            mb-1
            mt-1
        "
    >
        
No other suggestions
    </div>
      <Loader v-show="isVisible && !done"/> 
          <div v-if="adresses.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>  
              
      </div>
      <router-link  to="/suggestedAdresses" class="fw-600 ms-auto font-14 text-primary pe-3 pb-1 show-all" v-if="adresses.length > 0">See all</router-link>
    </template>
    <template v-else>
      <div class="pb-4 pt-4 border-top-xs">
      <Loader></Loader>
      </div>

    </template>







                  
            </div>
             </div>
              <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0">
                 
                  
                  <SuggestedAmis />
              </div>
              
        
      </div>

</template>
<script>
import SuggestedAmis from "../Feeds/suggestedAmis.vue";
import Loader from "../../layouts/loader/loader.vue"

export default {
  components:{
    SuggestedAmis,
    Loader
    

  },
    data(){
        return{
            adresses:[],
            page:1,
            lastPage:1,
            done:false,
            isVisible: false,
            totalResults:0,
            isAdressesReady:false,
        }

    },
    
    mounted(){
      this.getAdresses();    
    },
    beforeUpdate(){
      
    },
    methods:{
      visibilityChanged (isVisible) {
         //this.addFriendRef=[];
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
          this.isVisible                                                                                                                                                                                                                                                                                                                                                                 = isVisible;   
          this.page++;
          this.getAdresses();    
      },
    drawStarsYellow(count){
          return `<i class="fa fa-star rating-color"></i>`.repeat(count)
      },
    drawStarsGrey(count){
          return `<i class="fa fa-star"></i>`.repeat(5-count)
      },
    drawDollarsGreen(count){
          return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count)
      },
    drawDollarsGrey(count){
          return `<i class="fas fa-dollar-sign"></i>`.repeat(5-count)
      },

    getAdresses(){
      this.axios.get(`/suggedtedAdresse?page=${this.page}`)
      .then(res=>{
        this.adresses.push(...res.data.adresses.data);
        this.lastPage = res.data.adresses.last_page;
        this.totalResults = res.data.adresses.total;
        this.isAdressesReady=true;
       console.log(this.adresses)
    })
    },
     
    },
     computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     currentUser() {
            return this.$store.state.currentUser;
        },
    searchResults() {
            return this.$store.state.searchResults;
        },       
  },
};
</script>

<style lang="scss" scoped>
.btn-round-lg{
  border-radius: 10px !important;
}
.container-cover{
  transition: transform .4s ease-in-out;
  &:hover{
    cursor:pointer;
    opacity:0.7;
  }
  &:hover .update-cover{
    visibility: visible;
    transform: scale(2);

  }
}
.container-logo{
  transition: transform .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
  }
  &:hover .update-logo{
    visibility: visible;
    transform: scale(2);

  }
}
.update-logo{
  color:rgba(0, 0, 0, 0.3);
  transition: transform .4s ease-in-out;
  position:absolute !important;
  font-size:15px;
  visibility: hidden;
  top:30%;
  left:45%;

}
.update-cover{
  transition: transform .4s ease-in-out;
  position:absolute !important;
  visibility: hidden;
  color:rgba(0, 0, 0, 0.3);
  bottom:33%;
  left:48%;
  font-size:60px;
}
.addPubBtn span{
   user-select: none;
   transition: all .4s ease-in-out;
  &:hover{
    cursor:pointer;
   
    text-decoration: underline !important;
    
   
  }

}


.nav-tabs li a {
    &:hover{
        border-bottom: 2px#aaa solid !important;
    color: #000 !important;
    }
    transition: all 0.1s ease-in-out !important;
 }
.bg-mini-gradiant{
  color:white !important
}
.btnAddFav{
  color:white !important
}
.btnSkeleton{
  width:80px
}
.btn-follow{
   background-color: #2e58a6 !important;
}
.btn-follow , .btn-unfollow {
  &:hover{
      cursor:pointer;
     box-shadow: 2px 2px 2px #8888884d ;
     background-color: #2e58a6 !important;
     color:#fff !important

  }
  
  transition: all .4s ease-in-out;
}
.btn-unfav{
  color:red;
  transition: color .4s ease-in-out;
  &:hover{
    color:rgba(255,0,0,0.5);
    cursor:pointer;
    color:#999 !important;

  }
}
.btn-add-fav{
  transition:all .4s ease-in-out;
  color:black !important;
  &:hover{
    color:red !important;
    cursor:pointer;
  }
}
.rounded-xl {
  border-radius:15px !important
}
.text-grey-500 {
    color: #adb5bd!important;
}
.rounded-circle{
 border-radius:10px !important
}
.show-all{
  &:hover{
    text-decoration: underline !important;
  }
  color:#2e58a6 !important
}
.link-user a{
  color:#000 !important;

&:hover{
  color:#2e58a6 !important;
  text-decoration: underline !important
}
}
.btn-round-sm{
  border-radius:5px !important
}
.container-adrs-inf{
  padding: 0rem 1rem 0.5rem !important
}
.rating-color {
    color: #fbc634 !important
}
.rating-grey{
  color:#d2e3ff !important
}
.couts-color{
  color:rgb(16, 92, 16)
}
.w-img{
  width:100% !important;
  height:90px !important;
  vertical-align:bottom !important;
  border-radius: 10px
  //text-align:center;
  //left:20%;
}
.elipsis{
  white-space:nowrap;
  text-overflow:ellipsis;
   overflow: hidden;
}
.d-i-b{
  display:inline-block !important
}
.w60{
  width:60px !important
} 
.scroll-bar{
  overflow-x:hidden
}
</style>