<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="
                      card
                      w-100
                      shadow-xss
                      rounded-xxl
                      border-0
                      mb-1
                      mt-3
                      overflow-hidden
                    ">
        <template v-if="isReady">
          <div @click="toggleModalCover" v-if="currentUser.id == adresse.visiteur_id"
            v-tooltip.bottom-start="'Update cover photo'" class="
                          card-body
                          position-relative
                          h240
                          bg-image-cover bg-image-center
                          container-cover
                        " :style="`background-image: url(${assetsBaseUrl}${adresse.cover});`">
            <a class="update-cover"><i class="far fa-edit"></i></a>
          </div>

          <div v-else class="
                          card-body
                          position-relative
                          h240
                          bg-image-cover bg-image-center
                        " :style="`background-image: url(${assetsBaseUrl}${adresse.cover});`"></div>
        </template>
        <template v-else>
          <div class="
                          card-body
                          position-relative
                          h240
                          bg-image-cover bg-image-center
                          container-cover
                        "></div>
        </template>

        <div class="
                        card-body
                        d-block
                        pt-4
                        text-center
                        position-relative
                        card-body-m
                      ">
          <!-- si admin -->
          <template v-if="currentUser.id == adresse.visiteur_id">
            <figure :key="refreshLogoKey" @click="toggleModalLogo" v-if="isReady" class="
                            avatar
                            mt--6
                            position-relative
                            w75
                            z-index-1
                            w100
                            z-index-1
                            ms-auto
                            me-auto
                            container-logo
                          " v-tooltip.top-start="'Update logo'">
              <template v-if="
                !adresse.google_place_id && adresse.google_place_id != null
              ">
                <a class="update-logo"><i class="far fa-edit"></i></a>
                <img v-if="isReady" :src="`${assetsBaseUrl}${adresse.logo}`" alt="image"
                  class="p-1 bg-white rounded-xs w-100 logo-m logo-p" />
              </template>
              <template v-else>
                <a class="update-logo"><i class="far fa-edit"></i></a>
                <img v-if="isReady" :src="`${assetsBaseUrl}${adresse.logo}`" alt="image"
                  class="p-1 bg-white rounded-xl w-100 h100 logo-m logo-p" />
              </template>
            </figure>
            <figure v-else class="
                            avatar
                            mt--6
                            position-relative
                            w75
                            z-index-1
                            w100
                            z-index-1
                            ms-auto
                            me-auto
                          ">
              <div class="p-1 bg-white rounded-xl w-100 p-4">
                <loaderImage></loaderImage>
              </div>
            </figure>
          </template>

          <!-- sinon admin -->
          <template v-else>
            <figure v-if="isReady" class="
                            avatar
                            mt--6
                            position-relative
                            w75
                            z-index-1
                            w100
                            z-index-1
                            ms-auto
                            me-auto
                          ">
              <template v-if="
                !adresse.google_place_id && adresse.google_place_id != null
              ">
                <img v-if="isReady" :src="`${assetsBaseUrl}${adresse.logo}`" alt="image"
                  class="p-1 bg-white rounded-xl w-100 logo-m logo-p" />
              </template>
              <template v-else>
                <img v-if="isReady" :src="`${assetsBaseUrl}${adresse.logo}`" alt="image"
                  class="p-1 bg-white rounded-xl w-100 logo-m logo-p" />
              </template>
            </figure>
            <figure v-else class="
                            avatar
                            mt--6
                            position-relative
                            w75
                            z-index-1
                            w100
                            z-index-1
                            ms-auto
                            me-auto
                          ">
              <div class="p-1 bg-white rounded-xl w-100 p-4">
                <loaderImage></loaderImage>
              </div>
            </figure>
          </template>

          <h4 class="font-xs ls-1 fw-700 text-grey-900 adrs-mm">{{ adresse.rs }}</h4>

          <div v-if="isReady" class="
                          d-flex
                          align-items-center
                          pt-0
                          position-absolute
                          left-15
                          top-10
                          mt-4
                          ms-2
                        ">
            <h4 class="
                            font-xsssss
                            text-center
                            d-none d-lg-block
                            text-grey-500
                            fw-600
                            ms-2
                            me-2
                          ">
              <b class="
                              text-grey-900
                              mb-1
                              font-sm
                              fw-700
                              d-inline-block
                              ls-3
                              text-dark
                            ">{{ feeds.length }}
              </b>
              Publications
            </h4>
            <h4 class="
                            font-xsssss
                            text-center
                            d-lg-block
                            text-grey-500
                            fw-600
                            ms-2
                            me-2
                            mb-m
                          ">
              <b class="
                              text-grey-900
                              mb-1
                              font-sm
                              fw-700
                              d-inline-block
                              ls-3
                              text-dark
                  
                            ">{{ followers.length }}
              </b>
              Followers
            </h4>
          </div>
          <div v-else class="">
            <SkeletonAdresseProfil></SkeletonAdresseProfil>
          </div>

          <div v-if="isReady" class="
                          d-flex
                          align-items-center
                          justify-content-center
                          position-absolute
                          right-15
                          top-10
                        ">
            <a v-if="!isFollowed" @click="follow" class="
                            d-lg-block
                            p-3
                            z-index-1
                            rounded-3
                            text-white
                            font-xsssss
                            text-uppercase
                            fw-700
                            ls-3
                            btn-follow
                          ">Follow</a>
            <a v-else @click="unfollow" class="
                            d-lg-block
                            bg-grey
                            p-3
                            z-index-1
                            rounded-3
                            text-black
                            font-xsssss
                            text-uppercase
                            fw-700
                            ls-3
                            btn-unfollow
                          ">Unfollow</a>

            <a v-if="!isFavorite" v-tooltip.bottom-start="'Add to favorites'" @click="addFavorite" class="
                            btn-add-fav
                            d-lg-block
                            bg-grey
                            btn-round-lg
                            ms-1
                            rounded-3
                            text-white-700
                            btnAddFav
                            btn-round-sm-m
                          "><i class="feather-heart font-md"></i></a>

            <a v-else v-tooltip.bottom-start="'Remove from favorites'" @click="removeFavorite" class="
                            d-lg-block
                            bg-grey
                            btn-unfav btn-round-lg
                            ms-1
                            rounded-3
                            text-white-700
                            btn-round-sm-m
                          ">
              <i class="fas fa-heart font-md"></i>
            </a>
          </div>
          <div v-else class="
                          d-flex
                          align-items-center
                          justify-content-center
                          position-absolute
                          right-15
                          top-10
                          mt-2
                          me-2
                        ">
            <a href="#" class="
                            d-none d-lg-block
                            bg-grey
                            p-3
                            z-index-1
                            rounded-3
                            text-white
                            font-xsssss
                            text-uppercase
                            fw-700
                            ls-3
                            btnSkeleton
                            p-4
                          "></a>
            <a v-tooltip.bottom-end="'Add to favorites'" href="#" class="
                            d-lg-block
                            bg-grey
                            btn-round-lg
                            ms-1
                            rounded-3
                            text-white-700
                            btnAddFav
                            btn-round-sm-m
                          "><i class="feather-heart font-md"></i></a>
          </div>
          <a v-if="adresse.visiteur_id == 1 && !isDemandeAdminSent" @click="toggleModalSendAdminRequest"
            ref="btnDemandeAdmin" class="
                          d-lg-block
                          bg-green-trippy
                          d-none
                          p-2
                          z-index-1
                          rounded-3
                          text-black
                          font-xsssss
                          text-uppercase
                          fw-700
                          ls-3
                          btn-unfollow
                          position-absolute
                          right-15
                          top-15
                          mt-0
                          me-0
                          admin-btn
                        ">Send administration request</a>
        </div>

        <div class="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
          <ul class="
                          nav nav-tabs
                          h55
                          d-flex
                          product-info-tab
                          border-bottom-0
                          ps-4
                        " id="pills-tab" role="tablist">
            <li class="list-inline-item me-5">
              <router-link :to="{
                name: 'feedadresse',
                params: { id: this.$route.params.id },
              }" class="
                              fw-700
                              font-xssss
                              text-grey-500
                              pt-3
                              pb-3
                              ls-1
                              d-inline-block
                            " href="#navtabs1" data-toggle="tab"><i class="fas fa-home"></i> Home</router-link>
            </li>
            <li class="list-inline-item me-5">
              <router-link :to="{
                name: 'photosadresse',
                params: { id: this.$route.params.id },
              }" class="
                              fw-700
                              font-xssss
                              text-grey-500
                              pt-3
                              pb-3
                              ls-1
                              d-inline-block
                            " href="#navtabs2" data-toggle="tab"><i class="far fa-images"></i> Photos</router-link>
            </li>
            <li class="list-inline-item me-5">
              <router-link :to="{
                name: 'videosadresse',
                params: { id: this.$route.params.id },
              }" class="
                              fw-700
                              font-xssss
                              text-grey-500
                              pt-3
                              pb-3
                              ls-1
                              d-inline-block
                            " href="#navtabs3" data-toggle="tab"><i class="fas fa-video"></i> Videos</router-link>
            </li>
            <li class="list-inline-item me-5">
              <router-link :to="{
                name: 'avisadresse',
                params: { id: this.$route.params.id },
              }" class="
                              fw-700
                              font-xssss
                              text-grey-500
                              pt-3
                              pb-3
                              ls-1
                              d-inline-block
                            " href="#navtabs4" data-toggle="tab"><i class="fas fa-star"></i> Rates</router-link>
            </li>
            <li class="list-inline-item me-5">
              <router-link :to="{
                name: 'aproposadresse',
                params: { id: this.$route.params.id },
              }" class="
                              fw-700
                              font-xssss
                              text-grey-500
                              pt-3
                              pb-3
                              ls-1
                              d-inline-block
                            " href="#navtabs3" data-toggle="tab"><i class="fas fa-info-circle"></i> About</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="col-xl-8 col-xxl-9 col-lg-8">
      <div class="
                      card
                      w-100
                      shadow-xss
                      rounded-xxl
                      border-0
                      ps-4
                      pe-4
                      pb-3
                      mb-1
                      mt-0
                      addPub
                    ">
        <div class="card-body p-0 mt-3 position-relative"></div>
        <div class="card-body d-flex p-0 mt-0 p-2">
          <a @click="toggleModal" class="
                          addPubBtn
                          d-flex
                          align-items-center
                          font-xsss
                          fw-500
                          ls-1
                          text-grey-700 text-dark
                          pe-4
                        "><i class="font-md text-success feather-image me-2"></i><span
              class="col-xs-12 fw-900 ls-1">Share
              your
              experience</span></a>
        </div>
      </div>

      <div class="card w-100 shadow-xss rounded-xxl border-0 p-0 mb-3">
        <router-view :key="refreshKey" />
      </div>
    </div>
    <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0">
      <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
        <div class="card-body d-flex align-items-center p-4">
          <span class="fw-700 mb-0 font-14 text-grey-900 ls-1">Followers</span>
          <a v-if="followersList.length > 10" href="#" class="fw-600 ms-auto font-14 text-primary">Show all</a>
        </div>
        <template v-if="isFollowerListReady">
          <div v-for="follower in followersList" :key="isFollowed"
            class="card-body d-flex pt-4 ps-4 pe-4 pb-0 border-top-xs bor-0">
            <figure class="avatar me-3">
              <img v-if="isFollowerListReady" :src="`${assetsBaseUrl}${follower.visiteur.photo}`" alt="image"
                class="shadow-sm rounded-circle w45" />
            </figure>
            <router-link :to="{
              name: 'feedvisiteur',
              params: { id: follower.visiteur.id },
            }" class="fw-700 text-grey-900 font-xssss mt-2">{{ follower.visiteur.nom }}
            </router-link>
          </div>
        </template>
        <template v-else>
          <div class="card-body d-flex pt-4 ps-4 pe-4 pb-0 border-top-xs bor-0">
            <Loader></Loader>
          </div>
        </template>
      </div>
    </div>
  </div>

  <!-- ------------------------------------------------(MODAL)-------------------------------------------->
  <ModalSendAdminRequest v-if="isShowModalSendAdminRequest" @close="toggleModalSendAdminRequestClose"
    @refreshStatus="refreshStatus"></ModalSendAdminRequest>

  <BaseModal v-if="isShowModal" @close="toggleModalClose" scrollable title="Ajouter une publication"
    :adresseId="adresseId" @refresh="refresh">
  </BaseModal>
  <!-- ------------------------------------------------(MODAL)-------------------------------------------->
  <ModalCover v-if="isShowModalCover" @close="toggleModalCoverClose" scrollable title="Change cover photo"
    :adresseId="adresseId" @refreshCover="refreshCover">
  </ModalCover>

  <ModalLogo v-if="isShowModalLogo" @close="toggleModalLogoClose" scrollable title="Change profil photo"
    :adresseId="adresseId" @refreshLogo="refreshLogo">
  </ModalLogo>
</template>

<script>
import ModalSendAdminRequest from "../../layouts/modal/modalSendAdminAdresse.vue";
import BaseModal from "../../layouts/modal/modal.vue";
import ModalCover from "../../layouts/modal/modalCover.vue";
import ModalLogo from "../../layouts/modal/modalLogo.vue";
import CreateFeed from "../Feeds/CreateFeed.vue";
import Skeleton from "../../layouts/skeleton/Skeleton.vue";
import SkeletonComment from "../../layouts/skeleton/SkeletonComment.vue";
import Loader from "../../layouts/loader/loader.vue";
import ModalComment from "../../layouts/modal/modalComment.vue";
import loaderImage from "../../layouts/loader/loaderImage.vue";
import SkeletonAdresseProfil from "../../layouts/skeleton/SkeletonAdresseProfil.vue";
import SkeletonBtn from "../../layouts/skeleton/SkeletonBtn.vue";

export default {
  components: {
    BaseModal,
    CreateFeed,
    Skeleton,
    Loader,
    SkeletonComment,
    ModalComment,
    loaderImage,
    SkeletonAdresseProfil,
    SkeletonBtn,
    ModalCover,
    ModalLogo,
    ModalSendAdminRequest,
  },
  destroyed() {
    const controller = new AbortController();
    controller.abort()
  },
  mounted() {
    this.$nextTick(() => {
      this.adresseId = +this.$route.params.id;
      this.getFollowersList();
      this.getFeeds();
    });
  },

  data() {
    return {
      isDemandeAdminSent: false,
      isShowModalSendAdminRequest: false,
      refreshLogoKey: false,
      followersList: [],
      isFollowerListReady: false,
      adresse: [],
      feeds: [],
      comments: [],
      followers: [],
      rates: [],
      isFollowed: {},
      isFavorite: {},
      isRated: {},
      isReady: false,
      //Page---------------------------
      isShowModal: false,
      isShowModalCover: false,
      isShowModalLogo: false,
      lastPage: 1,
      lastCommentPage: 1,
      page: 1,
      pageComment: 1,
      isPrevCommentReady: true,
      adresseId: Number,
      refreshKey: true,
    };
  },

  methods: {
    refreshStatus() {
      this.isDemandeAdminSent = true;
    },

    addFavorite() {
      this.isFavorite = true;
      this.axios
        .post(`/favoris/add`, {
          visiteurId: this.currentUser.id,
          adresseId: this.adresseId,
        })
        .then((res) => { });
    },
    removeFavorite() {
      this.isFavorite = false;
      this.axios
        .post(`favoris/delete`, {
          visiteurId: this.currentUser.id,
          adresseId: this.adresseId,
        })
        .then((res) => { });
    },
    getFollowersList() {
      this.isFollowerListReady = false;
      this.axios
        .post(`/followByAdress`, {
          adresseId: this.adresseId,
        })
        .then((res) => {
          this.followersList = res.data.follows;
          this.isFollowerListReady = true;
          //////console.log(res.data.follows );
        });
    },

    follow() {
      this.isFollowerListReady = false;
      this.isFollowed = true;
      this.axios
        .post(`/follow`, {
          visiteurId: this.currentUser.id,
          adresseId: this.adresseId,
        })
        .then((res) => {
          this.getFollowersList();
        });
    },

    unfollow() {
      this.isFollowerListReady = false;
      this.isFollowed = false;
      this.axios
        .post(`/unfollow`, {
          visiteurId: this.currentUser.id,
          adresseId: this.adresseId,
        })
        .then((res) => {
          this.getFollowersList();
        });
    },
    //--------------------------
    toggleModalSendAdminRequest() {
      this.isShowModalSendAdminRequest = !this.isShowModalSendAdminRequest;
    },
    toggleModalSendAdminRequestClose() {
      this.isShowModalSendAdminRequest = false;
    },

    //-------------------------
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
    toggleModalClose() {
      this.isShowModal = false;
    },
    toggleModalCover() {
      this.isShowModalCover = !this.isShowModalCover;
    },
    toggleModalLogo() {
      this.isShowModalLogo = !this.isShowModalLogo;
    },
    toggleModalCoverClose() {
      this.isShowModalCover = false;
    },
    toggleModalLogoClose() {
      this.isShowModalLogo = false;
    },
    refresh() {
      this.refreshKey = !this.refreshKey;
    },

    refreshLogo() {
      this.getFeeds();
    },

    refreshCover() {
      this.getFeeds();
    },

    getFeeds() {
      this.axios
        .get(`publications/${this.adresseId}?page=${this.page}`)
        .then((res) => {
          this.feeds.push(...res.data.feeds.data);
          this.adresse = res.data.Adresse;
          this.isReady = true;
          this.lastPage = res.data.feeds.last_page;
          this.followers = res.data.followers;
          this.rates = res.data.avis;
          this.isFollowed = res.data.isFollowed;
          this.isFavorite = res.data.isFavorite;
          this.isRated = res.data.isRated;
          this.isDemandeAdminSent = res.data.isDemandeAdminSent;
          ////console.log(res.data.Adresse);
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-cover {
  transition: transform 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:hover .update-cover {
    visibility: visible;
    transform: scale(2);
  }
}

.container-logo {
  transition: transform 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:hover .update-logo {
    visibility: visible;
    transform: scale(2);
  }
}

.update-logo {
  color: rgba(150, 150, 150, 0.9);
  transition: transform 0.4s ease-in-out;
  position: absolute !important;
  font-size: 10px;
  visibility: hidden;
  top: 71%;
  left: 77% !important;
  z-index: 5;
}

.update-cover {
  transition: transform 0.4s ease-in-out;
  position: absolute !important;
  visibility: hidden;
  color: rgba(0, 0, 0, 0.3);
  bottom: 3%;
  left: 97%;
  font-size: 17px;
}

.addPubBtn span {
  user-select: none;
  transition: all 0.4s ease-in-out;

  &:hover {
    cursor: pointer;

    text-decoration: underline !important;
  }
}

.router-link-active.router-link-exact-active {
  &:hover {
    border-bottom: 2px#666 solid !important;
    color: #000 !important;
  }

  border-bottom: 2px#333 solid !important;
  color: #000 !important;
}

.nav-tabs li a {
  &:hover {
    border-bottom: 2px#aaa solid !important;
    color: #000 !important;
  }

  transition: all 0.1s ease-in-out !important;
}

.bg-mini-gradiant {
  color: white !important;
}

.btnAddFav {
  color: white !important;
}

.btnSkeleton {
  width: 80px;
}

.btn-follow {
  background-color: #2e58a6 !important;
}

.btn-follow,
.btn-unfollow {
  &:hover {
    cursor: pointer;
    box-shadow: 2px 2px 2px #8888884d;
    background-color: #2e58a6 !important;
    color: #fff !important;
  }

  transition: all 0.4s ease-in-out;
}

.btn-unfav {
  color: red;
  transition: color 0.4s ease-in-out;

  &:hover {
    color: rgba(255, 0, 0, 0.5);
    cursor: pointer;
    color: #999 !important;
  }
}

.btn-add-fav {
  transition: all 0.4s ease-in-out;
  color: black !important;

  &:hover {
    color: red !important;
    cursor: pointer;
  }
}

.rounded-xl {
  border-radius: 15px !important;
}

.text-grey-500 {
  color: #adb5bd !important;
}

.top-15 {
  top: 7em !important;
}

@media (max-width: 767px) {

  .mb-m {
    bottom: 25px;
    position: relative;
  }

  .row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .h240 {
    height: 150px !important;
    margin-bottom: 0rem !important;
  }

  .logo-m logo-p {
    width: 70px !important;
    height: 70px !important;
  }

  .justify-content-center {
    justify-content: flex-end !important;
  }

  .card-body-m {
    padding: 0;
  }

  .btn-follow,
  .btn-unfollow {
    height: 35px;
    border-radius: 35px;
    display: inline-block;
    line-height: 5px !important;
    text-align: center;
  }

  .btn-round-lg {
    width: 35px;
    height: 35px;
    border-radius: 35px;
    display: inline-block;
    line-height: 35px !important;
    text-align: center;
    margin-right: 0.25rem !important;
  }

  i {
    line-height: 35px !important;
  }

  .right-15 {
    right: 5px !important;
  }

  .top-10 {
    top: 4px !important;

  }

  .admin-btn {
    display: inline-block !important;
    width: 120px !important;
    top: 4.5em !important;
    height: 46px !important;
    line-height: 11px !important;
    right: 20px !important;

  }

  .adrs-mm {
    width: 65% !important;
    padding: 5px;
  }
}

.bg-green-trippy {
  background-color: #6BBB2A !important;
  color: #fff !important;
}

.logo-p {
  max-height: 90px;
}
</style>