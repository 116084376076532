<template lang="">
 <BaseModal v-if="isShowModal" @close="toggleModalClose" scrollable title="Ajouter une publication" >
 <!-- @refresh="refresh" -->
      
</BaseModal>
<!-- <div class="col-xl-8 col-xxl-9 col-lg-8"> -->
  <div class="card w-100 shadow-xss rounded-xxl border-0 ps-4 pe-4 pb-3 mb-1 mt-1 addPub">
                               
                                <div class="card-body p-0 mt-3 position-relative">
                                 
                                </div>
                                <div class="card-body d-flex p-0 mt-0 p-2">
                                   
                                    <a @click="toggleModal" class="addPubBtn d-flex align-items-center font-xsss fw-500 ls-1 text-grey-700 text-dark pe-4"><i class="font-md text-success feather-image me-2"></i><span class="col-xs-12 fw-900 ls-1">Share your experience</span></a>
                                   
                                
                                </div>
                              
 </div> 
 <!-- </div> -->
</template>
<script>
import BaseModal from "../../layouts/modal/modalGlobal.vue"
export default {
  name: "createFeed",
  components:{
  BaseModal
  },
   data(){
    return{
      isShowModal: false,
     

    }
  },
  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
  methods:{
   toggleModal() {
      this.isShowModal = !this.isShowModal ;
      
    },
     toggleModalClose() {
      this.isShowModal = false;
    },
}
}

</script>
<style lang="scss">
.addPubBtn span{
   user-select: none;
   transition: all .4s ease-in-out;
  &:hover{
    cursor:pointer;
   
    text-decoration: underline !important;
    
   
  }
}
  </style>
