<template lang="">
 <ModalMutualFriend v-if="isShowModalMutualFriend" @close="toggleModalCloseMutualFriend" :friendId="friendId"></ModalMutualFriend>
  <div class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
    
       <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0">
      <h4 class="fw-700 mb-0 font-14 text-grey-900">Sent requests 
         <span v-if="isDemandesReady"> ({{demandes.length}}) </span>
         </h4>
      </div>
      <template v-if="isDemandesReady">
        <div class="card-body d-flex pt-2 pb-2 ps-4 pe-4 border-top-xs bor-0" v-if="demandes.length == 0">No requests</div>
        <div  v-for="demande in demandes" >
      <div class="card-body d-flex pt-2 ps-4 pe-4 pb-0 border-top-xs bor-0">
         <router-link :to="{name :'feedvisiteur', params: { id : demande.receiver.id } }"  v-tooltip.left-start="'Show profil'">
               
            <figure class="avatar me-3">
              <img :src="`${assetsBaseUrl}${demande.receiver.photo}`" alt="image" class="shadow-sm rounded-circle w45">
              </figure>
               </router-link>
              <h4 class="fw-700 text-grey-900 font-xssss mt-1 link-user">
              <router-link v-tooltip.top-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : demande.receiver.id } }" >{{demande.receiver.nom}}
                </router-link>
                <span v-if="demande.mutualFriend > 0" @click="toggleModalMutualFriend(demande.receiver.id)" class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500 btn-add-friend">{{demande.mutualFriend}}  mutual friends</span>
                <span v-else  class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">no mutual friends</span>
              </h4>
      
     
               </div>
              <div class="card-body d-flex align-items-center pt-0 ps-4 pe-4 pb-4">
                  <a @click="removeFriend(demande.receiver.id)" class="p-2 lh-20 w100 bg-grey text-grey-800 text-center font-xssss fw-600 ls-1 rounded-md btn-add-friend">Cancel</a>
              </div>
         </div>
      
     
       <router-link :to="{name :'sentRequests', params: { id : this.currentUser.id } }" v-if="demandes.length > 3"  class="fw-600 ms-auto font-14 text-primary pe-3 pb-1 show-all">See all</router-link>
  </template> 
  <template v-else>
    <div class="pb-4 pt-4 border-top-xs">
     <Loader></Loader>
     </div>
  </template> 
  </div>
</template>
<script>
import Loader from "../../layouts/loader/loader.vue";
import ModalMutualFriend from "../../layouts/modal/modalMutualFriend.vue";

export default {
  name: "demandeAmis",
  components:{
    Loader,
    ModalMutualFriend
    
  },
  data(){
    return{
      isDemandesReady:false,
      demandes:[],
      page:1,
      lastPage:1,
      done:false,
      addFriendRef:[],
      isVisible: false,
      totalResults:0,
      btnShowMutualFriendRef:[],
      tooltipMutualFriendRef:[],
      isShowModalMutualFriend:false,
      friendId:null,

    }
  },
    computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
  methods:{
       toggleModalCloseMutualFriend(id){

      this.isShowModalMutualFriend = false;  

    },

    toggleModalMutualFriend(id){

      this.isShowModalMutualFriend = !this.isShowModalMutualFriend;
      this.friendId = id;

    },
    getDemandes(){
      this.isDemandesReady=false;
      this.axios.get('/amis/demamdesAmisSent')
      .then(res=>{
        this.demandes = res.data.demandesAmis;
        this.isDemandesReady=true;
        //console.log(this.demandes)
    })
    },
      
    removeFriend(id){
       this.axios.post('amis/supprimerAmis',{
        visiteur_id:id,
      }).then(res=>{
        this.getDemandes();

      })

    },

  },
  mounted(){
    this.getDemandes();
  }
};
</script>
<style lang="scss" scoped>
.rounded-md{
  &:hover{
     box-shadow: 2px 2px 2px #8888884d ;
  }
  border-radius:5px
}
.rounded-circle{
 border-radius:10px !important
}
.show-all{
  &:hover{
    text-decoration: underline !important;
  }
  color:#2e58a6 !important
}
.confirm-btn{
  background-color:#2e58a6 !important;
  //border-radius:10px !important

}
.rounded-circle{
 border-radius:10px !important
}
.link-user a{
  color:#000 !important;

&:hover{
  color:#2e58a6 !important;
  text-decoration: underline !important
}
}

  .btn-add-friend{
   &:hover{
     cursor:pointer;
      text-decoration: underline !important
   }
 }
  </style>
