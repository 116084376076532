<template lang="">
  <div class="row">
    <div class="col-lg-12">
      <div
        class="
          chat-wrapper
          p-3
          w-100
          position-relative
          scroll-bar
          bg-white
          theme-dark-bg
          chat-left scroll-bar
        "
      > 
        <ul class="email-message">
        <template v-if="isNotifiReady">
        
          <li v-for="(notif,index) in notifications"  :ref="setNotifRef"
          @click.prevent="markNotificationAsRead(notif.id,index,notif.type,notif.data.visiteur.id)" :class="notif.read_at == null ? 'unread':''">
            <a
              href="#"
              class="rounded-3 theme-light-bg"
            >
            <div class="form-check mt-1">

            <span class="  icon-notif" v-if="notif.type == 'App\\Notifications\\commentNotification'">
            <i class="fas fa-comment"></i>
            </span>
            <span class=" icon-notif" v-else-if="notif.type == 'App\\Notifications\\storyNotification'">
            <i class="fas fa-photo-video"></i>
            </span>
            <span class=" icon-notif" v-else-if="notif.type == 'App\\Notifications\\postNotification'">
            <i class="fas fa-photo-video"></i>
            </span>
            <span class=" icon-notif" v-else-if="notif.type == 'App\\Notifications\\tagNotification'">
              <i class="fas fa-tag"></i>
            </span>
            <span class=" icon-notif" v-else-if="notif.type == 'App\\Notifications\\friendNotification'">
               <i class="fas fa-user-plus"></i>
            </span>
            <span v-else-if="notif.type == 'App\\Notifications\\jaimeNotification'" class=" icon-notif">
              <i v-if="notif.data.jaime.reaction_type == 2 " class="fas fa-heart"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 5" class="fas fa-frown"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 4" class="fas fa-laugh"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 1" class="fas fa-thumbs-up"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 7" class="fas fa-thumbs-down"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 6" class="fas fa-angry"></i>
              <i v-else-if="notif.data.jaime.reaction_type == 3" class="fas fa-surprise"></i>
            </span>
              
            </div>
             

         
              <div class="email-user">
                  <img
                    :src="`${assetsBaseUrl}${notif.data.visiteur.photo}`"
                    alt="user"
                    class="w45   left-0 rounded-3  me-2
                    ls-3"
                />
               
                <h6
                  class="
                    font-xssss
                    text-grey-900 text-grey-900
                    mb-0
                    mt-0
                    fw-700
                  "
                >
                  {{notif.data.visiteur.prenom}} {{notif.data.visiteur.nom}}
                </h6>
              </div>
              
              <div  class="email-subject text-grey-900 text-dark fw-600 font-xssss">
                {{notif.data.message}}
              </div>

              <div v-if="notif.data.commentaire" class="email-text text-grey-500 fw-600 font-xssss">
                 {{notif.data.commentaire.texte_com}} 
              </div>
             
              <div class="email-time text-grey-500 fw-600"> {{ $filters.timeAgo(notif.created_at)}}</div>
            </a>
               

          </li>
          <div v-if="done"
                class="
                    
                    w-100
                    text-center
                    border-0
                    p-2
                    mb-1
                    mt-1
                    
                "
            >
                
                No other notifications
            </div><div>
      <Loader class="mt-2" v-show="isVisible && !done"/> 
      </div>
        <div v-if="notifications.length && isNotifiReady" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div> 
        <div v-else class="card-body d-flex pt-2 ps-4 pe-4 pb-0 bor-0 col-xl-12 col-xxl-12 col-lg-12 text-center"> No results found</div> 

          </template>
          <template v-else>

              <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0 col-xl-12 col-xxl-8 col-lg-12">
             
             
                <Loader class="mt-1 mb-1"></Loader>
               
             </div>

          </template>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import Pusher from 'pusher-js';
import Loader from "../loader/loader.vue";
import SkeletonComment from "../skeleton/Skeleton.vue";
export default {
  name: "vueNavBar",
  components: {
    Loader,
    SkeletonComment

  },
  data() {
    return {
      isNotifiReady: false,
      notifications: [],
      notifCount: 0,
      page: 1,
      lastPage: 1,
      done: false,
      isVisible: false,
      refreshNotif: false,
      notifRef: [],
    };
  },
  mounted() {
    this.$store.dispatch("getuser");
    this.getNotifications();

    // this.subscribe()

  },
  // beforeUpdate(){
  //   this.notifRef = [];  
  // },

  methods: {
    setNotifRef(el) {
      if (el && !this.notifRef.includes(el)) {
        this.notifRef.push(el);
        //console.log(this.notifRef);
      }

    },
    visibilityChanged(isVisible) {

      this.notifRef = [];

      if (!isVisible) {
        return
      }
      if (this.page >= this.lastPage) {
        return this.done = true
      }
      this.isVisible = isVisible;

      this.page++;
      this.getNotifications();

      let a = new Set(this.notifRef);
      this.notifRef = [...a];

    },
    markNotificationsAsRead() {
      this.notifRef = [];
      this.axios.get('markNotificationsAsRead').then(res => {
        this.$emit('markAsReady');
        //this.getNotifications();
        this.notifRef.forEach(el => {
          el.classList.remove('unread');
        })


      })

    },
    markNotificationAsRead(notifId, i, type, userID) {

      //console.log(`${notifId} ${i} ${type} ${userID}`);
      this.notifRef = [];
      this.axios.post('markNotificationAsRead', {
        notif_ID: notifId
      }).then(res => {
        this.$emit('markOneAsReady');
        //this.getNotifications();
        this.notifRef[i].classList.remove('unread');
        if (type == 'App\\Notifications\\friendNotification') {
          this.$router.push(`/feedvisiteur/${userID}`)
        }
      }).then(res => {
        if (type == 'App\\Notifications\\jaimeNotification') {
          this.$router.push("/showPost/");

        }
      })

    },
    getNotifications() {
      this.notifRef = [];
      this.axios.get(`/notifications?page=${this.page}`).then(res => {
        this.notifications.push(...res.data.notifications.data);

        this.notifCount = res.data.unreadNotifCount;
        this.lastPage = res.data.notifications.last_page;
        this.isNotifiReady = true;
        console.log(res.data)

      })
    },
    //  subscribe () {
    //    //Pusher.logToConsole = true;
    //   let pusher = new Pusher('80bcf26201ca31d02cc5', { cluster: 'mt1' })
    //   pusher.subscribe('trippybookNotifications')
    //   pusher.bind('BroadcastNotificationCreated', data => {
    //    // console.log('ooooooooooo'+JSON.stringify(data));
    //     this.notifications.unshift(JSON.stringify(data))
    //   })
    // },
    // initPusher(){
    // //Pusher.logToConsole = true;

    // var pusher = new Pusher('80bcf26201ca31d02cc5', {
    //   cluster: 'mt1'
    // });

    // var channel = pusher.subscribe('trippybookNotifications');
    // channel.bind('BroadcastNotificationCreated', function(data) {
    //   // app.messages.push(JSON.stringify(data));
    //   //console.log('ooooooooooo'+JSON.stringify(data));
    //   this.notifications.push(JSON.stringify(data));
    // });

    // },

  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.email-message a .email-time {
  flex: 0 0 100px;
  width: 100px;
}

.form-check {
  padding-left: 0
}

.fa-comment,
.fa-photo-video,
.fa-tag,
.fa-user-plus,
.fa-thumbs-up {
  color: #2e58a6;
}

.fa-heart {
  color: red;
}

.fa-laugh,
.fa-frown,
.fa-angry,
.fa-surprise {
  color: rgb(223, 184, 12);
}

.fa-thumbs-down {
  color: rgb(255, 102, 0);
}

.unread {
  background-color: #e8f1fa !important;

}

@media (max-width: 767px) {
  .email-message a .email-subject {
    display: inline-block !important;
    position: absolute;
    top: 40px;
    left: 35px
  }


  .email-message a .form-check {
    display: inline-block !important;
    position: absolute;
    z-index: 99;
    right: 10px;
    bottom: 10px;
  }
}
</style>
