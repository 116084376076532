<template lang="">
  <ModalMutualFriend v-if="isShowModalMutualFriend" @close="toggleModalCloseMutualFriend" :friendId="friendId"></ModalMutualFriend>

  <div v-if="friends.length > 0" class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
    
       <div class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0">
         
      <h4 class="fw-700 mb-0 font-14 text-grey-900">Suggested friends <span v-if="isFriendsReady">  ({{totalResults}}) </span></h4>
      </div>
      <template v-if="isFriendsReady">
         <div class="chat-left scroll-bar pt-2">
      <div v-for="(friend,index) in friends" class="card-body d-flex pt-2 ps-4 pe-2 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-2">
          
        
        <figure class="avatar me-2 mb-0">
          <router-link v-tooltip.left-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.id } }" >
         <img  :src="`${assetsBaseUrl}${friend.photo}`" alt="image" class="shadow-sm rounded-circle w45"> 
         </router-link>
        </figure>
        
        
              <h4  class="fw-700 text-grey-900 font-xssss mt-1 link-user">
              <router-link v-tooltip.top-start="'Show profil'" :to="{name :'feedvisiteur', params: { id : friend.id } }" >{{friend.prenom}} {{friend.nom}}
                </router-link>
                <span v-if="friend.mutualFriend > 0" @click="toggleModalMutualFriend(friend.id)" class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500 get-mutual-friend">{{friend.mutualFriend}} mutual friends</span>
                <span v-else class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">no mutual friends</span>

                <div class="position-relative">
                

                
                </div>
              </h4>
              <a :ref="setFriendRef" @click="addFriend(friend.id,index)"  class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-0"></a>
         
        
        
       </div>
               <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-2
            mb-1
            mt-1
        "
    >
        
No other suggestions
    </div>
      <Loader v-show="isVisible && !done"/> 
        <div v-if="friends.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>    
      </div>
      <router-link :to="{name :'suggestedFriends', params: { id : this.currentUser.id } }" class="fw-600 ms-auto font-14 text-primary pe-3 pb-1 show-all" v-if="friends.length > 3 && !done">See all</router-link>
    </template>
    <template v-else>
      <div class="pb-4 pt-4 border-top-xs">
      <Loader></Loader>
      </div>

    </template>
  </div>
 
</template>
<script>
import MutualFriendHover from "../../layouts/modal/modalHoverMutualFriend.vue"
import { useToast } from 'vue-toastification';
const toast = useToast();
import Loader from "../../layouts/loader/loader.vue";
import ModalMutualFriend from "../../layouts/modal/modalMutualFriend.vue";
export default {
  name: "amis",
   components:{
    Loader,
    MutualFriendHover,
    ModalMutualFriend
    
  },
  data(){
    return{
      friends:[],
      isFriendsReady:false,
      page:1,
      lastPage:1,
      done:false,
      addFriendRef:[],
      isVisible: false,
      totalResults:0,
      btnShowMutualFriendRef:[],
      tooltipMutualFriendRef:[],
      isShowModalMutualFriend:false,
      friendId:null,


    }
  }, 
  methods:{
    //---------------------(MutualFriends)------------------------------
  

    toggleModalCloseMutualFriend(id){

      this.isShowModalMutualFriend = false;  

    },

    toggleModalMutualFriend(id){

      this.isShowModalMutualFriend = !this.isShowModalMutualFriend;
      this.friendId = id;

    },
    //---------------------------------------------------------
     setBtnShowMutualFriendRef(el){
         
        if (el && !this.btnShowMutualFriendRef.includes(el)) {
          this.btnShowMutualFriendRef.push(el);
        }
      },
       setTooltipMutualFriendRef(el){
         
        if (el && !this.tooltipMutualFriendRef.includes(el)) {
          this.tooltipMutualFriendRef.push(el);
        }
      },
    showMutualFriend(id,i){
      this.tooltipMutualFriendRef[i].classList.remove('hide');
      this.tooltipMutualFriendRef[i].classList.add('show');

    },
    getMutualFriends(id,index){

    },
     setFriendRef(el){
         
        if (el && !this.addFriendRef.includes(el)) {
          this.addFriendRef.push(el);
        }
      },
      removeFriend(id,i){
       if( this.addFriendRef[i].classList.contains('btn-decline')){

           // console.log(this.addFriendRef[i]);
            this.addFriendRef[i].removeAttribute('cancel-data');
            this.addFriendRef[i].classList.add('fa-user-plus','btn-add');
            this.addFriendRef[i].classList.remove('fa-times','btn-decline');
          //   toast.error("Friend request cancled", {
          //   position: "bottom-right",
          //   timeout: 3000,
          //   closeOnClick: true,
          //   pauseOnFocusLoss: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   draggablePercent: 0.6,
          //   showCloseButtonOnHover: false,
          //   hideProgressBar: false,
          //   closeButton: "button",
          //   icon: true,
          //   rtl: false
          // });

       this.axios.post('amis/supprimerAmis',{
        visiteur_id:id,
        


      }).then(res=>{
        //this.getFriendStatus();

      })
        
       
       }else{

          this.addFriend(id,i);
         

              
       }

    },
     addFriend(id,i){
       //console.log(this.addFriendRef[i]);
         if( this.addFriendRef[i].hasAttribute('cancel-data')){
          this.removeFriend(id,i)
         

         }else{
       // this.asyncMethod(false);
        //this.addFriendRef[i].lastElementChild.tooltip.content ='lool'
        //this.addFriendRef[i].lastElementChild.tooltip.content = 'Cancel';
         //console.log(this.addFriendRef[i]);
        this.addFriendRef[i].setAttribute('cancel-data' ,"cancel" );
        this.addFriendRef[i].classList.remove('fa-user-plus','v-popper--has-tooltip','btn-add');
        this.addFriendRef[i].classList.add('fa-times','btn-decline');
        

          //   toast.success("Friend request sent successfully", {
          //   position: "bottom-right",
          //   timeout: 3000,
          //   closeOnClick: true,
          //   pauseOnFocusLoss: true,
          //   pauseOnHover: true,
          //   draggable: true,
          //   draggablePercent: 0.6,
          //   showCloseButtonOnHover: false,
          //   hideProgressBar: false,
          //   closeButton: "button",
          //   icon: true,
          //   rtl: false
          // });
     
      this.axios.post('amis/ajouterAmis',{
        visiteur_id:this.currentUser.id,
        receiver_id:id


      }).then(res=>{
        //this.getFriendStatus();
       

      })
         }
    },
       visibilityChanged (isVisible) {
         this.addFriendRef=[];
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
          this.isVisible = isVisible;
    
            this.page++;
            this.getFriends();
            

  
         
      },
    getFriends(){
      //this.isFriendsReady=false;
      this.axios.get(`/amisSuggested?page=${this.page}`)
      .then(res=>{
         this.friends.push(...res.data.amis.data);
        //this.friends = res.data.amis.data;
        this.isFriendsReady=true;
        this.lastPage = res.data.amis.last_page;
        this.totalResults = res.data.amis.total;
        //console.log(this.friends)
    })
    },

  }, 
  mounted(){
    this.getFriends();

  },
  computed: {
          currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
.rounded-circle{
 border-radius:10px !important
}
.show-all{
  &:hover{
    text-decoration: underline !important;
  }
  color:#2e58a6 !important
}
.link-user a{
  color:#000 !important;

&:hover{
  color:#2e58a6 !important;
  text-decoration: underline !important
}
}
.btn-round-sm{
  border-radius:5px !important
}  .chat-left {
    height: calc(100vh - 400px) !important;
}
.btn-add , .btn-decline{
  border-radius:5px !important;
  transition: background-color .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
    color:#fff !important;
  }
}
.btn-add{
  &:hover{
background-color: #2e58a6 !important;
  }
}
.btn-decline{
  
  &:hover{
background-color: #f00 !important;
  }
}
.get-mutual-friend{
  &:hover{
    cursor:pointer;
    text-decoration:underline
  }
 

}
  .show{
   display: block !important; 
   }
   .hide{
      display: none !important; 

   }

</style>
