<template lang="">
  <ModalAdresse
    v-if="isShowModal"
    @close="toggleModalClose"
    @refreshAdresse ="refreshAdresse"
    scrollable
  ></ModalAdresse>
  <div class="">
    <div class="col-xl-12 chat-left scroll-bar">
      <div class="card shadow-xss w-100 d-block d-flex border-0 p-4 mb-3">
        <div class="card-body d-flex align-items-center p-0">
          <h2 class="fw-700 mb-0 mt-0 font-md text-grey-900">My adresses
</h2>
          <div class="search-form-2 ms-auto">
            <i class="ti-search font-xss"></i>
            <input
           
            v-model.trim="searchKey"
              type="text"
              class="
                form-control
                text-grey-500
                mb-0
                bg-greylight
                theme-dark-bg
                border-0
              "
              placeholder="Search.."
            />
          </div>
          <a v-tooltip.top-start="'Add an address'"
            @click="toggleModal"
            class="
              btn-round-md
              ms-2
              
              theme-dark-bg
              rounded-3
              addAdresseBtn
            "
          >
            <i class="fa fa-plus"></i
          ></a>
        </div>
      </div>


      
<!-- *************************************************************************** -->
     
      <div class="row ps-2 pe-2">
        
           <!-- <MesAdressesActives></MesAdressesActives> -->

           <template v-if="isAdressesReady">
         <template v-if="adresses.length == 0">
          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1
            "
          >
           <div class="card-body pt-4 pb-4 border-0 rounded-3">
            No result found
             
             
              <div class="clearfix"></div>
             
            </div>
            <div class="card-image w-100 mb-3 border-0">
              <a href="#"
              class="position-relative d-block w-100 border-0">
                 
              </a>
            </div>
           
            
         </div> 
        </template>  
   <div v-for="adresse in filtredResults" :key="keyAdresse" class="col-lg-12 col-md-12 col-sm-6 pe-2 ps-2 adr mb-2">

          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss    
              rounded-3
              overflow-hidden
              flex-row
            "
          >
            <div class="col-md-2 border-0 p-0">
              <template v-if="!adresse.google_place_id && adresse.google_place_id == null">
              <router-link  :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }"
              class="position-relative d-block">
               <v-img
        :src="`${assetsBaseUrl}${adresse.logo}`"
        :lazy-src="`${assetsBaseUrl}${adresse.logo}`"
        aspect-ratio="1"
        cover
        class="bg-grey-lighten-2 "
      > <template v-slot:placeholder>
      <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular
          color="grey-lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
    </template></v-img>

          </router-link>
              </template>
              <template v-else>
                 <router-link  :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }"
              class="position-relative d-block">
                   <v-img
        :src="`${assetsBaseUrl}${adresse.logo}`"
        :lazy-src="`${assetsBaseUrl}${adresse.logo}`"
        aspect-ratio="1"
        cover
        class="bg-grey-lighten-2 "
      > <template v-slot:placeholder>
      <div class="d-flex align-center justify-center fill-height">
        <v-progress-circular
          color="grey-lighten-4"
          indeterminate
        ></v-progress-circular>
      </div>
    </template></v-img>
              </router-link>
              </template>
            </div>

            <div class="card-body pt-1 d-i-b col-md-8 container-adrs-inf elipsis">
              <h4 class="fw-700 font-xss mt-0 lh-28 mb-0">
                <router-link :to="{ name: 'feedadresse', params: { id: `${adresse.id}` } }" class="text-dark text-grey-900 adresse-rs">{{adresse.rs}}</router-link>
              </h4>
              <h6 class="font-xsssss text-grey-500 fw-600 mt-0 mb-2">
                {{adresse.adresse}}
              </h6>
              <div class="star d-block w-100 text-left mt-0">
                <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
                <div class="rating-color d-i-b" v-html="drawStarsYellow(adresse.rateRound)"></div>
                <div class="d-i-b rating-grey" v-html="drawStarsGrey(adresse.rateRound)"></div>
                </router-link>
              
              </div>
               <div class="clearfix"></div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
              <router-link :to="{ name: 'avisadresse', params: { id: `${adresse.id}` } }" >
              <div class="couts-color d-i-b" v-html="drawDollarsGreen(adresse.coutsRound)"></div>
              <div class="d-i-b rating-grey" v-html="drawDollarsGrey(adresse.coutsRound)"></div>
              </router-link>
              
              </div>
              <div class="star w-100 d-block text-left mt-0 font-xssss">
                <template v-for="serv in adresse.services">

                  <span v-html="serv.icone"></span>
                  
                </template>
              </div>

            
             
            </div>  
         </div>
         


        </div>
  </template>
        <template v-else>
          <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss
              border-0
              rounded-3
              overflow-hidden
              me-1
            "
          >
            <div class="card-image w-100 mb-3 pt-4 border-0">
              
                 <!-- <Skeleton></Skeleton> -->
             
             <Loader></Loader>
            <div class="card-body pt-0">
            
             
             
              <div class="clearfix"></div>
             
            </div>
            </div>
            
         </div> 
        </template>




 <div class="mt-4 mb-4" v-if="filtredResults.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>

 <Loader v-show="isVisible && !done"/> 
        
      </div>
<!-- **********************************************************************************-->


    </div>
<!-- ************************************(map)*************************************-->
    <!-- <AdressesMap></AdressesMap> -->
    <!-- <div class="col-xl-6 col-md-12 col-sm-12  ps-0 chat-left">
      <div class="card w-00 border-0 shadow-none rounded-3 border-0 mb-4 overflow-hidden ">

                              <GMapMap
                                :options="options"
                                :center="center"
                                :zoom="zoom"
                                ref="myMapRef"
                                map-type-id="terrain"
                                style="width: 100vw; height: 100vh"
                             
                            >

                              <GMapCluster
                              :minimumClusterSize="2" 
                              :zoomOnClick="true"
                              :styles="clusterIcon"
                              >
                               <GMapMarker :key="m.id" v-for="m in adressesMap" :position="m.position" :clickable="true"
                                @click="openMarker(m.id)"
                                   :icon=
                                '{
                                      url: "https://ressources.trippybook.com/assets/images/pin-map.png",
                                      scaledSize: {width: 30, height: 40},
                                      
                                      
                                  }' >
                                  <GMapInfoWindow
                                  :closeclick="true"
                                  @closeclick="openMarker(null)"
                                  :opened="openedMarkerID === m.id"
                                
                              >
                                <div>
                                  <infoAdresse :adresseId="m.id"></infoAdresse>
                                </div>
                              </GMapInfoWindow>
                              </GMapMarker>
                              </GMapCluster> 
                          

                            </GMapMap>

</div>
</div> -->

 <!-- -*************************************************************************** -->
  </div>
</template>
<script>
import MesAdressesActives from "../profil/Adresses/MesAdressesActives.vue";

import ModalAdresse from "../../layouts/modal/modalAdresse.vue";
import Loader from "../../layouts/loader/loader.vue";
import Skeleton from "../../layouts/skeleton/SkeletonAdresse.vue";
import infoAdresse from "../../layouts/infoAdresse.vue";
export default {
  name: "MesAdrresses",
  components: {
    ModalAdresse,
    Loader,
    Skeleton,
    MesAdressesActives,
    infoAdresse,
  },
  data() {
    return {
      //-------------PAGE-------------
      lastPage: 1,
      page: 1,
      isVisible: false,
      done: false,
      //------------

      searchKey: "",
      isShowModal: false,
      isAdressesReady: false,
      adresses: [],
      adressesMap: [],
      keyAdresse: true,
      //map-------------
      clusterIcon: [
        {
          height: 50,
          width: 50,

          url: "https://ressources.trippybook.com/assets/images/cluster2.png",
          anchorIcon: [0, 0],
          anchorText: [8, -10],
          className: "cluster-custom",

          textColor: "#1e74fd",
          textSize: 15,
          textLineHeight: 15,
          fontWeight: "bold",
        },
      ],
      openedMarkerID: null,
      center: { lat: 36.173357, lng: 10.091831 },
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      markers: [
        {
          position: {
            lat: 36.173357,
            lng: 10.091831,
          },
        },
      ],
      zoom: 5,
    };
  },

  mounted() {
    this.getMesAdresses();
  },

  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    filtredResults() {
      return this.adresses.filter(
        (adresse) =>
          adresse.rs.toLowerCase().includes(this.searchKey.toLowerCase())
        // ||
        // adresse.adresse.toLowerCase().includes(this.searchKey.toLowerCase())
      );
    },
  },

  methods: {
    drawStarsYellow(count) {
      return `<i class="fa fa-star rating-color"></i>`.repeat(count);
    },
    drawStarsGrey(count) {
      return `<i class="fa fa-star"></i>`.repeat(5 - count);
    },
    drawDollarsGreen(count) {
      return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count);
    },
    drawDollarsGrey(count) {
      return `<i class="fas fa-dollar-sign"></i>`.repeat(5 - count);
    },
    openMarker(id) {
      this.openedMarkerID = id;
    },
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
    toggleModalClose() {
      this.isShowModal = false;
    },

    refreshAdresse() {
      this.getMesAdresses();
    },
    visibilityChanged(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.page >= this.lastPage) {
        return (this.done = true);
      }
      this.isVisible = isVisible;

      this.page++;
      this.getMesAdresses();
    },

    getMesAdresses() {
      this.keyAdresse = !this.keyAdresse;
      //this.isAdressesReady = false;
      this.axios
        .post(`mesAdresses?page=${this.page}`, {
          searchKey: this.searchKey,
        })
        .then((res) => {
          this.keyAdresse = true;
          //this.adresses = res.data.adresses;

          this.adresses.push(...res.data.adresses.data);
          this.lastPage = res.data.adresses.last_page;
          //console.log(this.adresses);

          this.adressesMap = [];
          res.data.adresses.data.forEach((element, index) => {
            this.adressesMap.push({
              id: element.id,
              position: { lat: element.lat, lng: element.long },
            });
          });
          this.isAdressesReady = true;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-container {
  position: relative !important;
}

.filter {
  height: 265px;
  line-height: 50px;
  width: 100%;
  text-decoration: none;
  position: absolute;
  top: 70px;
  z-index: -1;
  transition: top background-color 0.4s ease-in-out !important;
  margin-bottom: 5px;
  //background-color: rgba(0, 0, 0, 0.9) !important;
  background-color: #ced4dae7 !important;
  margin-left: -25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.filter-open {
  z-index: 1;
  top: 93px;
  opacity: 1;

  transition: top 0.4s ease-in-out !important;
}

.filter-close {
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
}

.active-filter {
  color: #124698 !important;
  background-color: #d2e3ff !important;
  border-color: #2e58a6 !important;
}

.btn-round-md {
  &:hover {
    background-color: #d2e3ff !important;
    transition: all 0.4s ease-in-out;
  }
}

.d-i-b {
  display: inline-block !important;
}

.card-image {
  overflow: visible !important;
  border: 1px solid black;
}

.chat-left {
  height: calc(100vh - 125px) !important;
}

//395
select.sort {
  border-radius: 7px !important;
  color: #000 !important;
  width: fit-content !important;
}

.form-control {
  line-height: 38px !important;
  height: 38px !important;
}

.box-minmax {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #000;

  span:first-child {
    margin-left: 10px;
  }
}

.range-slider {}

.rs-range {
  width: 100%;
  height: 3px;
  color: #000;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }

  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 15px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }

  &::-moz-focus-outer {
    border: 0;
  }
}

.rs-label {
  top: 50px;
  transform-origin: center center;
  display: block;
  width: 90px;
  height: 35px;
  background: transparent;
  border-radius: 50%;
  line-height: 20px;
  // text-align: center;
  font-weight: bold;
  //padding-top: 22px;
  box-sizing: border-box;
  //border: 2px solid #ddd;
  //margin-top: -20px;
  margin-left: 0px;
  left: attr(value);
  color: #000;
  font-style: normal;
  font-weight: 20px;
  line-height: normal;
  font-size: 12px;
}

.form-group,
.form-control {
  opacity: 1 !important;
  color: #000;
}

.form-group {
  margin-left: 3px;
}

.toggle input {
  background-color: #d2e3ff;
}

.toggle input[type="checkbox"]:checked+.toggle-icon {
  background: #2e58a6;
  margin-left: 3px;
}

.icon-tab {
  display: inline-block;
}

// .vue-map-container {
//   height: 540px !important;
// }
.w-img {
  width: 50% !important;
  height: 50% !important;
  vertical-align: bottom !important;
  border-radius: 10px;
  //text-align:center;
  //left:20%;
}

.scroll-bar {
  // margin-left: -55px;
  overflow-x: hidden;
}

.filter-control {
  visibility: hidden !important;
  position: absolute !important;
}

.you-are-here {
  border: 1px solid black;
  height: 100px;
  width: 100px;
}

.text-show-filter {
  padding: 5px;
}

.show-filter {
  background-color: #2e58a6;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
  transition: background-color 0.4s ease-in-out;

  &:hover .span-fa-filter {
    background-color: #000;
  }

  &:hover {
    background-color: #555;
  }

  .filter-on-icon {
    background-color: #000;
  }

  .filter-on-text {
    background-color: #555 !important;
  }
}

.span-fa-filter {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 8px;
  transition: background-color 0.4s ease-in-out;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.fa-times {
  padding: 3px;
}

.cluster-custom {
  width: 30px !important;
  height: 30px !important;
  background-color: red !important;
}

.gm-style-iw-c {
  padding: 0 !important;
}

.container-adrs-inf {
  padding: 0rem 1rem 0.5rem !important;
}

.rating-color {
  color: #fbc634 !important;
}

.rating-grey {
  color: #d2e3ff !important;
}

.couts-color {
  color: rgb(16, 92, 16);
}

.btn-remove-fav {
  &:hover {
    cursor: pointer;

    //color:#999 !important
  }

  transition: all 0.4s ease-in-out;
  z-index: 5;
  color: #f00;
}

.btn-add-fav {
  &:hover {
    cursor: pointer;
    // color:#f00  !important;
  }

  transition: all 0.4s ease-in-out;
  z-index: 5;

  color: #999 !important;
}

.btn-toggle-fav {
  right: -10px !important;
  top: -10px !important;
}

.elipsis {

  width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.toggle-map-result {
  display: none;
}

@media (max-width: 768px) {

  .elipsis {

    width: 280px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }

  //   .vue-map-container{
  //   height: 635px !important;
  // }
  .main-content {
    height: 100% !important;

  }

  .filter-resultat-hide {
    visibility: hidden;
    height: 0px;
    position: absolute !important;
    //  top: 0
  }


  .scroll-bar {
    margin-left: -15px;
  }

  .filter-resultat {
    order: 1;


  }


  // .chat-left {
  //   height: 100% !important;

  // }

  .row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
</style>