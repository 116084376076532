<template>
 <portal to="modal">
    <transition name="fade">
      <div
        class="modall"
      >
      <div class="wrapper col-md-6 col-sm-12">
        <div class="modal-container">
          <div
            @click.self.stop.prevent="handleClose"
            class="absolute inset-0 bg-gray-500 opacity-75"
          ></div>
        </div>

        <div
          class="z-10 bg-white rounded-modal overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="bg-white px-4 pt-2 pb-2 sm:p-5 sm:pb-5">
            <div class="sm:flex sm:items-start">
              <div class="w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                <div class="flex items-center justify-between">
                  <h3
                    class="pt-2 text-lg modal-header-txt text-gray-900 fw-600"
                    id="modal-headline"
                  >Change cover photo</h3>
                 
              
                  <i class="feather-x-square pt-1" @click.prevent="handleClose">
                  
                  </i>
                  <hr>
                </div>
                <div class="" :class="{ 'scrollable': scrollable }">
                    
                                    
                  <div class=" w-100 rounded-xxl border-0 ps-4 pe-4 mb-3">

                                 
                                
                               
                               
                           <!-- <input id="userId" type="hidden" :value="userId"> -->
                            <!-- <input type="hidden" id="currentUserId" :value="currentUser.id">  -->

                            <div ref="drop" method="POST" enctype="multipart/form-data" id="myAwesomeDropzone" class="dropzone">
                                <div class="fallback">

                                    <input name="file" type="file" multiple />


                                </div>
                           </div>
                  
     </div>
 
                  <slot />
                </div>
              </div>
            </div>
          </div>
          <div class="card-footer bg-gray-50 px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse container-publish" :class="isUploading ? 'loader':''">
            <slot name="footer"></slot>
            <span class="mt-0 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto spanBtn">
                 <button v-if="!isUploading" id="btnAddPub" @click="sending"
            type="button"
            class="btnPub inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
          >Update</button>
       <template class="" v-else> <Loader></Loader> </template>
           
              
               
            </span>
          </div>
        </div>
      </div>
      </div>
    </transition>
  </portal>
</template>

<script>
// import { DropZone } from 'dropzone-vue';
import {Dropzone} from "dropzone";
import Loader from '../../layouts/loader/loaderImage.vue';

    
export default {
  
  name: "BaseModal",
  components:{
    Loader
  },
 

  data(){
     return{
       textPub: '',
       token: '',
       test:'firas',
       myDropzone:'',
       isUploading:false,

       
       
     }
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
    userId:Number,
  },
  created() {
    document.addEventListener("keyup", this.onClose);
     
   this.token = localStorage.getItem('token');
  
  },
  beforeMount(){

  },

  updated(){
  
  },

  mounted(){

  setTimeout(()=> { this.dropInit(),500});
 
  
   
 
  },


  

  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {
    dropInit(){
          //setTimeout(() => {,1000})
    Dropzone.autoDiscover = false;
    Dropzone.options.myAwesomeDropzone = {
    paramName: "file", // The name that will be used to transfer the file
    maxFilesize: 25, // MB
     url: `${this.apiBaseUrl}updateCoverUser`,
    addRemoveLinks: true,
    uploadMultiple: false,
    withCredentials:true,
    headers:{'Authorization': 'Bearer '+ localStorage.getItem('token')},
    autoProcessQueue: false,
    maxFiles: 1,
    parallelUploads: 1,
    acceptedFiles: 'image/*',
    dictRemoveFile: 'Supprimer',
    dictCancelUploadConfirmation:"Voulez-vous vraiment annuler ce téléchargement ?",
    dictDefaultMessage: "Drop your photo here to upload",
    dictCancelUpload:"Annuler",
    dictInvalidFileType: 'Ce formulaire n\'accepte que les images et les videos.',
    dictFileTooBig: `la taille du fichier est trop grande. {{filesize}} le max accepté est {{maxFilesize}} `



};

var myDropzone = new Dropzone("#myAwesomeDropzone", {
    parallelUploads: 22,
    init: () =>  {
       var myDropzone = Dropzone.forElement("#myAwesomeDropzone");

        
        document.getElementById("btnAddPub").addEventListener("click",  (e) => {
           
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
        });

        myDropzone.on('sending',  (data, xhr, formData) => {

            this.isUploading = true;

            formData.append("userId", this.$route.params.id);
           

        });
         myDropzone.on('success', (data, xhr, formData) => {
              
            this.handleRefresh();
             this.handleClose();
              //this.$router.go();
            
            
          

            });

    }
});

    },
    handleClose() {
      this.$emit("close");
      
    },
     handleRefresh() {
      this.$emit("refreshCover");
      
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
     
  },
      computed: {
        currentUser() {
            return this.$store.state.currentUser;
        },
         assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
    

    },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/dropzone.min.css";
.btnPub{
  transition: all .4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color:#fff;
  
  &:hover{
background-color: #1259cc !important;



  }
}
.spanBtn{
  display: inline-block;
  width: 100%;


}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modall{
   position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
 
}
.wrapper{
  //width: 40%;
  margin: 0 auto;
  border-radius: 10px;

}
.modal-container {

  margin: 30px 50px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
     overflow: auto;
}
.modal-header-txt{
  display: inline-block;
}
.feather-x-square{
  &:hover{
    cursor:pointer;
    color:red;
    transition: all .4s ease-in-out;
  }
  float:right;
  font-size: 32px;
}
.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
  
  
  
}
.loader{
  height:50px !important
}
.rounded-modal{
  border-radius:10px
}
@media screen and (max-width: 989px) {
.modal-container {

  margin: 0px 10px;
  padding: 0px 20px;
  
}

}

@media (max-width: 767px) {

  .bg-greylight{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
 .card-m{
   padding-left:0px !important;
    padding-right:0px !important;

   
 }
    .modal-container {
    margin: 0px 10px !important;
    
  }
   .wrapper {
  margin: 0 auto;
  height: 100% !important;
  }
  .rounded-modal {
    border-radius: 0px;
    width:100%;
    height: 100% !important;
  }
  .card-footer{
    position: relative;
    bottom: 0;
    border-top:0 ;
    
  }
  .container-publish{
    background: none;
    // position: absolute;
    // bottom: 10px;
    // width: 100%;
  }
  .card-mm{
       padding-left:0px !important;
    padding-right:0px !important;
  }
}
.dropzone{ 
  border-radius: 15px;
  background-color: #eff3fa;
}
</style>