<template lang="">
<div v-if="!isAdressessReady">

  <Loader></Loader>
</div>  

<div v-else>

            <template v-if="searchResults.publications.data.length"> 
              <div  class="pt-2 border-top-xs">
              <h2>Posts ({{searchResults.adresses.total}}) : </h2>
              <div class="chat-left scroll-bar pt-2 w-100">
              <div class="row">
              <div v-for="(pub,indexx) in searchResults.publications.data"  class="col-md-6 pe-2 ps-2">
               
             <div
            class="
              card
              w-100
              p-0
              hover-card
              shadow-xss 
              rounded-3
              overflow-hidden
              me-1
              col-lg-3
              d-i-b
              elipsis
            "
          >
         
            <div class=" mb-1 mt-1 d-i-b col-md-4 border-0">
              
              
              <div class="position-relative d-block">
              <template v-if="pub.medias[0].type == 1">
              <router-link :to="`/showPost/${pub.id}/${pub.medias[0].id}`">
              <img 
                  :src="`${assetsBaseUrl}${pub.medias[0].src}`"
                  alt="image"
                  class="w-img  ps-1 img-pub"
                />
              </router-link>
              

              </template>
              <template v-else>
                
                <router-link :to="`/showPost/${pub.id}/${pub.medias[0].id}`">
                 <img 
                  :src="`${assetsBaseUrl}images/videothumbnail.png`"
                  alt="image"
                  class="w-img  ps-1 img-pub"
                />
                </router-link>
              </template>
                
                </div>
              
              
             
              
            </div>
             
            <div class="card-body pt-1 d-i-b col-md-8 container-adrs-inf container-pub-inf">
              <h4 class="fw-700 font-xss mt-0 lh-28 mb-0 elipsis adrs-pub">
                <router-link :to="{ name: 'feedadresse', params: { id: `${pub.adresse.id}` } }" class="text-dark text-grey-900 elipsis">{{pub.adresse.rs}}</router-link>
              </h4>
              
              <h6 class="font-xssss text-grey-500 fw-600 mt-0 mb-0 elipsis">
                {{pub.text_pub}}
              </h6>
            </div> 
             
             
             </div> 
            </div>
            </div>  

            <div v-if="done"
                    class="
                        card
                        w-100
                        text-center
                        shadow-xss
                        rounded-xxl
                        border-0
                        p-2
                        mb-1
                        mt-1
                        ms-3
                        centerr-flex
                    "
                >
           No other posts
    </div>
    <div>
      <Loader v-show="isVisible && !done"/> 
      </div>
        <div v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>
               </div>  
               </div>    
             </template>
             <template v-else>
              
              No result found
           

             </template>
        
  </div>     
</template>
<script>
import { useToast } from 'vue-toastification';
const toast = useToast()
import Loader from "../../layouts/loader/loader.vue"
export default {
  components:{
    Loader
  },
    data(){
        return{
          NavBarSearchKey:'',   
          isFollowed:false,
          toggleFavRef:[],          
          btntoggleFollow:[],
          spantoggleFollow:[],
          adresses:[],
          page:1,
          lastPage:1,
          done:false,
          isVisible: false,
          totalResults:0,
          isAdressessReady:false,
        
        }

  },
      destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
    mounted(){
      this.getSearchResultsPage();
        

    },
    beforeUpdate(){
      this.NavBarSearchKey = this.$store.state.NavBarSearchKey;
      
    },
   
    methods:{
        getSearchResultsPage(){
        this.axios.post(`searchNavBarPub?page=${this.page}`, {
                searchFilter: this.$store.state.NavBarSearchKey             
                }).then(res=>{
                   this.adresses.push(...res.data.publications.data);  
                    this.lastPage = res.data.publications.last_page;
                    this.totalResults = res.data.publications.total;
                    this.isAdressessReady = true;
                    

                })
      },
      
       visibilityChanged (isVisible) {
            
        if(!isVisible){ return }
        if(this.page >= this.lastPage){return this.done = true}
        
         this.toggleFavRef = [];
         this.spantoggleFollow = [];
         this.btntoggleFollow = [];
          this.isVisible = isVisible;
    
            this.page++;
            this.getSearchResultsPage();  
      },
     

      
      //Fav-------------------------------------------------------
      setSpantoggleFollow(el){
           if (el && !this.spantoggleFollow.includes(el)) {
          this.spantoggleFollow.push(el);
        }
      },
      setToggleFavRef(el){
        if (el && !this.toggleFavRef.includes(el)) {
          this.toggleFavRef.push(el);
        }
      },
       setFriendRef(el){
         
        if (el && !this.addFriendRef.includes(el)) {
          this.addFriendRef.push(el);
        }
      },
      setBtntoggleFollow(el){
        if (el && !this.btntoggleFollow.includes(el)) {
          this.btntoggleFollow.push(el);
        }

      },
      
    
  
    toggleFavorite(id,i){
                   console.log(this.toggleFavRef[i]);
      
      if(this.toggleFavRef[i].firstElementChild.classList.contains("btn-remove-fav")){
        this.toggleFavRef[i].firstElementChild.classList.remove('btn-remove-fav');
        this.toggleFavRef[i].firstElementChild.classList.add('btn-add-fav');

        this.removeFavorite(id);

      }else{
        this.toggleFavRef[i].firstElementChild.classList.remove('btn-add-fav');
        this.toggleFavRef[i].firstElementChild.classList.add('btn-remove-fav');

        this.addFavorite(id);

      }

    },

    removeFavorite(id){
      this.axios.post(`favoris/delete`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,     
      }).then(res=> {
       
        
        
      });
    },
     addFavorite(id){    
      this.axios.post(`/favoris/add`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
         
        
        
      });

    },
      //friend-------------------------------------------------
    
    
    //Adresse--------------------------------------------------------------------
    toggleFollow(id,i){

       if( this.btntoggleFollow[i].classList.contains('btn-follow')){
         this.btntoggleFollow[i].classList.remove('btn-follow');
         this.btntoggleFollow[i].classList.add('btn-unfollow');
          this.btntoggleFollow[i].firstElementChild.classList.remove('fa-user-plus');
         this.btntoggleFollow[i].firstElementChild.classList.add('fa-user-times');
          this.spantoggleFollow[i].textContent ="Unfollow";
         
       

          this.axios.post(`/follow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
        

      
        
      });

       }else{
         
         this.spantoggleFollow[i].textContent = "Follow";
         this.btntoggleFollow[i].classList.remove('btn-unfollow');
        this.btntoggleFollow[i].classList.add('btn-follow');
         this.btntoggleFollow[i].firstElementChild.classList.add('fa-user-plus');
         this.btntoggleFollow[i].firstElementChild.classList.remove('fa-user-times');
         
         this.axios.post(`/unfollow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:id,
        
      }).then(res=> {
       // this.getFollowersList();
        
      });

       }

     

    },

      follow(){
      this.isFollowerListReady = false;
       this.isFollowed = true;
      this.axios.post(`/follow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:this.adresseId,
        
      }).then(res=> {
        //this.getFollowersList();

      
        
      });
    },

     unfollow(){
       this.isFollowerListReady = false;
       this.isFollowed = false;
      this.axios.post(`/unfollow`,
      {
        visiteurId:this.currentUser.id,
        adresseId:this.adresseId,
        
      }).then(res=> {
       // this.getFollowersList();
        
      });
    },
    //--------------------------------------------------------------------------------
     drawStarsYellow(count){
          return `<i class="fa fa-star rating-color"></i>`.repeat(count)
      },
    drawStarsGrey(count){
          return `<i class="fa fa-star"></i>`.repeat(5-count)
      },
    drawDollarsGreen(count){
          return `<i class="fas fa-dollar-sign dollars"></i>`.repeat(count)
      },
    drawDollarsGrey(count){
          return `<i class="fas fa-dollar-sign"></i>`.repeat(5-count)
      },
    //-------------------------------------------------------------------------------
      getSearchResults(){

        // this.adresses.push(...res.data.adresses.data);
        // this.lastPage = res.data.adresses.last_page;
         this.$store.dispatch('getSearchResult' , 
        {NavBarSearchKey:this.NavBarSearchKey,
        pageAdresse:this.pageAdresse,
        pagePeople:this.pagePeople});
        this.$router.push("/searchResultAll");
      },
    },
     computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
     currentUser() {
            return this.$store.state.currentUser;
        },
    searchResults() {
            return this.$store.state.searchResults;
        },
         isReady() {
            return this.$store.state.searchResultsIsReady;
        },
       
       
  },
};
</script>
<style lang="scss" scoped>

.container-adrs-inf{
  padding: 0rem 0rem 0.5rem 2rem !important;
  position: absolute;
top:5px;
}
.centerr-flex{
  justify-content: center;
}
.card{
  flex-direction: row !important
}
.rounded-circle{
 border-radius:10px !important
}
.btn-unfollow, .btn-follow{
  
  bottom:0px !important;
  right:0px !important;
  // background-color: #2e58a6 !important;
  color:#000 !important;
  // font-size:16px !important
}
.btn-unfollow {
  border-radius: 5px;
  &:hover{
      cursor:pointer;
     box-shadow: 1px 1px 2px #8888884d inset ;
     
     

  }
}
.btn-follow{
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color:#fff !important;
   &:hover{
      cursor:pointer;
     box-shadow: 1px 1px 2px #222 inset ;
     
     

  }
}
.top-0 {
    top: -100px !important;
}
.rounded-3{
  border-radius: 5px !important;
  border-right: #fff 2px solid ;
}
.btn-round-sm, .btn-add , .btn-accept, .btn-decline{
  border-radius:5px !important;
  transition: background-color .4s ease-in-out;
  &:hover{
    cursor:pointer;
    
    color:#fff !important;
  }
}
.btn-add{
  &:hover{
background-color: #2e58a6 !important;
  }
}

.btn-accept{
  &:hover{
background-color: rgb(43, 110, 43) !important;
  }
}
.see-all-btn{
  &:hover{
    cursor:pointer;
    text-decoration:underline !important;
  }
}
.ms-6 {
    margin-left: 5rem !important;
}

.rating-color {
    color: #fbc634 !important
}
.rating-grey{
  color:#d2e3ff !important
}
.couts-color{
  color:rgb(16, 92, 16)
}
 .btn-remove-fav{
   &:hover{
     cursor:pointer;

     //color:#bbb !important
   }
  
   transition: all .4s ease-in-out;
   z-index:5;
   color:#f00;
  
 }

  .btn-add-fav{
   &:hover{
     cursor:pointer;
     color:#f00  !important;

     
   }
  
   transition: all .4s ease-in-out;
   z-index:5;
   
   color:#bbb !important;
   
 }
 .btn-toggle-fav{
   left:-15px !important;
   top:-9px !important;
 }
 .w-img{
  width:100% !important;
  height:90px !important;
  vertical-align:bottom !important;
  border-radius: 10px
  //text-align:center;
  //left:20%;
}
.elipsis{

   width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
// .btn-followw{
  // bottom:0px !important;
  // right:0px !important
// }
.is-friend{
    bottom:-8px !important;
  right:-132px !important;
  background: #fff !important;
  border-radius:5px;
}
.d-i-b{
  display:inline-block !important
}
.scroll-bar{
   overflow-x: hidden;
 }
 .w-100{
   width : 100% !important
 }    

 @media (max-width: 767px) {

  .container-pub-inf{
padding: 0rem 0rem 0.5rem 1rem !important;
position: absolute;
top:5px;

}
.adrs-pub{
  width: 250px !important;

}
.elipsis{

   width: 135px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}
}
.media-fit{
  object-fit: cover !important;
}
.img-pub{
  width: 120px !important;
  height: 100px !important;
}

</style>