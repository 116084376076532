<template>
<div class="row mtm">

    <div class="col-xl-12 col-md-12 col-sm-12 ps-0 chat-left" style="height:300px">
      <div ref="Gmap"
        class="
          card
          w-00
          border-0
          shadow-none
          rounded-3
          border-0
          mb-4
          overflow-hidden
          position-relative
          g-map-m
        "
      >
        <GMapMap
          :options="options"
          :center="{lat: currentAdress.lat , lng: currentAdress.long}"
          :zoom="zoom"
          ref="myMapRef"
          map-type-id="terrain"
          style="height: 90vh%"
        >

         

          <GMapCluster
            :minimumClusterSize="2"
            :zoomOnClick="true"
            :styles="clusterIcon"
          >
            <GMapMarker
              :key="m.id"
              v-for="m in markers"
              :position="m.position"
              :clickable="true"
              @click="openMarker(m.id)"
              :icon="{
                url: 'https://ressources.trippybook.com/assets/images/pin-map.png',
                scaledSize: { width: 30, height: 40 },
              }"
            >
              <GMapInfoWindow
                :closeclick="true"
                @closeclick="openMarker(null)"
                :opened="openedMarkerID === m.id"
              >
                 
                  <infoAdresse :adresseId="currentAdress.id"></infoAdresse>
                 
              </GMapInfoWindow>
            </GMapMarker>
          </GMapCluster>
        </GMapMap>
      </div>
    </div>
    
  </div>
</template>
<script>
import Loader from "../../layouts/loader/loaderLogin.vue";
import Skeleton from "../../layouts/skeleton/SkeletonAdresse.vue";
import Skeletonn from "../../layouts/skeleton/SkeletonComment.vue";
import infoAdresse from "../../layouts/infoAdresse.vue";
import FilterAprox from "../../layouts/filterAdresse.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    Loader,
    Skeletonn,
    Skeleton,
    infoAdresse,

    FilterAprox,
  },
  emits: ["dragCircle", "drag", "dragend"],
  props: ['currentAdress'],

  data() {
    return {
      isMapOn: true,
      toggleFavRef: [],
      filterOn: false,
      isPaysSelectReady: false,
      isVillesSelectReady: false,
      readyCheck: false,
      searchFilter: "",
      latCercleMoved: 0,
      lngCercleMoved: 0,
      isFilterOn: false,
      isTeleportOn: false,
      paysLat: 0,
      paysLng: 0,
      villeLat: 0,
      villeLng: 0,
      contLat: 0,
      contLng: 0,
      latMin: 0,
      latMax: 0,
      lngMin: 0,
      lngMax: 0,
      latCercle: 0,
      lngCercle: 0,

      evalsID: null,

      coutsID: null,
      valueCat: null,
      isAprox: true,
      range: 20,
      circleOptions: {
        strokeColor: "#124698 ",
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: "#124698 ",
        fillOpacity: 0.1,
        zIndex: 99,
      },

      circleOptionsAprox: {
        strokeColor: "#f00 ",
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: "#f00 ",
        fillOpacity: 0.04,
      },
      clusterIcon: [
        {
          height: 50,
          width: 50,

          url: "https://ressources.trippybook.com/assets/images/cluster2.png",
          anchorIcon: [0, 0],
          anchorText: [8, -10],
          className: "cluster-custom",

          textColor: "#2e58a6",
          textSize: 15,
          textLineHeight: 15,
          fontWeight: "bold",
        },
      ],

      adresses: [],
      isAdressesReady: false,
      isCategorieReady: false,
      isContinentReady: false,
      isPaysReady: false,
      isVillesReady: false,
      continents: [],
      categories: [],
      pays: [],
      villes: [],
      catID: [],
      continentID: null,
      paysID: "",
      villeID: "",
      lat: 0,
      long: 0,
      zoom: 15,
      keyAdresse: false,
      centerCircle: { lat: 36, lng: 10 },

      //map-----------------------
      openedMarkerID: null,
      center: { lat: 36.173357, lng: 10.091831 },
      options: {
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: true,
        fullscreenControl: false,
      },
      markers: [
        {
          position: {
            lat: 36.173357,
            lng: 10.091831,
          },
        },
      ],

      testBounds: [
        {
          position: {
            lat: 34,
            lng: 10,
          },
        },
      ],
      adressesMap: [],
    };
  },
 
  computed: {
    
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    
  },
   destroyed() {
    const controller = new AbortController();
     controller.abort()
  },

  mounted() {
    this.markers[0].position.lat = this.currentAdress.lat;
    this.markers[0].position.lng = this.currentAdress.long;
    this.center.lat = this.currentAdress.lat;
    this.center.lng = this.currentAdress.long;

  },

  methods: {
   


    openMarker(id) {
      this.openedMarkerID = id;
    },
 
 
    openInfoWindow(id) {
      alert(IDBKeyRange);
    },
   
  },
};
</script>

<style lang="scss" scoped>

.vue-map{
  width: 45vw !important
}
.filter-container {
  position: relative !important;
}

.filter {
  height: 265px;
  line-height: 50px;
  width: 100%;
  text-decoration: none;
  position: absolute;
  top: 70px;
  z-index: -1;
  transition: top background-color 0.4s ease-in-out !important;
  margin-bottom: 5px;
  //background-color: rgba(0, 0, 0, 0.9) !important;
  background-color: #ced4dae7 !important;
  margin-left: -25px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.filter-open {
  z-index: 1;
  top: 93px;
  opacity: 1;

  transition: top 0.4s ease-in-out !important;
}
.filter-close {
  z-index: -1;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0);
}
.active-filter {
  color: #124698 !important;
  background-color: #d2e3ff !important;
  border-color: #2e58a6 !important;
}
.btn-round-md {
  &:hover {
    background-color: #d2e3ff !important;
    transition: all 0.4s ease-in-out;
  }
}
.d-i-b {
  display: inline-block !important;
}
.card-image {
  overflow: visible !important;
  border: 1px solid black;
}

//395
select.sort {
  border-radius: 7px !important;
  color: #000 !important;
  width: fit-content !important;
}
.form-control {
  line-height: 38px !important;
  height: 38px !important;
}
.box-minmax {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  color: #000;
  span:first-child {
    margin-left: 10px;
  }
}
.range-slider {
}
.rs-range {
  width: 100%;
  height: 3px;
  color: #000;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }
  &::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: none;
    background: #ffffff;
    border-radius: 0px;
    border: 0px solid #010101;
  }

  &::-webkit-slider-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 42px;
    width: 22px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }
  &::-moz-range-thumb {
    box-shadow: none;
    border: 0px solid #ffffff;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.25);
    height: 30px;
    width: 15px;
    border-radius: 22px;
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -20px;
  }
  &::-moz-focus-outer {
    border: 0;
  }
}
.rs-label {
  top: 50px;
  transform-origin: center center;
  display: block;
  width: 90px;
  height: 35px;
  background: transparent;
  border-radius: 50%;
  line-height: 20px;
  // text-align: center;
  font-weight: bold;
  //padding-top: 22px;
  box-sizing: border-box;
  //border: 2px solid #ddd;
  //margin-top: -20px;
  margin-left: 0px;
  left: attr(value);
  color: #000;
  font-style: normal;
  font-weight: 20px;
  line-height: normal;
  font-size: 12px;
}
.form-group,
.form-control {
  opacity: 1 !important;
  color: #000;
}
.form-group {
  margin-left: 3px;
}

.toggle input {
  background-color: #d2e3ff;
}
.toggle input[type="checkbox"]:checked + .toggle-icon {
  background: #2e58a6;
  margin-left: 3px;
}
.icon-tab {
  display: inline-block;
}
.vue-map-container {
  height: 450px !important;
}
.w-img {
  width: 100% !important;
  height: 90px !important;
  vertical-align: bottom !important;
  border-radius: 10px;
  //text-align:center;
  //left:20%;
}
.scroll-bar {
  margin-left: -55px;
  overflow-x: hidden;
}
.filter-control {
  visibility: hidden !important;
  position: absolute !important;
}
.you-are-here {
  border: 1px solid black;
  height: 100px;
  width: 100px;
}

.text-show-filter {
  padding: 5px;
}
.show-filter {
  background-color: #2e58a6;
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 10px;
  color: white;
  border: 0;
  border-radius: 5px;
  padding: 5px 0;
  transition: background-color 0.4s ease-in-out;
  &:hover .span-fa-filter {
    background-color: #000;
  }
  &:hover {
    background-color: #555;
  }
  .filter-on-icon {
    background-color: #000;
  }
  .filter-on-text {
    background-color: #555 !important;
  }
}
.span-fa-filter {
  background-color: rgba(0, 0, 0, 0.2);
  padding: 8px;
  transition: background-color 0.4s ease-in-out;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.fa-times {
  padding: 3px;
}
.cluster-custom {
  width: 30px !important;
  height: 30px !important;
  background-color: red !important;
}
.gm-style-iw-c {
  padding: 0 !important;
}
.container-adrs-inf {
  padding: 0rem 1rem 0.5rem !important;
}
.rating-color {
  color: #fbc634 !important;
}
.rating-grey {
  color: #d2e3ff !important;
}
.couts-color {
  color: rgb(16, 92, 16);
}
.btn-remove-fav {
  &:hover {
    cursor: pointer;

    //color:#999 !important
  }

  transition: all 0.4s ease-in-out;
  z-index: 5;
  color: #f00;
}

.btn-add-fav {
  &:hover {
    cursor: pointer;
    // color:#f00  !important;
  }

  transition: all 0.4s ease-in-out;
  z-index: 5;

  color: #999 !important;
}
.btn-toggle-fav {
  right: -10px !important;
  top: -10px !important;
}
.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.toggle-map-result{
  display: none;
}
@media (max-width: 768px) {

  .vue-map{
  width: 100vw !important
}
  .vue-map-container{
  height: 635px !important;
}
  .main-content {
    height: 100% !important;
    
  }
  .filter-resultat-hide{
   visibility: hidden;
   height:0px;
   position:absolute !important;
  //  top: 0
  }
  
  .toggle-map-result{
    display: block;
    z-index: 9999;
    position: fixed;
    bottom: 1px;
    background-color: #2e58a6;
    color: #fff;
    border-radius: 0rem !important;

  }
  .scroll-bar {
    margin-left: -15px;
  }
  .filter-resultat {
    order: 1;
    

  }
  .g-map-hide-m{
    visibility: hidden !important;
    position: absolute !important;
  }
  .g-map-m {
    height: 100%;
    
  }
  .chat-left {
    height: 250px !important;
    
  }
 
  .row > * {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}


@media (max-width: 768px) {
  .chat-left{
    height: 100% !important;
  }
}

</style>
