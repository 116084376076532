<template lang="">
    <div class="middle-sidebar-left">
            <div class="middle-wrap">
                <div class="card w-100 border-0 bg-white shadow-xs p-0 mb-4">
                    <div class="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
                        <router-link to="/parametres" class="d-inline-block mt-2"><i class="ti-arrow-left font-sm text-white"></i></router-link>
                        <h4 class="font-xs text-white fw-600 ms-4 mb-0 mt-2">Change password</h4>
                    </div>
                    <div class="card-body p-lg-5 p-4 w-100 border-0">
                    <form @submit.prevent="updatePassword">
                             <div class="row">
                                <div class="col-lg-12 mb-3">
                                    <div class="form-gorup">
                                        <label class="mont-font fw-600 font-12" for="current_password">Current password
                                        <span class="text-danger">*</span></label>
                                        <input @keyup="removeAlert" :class="errors.current_password ? 'is-invalid':''" type="password" name="current_password" v-model="currentPassword" class="form-control">
                                    </div>
                                     <div v-if="errors.current_password" class="alert alert-danger alert-dismissible" role="alert">
                                    {{errors.current_password[0]}}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>        
                                </div>

                                <div class="col-lg-12 mb-3">
                                    <div class="form-gorup">
                                        <label class="mont-font fw-600 font-12" for="new_paswword">New  password<span class="text-danger">*</span></label>
                                        <input @keyup="removeAlert" :class="errors.new_paswword ? 'is-invalid':''" type="password" name="new_paswword" 
                                        v-model="newPaswword" class="form-control">
                                    </div> 
                                    <div v-if="errors.new_paswword" class="alert alert-danger alert-dismissible" role="alert">
                                    {{errors.new_paswword[0]}}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>        
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-lg-12 mb-3">
                                    <div class="form-gorup">
                                        <label class="mont-font fw-600 font-12" for="confirm_password">Confirm password
                                        <span class="text-danger">*</span></label>
                                        <input @keyup="removeAlert" :class="errors.confirm_password ? 'is-invalid':''" type="password" name="confirm_password" v-model="confirmPassword" class="form-control">
                                    </div> 
                                     <div v-if="errors.confirm_password" class="alert alert-danger alert-dismissible" role="alert">
                                    {{errors.confirm_password[0]}}
                                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>  
                                           
                                </div>                                     
                            </div>
                            <div class="row">
                                <div class="col-lg-12 mb-0">
                                    <input type="submit" value="Submit" class="bg-current text-center text-white font-xsss fw-600 p-3 w175 rounded-3 d-inline-block float-right">
                                </div>
                            </div>

                             
                            </form>
                    </div>
                </div>
                <!-- <div class="card w-100 border-0 p-2"></div> -->
            </div>
        </div>
</template>
<script>
import { useToast } from 'vue-toastification';
const toast = useToast();
export default {
  name: "MotDePasse",
  data(){
      return {
          currentPassword:"",
          newPaswword:"",
          confirmPassword:"",
          errors:{}

      }
  },
  methods:{
      removeAlert(e){
          e.target.classList.remove('is-invalid');

      },
      updatePassword(){
          this.axios.post('/updatePassword',{
              current_password:this.currentPassword,
              new_paswword:this.newPaswword,
              confirm_password:this.confirmPassword

          }).then(res=>{
              console.log(res.data);
                toast.success("Updated successfully ", {
                position: "top-right",
                timeout: 5000,
                closeOnClick: true,
                pauseOnFocusLoss: true,
                pauseOnHover: true,
                draggable: true,
                draggablePercent: 0.6,
                showCloseButtonOnHover: false,
                hideProgressBar: true,
                closeButton: "button",
                icon: true,
                rtl: false
                });
          }).catch(error=>{
                console.log(error.response.data.errorsValidation);
                this.errors=error.response.data.errorsValidation;

               
            
          })
          
      },
    },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted(){

  },
};
</script>
<style lang="scss" scoped>
.bg-current {
  background-color: #2e58a6 !important;
}
.alert{
    margin-top:2px !important;
    margin-bottom: 0px  !important;
    padding: 0.25rem 1rem;
}
.alert-dismissible .btn-close{
    padding:0.5rem 1rem !important
}
</style>