<template lang="">
        <div class="col-xl-12 col-xxl-8 col-lg-12 pe-0">
                            <div v-if="isReady" class="card w-100 shadow-xss rounded-xxl border-0 mb-3">
                                <div class="card-body d-block p-4">
                                    <h4 class="fw-700 mb-3 font-xsss text-grey-900">About</h4>
                                    <!-- <p class="fw-500 text-grey-500 lh-24 font-xssss mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi nulla dolo </p> -->
                                </div>
                                <div v-if="user.facebook" class="card-body border-top-xs d-flex">
                                    <i class="feather-facebook text-grey-500 me-3 font-lg"></i>
                                    <h4 class="fw-700 text-grey-900 font-xssss mt-0">Facebook <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{user.facebook}}</span></h4>
                                </div>

                                <div v-if="user.instagram" class="card-body d-flex pt-0">
                                    <i class="feather-instagram text-grey-500 me-3 font-lg"></i>
                                    <h4 class="fw-700 text-grey-900 font-xssss mt-0">Instagram <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{user.instagram}}</span></h4>
                                </div>
                                <div v-if="user.adresse" class="card-body d-flex pt-0">
                                    <i class="feather-map-pin text-grey-500 me-3 font-lg"></i>
                                    <h4 class="fw-700 text-grey-900 font-xssss mt-1">{{user.adresse}} </h4>
                                </div>
                                <div v-if="user.tel" class="card-body d-flex pt-0">
                                    <i class="feather-phone text-grey-500 me-3 font-lg"></i>
                                    <h4 class="fw-700 text-grey-900 font-xssss mt-1">{{user.tel}}</h4>
                                </div>
                            </div>
                         
                        <div v-else>
                          <Loader></Loader>
                        </div>
                        </div>
                        

</template>
<script>
import Loader from "../../../layouts/loader/loader.vue";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      adresse: [],
      feeds: [],
      comments: [],
      isReady: false,
      user: [],
      //Page---------------------------
      isShowModal: false,
      lastPage: 1,
      lastCommentPage: 1,
      page: 1,
      pageComment: 1,
      isPrevCommentReady: true,
    };
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted() {
    this.getFeeds();
    this.getUser();
  },

  methods: {
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },

    getUser() {
      this.axios.get(`show/${this.$route.params.id}`).then((res) => {
        this.user = res.data.visiteur;
        console.log(this.user);
        console.log(res.data);
      });
    },

    getFeeds() {
      this.isReady = false;
      this.axios
        .get(`publications/${this.$route.params.id}?page=${this.page}`)
        .then((res) => {
          this.feeds.push(...res.data.feeds.data);
          this.adresse = res.data.Adresse;
          this.isReady = true;
          this.lastPage = res.data.feeds.last_page;
          //  console.log(res.data.feeds.data);
          //console.log(res.data.Adresse);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
</style>