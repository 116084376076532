<template>
  <portal to="modal">
    <transition name="fade">
      <form id="form-adress" enctype="multipart/form-data">
        <div class="modall">
          <div class="wrapper col-md-6 col-sm-12">
            <div class="modal-container">
              <div @click.self.stop.prevent="handleClose" class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <div class="z-10 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
              role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="bg-white px-4 pt-2 pb-2 sm:p-5 sm:pb-5">
                <div class="sm:flex sm:items-start">
                  <div class="w-full text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <div class="flex items-center justify-between headModal">
                      <h3 class="pt-2 text-lg modal-header-txt text-gray-900 fw-700" id="modal-headline">You want to be
                        admin for this adress ?</h3>


                      <i v-if="!isUploading" class="feather-x-square pt-1" @click.prevent="handleClose">

                      </i>
                      <hr>
                    </div>
                    <div class="" :class="{ 'scrollable': scrollable }">


                      <div class="card w-100 shadow-xss rounded-xxl border-0 ps-4 pe-4 mb-3 modalBody">



                        <div class="card-body p-0 mt-2 position-relative">






                          <div class="row">
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-barcode shadow-sm w30"></i>
                              </figure>
                              <input v-model="matFiscale" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Tax registration number">
                            </div>
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-home shadow-sm w30"></i>
                              </figure>
                              <input v-model="raisSociale" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Social reason *" required>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-envelope shadow-sm w30"></i>
                              </figure>
                              <input v-model="email" type="email"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Email">
                            </div>
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-map-marker-alt shadow-sm w30"></i>
                              </figure>
                              <input v-model="adresse" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="address *" required>
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-mail-bulk shadow-sm w30"></i>
                              </figure>
                              <input v-model.number="codePostal" type="number"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Postal code
  ">
                            </div>
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-phone shadow-sm w30"></i>
                              </figure>
                              <input v-model.number="tel" type="number"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Phone *" required>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">

                                <i class="fab fa-facebook-square shadow-sm w30"></i>
                              </figure>
                              <input v-model.trim="facebook" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Facebook">
                            </div>
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">

                                <i class="fab fa-instagram shadow-sm w30"></i>
                              </figure>
                              <input v-model.trim="instagram" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Instagram">
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">

                                <i class="fab fa-youtube shadow-sm w30"></i>
                              </figure>
                              <input v-model.trim="youtube" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Youtube">
                            </div>
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">

                                <i class="fab fa-twitter-square shadow-sm w30"></i>
                              </figure>
                              <input v-model.trim="twitter" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Twitter">
                            </div>
                          </div>


                          <div class="row">
                            <div class="col-md-6 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                                <i class="fas fa-mouse-pointer shadow-sm w30"></i>
                              </figure>
                              <input v-model.trim="siteWeb" type="text"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Web site">
                            </div>


                          </div>

                          <div class="row">

                            <div class="col-md-12 position-relative">
                              <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">

                                <i class="fas fa-info-circle shadow-sm w30"></i>
                              </figure>
                              <textarea v-model.trim="presentation" type="url"
                                class="bor-0 w-100 rounded-xxl p-2 ps-10 font-xsss text-grey-500 fw-500 border-light-md theme-dark-bg"
                                placeholder="Message "></textarea>
                            </div>

                          </div>




                          <div class="row">
                            <div ref="drop" method="POST" id="myAwesomeDropzone" class="dropzone">
                              <div class="fallback">

                                <input name="file" type="file" />


                              </div>
                            </div>
                          </div>



                        </div>
                      </div>


                      <slot />
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer bg-gray-50 px-4 py-6 sm:px-6 sm:flex sm:flex-row-reverse">
                <slot name="footer"></slot>
                <span class="mt-0 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto spanBtn">
                  <button v-if="!isUploading" id="btnAddAdresse" :disabled="!isFormValid" type="submit"
                    class="btnPub inline-flex justify-center w-full rounded-md border border-gray-300 px-3 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    :class="isFormValid ? 'btn-disabled' : ''">Send</button>

                  <template class="" v-else>
                    <Loader></Loader>
                  </template>


                </span>


              </div>


            </div>

          </div>

        </div>
      </form>
    </transition>

  </portal>
</template>

<script>
import { Dropzone } from "dropzone";
import Loader from '../../layouts/loader/loaderImage.vue';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  components: { Loader },
  name: "BaseModal",



  data() {
    return {
      isUploading: false,
      isFormValid: true,
      zoom: 4,
      center: { lat: 36.173357, lng: 10.091831 },
      options: {
        zoomControl: true,
        mapTypeControl: true,
        scaleControl: true,
        streetViewControl: true,
        rotateControl: true,
        fullscreenControl: true,
      },
      markers: [
        {
          position: {
            lat: 36.173357, lng: 10.091831
          },
        }
      ],

      token: '',
      categories: [],
      isCategorieReady: false,
      isServiceReady: false,
      isContinentReady: false,
      isPaysReady: false,
      isVillesReady: false,
      services: [],
      continents: [],
      pays: [],
      villes: [],
      //V-Model-----------------------
      catID: '',
      continentID: '',
      paysID: '',
      villeID: '',
      matFiscale: '',
      raisSociale: '',
      email: '',
      adresse: '',
      codePostal: '',
      tel: '',
      siteWeb: '',
      lat: 36.173357,
      lng: 10.091831,
      presentation: '',
      facebook: '',
      twitter: '',
      youtube: '',
      instagram: '',
      servicesChoisis: [],


    }
  },
  props: {

    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    document.addEventListener("keyup", this.onClose);
    this.token = localStorage.getItem('token');

  },
  mounted() {
    this.axios.get('/continents').then(res => {
      //console.log(res.data.categories);
      this.continents = res.data.continents;
      this.isContinentReady = true;
    });
    setTimeout(() => { this.dropInit(), 500 });
    this.axios.get('/categories').then(res => {
      //console.log(res.data.categories);
      this.categories = res.data.categories;
      this.isCategorieReady = true;
    });

  },
  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
  methods: {

    dropInit() {
      //setTimeout(() => {,1000})
      Dropzone.autoDiscover = false;
      Dropzone.options.myAwesomeDropzone = {
        paramName: "file", // The name that will be used to transfer the file
        maxFilesize: 25, // MB
        url: `${this.apiBaseUrl}demandeAdministration`,
        addRemoveLinks: true,
        uploadMultiple: true,
        withCredentials: true,
        headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') },
        autoProcessQueue: false,
        maxFiles: 6,
        parallelUploads: 6,
        acceptedFiles: 'image/*,application/pdf,.doc,.docx,.xls,.xlsx,.csv,.ppt',
        dictRemoveFile: 'Delete',
        dictCancelUploadConfirmation: "Are you sure you want to cancel this download?",
        dictDefaultMessage: "Drop your court documents (photo,pdf,doc..) here to upload",
        dictCancelUpload: "",
        dictInvalidFileType: 'This form only accepts images,pdf,doc',
        dictFileTooBig: `the file size is too large. {{filesize}} the max accepted is {{maxFilesize}} `



      };

      var myDropzone = new Dropzone("#myAwesomeDropzone", {
        parallelUploads: 22,
        init: () => {
          var myDropzone = Dropzone.forElement("#myAwesomeDropzone");
          var form = document.getElementById("form-adress");


          form.addEventListener("submit", (e) => {
            e.preventDefault();
            e.stopPropagation();
            if (myDropzone.files.length) {

              myDropzone.processQueue();
            } else {

              this.AjouterAdresse();

            }
          });

          myDropzone.on('sendingmultiple', (data, xhr, formData) => {

            this.isUploading = true;

            formData.append("mf", this.matFiscale);
            formData.append("rs", this.raisSociale);
            formData.append("email", this.email);
            formData.append("adresse", this.adresse);
            formData.append("code_postal", this.codePostal);
            formData.append("tel", this.tel);
            formData.append("site_web", this.siteWeb);
            formData.append("message", this.presentation);
            formData.append("adresse_id", this.$route.params.id);



          });
          myDropzone.on('successmultiple', (data, xhr, formData) => {

            //this.handleRefresh();
            this.handleClose();
            this.refreshStatus();
            toast.success("Address successfully added ", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });

          });

          myDropzone.on('error', (file, data) => {

            this.isUploading = false;


          });


        }
      });

    },

    AjouterAdresse() {
      this.isUploading = true;
      this.axios.post('adresses', {

        mf: this.matFiscale,
        rs: this.raisSociale,
        email: this.email,
        adresse: this.adresse,
        code_postal: this.codePostal,
        code_postal: this.codePostal,
        tel: this.tel,
        site_web: this.siteWeb,
        lat: this.lat,
        long: this.lng,
        presentation: this.presentation,
        ville_id: this.villeID,
        categorie_id: this.catID,
        proprietaire_id: this.currentUser.id,
        services: this.servicesChoisis,

      }).then(res => {
        this.handleClose();
        this.refreshStatus();
        toast.success("Request successfully sent ", {
          position: "bottom-right",
          timeout: 3000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }).catch(error => {
        this.isUploading = false;

      })

    },

    drag(e) {

      this.lat = +e.latLng.lat();
      this.lng = +e.latLng.lng();
      this.center = { lat: e.latLng.lat(), lng: e.latLng.lng() }
    },

    refreshStatus() {
      this.$emit("refreshStatus");

    },
    handleClose() {
      this.$emit("close");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },

    getService(id) {
      this.axios.get(`services/${id}`).then(res => {
        this.services = res.data.services;
        console.log(this.services);
        this.isServiceReady = true;
        setTimeout(() => { document.getElementsByClassName('modalBody')[0].scrollBy(0, 500), 500 })
      })
    },
    getPays(id) {


      this.axios.get(`pays/${id}`).then(res => {
        this.pays = res.data.pays;
        this.isPaysReady = true;
        this.villes = '';
      })
    },

    getVilles(id) {
      this.axios.get(`villes/${id}`).then(res => {
        this.villes = res.data.villes;
        this.isVillesReady = true;
      })
    },

  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },


  },
};
</script>

<style lang="scss" scoped>
.btnPub {
  transition: all .4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;



  }
}

.spanBtn {
  display: inline-block;
  width: 100%;


}


.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;

}

.wrapper {
  //width: 60%;
  margin: 0 auto;
  border-radius: 10px;

}

.modal-container {

  margin: 0px 50px;
  padding: 15px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;

}

.modal-header-txt {
  display: inline-block;
}

.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all .4s ease-in-out;
  }

  float:right;
  font-size: 32px;
}

.dropzone__box {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}

.row {
  margin-bottom: 10px
}

.vue-map-container {
  height: 280px !important;
  width: 100% !important;
  border-radius: 10px;
}

.modalBody {
  height: 400px !important;
  overflow: auto !important;
}

.headModal {
  position: sticky;
  top: 20px
}

.z-10 {
  border-radius: 20px;
}

textarea {
  resize: none;
}

@media screen and (max-width: 989px) {
  .modal-container {

    margin: 0px 10px;
    padding: 0px 20px;

  }

}

@media (max-width: 767px) {

  .bg-greylight {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .card-m {
    padding-left: 0px !important;
    padding-right: 0px !important;


  }

  .modal-container {
    margin: 0px 10px !important;

  }

  .wrapper {
    margin: 0 auto;
    height: 100% !important;
  }

  .z-10 {
    border-radius: 0px;

  }

  .rounded-modal {
    border-radius: 0px;
    width: 100%;
    height: 100% !important;
  }

  .modalBody {
    height: 46rem !important;
  }

  .card-footer {
    position: relative;
    bottom: 0;
    border-top: 0;

  }

  .container-publish {
    background: none;

  }

  .card-mm {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}

.dropzone {
  border-radius: 15px;
  background-color: #eff3fa;
}</style>