<template lang="">
  
    <div class="preloader-wrap">
        <div class="box shimmer">
            <div class="lines">
                <div class="line s_shimmer"></div>
               
                
                
            </div>
        </div>
      
  
    </div>
    <!-- loader wrapper -->
</template>
<script>
export default {
    name: "SkeletonBtn",
};
</script>
<style lang="scss" scoped>

// .lines{
//     height: 100% !important

// }
.line{
    //width: 300px !important; 
    height: 36px !important;
    border-radius:10px
}
.box{
    height: 6.8vh !important;
    margin: 0 !important
}


</style>
