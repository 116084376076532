<template lang="">
  <div>
    <!-- Favicon icon -->
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      :href="`${assetsBaseUrl}images/favicon.png`"
    />


    <div v-if="loading" class="loading">
      <Loader></Loader>
    </div>
    <div class="main-wrap">
      <div class="nav-header bg-transparent shadow-none border-0">
        <div class="nav-top">
         <a href="#"
          ><img :src="`${assetsBaseUrl}LogoTrippy/logo.png`" class="display1-size me-2 ms-0">
          </a>       
        </div>
      </div>

      <div class="row">
        
        <div
          class="
            col-xl-6
            d-none d-xl-block
            p-0
            vh-100
            bg-image-cover bg-no-repeat
          "
        
        >
        
           <Splide
                :options="{
                  rewind: true,
                  perPage: 1,
                  autoWidth: true,
                  arrows: false,
                  pagination: false,
                  wheel: false,
                  type   : 'fade',
                  isNavigation: false,
                  autoplay: true,
                  interval: 5000,
                  speed:5000,
                  autoHeight:true
                  
                  
                }"
              >
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image05.jpg`">
          
              </SplideSlide>
            
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image01.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image02.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image03.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image04.jpg`">
          
              </SplideSlide>
              
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image06.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image07.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image08.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image10.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image11.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image12.jpg`">
          
              </SplideSlide>
        </Splide>

        </div> 
        <div
          class="
            col-xl-6
            vh-100
            align-items-center
            d-flex
            bg-white
            rounded-3
            overflow-hidden
          "
        >
          <div class="card border-0 ms-auto me-auto login-card">
            <div class="card-body rounded-0 text-left">
              <h2
                class="fw-700 display1-size display2-md-size mb-3 text-center"
              >
                Log in

              </h2>
              <form @submit.prevent="login" id="loginForm">
                <input
                  type="hidden"
                  name="_token"
                  value="116oCHeq7wkDSK9ox9PpF329esu6Kp8wwUpPkgCz"
                />

                <div class="form-group icon-input mb-2">
                  <i class="font-sm ti-email text-grey-500 pe-0"></i>
                  <input
                    type="email"
                    class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xsss
                      fw-600
                    "
                    id="email"
                    placeholder="E-mail address"
                    v-model="email"
                    name="email"
                  />
                </div>
                <div class="form-group icon-input mb-2">
                  <input
                    type="Password"
                    class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xss
                      ls-3
                    "
                    id="password"
                    placeholder="Password"
                    v-model="password"
                    name="password"
                  />
                  <i class="font-sm ti-lock text-grey-500 pe-0"></i>
                </div>
                <input type="hidden" name="lat" id="lat" value="" />
                <input type="hidden" name="long" id="long" value="" />
                <!-- <div class="form-check text-left mb-3">
                  <a
                    
                    class="fw-600 font-xsss text-grey-700 mt-1 float-right"
                    >Forgot your password?</a
                  >
                </div> -->
                <div class="col-sm-12 p-0 text-left">
                  <div class="form-group mb-1">
                    <button
                      type="submit"
                      role="button"
                      class="
                        form-control
                        text-center
                        style2-input
                        text-white
                        fw-600
                        bg-dark
                        border-0
                        p-0
                      "
                    >
                      Connexion
                    </button>
                  </div>
                  <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                    I don't have an account yet
                    <router-link class="fw-700 ms-1" :to="{ name: 'register' }"
                      >Sign up</router-link
                    >
                  </h6>
                </div>
              </form>

              <div class="col-sm-12 p-0 text-center mt-2">
                <h6
                  class="
                    mb-1
                    d-inline-block
                    bg-white
                    fw-500
                    font-xsss
                    text-grey-500
                    mb-3
                  "
                >
                  Or, Log in with your social account
                </h6>
                <div class="mb-1 text-center social-login">
                  <a @click="loginFacebook"  class="text-center p-2 d-block social-btn mb-1 fw-600 btn-facebook" 
                     style="box-shadow: 1px 1px 5px rgb(168, 167, 167)"><i class="fab fa-2x fa-facebook-f  me-3 ms-2 float-left"></i>Sign in with Facebook</a>
                     
                  

                    <a @click="loginGoogle" class="text-center border-0 p-0 mb-2 p-2 d-block social-btn btn-google fw-600" style="box-shadow: 1px 1px 5px rgb(168, 167, 167)"
                    ><i class="fab fa-2x fa-google me-3 ms-2  float-left"></i>Sign in with Google</a>
                    
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Loader from "../layouts/loader/loaderLogin.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  components: {
    Loader,
    Splide, SplideSlide
  },

  data() {
    return {
      email: "",
      password: "",
      errors: {},
      isValid: true,
      loading: false,
    };
  },

  mounted() {
    window.addEventListener("message", this.onMessage, false);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  methods: {
    loginFacebook() {
      const newWindow = openWindow("", "message");
      this.axios
        .get("login/facebook")
        .then((response) => {
          newWindow.location.href = response.data.url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    loginGoogle() {
      const newWindow = openWindow("", "message");
      this.axios
        .get("login/google")
        .then((response) => {
          newWindow.location.href = response.data.url;
        })
        .catch(function (error) {
          console.error(error);
        });
    },

    onMessage(e) {
      console.log(e);
      if (!e.data.token) {
        return;
      }
      // localStorage.setItem('user',e.data.name)
      localStorage.setItem("token", e.data.token);

      this.$router.push("/adressesaproximite");
    },

    //-------------------------------

    login() {
      if (this.email != "" && this.password != "") {
        this.loading = true;
      }
      this.axios
        .post("login", {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            localStorage.setItem("token", res.data.access_token);
            toast.success("login successfully ", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$router.push("/adressesaproximite");
          }
        })
        .catch((error) => {
          this.loading = false;
          if (error.response.status == 400) {
            this.errors = error.response.data.errorsValidation;
            console.log(error.response.data.errorsValidation);
            toast.error("Invalid Credentials ! ", {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else if (error.response.status == 401) {
            this.errors = error.response.data.errorsValidation;
            console.log(error.response.data.errorsValidation);
            toast.error("Invalid Credentials ! ", {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          } else if (error.response.status == 403) {
            this.errors = error.response.data.errorsValidation;
            console.log(error.response.data.errorsValidation);
            toast.error("Your email address is not verified.", {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        });
    },
  },
  beforeCreate() {
    if (localStorage.getItem("token")) {
      this.$router.push("/adressesaproximite");
    }
  },
  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
function openWindow(url, title, options = {}) {
  if (typeof url === "object") {
    options = url;
    url = "";
  }
  options = { url, title, width: 650, height: 500, ...options };
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screen.left;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screen.top;
  const width =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    window.screen.width;
  const height =
    window.innerHeight ||
    document.documentElement.clientHeight ||
    window.screen.height;
  options.left = width / 2 - options.width / 2 + dualScreenLeft;
  options.top = height / 2 - options.height / 2 + dualScreenTop;
  const optionsStr = Object.keys(options)
    .reduce((acc, key) => {
      acc.push(`${key}=${options[key]}`);
      return acc;
    }, [])
    .join(",");
  const newWindow = window.open(url, title, optionsStr);
  if (window.focus) {
    newWindow.focus();
  }
  return newWindow;
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/style.css";
@import "../../assets/css/themify-icons.css";
@import "../../assets/css/feather.css";

a {
  text-decoration: none !important;
}
.loading {
  background-color: transparent;
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 99;
  margin: 20% auto !important;
}
.is-active {
  border-bottom: 0 !important;
}
.splide{
  height: 100vh !important;
}
.image-fit {
  object-fit: cover !important;
}
.social-btn{
  cursor:pointer;
  border-radius: 5px;
   color:#fff;
   &:hover{
      color:#fff;


   }
}
.btn-facebook{
  background-color: #2B4663;
  color:#fff
  
}
.btn-google{
  background-color: #C23321;
  color:#fff
  
}
.fa-facebook-f , .fa-google{
  color:#fff
}
.clear-fix{
  clear: both;
}
</style>
