<template lang="">
   <ModalSocialMediaShare v-if="isShowModalShare" @close="toggleModalCloseShare" :postId="postIdToShare" :mediaId="mediaIdToShare">
     </ModalSocialMediaShare>
    <ModalJaimeList v-if="isShowModalJaime" @close="toggleModalCloseJaime" :postId="postIdToJaimeList"></ModalJaimeList>
     <ModalJaimeCommentList v-if="isShowModalJaimeComment" @close="toggleModalJaimeCommentClose" :commentID="commentIdToJaimeList"></ModalJaimeCommentList>
 <portal to="modal">
    <transition name="fade">
      <div
        class="modall"
      >
        <div class="lb-data">
     
      <div class="lb-closeContainer">
        <a @click.prevent="handleClose"  class="lb-close" style="z-index:9999"><i class="fas fa-times"></i></a>
      </div>
      
    </div>
      <div class="wrapper">
        <div class="modal-container">
          <div
            @click.self.stop.prevent="handleClose"
            class="absolute inset-0 bg-gray-500 opacity-75"
          ></div>
        </div>
<!-- -----------------------------------------------(LOADER)----------------------------------- -->

    <div v-if="!isPostReady" class="lightbox" style="display: block; top: 90px; left: 0px;">
    <div class="lb-outerContainer" style="width: 600px; height: 471px;">
      
   
            <div class="position-absolute loader-img"><LoaderImage/></div>
           <!-- <SkeletonImage /> -->

   
  </div>

  <div class="lb-dataContainer" @click.stop="handleClose" style="display: block; width: 500px;">
    <div class="lb-data">
     
      
    </div>
</div>

<!-- -----------------------------------------------(FIN LOADER)----------------------------------- -->


<!-- ----------------------------------------------(FIN IMAGES)---------------------------->
<!-- --------------------------------------------(COMMENTS)----------------------------- --> 
      

</div>





  <div v-else class="lightbox" style="display: block; top: 90px; left: 0px; ">
      <div class="lb-dataContainer" style="display: block;">
  
</div>
    <div class="lb-outerContainer center-flex" style="width: 600px; height: 471px;">
    <div class="lb-container  media-fit">

<!-- -------------------------------------------------------------------------------------------------------------------------->

<div id="carouselExampleControls" class="carousel slide" data-bs-interval="false"  data-interval="false" data-keybord="true">

                             <div class="carousel-inner">
                              
                                
                         
                                    <div v-for ="(x,index) in post.medias" class="carousel-item" :class="index == mediaPos ? 'active' : '' " >
                                        
                                        <img v-if="x.type == 1" :src="`${assetsBaseUrl}${x.src}`" class="lb-image d-block w-100 blur-up lazyload" alt="...">
                                        
                                        
                                        <video v-else controls class="lb-image d-block w-100 blur-up lazyload">   
                                        <source :src="`${assetsBaseUrl}${x.src}`"
                                        type="video/mp4">
                                        Sorry, your browser doesn't support embedded videos.
                                        </video>
                                          
                                        
                                    </div>
                            </div>
                            
                           <div v-if="post.medias.length > 1 && isMediaCommentReady" class="lb-nav" style="display: block;"
                           >
                           
                         
                         
                            
                                          <button ref="btnNext" @click="getPrevMedia(currentMediaId)" class="lb-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev" style="display: block;">
                                         
                                          </button>
                                          <button @click="getNextMedia(currentMediaId)"  class="lb-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next" style="display: block;">
                                          
                                              
                                          </button>
                                          
                                          
 
</div>
</div>

<!-- ---------------------------------------------------------------(IMAGES)------------------------------------------------------ -->
           
<!-- ---------------------------------------------------------------(LOADER)------------------------------------------------------ -->
            <div class="lb-loader" style="">    
            </div>
    </div>
  </div>


<!-- ---------------------------------------------------------------(FIN IMAGES)--------------------------------------------------- --> 




<!-- ---------------------------------------------------------------(COMMENTS)--------------------------------------------------- --> 

        
<div v-if="isMediaCommentReady" class="right-comment theme-dark-bg">


  <div class="card-body ps-2 pe-4 pb-0 d-flex">
   <router-link :to="{name :'feedadresse', params: { id : post.adresse.id } }">
    <figure class="avatar me-3">
      <img :src="`${assetsBaseUrl}${post.adresse.logo}`" alt="image" class="shadow-sm border-rad w45">
    </figure>
    </router-link>
    
    <h4 class="fw-700 text-grey-900 font-xssss mt-1 text-left"><router-link :to="{name :'feedadresse', params: { id : post.adresse.id } }">{{post.adresse.rs}} </router-link> 
         <span class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500">{{ $filters.timeAgo(post.created_at) }}</span></h4>
         
  </div>

  <div class="card-body d-flex pt-0 mt-0 pb-1 col-12 social-action-container pe-1 ps-1"> 
<div class="social-actions col-md-12 col-12">




                            <div @mouseleave="unhoverr()" @click="deletReaction(currentUser.id,currentMedia.id,currentMedia.jaimes.length)" @mouseover.self="hover($event)" class="p-1 lh-28  text-grey-800 text-center font-xsss fw-700 col-sm-4 col-3 col-md-3 d-inline-block social-action" :class="reacted ? 'socialClicked' : ''" ref="btnReactRef">
                             
                               <template v-if=" !reacted">
                              <span class=""><i class="feather-thumbs-up btn-round-sm"></i> Like</span>
                             </template>
                              
                              <template v-else-if=" reacted == '1' ">
  <span class="socialClicked" style="color: blue">Like</span>
</template>
                               <template v-else-if="reacted == '2' ">
  <i
    class="feather-heart btn-round-sm socialClicked"
    style="color: red !important"
  ></i
  ><span class="socialClicked" style="color: red">Love</span>
</template>
                               <template v-else-if=" reacted == '3' ">
  <i
    class="em em-astonished btn-round-sm socialClicked"
    style="color: rgb(247, 177, 37) !important"
  ></i
  ><span class="socialClicked" style="color: rgb(247, 177, 37)">Wouah</span>
</template>
                             <template v-else-if="reacted == '4' ">
  <i
    class="em em-rolling_on_the_floor_laughing btn-round-sm socialClicked"
    style="color: rgb(247, 177, 37) !important"
  ></i
  ><span class="socialClicked" style="color: rgb(247, 177, 37)">Haha</span>
</template>
                             <template v-else-if="reacted == '5' ">
  <i
    class="em em-cry btn-round-sm socialClicked"
    style="color: rgb(247, 177, 37) !important"
  ></i
  ><span class="socialClicked" style="color: rgb(247, 177, 37)">Sad</span>
</template>

                             <template v-else-if=" reacted == '6' ">
  <i
    class="em em-angry btn-round-sm socialClicked"
    style="color: rgb(247, 177, 37) !important"
  ></i
  ><span class="socialClicked" style="color: rgb(233, 113, 15)">Angry</span>
</template>
                             <template v-else-if=" reacted == '7' ">
  <i
    class="em em-thumbsdown btn-round-sm socialClicked"
    style="color: rgb(233, 113, 15) !important"
  ></i
  ><span class="socialClicked" style="color: rgb(233, 113, 15)">Dislike</span>
</template>
                             
                             
                              
                              
                              
                              </div>

                            <div @click="focusComment()" class="p-1 lh-28  text-grey-800 text-center font-xsss fw-700 col-sm-4 col-md-5 col-5 d-inline-block social-action"><i class="feather-message-circle text-dark text-grey-900 btn-round-sm"></i> Comment</div>
                            <div @click="showAlertShare(post.id, post.visiteur_id, currentMediaId)" class="p-1 lh-28  text-grey-800 text-center font-xsss fw-700 col-sm-4 col-4 d-inline-block social-action col-md-4" ><i class="feather-share-2 text-grey-900 text-dark btn-round-sm"></i> Share</div>
                        </div>

                       

                          
                        


           
  </div>
<div class="row p-2 d-flex ps-2 pe-4 pt-1 mt-0 h-10">
<div class="col-md-5 col-5">
   <template v-if="isJaimeGroupReady ">
                            <a v-if="jaimesGroup" class="emoji-bttn d-flex align-items-center fw-400 text-grey-900 text-dark lh-20 font-10 me-2" @click="toggleModalJaime(currentMediaId,index)">
                            
                            <i v-if="jaimesGroup.hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="jaimesGroup.hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="jaimesGroup.hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="jaimesGroup.hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="jaimesGroup.hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="jaimesGroup.hasOwnProperty('6')" class="em em-angry"></i>
                              <i v-if="jaimesGroup.hasOwnProperty('7')" class="em em-thumbsdown"></i>  
                            </a>
                            <a v-else @click="toggleModalJaime(currentMediaId,index)">
                                <i v-if="jaimesCountByType.hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="jaimesCountByType.hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="jaimesCountByType.hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="jaimesCountByType.hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="jaimesCountByType.hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="jaimesCountByType.hasOwnProperty('6')" class="em em-angry"></i> 
                              <i v-if="jaimesCountByType.hasOwnProperty('7')" class="em em-thumbsdown"></i>

                            </a>
       </template>
       <template v-else>
         <div class="position-relative pb-3">
         <a class="emoji-bttn align-items-center fw-400 text-grey-900 text-dark lh-20 font-10 loader-jaime">
         <loaderJaime></loaderJaime>
         </a>
         </div>
       </template>    
 
</div>
<div class="col-md-1 position-relative col-2">
<span v-show="(jaimesGroup || currentMedia.jaimes.length >= 1 ) "  class="jaime-group">
                               <template v-if="currentMedia.jaimes"><span ref="jaimeCountRef" class="d-inline-block ps-5 pb-1">{{ currentMedia.jaimes.length }}</span></template> 
 
 
 </span>
 </div>
<div class="col-md-5 col-5">
   <a @click="showComments(currentMedia.id)" class=" fw-600 text-grey-900 text-dark lh-26 font-14 comment-count" ref="commentCountRef"  v-show="currentMedia.commentaires.length >= 1">{{currentMedia.commentaires.length }} Comments</a>
   </div>
   </div>
<!-- ----------------------------------------------(COMMENT)---------------------------------------------------------->  
 <div @mouseover="hoverTwo" @mouseleave="unhover(index)" class="emoji-wrap hidden" ref="socialactRef">
                                <ul class="emojis list-inline mb-0">
                                    <li role="button" @click="jaime(currentUser.id,currentMedia.id,index)" class="emoji list-inline-item" title="Like"><i class="em em---1"></i> </li>
                                    <li role="button" @click="jadore(currentUser.id,currentMedia.id,index)" class="emoji list-inline-item" title="Love"><i class="em em-heart"></i></li>
                                     <li role="button" @click="dislike(currentUser.id,currentMedia.id,index)" class="emoji list-inline-item" title="Dislike"><i class="em em-thumbsdown"></i></li>
                                    <!-- <li  @click="haha(currentUser.id,currentMedia.id,index)" role="button" class="emoji list-inline-item" title="Haha"><i class="em em-rolling_on_the_floor_laughing"></i> </li>
                                    <li @click="wouah(currentUser.id,currentMedia.id,index)" role="button" class="emoji list-inline-item" title="Wouah"><i class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i></li>
                                    <li  @click="triste(currentUser.id,currentMedia.id,index)" role="button" class="emoji list-inline-item" title="Sad"><i class="em em-cry"></i></li>
                                    <li @click="grrr(currentUser.id,currentMedia.id,index)"  role="button" class="emoji list-inline-item" title="Angry"><i class="em em-angry"></i> </li> -->
                                </ul>
                            </div>  
  <div class="card w-100 border-0 shadow-none scroll-bar chat-left">
    <div class="card-body border-top-xs pt-4 pb-3 pe-4 d-block ps-5"> 
      
 <template v-if="!isCommentReady" >
                        <!-- <div class="preloader"></div> -->
                      <div  class="chat w-100 d-block text-left theme-dark-bg">
                            <Loader/>   
                            </div>
                            
 </template> 
 <template v-else>
   <div v-if=" comments.length == 0">No comments</div>
   <div ref="commentListRef" class="">

     <template v-if="isCommentReady" >
       <div>
                        <a @click="getPrevComments(currentMedia.id,index)" v-if="this.pageComment < this.lastCommentPage" class="p-2 lh-28  text-grey-600 text-center font-xsss fw-500 comment-pagination">See previous comments</a>
                        <span class="" v-if="this.lastCommentPage >= 2">{{this.pageComment}}/{{this.lastCommentPage}}</span>
        </div>
                       <div class="fix-float"></div> 
                          <template v-if="!isPrevCommentReady" >
                            <Loader/>
                             <!-- <SkeletonComment />    -->
                          </template> 
                           </template> 


<div v-for="(com,indexComment) in comments" class="mb-1">
  <router-link :to="{name :'feedvisiteur', params: { id : com.visiteur_id } }">
      <figure class="avatar position-absolute left-0 ms-2 mt-1">
         <img :src="`${assetsBaseUrl}${com.visiteur.photo}`" alt="image" class="shadow-sm border-logo-rad w30">
        <!-- <img src="" alt="image" class="shadow-sm rounded-circle w35"> -->
      </figure>
  </router-link>
      <div  class="chat p-3 bg-greylight rounded-xxl d-block text-left theme-dark-bg ps-6">
        <h4 class="fw-700 text-grey-900 font-xssss mt-0 mb-1"><router-link :to="{name :'feedvisiteur', params: { id : com.visiteur_id } }">
         {{com.visiteur.prenom}} {{com.visiteur.nom}} </router-link>

         <!--***************************************************************************************************-->


              <a v-if="isCommentUser(currentUser.id,post.visiteur_id) || isCommentUser(com.visiteur.id,currentUser.id)" href="#" class="delete-comment-btn float-right text-grey-800 font-xsss" id="dropdownMenu9" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i class="ti-more-alt text-grey-900 font-14"></i></a>
                            <div v-if="isCommentUser(currentUser.id,post.visiteur_id)  || isCommentUser(com.visiteur.id,currentUser.id)" class="dropdown-menu dropdown-menu-start rounded-cc border-0 shadow-lg edit-comment" aria-labelledby="dropdownMenu7">
                             <!-- @click.prevent="deleteComment(comment.id,x.id,index)" -->
                             <div @click="showAlertDeleteCom(com.id,post.id,index)"
                                role="button"  class="del-up v-top card-body ">
                                    <!-- <i class="fa fa-trash-alt text-grey-500 me-3 font-lg"></i> -->
                                    <h4 class="fw-600 text-grey-900 font-14 d-i">
                                        
                                          Delete 
                                          <!-- <span class="d-block font-14 fw-500 lh-3 text-grey-500"></span> -->
                                          </h4>
                                </div>
                                <div v-if="isCommentUser(com.visiteur.id,currentUser.id)"  @click.prevent="editComment(com.id,index)" role="button"  class="del-up v-bottom card-body ">
                                    <!-- <i class="fa fa-edit text-grey-500 me-3 font-lg"></i> -->
                                    <h4 class="fw-600 text-grey-900 font-14 d-i">
                                        
                                          Edit 
                                          <!-- <span class="d-block font-14 fw-500 lh-3 text-grey-500"></span> -->
                                          </h4>
                                </div>
                             
                            </div>




        <!-- <a href="#" class="ms-auto"><i class="ti-more-alt float-right text-grey-800 font-xsss"></i></a> -->
        </h4><p class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0"> {{com.texte_com}} </p>
         <div class="tags lh-28 ms-1 font-xssss fw-600 d-i-b" >
                                    <ul class="d-i-b me-1">
                                        <li v-for="tagAmis in com.amis_tags" class="d-i-b  me-1">
                                          <router-link :to="{name :'feedvisiteur', params: { id : tagAmis.visiteur.id } }">
                                          @{{tagAmis.visiteur.prenom}}
                                          </router-link>
                                        </li>
                                    </ul>
                                    <ul  class="d-i-b">
                                        <li v-for="tagAdresse in com.adresse_tags" class="d-i-b  me-1">
                                            <router-link :to="{name :'feedadresse', params: { id : tagAdresse.adresse.id } }">
                                          @{{tagAdresse.adresse.rs}}
                                          </router-link>
                                        </li> 
                                    </ul>
        </div>
        <div class="count-jaime-comment d-flex">

          <template v-if="isJaimeGroupCommentReady[com.id] == true ">
                  <div class="jaime-group-comment d-i-b">
                            <a v-tooltip.top-start="'Show all'" @click="toggleModalJaimeComment(com.id,index)" v-if="jaimesGroupComment[com.id]" class="emoji-bttn fw-400 text-grey-900 text-dark lh-20 font-10">
                            
                            <i v-if="jaimesGroupComment[com.id].hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="jaimesGroupComment[com.id].hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="jaimesGroupComment[com.id].hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="jaimesGroupComment[com.id].hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="jaimesGroupComment[com.id].hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="jaimesGroupComment[com.id].hasOwnProperty('6')" class="em em-angry"></i>
                               <i v-if="jaimesGroupComment[com.id].hasOwnProperty('7')" class="em em-thumbsdown"></i> 
                            </a>
                            <a v-tooltip.top-start="'Show all'"   @click="toggleModalJaimeComment(com.id,index)" v-else class="emoji-bttn">
                                <i v-if="com.countJaimeByComment.hasOwnProperty('1')" class="em em---1"></i> 
                              <i v-if="com.countJaimeByComment.hasOwnProperty('2')" class="em em-heart"></i>
                              <i v-if="com.countJaimeByComment.hasOwnProperty('4')" class="em em-rolling_on_the_floor_laughing"></i> 
                              <i v-if="com.countJaimeByComment.hasOwnProperty('5')" class="em em-cry"></i>
                              <i v-if="com.countJaimeByComment.hasOwnProperty('3')" class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i> 
                              <i v-if="com.countJaimeByComment.hasOwnProperty('6')" class="em em-angry"></i> 
                              <i v-if="com.countJaimeByComment.hasOwnProperty('7')" class="em em-thumbsdown"></i> 

                            </a>
        </div>                    
       </template>
       <template v-else>
         <a class="emoji-bttn fw-400 text-grey-900 text-dark lh-20 font-10 me-2 d-i-b loader-jaime-comment">
         <LoaderJaimeComment></LoaderJaimeComment>
         </a>
       </template> 

       
        <span class="comment-jaime-count" :ref="setCountJaimeCommentRef"> {{ com.jaimes.length}}
        </span>
         </div>
        
      </div>
        <div class="position-relative">
                                    <div @mouseover="hoverTwoComment" @mouseleave="unhoverComment(indexComment)" class="emoji-wrap-comment emoji-wrap hidden" :ref="setTooltipCommentJaimeRef">
                                    <ul class="emojis list-inline mb-0">
                                    <li role="button" @click="jaimeComment(currentUser.id,com.id,indexComment)" class="emoji list-inline-item" title="Like"><i class="em em---1"></i> </li>
                                    <li role="button" @click="jadoreComment(currentUser.id,com.id,indexComment)" class="emoji list-inline-item" title="J'adore"><i class="em em-heart"></i></li>
                                    <li  @click="hahaComment(currentUser.id,com.id,indexComment)" role="button" class="emoji list-inline-item" title="Haha"><i class="em em-rolling_on_the_floor_laughing"></i> </li>
                                    <li @click="wouahComment(currentUser.id,com.id,indexComment)" role="button" class="emoji list-inline-item" title="Wouah"><i class="em em-astonished" aria-role="presentation" aria-label="ASTONISHED FACE"></i></li>
                                    <li  @click="tristeComment(currentUser.id,com.id,indexComment)" role="button" class="emoji list-inline-item" title="Triste"><i class="em em-cry"></i></li>
                                    <li @click="grrrComment(currentUser.id,com.id,indexComment)"  role="button" class="emoji list-inline-item" title="Grrr"><i class="em em-angry"></i> </li>
                                </ul>
                            </div> 
                            </div>
      <div class="comment-jaime">
     


                                  
 



    <!-- btn jaimle comment-->
      <div :ref="setBtnJaimeCommentRef" @mouseleave="unhoverrComment(indexComment)" @click="deletReactionComment(indexComment,currentUser.id,com.id,com.jaimes.length)" @mouseover="hoverJaimeComment(indexComment,$event)" class="fw-400 text-grey-800 font-xssss mt-0 mb-1 btn-jaime-comment" :class="com.react ? 'socialClicked' : ''">
        <template v-if="com.react == false">
                              <span class="">Like</span>
                             </template>
                              
                              <template v-else-if=" com.react == '1' ">
                              <span class="socialClicked" style="color:blue">Like</span>
                             </template>
                               <template v-else-if="com.react == '2' ">
                             <span class="socialClicked" style="color:red">Love</span>
                             </template>
                               <template v-else-if=" com.react == '3' ">
                             <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>
                             </template>
                             <template v-else-if="com.react == '4' ">
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>
                             </template>
                             <template v-else-if="com.react == '5' ">
                            <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>
                             </template>

                             <template v-else-if="com.react == '6' ">
                             <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>
                             </template>
                             
                            
                                 
                            </div>









      <span v-if="com.updated_at != com.created_at " class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0 datetimeComment">updated {{ $filters.timeAgo(com.updated_at) }}</span>
      <span v-else class="fw-500 text-grey-500 lh-20 font-xssss w-100 mt-2 mb-0 datetimeComment">{{ $filters.timeAgo(com.created_at) }}</span>
      </div>
      <div class="clear-float"></div>
      
      </div>
      
      </div>

      
     </template>  



    </div>
    

    
 </div>
  <ul class="autocomplete-results hide rounded-xxl" ref="tooltipTagFriend">
                            
                            <li v-for="(tagFriend,indexResult) in tagFriendsList" class="autocomplete-result d-flex elipsis">
                              <template v-if="indexResult < 5">
  <!-- firend -->
  <div
    v-if="tagFriend.hasOwnProperty('photo')"
    class="d-flex"
    @click="tagFriends(index, tagFriend.id, tagFriend.prenom, 'user')"
  >
    <figure class="avatar me-2 mb-0">
      <img
        :src="`${assetsBaseUrl}${tagFriend.photo}`"
        alt="image"
        class="shadow-sm rounded-circle w45"
      />
    </figure>
    <!-- d-flex friends-tag -->
    <div class="d-flex friends-tag">
      <div class="d-flex">
        <h4 class="fw-700 text-grey-900 font-xssss link-user">
          <span> {{ tagFriend.prenom }} </span>
        </h4>
        <h4 class="fw-700 text-grey-900 font-xssss ms-1 link-user">
          <span> {{ tagFriend.nom }} </span>
        </h4>
      </div>

      <div>
        <span
          v-if="tagFriend.mutualFriend > 0"
          class="d-block font-xssss fw-500 lh-3 text-grey-500 get-mutual-friend"
          >{{ tagFriend.mutualFriend }} mutual friends</span
        >
        <span v-else class="d-block font-xssss fw-500 lh-3 text-grey-500"
          >no mutual friends</span
        >
      </div>
    </div>
  </div>
  <!-- adresse -->
  <div
    v-if="tagFriend.hasOwnProperty('logo')"
    class="d-flex"
    @click="tagFriends(index, tagFriend.id, tagFriend.rs, 'adress')"
  >
    <figure class="avatar me-2 mb-0">
      <img
        :src="`${assetsBaseUrl}${tagFriend.logo}`"
        alt="image"
        class="shadow-sm rounded-circle w45"
      />
    </figure>
    <!-- d-flex friends-tag -->
    <div class="d-flex friends-tag">
      <div class="d-flex">
        <h4 class="fw-700 text-grey-900 font-xssss link-user">
          <span> {{ tagFriend.rs }} </span>
        </h4>
      </div>

      <div>
        <span
          class="d-block font-xssss fw-500 lh-3 text-grey-500 get-mutual-friend"
          >{{ tagFriend.adresse }}</span
        >
      </div>
    </div>
  </div>
</template>
</li>
  <div class="form-group mb-0 icon-input-tag">
                              <input ref="inputTagFriend" @keyup="findTag($event,index)" class="h60 bor-0 w-100 rounded-xxl p-2 ps-5 font-14 text-grey-500 fw-500 border-light-md-tag theme-dark-bg" type="text" placeholder="Find friend, adress.."> 
                              <i class="feather-search font-sm text-grey-400 right-search"></i>
                              </div>                              
  </ul>  
   <div class="mt-1 tags lh-28  font-xssss fw-600 hide" ref="taggedAreaRef">
                         <ul class="d-i-b me-1">
                           <li class="d-i-b  me-1" v-for="friend in taggedFriends">@{{friend.prenom}} <i @click="deleteTagFriend(friend.id,index)" class="fas fa-times delete-tag-btn"></i></li>
                         </ul>
                          <ul  class="d-i-b">
                           <li class="d-i-b  me-1" v-for="adresse in taggedAdresses">@{{adresse.rs}} <i @click="deleteTagAdresse(adresse.id,index)" class="fas fa-times delete-tag-btn"></i></li>
                         </ul>
                         </div>
   <div class="card-body p-0 addComment d-b position-absolute col-8">
    <figure class="avatar top-5 tof-profil position-absolute">
      <img :src="`${assetsBaseUrl}${currentUser.photo}`" alt="image" class="shadow-sm border-logo-rad w30">
    </figure>
      <input @blur="unfocusComment()"  @keyup="showAnnuler() , showtTooltipTagFriend($event,index)" @keyup.esc="cancelComment()" @keyup.enter="commenter(currentMedia.id,currentUser.id)" name="message" class="bor-0 w-90 rounded-xxl p-2 ps-10 font-14 text-grey-500 fw-500 border-light-md theme-dark-bg mb-2" type="text" placeholder="Comment..." ref="commentRef" autocomplete="off" >
      <br>

     
      <span ref="annulerCommentBtnRef" class="fw-500 text-grey-500 lh-10 font-xssss cancelComment hide col-md-12">press escape to  <a class="annulerCommentBtn" @click="cancelComment()">cancel</a></span>  
  </div> 
 
      
 


</div>
</div>

        <div
          class="z-10 bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          
        </div>
      </div>
      <div v-if="!isMediaCommentReady" class="right-comment scroll-bar theme-dark-bg">
        <div class="loader-container">
            <!-- <Loader/> -->
            <SkeletonComment />
        </div>
                
        

        
  <!-- <div class="card-body p-0 mt-3 position-relative">
    <SkeletonComment />
  </div> -->


</div>
      </div>
      
    </transition>
  </portal>
</template>

<script>
import ModalSocialMediaShare from "./modal/modalSocialMediaShare.vue";
import Skeleton from "./skeleton/Skeleton.vue";
import SkeletonComment from "./skeleton/SkeletonComment.vue";
import Loader from "./loader/loader.vue";
import LoaderImage from "./loader/loaderImageLightbox.vue";
import SkeletonImage from "./skeleton/SkeletonImage.vue";
import LoaderJaimeComment from "./loader/loaderJaimeComment.vue";
import ModalJaimeCommentList from "./modal/modalJaimeCommentList.vue";
import ModalJaimeList from "./modal/modalMediaJaimeList.vue";
import loaderJaime from "./loader/loaderJaime.vue";
import { useToast } from "vue-toastification";
const toast = useToast();
export default {
  name: "lightBox",
  components: {
    Skeleton,
    Loader,
    SkeletonComment,
    SkeletonImage,
    LoaderImage,
    loaderJaime,
    ModalSocialMediaShare,
    LoaderJaimeComment,
    ModalJaimeCommentList,
    ModalJaimeList
  },

  data() {
    return {
      //jaimeList
      isShowModalJaime: false,
      postIdToJaimeList: null,
      //jameCommentList
      isShowModalJaimeComment: false,
      commentIdToJaimeList: null,
      //share--------------
      isShowModalShare: false,
      postIdToSharepostId: 0,
      mediaIdToShare: 0,
      //tag---------------  
      taggedFriends: [],
      taggedAdresses: [],
      currentIndexPostToCmt: 0,
      currentCmtLength: 0,
      tagFriendsList: [],

      //group-----------
      jaimesGroupComment: [],
      isJaimeGroupCommentReady: [],
      //----------------------
      tooltipCommentJaimeRef: [],
      btnJaimeCommentRef: [],
      countJaimeCommentRef: [],
      jaimesCountByType: {},
      isJaimeGroupReady: true,
      jaimesGroup: {},
      comments: [],
      refreshCom: false,
      reacted: false,
      textPub: "",
      token: "",
      post: [],
      currentMedia: [],
      medias: [],
      currentMediaId: Number,
      isPostReady: false,
      isMediaCommentReady: false,
      //--------React---------------
      socialClicked: false,
      isCommentReady: true,
      isVisible: false,
      done: false,
      excecuted: false,
      show: false,
      //------Hover-----------------
      hovering: false,
      hoveringTwo: false,
      showed: false,
      hoverTimer: "",
      //-------Hover comment-------------------
      hoveringComment: false,
      hoveringTwoComment: false,
      showedComment: false,
      hoverTimerComment: "",
      //************************
      lastCommentPage: 1,

      pageComment: 1,
      isPrevCommentReady: true,


    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
  props: {
    postId: Number,
    mediaId: Number,
    mediaPos: Number,
  },
  emits: ['close'],

  created() {
    document.addEventListener("keyup", this.onClose);
    this.token = localStorage.getItem("token");
  },
  /* ---------------------------------------------------------(Mounted())------------------------------------------------------- */
  mounted() {
    this.getPosts();
    this.currentMediaId = this.mediaId;
    this.getGroupCountMedia();

    this.getMedia(this.currentMediaId);
    this.getComments("Media");
    //console.log(this.currentMediaId)
    this.checkHover();
    this.checkHoverComment();
  },
  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },
  beforeUpdate() {
    this.annulerCommentBtnRef = [];
    this.tooltipCommentJaimeRef = [];
    this.btnJaimeCommentRef = [];
  },
  methods: {
    //commentJaimeList---------------
    toggleModalJaimeComment(commentId, index) {
      this.isShowModalJaimeComment = !this.isShowModalJaimeComment;
      this.commentIdToJaimeList = commentId;
    },
    toggleModalJaimeCommentClose() {
      this.isShowModalJaimeComment = false;
    },
    //jaimeList--------------------------
    toggleModalJaime(postId, index) {
      this.isShowModalJaime = !this.isShowModalJaime;
      this.postIdToJaimeList = postId;
    },
    toggleModalCloseJaime() {
      this.isShowModalJaime = false;
    },
    //------------(Share)------------------
    toggleModalShare(postId, mediaId) {
      this.isShowModalShare = !this.isShowModalShare;
      this.postIdToShare = postId;
      this.mediaIdToShare = mediaId;

    },
    toggleModalCloseShare() {
      this.isShowModalShare = false;
    },
    showAlertShare(pubId, userId, mediaId, textPub) {
      const inputOptions = new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            0: "Public",
            1: "Friends",
            2: "Social media",
          });
        }, 100);
      });

      const { value: visibility } = this.$swal({
        title: "Select visibility",
        input: "radio",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#2e58a6",
        confirmButtonText: "Share it",
        inputOptions: inputOptions,
        inputValidator: (value) => {
          if (!value) {
            return "You need to choose something!";
          } else if (value == "0") {
            this.axios
              .post("partage", {
                visiteur_id: userId,
                partageable_id: pubId,
                partageable_type: "App\\Models\\Publication",
                etat: "0",
              })
              .then((res) => {
                toast.success("Post shared successfully ", {
                  position: "bottom-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
          } else if (value == "1") {
            this.axios
              .post("partage", {
                visiteur_id: userId,
                partageable_id: pubId,
                partageable_type: "App\\Models\\Publication",
                etat: "1",
              })
              .then((res) => {
                toast.success("Post shared successfully ", {
                  position: "bottom-right",
                  timeout: 3000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              });
          } else if (value == "2") {
            this.toggleModalShare(pubId, mediaId);
          }
        },
      });
    },
    showAlertDeleteCom(commentId, pubId, index) {
      this.$swal({
        title: "Are you sure?",
        text: "You want to delete this comment",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteComment(commentId, pubId, index);
        }
      });
    },

    isCommentUser(commentUserId, currentUserId) {
      if (commentUserId == currentUserId) {
        return true;
      } else {
        return false;
      }
    },

    /*------------------------------(Tag)----------------------------------*/
    showtTooltipTagFriend(e, i) {
      this.currentIndexPostToCmt = i;
      this.currentCmtLength = this.$refs.commentRef.value.length;
      console.log(this.$refs.commentRef.value.length);

      if (e.keyCode === 48) {
        this.$refs.tooltipTagFriend.classList.remove("hide");
        this.$refs.tooltipTagFriend.classList.add("show");
        this.$refs.inputTagFriend.focus();
      } else {
        this.$refs.tooltipTagFriend.classList.add("hide");
        this.$refs.tooltipTagFriend.classList.remove("show");
      }
    },
    deleteTagFriend(id, i) {
      this.taggedFriends = this.taggedFriends.filter((friend) => {
        return id != friend.id;
      });
    },
    deleteTagAdresse(id, i) {
      this.taggedAdresses = this.taggedAdresses.filter((adresse) => {
        return id != adresse.id;
      });
    },

    tagFriends(i, id, prenom, type) {
      this.$refs.tooltipTagFriend.classList.add("hide");
      this.$refs.tooltipTagFriend.classList.remove("show");
      this.$refs.inputTagFriend.value = "";
      this.$refs.taggedAreaRef.classList.add("show");
      //this.commentRef[i].value = `${this.commentRef[i].value.replace("@"," ")}`;
      this.$refs.commentRef.value = this.$refs.commentRef.value.slice(
        0,
        this.currentCmtLength - 1
      );

      this.$refs.commentRef.focus();

      switch (type) {
        case "user":
          if (!this.taggedFriends.some((el) => el.id === id)) {
            this.taggedFriends.push({ id: id, prenom: prenom });
          }

          break;
        case "adress":
          if (!this.taggedAdresses.some((el) => el.id === id)) {
            this.taggedAdresses.push({ id: id, rs: prenom });
          }
          break;
      }
    },
    findTag(e, i) {
      console.log(this.$refs.inputTagFriend.value);
      let searchKeyArray = this.$refs.inputTagFriend.value.split(" ");
      this.axios
        .post("getTagFriends", {
          tagSearchKeyPrenom: this.$refs.inputTagFriend.value,
          searchKeyArray: searchKeyArray,
        })
        .then((res) => {
          this.tagFriendsList = res.data.results;
        });
    },
    /* ------------------------------------------(reference commentaire->jaime)--------------------------------*/
    setTooltipCommentJaimeRef(el) {
      if (el && !this.tooltipCommentJaimeRef.includes(el)) {
        this.tooltipCommentJaimeRef.push(el);
      }
    },
    setBtnJaimeCommentRef(el) {
      if (el && !this.btnJaimeCommentRef.includes(el)) {
        this.btnJaimeCommentRef.push(el);
      }
    },

    setCountJaimeCommentRef(el) {
      if (el && !this.countJaimeCommentRef.includes(el)) {
        this.countJaimeCommentRef.push(el);
      }
    },
    //-------------------------------------------------
    checkHover() {
      setInterval(() => {
        if (this.hovering || this.hoveringTwo) {
          clearTimeout(this.hoverTimer);
        }
      }, 1000);
    },
    checkHoverComment() {
      setInterval(() => {
        if (this.hoveringComment || this.hoveringTwoComment) {
          clearTimeout(this.hoverTimerComment);
        }
      }, 1000);
    },
    // --------------------(jaime comment)
    hoverJaimeComment(i, event) {
      console.log(i);
      event.stopPropagation();
      this.hoverTimerComment = setTimeout(() => {
        this.hoveringComment = true;
        this.tooltipCommentJaimeRef[i].classList.add("show");
        this.tooltipCommentJaimeRef[i].classList.remove("hidden");

        this.hoveringTwoComment = false;
      }, 700);
    },
    hoverTwoComment() {
      this.hoveringTwoComment = true;
      clearTimeout(this.hoverTimerComment);
    },

    unhoverrComment(i) {
      this.hoveringComment = false;

      if (!this.hoveringTwoComment) {
        clearTimeout(this.hoverTimerComment);
        clearTimeout(+this.hoverTimerComment - 1);
        clearTimeout(+this.hoverTimerComment + 1);
        setTimeout(() => {
          if (!this.hoveringTwoComment && !this.hoveringComment) {
            this.tooltipCommentJaimeRef[i].classList.remove("show");
            this.tooltipCommentJaimeRef[i].classList.add("hidden");
          }
        }, 700);
      }
    },

    unhoverComment(i) {
      this.hoveringTwoComment = false;
      setTimeout(() => {
        this.tooltipCommentJaimeRef[i].classList.remove("show");
      }, 700);
    },

    //*********************************************** */
    getGroupCountMedia() {
      this.axios.get(`/jaimes/byMedia/${this.currentMediaId}`).then((res) => {
        this.jaimesGroup = res.data.countJaimeByType;
        this.isJaimeGroupReady = true;
        //console.log(this.jaimesGroup)
      });
    },

    getMedia(mediaId) {
      this.axios
        .get(`media/${mediaId}`)
        .then((res) => {
          ////console.log(res.data.media);
          this.currentMedia = res.data.media;
          this.reacted = res.data.react;
          this.jaimesCountByType = res.data.jaimesCountByType;
          console.log(res.data);
        })
        .then((res) => {
          this.isMediaCommentReady = true;
        });
    },

    handleClose() {
      this.$emit("close");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
    /* ---------------------------------------------------------(SliderBtn)------------------------------------------------------- */
    getNextMedia(mId) {
      this.isMediaCommentReady = false;
      this.isCommentReady = false;
      this.isJaimeGroupReady = false;

      if (mId == this.medias[this.medias.length - 1].id) {
        this.currentMediaId = this.medias[0].id;
      } else {
        let obj = this.medias.find((o) => o.id === mId);
        let objindex = this.medias.findIndex((o) => o.id === mId);

        console.log(+objindex++);
        // this.currentMediaId = +obj.id+1 ;
        this.currentMediaId = this.medias[+objindex++].id;
      }

      console.log(this.currentMediaId);
      this.axios
        .get(`media/${this.currentMediaId}`)
        .then((res) => {
          this.getComments("Media");
          this.currentMedia = res.data.media;
          this.reacted = res.data.react;
        })
        .then((res) => {
          this.isMediaCommentReady = true;
        });
      this.getGroupCountMedia();
    },

    getPrevMedia(mId) {
      this.isMediaCommentReady = false;
      this.isCommentReady = false;
      this.isJaimeGroupReady = false;
      if (mId == this.medias[0].id) {
        this.currentMediaId = this.medias[this.medias.length - 1].id;
      } else {
        let obj = this.medias.find((o) => o.id === mId);
        let objindex = this.medias.findIndex((o) => o.id === mId);

        console.log(+objindex--);
        // this.currentMediaId = +obj.id+1 ;
        this.currentMediaId = this.medias[+objindex--].id;
      }
      console.log(this.currentMediaId);
      this.axios
        .get(`media/${this.currentMediaId}`)
        .then((res) => {
          this.getComments("Media");
          this.currentMedia = res.data.media;
          this.reacted = res.data.react;
        })
        .then((res) => {
          this.isMediaCommentReady = true;
        });
      this.getGroupCountMedia();
    },

    getPosts() {
      this.axios.get(`publication/${this.postId}`).then((res) => {
        ////console.log(res.data.feeds);
        this.post = res.data.feeds;
        this.medias = res.data.feeds.medias;
        this.isPostReady = true;
        //console.log(this.medias)
      });
    },
    /* -----------------------------------------------(Social Actions)-------------------------------------------------------- */
    hover(event) {
      event.stopPropagation();
      this.hoverTimer = setTimeout(() => {
        this.hovering = true;
        this.$refs.socialactRef.classList.add("show");
        this.hoveringTwo = false;
      }, 700);
    },

    hoverTwo() {
      this.hoveringTwo = true;
      clearTimeout(this.hoverTimer);
    },

    unhoverr() {
      this.hovering = false;

      if (!this.hoveringTwo) {
        clearTimeout(this.hoverTimer);
        clearTimeout(+this.hoverTimer - 1);
        clearTimeout(+this.hoverTimer + 1);
        setTimeout(() => {
          if (!this.hoveringTwo && !this.hovering) {
            this.$refs.socialactRef.classList.remove("show");
          }
        }, 700);
      }
    },

    unhover() {
      this.hoveringTwo = false;
      setTimeout(() => {
        this.$refs.socialactRef.classList.remove("show");
      }, 700);
    },

    /* ------------------------------------------------------ (Commentaires)------------------------------------------------------- */
    focusComment() {
      this.$refs.commentRef.focus();
      this.$refs.commentRef.classList.add("comment-focus");
    },

    unfocusComment() {
      this.$refs.commentRef.classList.remove("comment-focus");
    },
    commenter(mediaId, userId) {
      this.isCommentReady = false;
      this.$refs.taggedAreaRef.classList.remove("show");
      this.$refs.taggedAreaRef.classList.add("hide");
      this.unfocusComment();
      // this.$refs.commentRef.setAttribute('disabled' ,"disabled" );

      if (!this.$refs.commentRef.hasAttribute("data")) {
        this.axios
          .post("/commentaire", {
            commentable_id: mediaId,
            commentable_type: "App\\Models\\Media",
            visiteur_id: userId,
            texte_com: this.$refs.commentRef.value,
            taggedFriends: this.taggedFriends,
            taggedAdresses: this.taggedAdresses,
          })

          .then((res) => {
            if (res.data.success) {
              this.$refs.commentRef.value = "";
              this.showAnnuler();
              this.$refs.commentCountRef.innerHTML =
                parseInt(this.$refs.commentCountRef.innerHTML) +
                1 +
                " Comments";
              this.$refs.commentCountRef.classList.add("show");
              this.pageComment = 1;
              this.getComments("Media");
              this.$refs.commentRef.removeAttribute("disabled");
              this.refreshCom = !this.refreshCom;
              this.getMedia(currentMediaId);
              this.$refs.commentListRef.classList.remove("hide");
              this.showComments(this.currentMediaId);
            }
          })
          .catch((error) => { });
      } else {
        this.axios
          .put(
            `/commentaire/${this.$refs.commentRef.getAttribute("data-id")}`,
            {
              reaction_type: 1,
              commentable_id: mediaId,
              commentable_type: "App\\Models\\Media",
              visiteur_id: userId,
              texte_com: this.$refs.commentRef.value,

            }
          )
          .then((res) => {
            if (res.data.success) {
              this.$refs.commentRef.value = "";
              this.showAnnuler();
              this.$refs.commentRef.removeAttribute("data");
              this.$refs.commentRef.removeAttribute("disabled");
              //this.$refs.commentCountRef.classList.add('show');
              this.pageComment = 1;
              this.getComments("Media");
              //$refs.commentListRef.classList.remove('hide');
            }
          })
          .catch((error) => { });
      }
      this.taggedFriends = [];
      this.taggedAdresses = [];
    },
    showComments(postId) {
      this.getComments("Media");
      this.$refs.commentListRef.classList.toggle("hide");
      this.pageComment = 1;
      this.lastCommentPage = 1;
      //this.isCommentReady = !this.isCommentReady ;
    },

    deleteComment(commentId, postId) {
      this.isCommentReady = false;
      this.axios.delete(`/commentaire/${commentId}`).then((res) => {
        this.pageComment = 1;
        this.$refs.commentCountRef.innerHTML =
          parseInt(this.$refs.commentCountRef.innerHTML) - 1 + " Comments";
        this.getComments("Media");
      });
    },
    editComment(commentId) {
      this.$refs.commentRef.focus();
      this.$refs.commentRef.setAttribute("disabled", "disabled");
      //get by id
      // open new input
      this.axios.get(`/commentaire/show/${commentId}`).then((res) => {
        ////console.log(res.data.commentaire);
        this.$refs.commentRef.removeAttribute("disabled");
        this.$refs.commentRef.value = res.data.commentaire.texte_com;
        this.$refs.commentRef.setAttribute("data", "toBeUpdated");
        this.$refs.commentRef.setAttribute("data-id", res.data.commentaire.id);
        this.$refs.commentRef.focus();
      });
    },

    cancelComment() {
      this.$refs.commentRef.value = "";
      this.$refs.commentRef.removeAttribute("data");
      this.showAnnuler();
    },
    getPrevComments(postId) {
      this.tooltipCommentJaimeRef = [];
      this.btnJaimeCommentRef = [];
      this.countJaimeCommentRef = [];

      let x = new Set(this.tooltipCommentJaimeRef);
      this.tooltipCommentJaimeRef = [...x];

      let t = new Set(this.btnJaimeCommentRef);
      this.btnJaimeCommentRef = [...t];

      let l = new Set(this.countJaimeCommentRef);
      this.countJaimeCommentRef = [...l];
      this.isCommentReady = false;
      this.isPrevCommentReady = false;
      this.pageComment++;
      if (this.pageComment <= this.lastCommentPage) {
        this.axios
          .get(`/commentaire/${postId}/Media?page=${this.pageComment}`)
          .then((res) => {
            this.lastCommentPage = res.data.commentaires.last_page;
            this.comments.unshift(
              ...res.data.commentaires.data.slice().reverse()
            );
            this.isCommentReady = true;

            this.isPrevCommentReady = true;
          })
          .catch((err) => {
            ////console.log(err);
          });
      }
    },
    getComments(commentableType) {
      this.tooltipCommentJaimeRef = [];
      this.btnJaimeCommentRef = [];
      this.countJaimeCommentRef = [];

      let x = new Set(this.tooltipCommentJaimeRef);
      this.tooltipCommentJaimeRef = [...x];

      let t = new Set(this.btnJaimeCommentRef);
      this.btnJaimeCommentRef = [...t];

      let l = new Set(this.countJaimeCommentRef);
      this.countJaimeCommentRef = [...l];

      this.axios
        .get(
          `/commentaire/${this.currentMediaId}/${commentableType}?page=${this.pageComment}`
        )
        .then((res) => {
          this.lastCommentPage = res.data.commentaires.last_page;
          this.comments = res.data.commentaires.data.slice().reverse();
          console.log(this.comments);
          this.isCommentReady = true;
          this.comments.forEach((ele) => {
            this.jaimesGroupComment[ele.id] = ele.countJaimeByComment;
            this.isJaimeGroupCommentReady[ele.id] = true;
          });
        })
        .catch((err) => {
          ////console.log(err);
        });
    },
    addJaimeComment() {
      //meme fonction de pub
    },
    deleteJaimeComment() {
      //meme function de pub
    },

    CF(commentUserId, currentUserId) {
      if (commentUserId == currentUserId) {
        return true;
      } else {
        return false;
      }
    },
    showAnnuler() {
      if (!this.$refs.commentRef.value == "") {
        this.$refs.annulerCommentBtnRef.classList.remove("hide");
      } else {
        this.$refs.annulerCommentBtnRef.classList.add("hide");
      }
    },

    /* ---------------------------------------------------------(jaimes)------------------------------------------------------- */
    dislike(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `<i class="socialClicked" style="color:rgb(233, 113, 15);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f44e.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 7px;"></i>
    <span class="socialClicked" style="color:rgb(233, 113, 15)">Dislike</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;

        this.axios.post("/jaime", {
          reaction_type: "7",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" 
        style="color:rgb(233, 113, 15);
        background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f44e.png);
        height: 1.5em;
        width: 1.5em;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 7px;"></i>
        <span class="socialClicked" style="color:rgb(233, 113, 15)">Dislike</span>`;
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime

          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "7",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                ////console.log('jaimeUpdated')     ;

                this.getGroupCountMedia();
              }
            });
        });
      }
    },

    //----------------------------------------------------------------------------------------------------------------------
    jaime(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `<i class="socialClicked" style="color:rgb(0, 0, 255);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f44d.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 7px;"></i>
    <span class="socialClicked" style="color:rgb(0, 0, 255)">Like</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;

        this.axios.post("/jaime", {
          reaction_type: "1",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" 
        style="color:rgb(0, 0, 255);
        background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f44d.png);
        height: 1.5em;
        width: 1.5em;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 7px;"></i>
        <span class="socialClicked" style="color:rgb(0, 0, 255)">Like</span>`;
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime

          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "1",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                ////console.log('jaimeUpdated')     ;

                this.getGroupCountMedia();
              }
            });
        });
      }
    },
    //----------------------------------------------------------------------------------------------------------------------
    jadore(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(255, 0, 0);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/2665.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(255, 0, 0)">Love</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "2",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(255, 0, 0);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/2665.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(255, 0, 0)">Love</span>`;
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime

          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "2",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                ////console.log('jaimeUpdated')     ;
                this.getGroupCountMedia();
              }
            });
        });
      }
    },

    //-----------------------------------------------------------------------------------------------------------------
    wouah(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f632.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "3",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f632.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime

          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "3",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                this.getGroupCountMedia();
              }
            });
        });
      }
    },
    //-----------------------------------------------------------------------------------------------------------------
    haha(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f923.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "4",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f923.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime
          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "4",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                ////console.log('jaimeUpdated')     ;
                this.getGroupCountMedia();
              }
            });
        });
      }
    },

    //----------------------------------------------------------------------------------------------------------------
    triste(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f622.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "5",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="socialClicked" style="color:rgb(247, 177, 37);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f622.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime

          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "5",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                this.getGroupCountMedia();
              }
            });
        });
      }
    },
    //----------------------------------------------------------------------------------------------------------------
    grrr(UserId, postId) {
      this.$refs.jaimeCountRef.classList.remove("hide");
      this.$refs.jaimeCountRef.classList.add("show");
      this.$refs.socialactRef.classList.remove("show");
      this.isJaimeGroupReady = false;
      if (!this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.socialactRef.setAttribute("data", "toBeUpdated");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="em socialClicked" style="color:rgb(233, 113, 15);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f620.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        this.$refs.jaimeCountRef.innerHTML =
          parseInt(this.$refs.jaimeCountRef.innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "6",
          jaimable_id: postId,
          jaimable_type: "App\\Models\\Media",
          visiteur_id: UserId,
        });
        this.getGroupCountMedia();
      } else {
        this.$refs.socialactRef.classList.remove("show");
        this.$refs.btnReactRef.classList.add("socialClicked");
        this.$refs.btnReactRef.innerHTML = `  <i class="em socialClicked" style="color:rgb(233, 113, 15);background-image: url(https://ressources.trippybook.com/assets/icons/72x72/1f620.png);height: 1.5em;
    width: 1.5em;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    vertical-align: middle;margin-bottom: 7px;"></i>
                              <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        //get JaimeID
        let jaimeId;
        this.axios.get(`/jaime/${postId}/Media/${UserId}/`).then((res) => {
          jaimeId = res.data.jaime.id;
          ////console.log(jaimeId);
          //Update Jaime
          this.axios
            .put(`jaime/${jaimeId}`, {
              reaction_type: "6",
              jaimable_id: postId,
              jaimable_type: "App\\Models\\Media",
              visiteur_id: UserId,
            })
            .then((res) => {
              if (res.data.success) {
                this.getGroupCountMedia();
              }
            });
        });
      }
    },

    //----------------------------------------------------------------------------------------------------------------

    deletReaction(userId, postId, jaimes) {
      this.isJaimeGroupReady = false;

      this.hovering = false;
      this.$refs.socialactRef.removeAttribute("data");
      this.$refs.socialactRef.classList.remove("show");
      if (this.$refs.btnReactRef.classList.contains("socialClicked")) {
        this.$refs.btnReactRef.innerHTML = `
                              Like`;
        let j = 1;
        do {
          if (jaimes > 0) {
            this.$refs.jaimeCountRef.innerHTML =
              parseInt(this.$refs.jaimeCountRef.innerHTML) - 1;
            this.$refs.btnReactRef.classList.remove("socialClicked");
            j++;
          } else {
            this.$refs.jaimeCountRef.innerHTML = `0`;
            this.$refs.btnReactRef.classList.remove("socialClicked");
          }
        } while (j == 0);
        this.$refs.btnReactRef.classList.remove("socialClicked");
        this.axios
          .post("/jaime/delete", {
            post_id: postId,
            visiteur_id: userId,
            jaimable_type: "App\\Models\\Media",
          })
          .then((res) => {
            this.getGroupCountMedia();
          })
          .catch((error) => { });
      } //else
      else {
        this.jaime(userId, postId, jaimes);
      }
    },
    //-------------------------------------(jaimeComment)---------------------------------------------
    jaimeComment(UserId, commentID, i) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.countJaimeCommentRef[i].classList.remove("hide");
      this.countJaimeCommentRef[i].classList.add("show");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (!this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.tooltipCommentJaimeRef[i].setAttribute("data", "toBeUpdated");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = ` 
    <span class="socialClicked" style="color:blue">Like</span>`;
        this.countJaimeCommentRef[i].innerHTML =
          parseInt(this.countJaimeCommentRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "1",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        });
        this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
          this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
          this.isJaimeGroupCommentReady[commentID] = true;

        });
      } else {
        this.tooltipCommentJaimeRef[i].classList.remove("show");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = `
        <span class="socialClicked" style="color:blue">Like</span>`;
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "1",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                    this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                    this.isJaimeGroupCommentReady[commentID] = true;
                  });
                }
              });
          });
      }
    },
    //-----------------------------------------------------------------------------------------------------
    jadoreComment(UserId, commentID, i) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.countJaimeCommentRef[i].classList.remove("hide");
      this.countJaimeCommentRef[i].classList.add("show");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (!this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.tooltipCommentJaimeRef[i].setAttribute("data", "toBeUpdated");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = `
    <span class="socialClicked" style="color:red">Love</span>`;
        this.countJaimeCommentRef[i].innerHTML =
          parseInt(this.countJaimeCommentRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "2",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        });
        this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
          this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
          this.isJaimeGroupCommentReady[commentID] = true;
        });
      } else {
        this.btnJaimeCommentRef[i].innerHTML = `
        <span class="socialClicked" style="color:red">Love</span>`;
        this.tooltipCommentJaimeRef[i].classList.remove("show");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "2",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                    this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                    this.isJaimeGroupCommentReady[commentID] = true;
                  });
                }
              });
          });
      }
    },

    //-----------------------------------------------------------------------------------------------------------------
    wouahComment(UserId, commentID, i) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.countJaimeCommentRef[i].classList.remove("hide");
      this.countJaimeCommentRef[i].classList.add("show");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (!this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.tooltipCommentJaimeRef[i].setAttribute("data", "toBeUpdated");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = `
    <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        this.countJaimeCommentRef[i].innerHTML =
          parseInt(this.countJaimeCommentRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "3",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        });
        this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
          this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
          this.isJaimeGroupCommentReady[commentID] = true;
        });
      } else {
        this.btnJaimeCommentRef[i].innerHTML = `
        <span class="socialClicked" style="color:rgb(247, 177, 37)">Wouah</span>`;
        this.tooltipCommentJaimeRef[i].classList.remove("show");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "3",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                    this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                    this.isJaimeGroupCommentReady[commentID] = true;
                  });
                }
              });
          });
      }
    },
    //-----------------------------------------------------------------------------------------------------------------
    hahaComment(UserId, commentID, i) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.countJaimeCommentRef[i].classList.remove("hide");
      this.countJaimeCommentRef[i].classList.add("show");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (!this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.tooltipCommentJaimeRef[i].setAttribute("data", "toBeUpdated");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = `
    <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        this.countJaimeCommentRef[i].innerHTML =
          parseInt(this.countJaimeCommentRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "4",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        });
        this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
          this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
          this.isJaimeGroupCommentReady[commentID] = true;
        });
      } else {
        this.btnJaimeCommentRef[i].innerHTML = `
        <span class="socialClicked" style="color:rgb(247, 177, 37)">Haha</span>`;
        this.tooltipCommentJaimeRef[i].classList.remove("show");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "4",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                    this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                    this.isJaimeGroupCommentReady[commentID] = true;
                  });
                }
              });
          });
      }
    },

    //----------------------------------------------------------------------------------------------------------------
    tristeComment(UserId, commentID, i) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.countJaimeCommentRef[i].classList.remove("hide");
      this.countJaimeCommentRef[i].classList.add("show");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (!this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.tooltipCommentJaimeRef[i].setAttribute("data", "toBeUpdated");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = `
    <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        this.countJaimeCommentRef[i].innerHTML =
          parseInt(this.countJaimeCommentRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "5",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        });
        this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
          this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
          this.isJaimeGroupCommentReady[commentID] = true;
        });
      } else {
        this.btnJaimeCommentRef[i].innerHTML = `
        <span class="socialClicked" style="color:rgb(247, 177, 37)">Sad</span>`;
        this.tooltipCommentJaimeRef[i].classList.remove("show");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "5",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                    this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                    this.isJaimeGroupCommentReady[commentID] = true;
                  });
                }
              });
          });
      }
    },
    //----------------------------------------------------------------------------------------------------------------
    grrrComment(UserId, commentID, i) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.countJaimeCommentRef[i].classList.remove("hide");
      this.countJaimeCommentRef[i].classList.add("show");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (!this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.tooltipCommentJaimeRef[i].setAttribute("data", "toBeUpdated");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        this.btnJaimeCommentRef[i].innerHTML = `
    <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        this.countJaimeCommentRef[i].innerHTML =
          parseInt(this.countJaimeCommentRef[i].innerHTML) + 1;
        this.axios.post("/jaime", {
          reaction_type: "6",
          jaimable_id: commentID,
          jaimable_type: "App\\Models\\Commentaire",
          visiteur_id: UserId,
        });
        this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
          this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
          this.isJaimeGroupCommentReady[commentID] = true;
        });
      } else {
        this.btnJaimeCommentRef[i].innerHTML = `
        <span class="socialClicked" style="color:rgb(233, 113, 15)">Angry</span>`;
        this.tooltipCommentJaimeRef[i].classList.remove("show");
        this.btnJaimeCommentRef[i].classList.add("socialClicked");
        //get JaimeID
        let jaimeId;
        this.axios
          .get(`/jaime/${commentID}/Commentaire/${UserId}/`)
          .then((res) => {
            jaimeId = res.data.jaime.id;
            //console.log(jaimeId);
            //Update Jaime

            this.axios
              .put(`jaime/${jaimeId}`, {
                reaction_type: "6",
                jaimable_id: commentID,
                jaimable_type: "App\\Models\\Commentaire",
                visiteur_id: UserId,
              })
              .then((res) => {
                if (res.data.success) {
                  //console.log('jaimeUpdated')     ;
                  this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                    this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                    this.isJaimeGroupCommentReady[commentID] = true;
                  });
                }
              });
          });
      }
    },
    //--------------------------------------------------------------------------------------------------------
    deletReactionComment(i, userId, commentID, jaimes) {
      this.isJaimeGroupCommentReady[commentID] = false;
      this.hoveringComment = false;
      this.tooltipCommentJaimeRef[i].removeAttribute("data");
      this.tooltipCommentJaimeRef[i].classList.remove("show");
      if (this.btnJaimeCommentRef[i].classList.contains("socialClicked")) {
        this.btnJaimeCommentRef[i].innerHTML = `  
                              J'aime`;
        let j = 1;
        do {
          if (jaimes > 1) {
            this.countJaimeCommentRef[i].innerHTML =
              parseInt(this.countJaimeCommentRef[i].innerHTML) - 1;
            this.btnJaimeCommentRef[i].classList.remove("socialClicked");
            j++;
          } else {
            this.countJaimeCommentRef[i].innerHTML = `0`;
            this.btnJaimeCommentRef[i].classList.remove("socialClicked");
          }
        } while (j == 0);
        this.btnJaimeCommentRef[i].classList.remove("socialClicked");
        this.axios
          .post("/jaime/delete", {
            post_id: commentID,
            visiteur_id: userId,
            jaimable_type: "App\\Models\\Commentaire",
          })
          .then((res) => {
            if (res.data.success) {
              this.axios.get(`/jaimes/byComment/${commentID}`).then((res) => {
                this.jaimesGroupComment[commentID] = res.data.countJaimeByComment;
                this.isJaimeGroupCommentReady[commentID] = true;
              });
            }
          })
          .catch((error) => { });
      } //else
      else {
        this.jaimeComment(userId, commentID, i, jaimes);
      }
    },

  },

};
</script>

<style lang="scss" scoped>
@import "../../assets/css/lightbox.css";
@import "../../assets/css/emoji.css";

.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #1e74fd !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;
  }
}

.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modall {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.7);
  background-color: rgba(0, 0, 0, 1);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  width: 40%;
  margin: 0 auto;
  border-radius: 10px;
  overflow-x: hidden
}

.modal-container {
  margin: 30px 50px;
  padding: 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}

.modal-header-txt {
  display: inline-block;
}

.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }

  float: right;
  font-size: 32px;
}

.dropzone__box {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}

.loader-img {
  top: 47%;
  left: 47%;
}

body.lb-disable-scrolling {
  overflow: hidden;
}

.lightboxOverlay {
  position: fixed;
  overflow: hidden;

  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  display: none;
  height: 100vh !important;
}

.lightbox {
  position: absolute;
  left: 0;
  width: calc(100% - 320px);
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: normal;
  outline: none;
}

body.right-comment-none .lightbox {
  width: 100% !important;
}

body.right-comment-none .right-comment {
  display: none;
}

.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;

  /* Image border */
  /*border: 4px solid white;*/
}

.lightbox a img {
  border: none;
}

.lb-outerContainer {
  //position: relative;
  zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 10px;

  /* Background color behind image.
     This is visible during transitions. */
  background-color: black;
}

.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}

.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
  background: url(../images/loading.gif) no-repeat;
}

.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 30%;
  width: 100%;
  z-index: 10;
}

.lb-container>.nav {
  left: 0;
}

.lb-nav button {
  outline: none;
  border: none;
  height: 500px;
  background-image: url("data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==");
}

.lb-prev,
.lb-next {
  height: 90px !important;
  cursor: pointer;
  display: block;
  top: 120%;
  position: relative
}

.lb-nav button.lb-prev {
  width: 12%;
  left: 0;
  float: left;
  background: url(https://ressources.trippybook.com/assets/images/prev.png) left 48% no-repeat;
  /*filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);*/
  opacity: 1;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav button.lb-prev:hover {
  /*filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);*/
  opacity: 1;
}

.lb-nav button.lb-next {
  width: 12%;
  right: 0;
  float: right;
  background: url(https://ressources.trippybook.com/assets/images/next.png) right 48% no-repeat;
  /*filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);*/
  opacity: 1;
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  -o-transition: opacity 0.6s;
  transition: opacity 0.6s;
}

.lb-nav button.lb-next:hover {
  /*filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);*/
  opacity: 1;
}

.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}

.lb-data {
  padding: 0 4px;
  color: #ccc;
}

.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}

.lb-data .lb-caption {
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
}

.lb-data .lb-caption a {
  color: #4ae;
}

.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999999;
}

.lb-data .lb-close {
  //border-top-right-radius:10px;
  transition: all 0.4s ease-in-out;
  display: block;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 25px;
  background-size: contain;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.9;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background: #f00 !important;
  color: #fff !important;
  z-index: 999999999 !important;

  &:hover {
    background: rgba(255, 0, 0, 1) !important;
  }

  &:hover .fa-times {
    color: #fff !important;
  }
}

.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

.lb-dataContainer.position-relative {
  top: 22px;
  z-index: 99;
  width: 600px !important;
}

.social-action {
  padding: 0px;
  font-size: 15px !important;

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
}

.social-actions {
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: 0px 1px rgba(128, 128, 128, 0.2);

  user-select: none;
}

.emoji-wrap {
  left: 10px;
  top: 5px;
  // display:block
}

.hide {
  display: none !important;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.annulerCommentBtn,
.comment-pagination {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}

.border-rad {
  border-radius: 15px !important;
}

.border-logo-rad {
  border-radius: 8px !important;
}

.tof-profil {
  margin: 1px 0 0 5px;
}

.d-b {
  display: block !important;
}

.comment-count {
  user-select: none;

  &:hover {
    cursor: pointer;
    color: #1e74fd !important;
    text-decoration: underline !important;
  }
}

.jaime-group {
  position: absolute !important;
  left: -80px !important;
  top: 14px !important;
}

.loader-jaime {
  position: absolute;
  top: 0px;
  left: 0px;
  padding-bottom: 2px;
}

.scroll-bar {
  overflow-x: hidden !important;
}

.w-90 {
  width: 143% !important;
}

.comment-jaime {
  float: left !important;
}

.clear-float {
  clear: both !important;
}

.btn-jaime-comment {
  padding: 0px 10px;
  display: inline-block;
  font-weight: bold !important;
  font-size: 13px;
  color: #000;

  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
    color: #2e58a6;
  }
}

.comment-focus {
  border: 2px#5869b8b4 solid;
}

.emoji-wrap-comment {
  position: absolute;
  z-index: 99;
  width: 118%;
  left: -30px;
  top: -55px;
}

.count-jaime-comment {
  &:hover {
    cursor: pointer !important;
  }

  float: right;
  display: inline-block;
  background-color: #f5f5f5;
  width: fit-content;
  //min-width: 10%;
  border-radius: 10px;
  text-align: end;
  border: 2px solid #fff;
  padding: 0 2px !important;
  line-height: 1.2em;
  // margin-top: 25px;
}

.fas,
.fa-times {
  color: white;
  padding-top: 0px;
}

.media-fit {
  object-fit: cover !important;

  margin: 0;
  position: relative;
  top: 50%;

  transform: translateY(-50%);
}

.carousel-item {
  background-color: #000;
}

.chat-left {
  height: calc(100vh - 235px);
}

//------------------------------------------(MediaQuery)-------------------------------------
@media (max-width: 1200px) {

  .lightbox .lb-image,
  .lb-outerContainer {
    width: 100% !important;
    height: 400px !important;
  }
}

@media (max-width: 768px) {

  .lb-prev,
  .lb-next {
    height: 70px !important;
    top: 110%;
    position: relative
  }

  .emoji-wrap {
    .emojis {
      padding-top: 0.25rem !important;

    }
  }

  .emoji-wrap-comment {
    width: 67%;
    left: -6px;
    top: -39px;

    .emojis {
      padding-top: 0.25rem !important;
    }
  }

  .lightbox {
    top: 0 !important;
    width: 100% !important;
  }

  .lightbox .lb-image,
  .lb-outerContainer {
    width: 100% !important;
    height: 250px !important;
  }

  //.lb-outerContainer {height: 0; }
  .chat-left {
    height: calc(100vh - 730px);
  }


  .lb-dataContainer {
    position: absolute;
    top: 0;
    z-index: 10;
  }

  .right-comment {
    top: auto !important;
    width: 100% !important;
    padding-bottom: 30px;
    height: 100% !important;

  }

  .loader-container {
    margin-top: 8rem !important
  }

  .lightboxOverlay {
    background-color: #fff;
    opacity: 1 !important;
  }

  .lb-data .lb-number {
    color: #fff;
  }

  .jaime-group {

    left: -110px !important;
  }
}

@media (min-width: 768px) {
  .addComment {
    bottom: 10px
  }
}

.social-action-container {
  padding-left: 0;
  padding-right: 0;
}

.form-group.icon-input-tag i {
  position: absolute;
  right: 12px;
  bottom: 10px;
}

.border-light-md-tag {
  border: 2px#5869b8b4 solid;
}

.tags {
  color: #2e58a6;

  &:hover {
    cursor: pointer !important;
    text-decoration: underline !important;
  }
}

.delete-tag-btn {
  color: black !important;

  &:hover {
    color: red !important;
  }
}

.autocomplete-results {
  border-radius: 5px;
  width: 95%;
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  // height: 150px;
  min-height: 1em;
  max-height: 22em;
  overflow: hidden;
  position: absolute;
  z-index: 999;
  bottom: 0px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 0px 1px rgba(128, 128, 128, 0.3);
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 5px 2px;
  cursor: pointer;
  font-weight: 500;
  border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #eee;
}

.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.friends-tag {
  flex-direction: column;
}

.edit-comment {
  padding: 0px !important;
  min-width: 6rem !important;
  text-align: center;
}

.del-up {
  padding: 2px 15px;
  transition: all 0.4s ease-in-out;

  h4 {
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
  }

  &:hover {
    background-color: #ccc;
    box-shadow: 2px 2px #bbb inset;
    transition: all 0.3s ease-in-out;
  }

  &:hover h4 {
    color: #2e58a6 !important;
  }
}

.v-top {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  margin-top: 0rem !important;
}

.v-bottom {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.comment-jaime-count {
  font-weight: 700;
  font-size: 10px;
  margin: 2px 0px 0px 2px;

  &:hover {
    cursor: pointer !important;
  }
}

.emoji-bttn {
  &:hover {
    cursor: pointer
  }
}

.emoji-bttn .em {
  height: 1.5em !important;
  width: 1.5em !important;

}

.jaime-group-comment .em {
  height: 1em !important;
  width: 1em !important;
  margin-bottom: 2px;

  &:hover {
    cursor: pointer !important;
  }
}
</style>
