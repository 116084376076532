<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="card w-100 border-0 p-0 bg-white shadow-xss rounded-xxl">
        <template v-if="isUserReady">
          <div @click="toggleModalCover" v-if="currentUser.id == profilID" v-tooltip.bottom-end="'Update cover photo'"
            class="
                      card-body
                      h240
                      p-0
                      rounded-xxl
                      overflow-hidden
                      m-3
                      container-cover
                      position-relative
                      bg-image-cover bg-image-center
                    " :style="`background-image: url(${assetsBaseUrl}${user.cover});`">
            <a class="update-cover"><i class="far fa-edit"></i></a>
          </div>
          <div v-else class="
                      card-body
                      h240
                      p-0
                      rounded-xxl
                      overflow-hidden
                      m-3
                      position-relative
                      bg-image-cover bg-image-center
                    " :style="`background-image: url(${assetsBaseUrl}${user.cover});`"></div>
        </template>
        <template v-else>
          <div class="
                      card-body
                      p-0
                      rounded-xxl
                      overflow-hidden
                      m-3
                      position-relative
                      h240
                      bg-image-cover bg-image-center
                    " :style="`background-image: url(${assetsBaseUrl}images/cover-default.png);`"></div>
        </template>

        <div class="card-body p-0 position-relative">
          <template v-if="currentUser.id == profilID">
            <figure v-if="isUserReady" @click="toggleModalLogo" class="
                        avatar
                        position-absolute
                        w100
                        h100
                        overflow-hidden
                        z-index-1
                        container-logo
                      " style="top: -40px; left: 30px" v-tooltip.bottom-start="'Update profil image'">
              <img v-if="isUserReady" :src="`${assetsBaseUrl}${user.photo}`" alt="image"
                class="float-right p-1 bg-white rounded-circle w-100 logo-p" />
              <a class="update-logo"><i class="far fa-edit"></i></a>
            </figure>
          </template>
          <template v-else>
            <figure v-if="isUserReady" class="avatar position-absolute w100 z-index-1" style="top: -40px; left: 30px">
              <img v-if="isUserReady" :src="`${assetsBaseUrl}${user.photo}`" alt="image"
                class="float-right p-1 bg-white rounded-circle w-100 logo-p" />
            </figure>
          </template>

          <figure v-if="!isUserReady" class="avatar position-absolute w100 z-index-1 pb-2" style="top: -40px; left: 30px">
            <div class="float-right p-4 bg-white rounded-circle w-100 logo-p">
              <loaderImage></loaderImage>
            </div>
          </figure>

          <h4 class="fw-700 font-sm mt-0 mb-lg-5 mb-4 pl-15">
            {{ user.prenom }} {{ user.nom
            }}<span class="fw-500 font-xssss text-grey-500 mt-1 mb-3 d-block">{{
  user.email
}}</span>
          </h4>
          <div class="
                      d-flex
                      align-items-center
                      justify-content-center
                      position-absolute-md
                      right-15
                      top-0
                      me-2
                    ">
            <template v-if="isUserReady">
              <template v-if="!isMyProfil">
                <template v-if="friendStatus == 0 && senderID == currentUser.id">
                  <a @click="removeFriend" class="
                              p-2
                              lh-20
                              w100
                              bg-grey
                              text-grey-800 text-center
                              font-xssss
                              fw-600
                              ls-1
                              rounded-md
                              btn-add-friend
                            " ref="btnAddFriend">Cancel</a>
                </template>
                <template v-else-if="friendStatus == 0 && receiverID == currentUser.id">
                  <a @click="confirmFriend" class="
                              p-2
                              lh-20
                              w100
                              me-2
                              text-white text-center
                              font-xssss
                              fw-600
                              ls-1
                              rounded-md
                              confirm-btn
                              btn-add-friend
                            " ref="btnAddFriend">Confirm</a>
                  <a @click="removeFriend" class="
                              p-2
                              lh-20
                              w100
                              bg-grey
                              text-grey-800 text-center
                              font-xssss
                              fw-600
                              ls-1
                              rounded-md
                              btn-add-friend
                            " ref="btnAddFriend">Cancel</a>
                </template>
                <template v-else>
                  <!-- test amis ou pas -->
                  <a v-if="friendStatus != 1" @click="addFriend" class="
                              p-2
                              lh-20
                              w100
                              me-2
                              text-white text-center
                              font-xssss
                              fw-600
                              ls-1
                              rounded-md
                              confirm-btn
                              btn-add-friend
                            " ref="btnAddFriend">Add Friend</a>
                  <a v-else-if="friendStatus == 1" @click="removeFriend" class="
                              p-2
                              lh-20
                              w100
                              bg-grey
                              text-grey-800 text-center
                              font-xssss
                              fw-600
                              ls-1
                              rounded-md
                              btn-add-friend
                            " ref="btnAddFriend">Unfriend</a>
                  <!-- request sent ou pas -->
                </template>
              </template>
            </template>
          </div>
        </div>

        <div class="card-body d-block w-100 shadow-none mb-0 p-0 border-top-xs">
          <Splide class="ps-3" :options="{
            rewind: true,
            perPage: 3,
            autoWidth: true,
            arrows: false,
            pagination: false,
            wheel: false,
            isNavigation: false,
          }">
            <SplideSlide>
              <li class="list-inline-item me-5">
                <router-link :to="{
                  name: 'feedvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs1" data-toggle="tab"><i class="fas fa-home"></i> Home</router-link>
              </li>
            </SplideSlide>
            <SplideSlide>
              <li class="list-inline-item me-5">
                <router-link :to="{
                  name: 'photosvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs2" data-toggle="tab"><i class="far fa-images"></i> Photos</router-link>
              </li>
            </SplideSlide>
            <SplideSlide>
              <li class="list-inline-item me-5">
                <router-link :to="{
                  name: 'videosvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs3" data-toggle="tab"><i class="fas fa-video"></i> Videos</router-link>
              </li>
            </SplideSlide>
            <SplideSlide>
              <li class="list-inline-item me-5">
                <router-link :to="{
                  name: 'followingvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs3" data-toggle="tab"><i class="fas fa-user-plus"></i> Following<span
                    class="fw-700 font-xsssss mb-2">({{ followsCount }})
                  </span></router-link>
              </li>
            </SplideSlide>
            <SplideSlide>
              <li v-if="profilID == currentUser.id" class="list-inline-item me-5">
                <router-link :to="{
                  name: 'favoritesvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs3" data-toggle="tab"><i class="fas fa-heart"></i> Favorites<span
                    class="fw-700 font-xsssss mb-2">({{ favorisCount }})
                  </span></router-link>
              </li>
            </SplideSlide>
            <SplideSlide>
              <li v-if="profilID == currentUser.id" class="list-inline-item me-5">
                <router-link :to="{
                  name: 'myamisvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs3" data-toggle="tab"><i class="fas fa-users"></i> Friends<span
                    class="fw-700 font-xsssss mb-2">({{ friendCount }})
                  </span>
                </router-link>
              </li>
            </SplideSlide>

            <SplideSlide>
              <li class="list-inline-item me-5">
                <router-link :to="{
                  name: 'aproposvisiteur',
                  params: { id: this.$route.params.id },
                }" class="
                            fw-700
                            font-xsss
                            text-grey-500
                            pt-3
                            pb-3
                            ls-1
                            d-inline-block
                          " href="#navtabs3" data-toggle="tab"><i class="fas fa-info-circle"></i> About</router-link>
              </li>
            </SplideSlide>
          </Splide>
          <!-- </ul> -->

          <!-- ------------------(****)------------->
        </div>
      </div>
    </div>

    <div class="col-xl-8 col-xxl-9 col-lg-8">
      <StoryHighlight ref="highlightStoryRef"></StoryHighlight>

      <CreateFeed></CreateFeed>

      <div class="card w-100 shadow-xss rounded-xxl border-0 p-0 mb-3 mt-0">
        <router-view />
      </div>
    </div>

    <div class="col-xl-4 col-xxl-4 col-lg-4 ps-lg-0 pt-1" v-if="!isMyfriends">
      <DemandeAmis />
      <DemandeAmisSent />
    </div>
  </div>

  <!-- ------------------------------------------------(MODAL)-------------------------------------------->
  <BaseModal v-if="isShowModal" @close="toggleModalClose" scrollable title="Ajouter une publication">
  </BaseModal>

  <ModalCover v-if="isShowModalCover" @close="toggleModalCoverClose" scrollable title="Change cover photo"
    :userId="currentUser.id" @refreshCover="refreshCover">
  </ModalCover>

  <ModalLogo v-if="isShowModalLogo" @close="toggleModalLogoClose" scrollable title="Change profil photo"
    :userId="currentUser.id" @refreshLogo="refreshLogo">
  </ModalLogo>

  <!-- <ModalStoryHighlight
    v-if="isShowModal"
    @close="toggleModalClose"
    @refreshStoryHighlight="refreshStoryHighlight"
  >
  </ModalStoryHighlight> -->
</template>

<script>
import ModalCover from "../../layouts/modal/modalCoverUser.vue";
import ModalLogo from "../../layouts/modal/modalPhotoUser.vue";
import BaseModal from "../../layouts/modal/modal.vue";
import CreateFeed from "../Feeds/CreateFeed.vue";
import Skeleton from "../../layouts/skeleton/Skeleton.vue";
import SkeletonComment from "../../layouts/skeleton/SkeletonComment.vue";
import Loader from "../../layouts/loader/loader.vue";
import ModalComment from "../../layouts/modal/modalComment.vue";
import loaderImage from "../../layouts/loader/loaderImage.vue";
import DemandeAmis from "../Feeds/DemandeAmis.vue";
import DemandeAmisSent from "../Feeds/DemandeAmisSent.vue";
import StoryHighlight from "../../pages/profil/Visiteur/hilightStrory.vue";
import ModalStoryHighlight from "../../layouts/modal/modalStoryHighlight.vue";
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    StoryHighlight,
    BaseModal,
    CreateFeed,
    Skeleton,
    Loader,
    SkeletonComment,
    ModalComment,
    loaderImage,
    DemandeAmis,
    DemandeAmisSent,
    ModalCover,
    ModalLogo,
    ModalStoryHighlight,
    Splide,
    SplideSlide,
  },
  destroyed() {
    const controller = new AbortController();
    controller.abort()
  },
  mounted() {
    this.$refs.highlightStoryRef.getStoryHighlight();
    this.getFeeds();
    this.getUser();
    this.getFriendStatus();
    this.profilID = this.$route.params.id;

    //this.checkHover();
  },
  beforeUpdate() {
    this.profilID = this.$route.params.id;
  },
  watch: {
    profilID(newprofilID, oldprofilID) {
      if (newprofilID != oldprofilID) {
        this.getFeeds();
        this.getUser();
        this.getFriendStatus();
        this.$refs.highlightStoryRef.getStoryHighlight();
        this.getStoryHighlight();
      }
    },
    "$route.params.id": {
      handler: function (value) {
        this.getFeeds();
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      storiesLength: 0,
      friendCount: 0,
      profilID: null,
      friendStatus: false,
      senderID: null,
      receiverID: null,
      friendStatus: false,
      isUserReady: false,
      isMyProfil: false,
      adresse: [],
      feeds: [],
      comments: [],
      isReady: false,
      user: [],
      //Page---------------------------
      isShowModal: false,
      lastPage: 1,
      lastCommentPage: 1,
      page: 1,
      pageComment: 1,
      isPrevCommentReady: true,
      userId: Number,
      isShowModalCover: false,
      isShowModalLogo: false,
      //-------------------------------
      followsCount: 0,
      favorisCount: 0,
    };
  },

  methods: {
    getStoryHighlight() {
      this.axios
        .post("getStoryHighlight", {
          userID: this.$route.params.id,
        })
        .then((res) => {
          //console.log(res.data.stories)
          this.storiesLength = res.data.stories.length;
        });
    },
    toggleModalCover() {
      this.isShowModalCover = !this.isShowModalCover;
    },
    toggleModalLogo() {
      this.isShowModalLogo = !this.isShowModalLogo;
    },

    toggleModalCoverClose() {
      this.isShowModalCover = false;
    },
    toggleModalLogoClose() {
      this.isShowModalLogo = false;
    },
    refreshLogo() {
      this.getUser();
    },

    refreshCover() {
      this.getUser();
    },
    confirmFriend() {
      this.axios
        .post("amis/confimer", {
          visiteur_id: this.$route.params.id,
        })
        .then((res) => {
          this.getFriendStatus();
        });
    },
    removeFriend() {
      this.axios
        .post("amis/supprimerAmis", {
          visiteur_id: this.$route.params.id,
        })
        .then((res) => {
          this.getFriendStatus();
        });
    },
    getFriendStatus() {
      this.axios
        .post("/amis/friendStatus", {
          visiteur_id: this.$route.params.id,
        })
        .then((res) => {
          this.friendStatus = res.data.friendStatus;
          this.senderID = res.data.sender;
          this.receiverID = res.data.receiver;
          // console.log(this.friendStatus);
          // console.log(this.senderID);
          // console.log(this.receiverID);
        })
        .catch((err) => {
          this.friendStatus = false;
        });
    },

    addFriend() {
      this.axios
        .post("amis/ajouterAmis", {
          visiteur_id: this.currentUser.id,
          receiver_id: this.$route.params.id,
        })
        .then((res) => {
          this.getFriendStatus();
        });
    },
    toggleModal() {
      this.isShowModal = !this.isShowModal;
    },
    toggleModalClose() {
      this.isShowModal = false;
    },

    getUser() {
      this.isUserReady = false;
      this.axios.get(`show/${this.$route.params.id}`).then((res) => {
        this.user = res.data.visiteur;
        this.isMyProfil = res.data.isMyProfil;
        this.isUserReady = true;
        this.friendCount = res.data.friendCount;
        this.followsCount = res.data.followsCount;
        this.favorisCount = res.data.favorisCount;

        //console.log(this.user);
      });
    },

    getFeeds() {
      this.axios
        .get(`publications/${this.$route.params.id}?page=${this.page}`)
        .then((res) => {
          this.feeds.push(...res.data.feeds.data);
          this.adresse = res.data.Adresse;
          this.isReady = true;
          this.lastPage = res.data.feeds.last_page;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  computed: {

    isMyfriends() {
      return this.$route.name === 'myamisvisiteur' || this.$route.name === 'receivedRequests' || this.$route.name === 'sentRequests' || this.$route.name === 'suggestedFriends';

    },

    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.addPubBtn span {
  user-select: none;
  transition: all 0.4s ease-in-out;

  &:hover {
    cursor: pointer;

    text-decoration: underline !important;
  }
}

.router-link-active.router-link-exact-active {
  border-bottom: 2px#333 solid !important;
  color: #000 !important;
}

.nav-tabs li a {
  &:hover {
    border-bottom: 2px#aaa solid !important;
    color: #000 !important;
  }

  transition: all 0.1s ease-in-out !important;
}

.btn-add-friend {
  &:hover {
    cursor: pointer;
    box-shadow: 1px 1px 1px #8888884d inset;
  }

  border-radius: 5px;
  transition: all 0.4s ease-in-out;
}

.rounded-circle {
  border-radius: 10px !important;
}

.confirm-btn {
  background-color: #2e58a6 !important;
}

.rounded-md {
  &:hover {
    box-shadow: 2px 2px 2px #8888884d;
  }

  border-radius: 5px;
}

.text-grey-500 {
  color: #adb5bd !important;
}

.container-cover {
  transition: transform 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  &:hover .update-cover {
    visibility: visible;
    transform: scale(2);
  }
}

.update-logo {
  color: rgba(150, 150, 150, 0.9);
  transition: transform 0.4s ease-in-out;
  position: absolute !important;
  font-size: 10px;
  visibility: hidden;
  top: 63%;
  left: 80% !important;
  z-index: 5;
}

.update-cover {
  transition: transform 0.4s ease-in-out;
  position: absolute !important;
  visibility: hidden;
  color: rgba(0, 0, 0, 0.3);
  bottom: 5%;
  left: 95%;
  font-size: 17px;
}

.h240 {
  height: 242px !important;
}

.container-logo {
  transition: transform 0.4s ease-in-out;

  &:hover {
    cursor: pointer;
  }

  &:hover .update-logo {
    visibility: visible !important;
    transform: scale(2);
  }
}

.h100 {
  height: 100px !important;
}

.row {
  overflow-x: hidden;
}

.is-active {
  border-bottom: 0 !important;
}

.nav {
  flex-wrap: nowrap;
}

@media (max-width: 768px) {
  .h240 {
    height: 150px !important;
    margin-bottom: 0rem !important;
  }

  .avatar {
    width: 70px !important;
  }

  .justify-content-center {
    justify-content: flex-end !important;
  }

  .row>* {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .rounded-3 {
    border-radius: 0px !important;
  }

  .product-info-tab li {
    margin-right: 6px !important;
  }

  .me-5 {
    margin-right: 1em !important;
  }
}

.pl-15 {
  padding-left: 135px !important
}

.logo-p {
  max-height: 90px;
}
</style>
