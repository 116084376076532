<template lang="">
  
    <div class="preloader-wrap">
        <div class="box shimmer">
            <div class="lines">
                <div class="line s_shimmer"></div>
               
                
                
            </div>
        </div>
      
  
    </div>
    <!-- loader wrapper -->
</template>
<script>
export default {
    name: "SkeletonBtn",
};
</script>
<style lang="scss" scoped>

// .lines{
//     height: 100% !important

// }
.line{
    width: 50px !important; 
    height: 80px !important;
}
.box{
    height: 10vh !important;
    margin: 0 !important
}


</style>
