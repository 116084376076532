<template lang="">
  <div>
    <div v-if="loading" class="loading">
      <Loader></Loader>
    </div>

    <nav class="navigation scroll-bar" :class="filterToggle? 'active-menu nav-active' : '' " ref="navigationRef" id="navigationRef">
      <div class="container ps-0 pe-0">
        <div class="nav-content">
           
           
           <div class="fw-600 font-14 text-grey-500 bg-white bg-transparent-card">
              
          <label class="toggle toggle-menu close-filter-m">
            <input type="checkbox" @click="closeFilter" ref="toggleMenuInput" id="toggle-menu">
            <a class="lb-close" style="z-index: 9999;">
          <i class="fas fa-times" ></i></a>
            <!-- <i class="fas fa-times fa-2x me-1"></i> -->
      
          </label>
          
            </div>
            
           <div
            class="
              nav-wrap
              
              rounded-xxl
              
              pt-3
              pb-1
            "
          >
         
            <div class="nav-caption fw-600 font-14 text-grey-500 filter-menu">
              
                 <portal-target v-if="filterToggle" name="filter"/>
              
              </div>
              </div>
          <div
            class="
              nav-wrap
              bg-white bg-transparent-card
              rounded-xxl
              shadow-xss
              pt-3
              pb-1
              mb-2
              mt-2
            "
          >
          
          
        
            <ul class="mb-1 top-content" @click="closeFilter">
              
              <li>
                <router-link  to="/feeds" class="nav-content-bttn open-font"
                  ><i v-tooltip.right-start="'Feeds'" class="feather-tv btn-round-md bg-blue-gradiant me-3"></i
                  ><span>Feeds</span></router-link
                >
                
              </li>
              
              <li class="adrs-aprox">
                <router-link to="/adressesaproximite" class="nav-content-bttn open-font"
                  ><i v-tooltip.right-start="'Near by addresses'"  class="feather-globe btn-round-md bg-mini-gradiant"></i
                  ><span class="adrs-aprox-span" >Nearby addresses
</span></router-link
                >
              </li>
             
                
             
         
            </ul>
          </div>

          <div
            class="
              nav-wrap
              bg-white bg-transparent-card
              rounded-xxl
              shadow-xss
              pt-3
              pb-1
            "
          >
            <div class="nav-caption fw-600 font-14 text-grey-500">
              <span>Account</span>
            </div>

            <ul class="mb-1" @click="closeFilter">
              <li class="logo d-none d-xl-block d-lg-block"></li>
              <li>
                <router-link
                  to="/parametres"
                  class="nav-content-bttn open-font h-auto pt-2 pb-2"
                  ><i v-tooltip.right-start="'Settings'"  class="font-sm feather-settings me-3 text-grey-500"></i
                  ><span>Settings</span></router-link
                >
              </li>
              <li>
               
                <router-link to="/adresses" class="nav-content-bttn open-font h-auto pt-2 pb-2"
                  ><i v-tooltip.right-start="'My adresses'"
                    class="font-sm fas fa-map-marked-alt me-3 text-grey-500"
                  ></i
                  ><span>My adresses</span></router-link>
              </li>
              <li>
           
                <router-link :to="{ name: 'feedvisiteur', params: { id: `${currentUser.id}` } }" class="nav-content-bttn open-font h-auto pt-2 pb-2"
                  ><i v-tooltip.right-start="'Story highlight'"
                    class="font-sm fab fa-gratipay me-3 text-grey-500"
                  ></i
                  ><span>Story highlight</span></router-link>
              </li>
              <li>
                <router-link
                  to="/statistiques"
                  class="nav-content-bttn open-font h-auto pt-2 pb-2"
                  ><i v-tooltip.right-start="'Statistics'" class="font-sm feather-pie-chart me-3 text-grey-500"></i
                  ><span>Statistics</span></router-link
                >
              </li>
              <li>
                <a
                  @click.prevent="logout"
                  role="button"
                  class="nav-content-bttn open-font h-auto pt-2 pb-2 logout-btn"
                  ><i v-tooltip.right-start="'Logout'" class="font-sm feather-lock me-3 text-black log-span"></i
                  ><span class="text-black log-span">Logout</span></a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <!-- navigation left -->
  </div>
</template>
<script>
import "v-tooltip/dist/v-tooltip.css";
import Loader from "../layouts/loader/loaderLogin.vue";
export default {
  name: "sideBar",
  components: {
    Loader,
  },
  data() {
    return {
      MenuFull: true,
      loading: false,
    };
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    filterToggle() {
      return this.$store.state.filterToggle;
    },
  
  },

  methods: {
    closeFilter() {
      this.$refs.navigationRef.classList.remove('nav-active', 'active-menu');
       this.$store.dispatch('filterToggle', false);
      //this.$store.dispatch("filterToggle", false);
    

    },
  

    logout() {
      const controller = new AbortController();
      controller.abort();
      this.loading = true;
      this.axios
        .post("logout")
        .then((res) => {
          this.loading = false;
          localStorage.removeItem("token");
          this.$router.push("/");
          // //console.log(res);
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
.adrs-aprox {
  margin: 0;
}
.adrs-aprox-span {
  margin-left: 5px;
}
.btn-round-md {
  border-radius: 10px !important;
}

.router-link-active.router-link-exact-active {
  span {
    color: #124698 !important;
    //background-color: #d2e3ff !important;
    // border-color: #bcd5fe !important;
  }
}
a {
  :hover {
    text-decoration: underline;
    transition: all 0.4s ease-in-out;
  }
}
.filter-menu {
  padding-left: 0px !important;
  white-space: normal;
}
.toggle-menu {
  float: right;
}
.fa-exchange-alt {
  color: #888;
}
.scroll-bar::-webkit-scrollbar {
  width: 5px;
  &:hover {
    cursor: pointer;
  }
}
.fa-chevron-right,
.fa-chevron-left {
  padding: 4px;
  border-radius: 5px;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: #124698;
    background-color: #d2e3ff;
  }
}
.loading {
  background-color: transparent;
  z-index: 99;
  margin: 20% auto !important;
  position: absolute;
  top: 10%;
  left: 50%;
}
.nav-content-bttn i {
  &:hover {
    text-decoration: none !important;
  }
}
.text-black {
  transition: all 0.4s ease-in-out;
}
.logout-btn {
  transition: all 0.4s ease-in-out;

  color: black !important;
  &:hover .text-black {
    //transition: all 0.4s ease-in-out;
    //color: white !important;
  }
}
.log-span {
  color: red !important;
}
.close-filter-m{
    display: none;
  }
@media (max-width: 768px) {

  .lb-close {
  //border-top-right-radius:10px;
  transition: all 0.4s ease-in-out;
  display: block;
  
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  font-size: 25px;
  background-size: contain;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.9;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background: #f00 !important;
  color: #fff !important;
  z-index: 999999999 !important;
  &:hover {
    background: rgba(255, 0, 0, 1) !important;
  }
  &:hover .fa-times {
    color: #fff !important;
  }
}

.lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}


  .nav-content{
    padding-left: 0px; 
    padding-right: 0px;
  }
  .close-filter-m{
    display: block;
  }
}

</style>
