<template>
  <portal to="modal">
    <div class="modall">
      <div class="wrapper col-md-6 col-sm-12">
        <div class="modal-container">
          <div
            @click.self.stop.prevent="handleClose"
            class="absolute inset-0 bg-gray-500 opacity-75"
          ></div>
        </div>

        <div
          class="z-10 bg-white rounded-modal overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div class="position-relative">
            <i
              class="feather-x-square pt-1 float-right position-absolute close-btn"
              @click.prevent="handleClose"
            ></i>

            <div
              class="w-100 rounded-xxl border-0  mb-3"
            >
              <div
                v-if="
                  !isAllReactionsReady ||
                  !isLikesReady ||
                  !isLovesReady ||
                  !isWouahsReady ||
                  !isHahasReady ||
                  !isSadsReady ||
                  !isAngrysReady
                "
              >
                <div class="card-body pt-3 pb-3 border-top-xs bor-0">
                  <div class="chat-left scroll-bar pt-2 w-100">
                   
                    <Skeleton></Skeleton>
                  </div>
                </div>
              </div>
              <tabs
                v-else
                @changed="tabChanged"
                @clicked="tabClicked"
                :options="{ useUrlFragment: false, defaultTabHash: 'All' }"
                class=""
                nav-class="nav nav-tabs  d-flex product-info-tab ps-4 pb-0"
                nav-item-class="list-inline-item me-5 hover-item"
                nav-item-link-class="fw-700 font-xsss text-grey-500 pt-3 pb-4 ls-1 d-inline-block"
                nav-item-link-active-class="pb-4"
              >
                <tab
                  v-if="isAllReactionsReady"
                  name="All"
                  id="All"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    > (${totalReactions})</span>`"
                >
                  <div
                    class="card-body pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <div
                        v-if="
                          !isAllReactionsReady ||
                          !isLikesReady ||
                          !isLovesReady ||
                          !isWouahsReady ||
                          !isHahasReady ||
                          !isSadsReady ||
                          !isAngrysReady
                        "
                      >
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <div
                        v-else
                        v-for="(jaime, index) in jaimes"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: jaime.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${jaime.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>
                          <i
                            v-if="jaime.reaction_type == 1"
                            class="em em-thumbsup position-absolute icon-reaction"
                          ></i>
                          <i
                            v-else-if="jaime.reaction_type == 2"
                            class="em em-heart position-absolute icon-reaction"
                          ></i>
                          <i
                            v-else-if="jaime.reaction_type == 3"
                            class="em em-astonished position-absolute icon-reaction"
                          ></i>
                          <i
                            v-else-if="jaime.reaction_type == 4"
                            class="em em-rolling_on_the_floor_laughing position-absolute icon-reaction"
                          ></i>
                          <i
                            v-else-if="jaime.reaction_type == 5"
                            class="em em-cry position-absolute icon-reaction"
                          ></i>
                          <i
                            v-else-if="jaime.reaction_type == 6"
                            class="em em-angry position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: jaime.visiteur.id },
                            }"
                            >{{ jaime.visiteur.prenom }}
                            {{ jaime.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!jaime.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ jaime.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${jaime.visiteur.id}`"
                          v-if="
                            !jaime.visiteur.isFriend &&
                            !jaime.visiteur.isMyProfil &&
                            !jaime.visiteur.isRequestSent
                          "
                          @click="addFriend(jaime.visiteur.id, index, null)"
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>

                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${jaime.visiteur.id}`"
                          v-else-if="jaime.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(jaime.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneAll"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleAll && !doneAll" />
                      <div
                        v-if="jaimes.length"
                        v-observe-visibility="{
                          callback: visibilityChanged,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>

                <tab
                  v-if="isLikesReady && totalLikes > 0"
                  class="position-relative"
                  prefix="<i class='fas fa-thumbs-up fa-1-5x blue'></i>"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    >(${totalLikes})</span>`"
                  name=""
                  id="Like"
                >
                  <div
                    class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isLikesReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div
                        v-else
                        v-for="(like, index) in Likes"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: like.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${like.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>
                          <i
                            class="em em-thumbsup position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: like.visiteur.id },
                            }"
                            >{{ like.visiteur.prenom }} {{ like.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!like.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ like.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${like.visiteur.id}`"
                          v-if="
                            !like.visiteur.isFriend &&
                            !like.visiteur.isMyProfil &&
                            !like.visiteur.isRequestSent
                          "
                          @click="
                            addFriend(
                              like.visiteur.id,
                              index,
                              like.reaction_type
                            )
                          "
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>
                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${like.visiteur.id}`"
                          v-else-if="like.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(like.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneLikes"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleLikes && !doneLikes" />
                      <div
                        v-if="Likes.length"
                        v-observe-visibility="{
                          callback: visibilityChangedLikes,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>

                <tab
                  v-if="isLovesReady && totalLoves > 0"
                  name=""
                  id="Love"
                  prefix="<i class='fas fa-heart fa-1-5x red'></i>"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    >(${totalLoves})</span>`"
                >
                  <div
                    class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isLovesReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div
                        v-else
                        v-for="(love, index) in Loves"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: love.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${love.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>

                          <i
                            class="em em-heart position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: love.visiteur.id },
                            }"
                            >{{ love.visiteur.prenom }} {{ love.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!love.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ love.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${love.visiteur.id}`"
                          v-if="
                            !love.visiteur.isFriend &&
                            !love.visiteur.isMyProfil &&
                            !love.visiteur.isRequestSent
                          "
                          @click="
                            addFriend(
                              love.visiteur.id,
                              index,
                              love.reaction_type
                            )
                          "
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>
                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${love.visiteur.id}`"
                          v-else-if="love.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(love.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneLoves"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleLoves && !doneLoves" />
                      <div
                        v-if="Loves.length"
                        v-observe-visibility="{
                          callback: visibilityChangedLoves,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>
                <tab
                  v-if="isWouahsReady && totalWouah > 0"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    >(${totalWouah})</span>`"
                  prefix="<i class='far fa-surprise fa-1-5x yellow'></i>"
                  name=""
                  id="Wouah"
                >
                  <div
                    class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isWouahsReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div
                        v-else
                        v-for="(Wouah, index) in Wouahs"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: Wouah.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${Wouah.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>

                          <i
                            class="em em-astonished position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: Wouah.visiteur.id },
                            }"
                            >{{ Wouah.visiteur.prenom }}
                            {{ Wouah.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!Wouah.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ Wouah.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${Wouah.visiteur.id}`"
                          v-if="
                            !Wouah.visiteur.isFriend &&
                            !Wouah.visiteur.isMyProfil &&
                            !Wouah.visiteur.isRequestSent
                          "
                          @click="
                            addFriend(
                              Wouah.visiteur.id,
                              index,
                              Wouah.reaction_type
                            )
                          "
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>
                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${Wouah.visiteur.id}`"
                          v-else-if="Wouah.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(Wouah.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneWouahs"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleWouahs && !doneWouahs" />
                      <div
                        v-if="Wouahs.length"
                        v-observe-visibility="{
                          callback: visibilityChangedWouahs,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>

                <tab
                  v-if="isHahasReady && totalHahas > 0"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    >(${totalHahas})</span>`"
                  prefix="<i class='far fa-laugh-squint fa-1-5x orange'></i>"
                  name=""
                  id="haha"
                >
                  <div
                    class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isHahasReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div
                        v-else
                        v-for="(haha, index) in hahas"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: haha.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${haha.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>

                          <i
                            class="em em-rolling_on_the_floor_laughing position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: haha.visiteur.id },
                            }"
                            >{{ haha.visiteur.prenom }} {{ haha.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!haha.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ haha.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${haha.visiteur.id}`"
                          v-if="
                            !haha.visiteur.isFriend &&
                            !haha.visiteur.isMyProfil &&
                            !haha.visiteur.isRequestSent
                          "
                          @click="
                            addFriend(
                              haha.visiteur.id,
                              index,
                              haha.reaction_type
                            )
                          "
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>
                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${haha.visiteur.id}`"
                          v-else-if="haha.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(haha.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneHahas"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleHahas && !doneHahas" />
                      <div
                        v-if="hahas.length"
                        v-observe-visibility="{
                          callback: visibilityChangedHahas,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>
                <tab
                  v-if="isSadsReady && totalSads > 0"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    >(${totalSads})</span>`"
                  prefix="<i class='far fa-sad-tear fa-1-5x yellow'></i>"
                  name=""
                  id="sad"
                >
                  <div
                    class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isSadsReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div
                        v-else
                        v-for="(sad, index) in sads"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: sad.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${sad.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>

                          <i
                            class="em em-cry position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: sad.visiteur.id },
                            }"
                            >{{ sad.visiteur.prenom }} {{ sad.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!sad.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ sad.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${sad.visiteur.id}`"
                          v-if="
                            !sad.visiteur.isFriend &&
                            !sad.visiteur.isMyProfil &&
                            !sad.visiteur.isRequestSent
                          "
                          @click="
                            addFriend(sad.visiteur.id, index, sad.reaction_type)
                          "
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>
                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${sad.visiteur.id}`"
                          v-else-if="sad.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(sad.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneSads"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleSads && !doneSads" />
                      <div
                        v-if="sads.length"
                        v-observe-visibility="{
                          callback: visibilityChangedSads,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>

                <tab
                  v-if="isAngrysReady && totalAngrys > 0"
                  :suffix="`<span class='fw-700 font-xsssss text-grey-500 mb-4 ls-1'
                    >(${totalAngrys})</span>`"
                  prefix="<i class='far fa-angry fa-1-5x orange'></i>"
                  name=""
                  id="angry"
                >
                  <div
                    class="card-body d-flex pt-3 ps-4 pe-4 pb-3 border-top-xs bor-0"
                  >
                    <div class="chat-left scroll-bar pt-2 w-100">
                      <!-- ------------------------------ -->

                      <div v-if="!isAngrysReady">
                        <Loader></Loader>
                        <Skeleton></Skeleton>
                      </div>
                      <!-- ------------------------------ -->

                      <div
                        v-else
                        v-for="(Angry, index) in Angrys"
                        class="d-flex ps-4 pe-4 pb-0 border-top-xs bor-0 bg-greylight ms-3 me-3 rounded-3 mb-1"
                      >
                        <figure class="avatar me-2 mb-1 pt-1 position-relative">
                          <router-link
                            v-tooltip.left-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: Angry.visiteur.id },
                            }"
                          >
                            <img
                              :src="`${assetsBaseUrl}${Angry.visiteur.photo}`"
                              alt="image"
                              class="shadow-sm rounded-circle w45"
                            />
                          </router-link>

                          <i
                            class="em em-angry position-absolute icon-reaction"
                          ></i>
                        </figure>

                        <h4
                          class="fw-700 text-grey-900 font-xssss mt-2 link-user"
                        >
                          <router-link
                            v-tooltip.top-start="'Show profil'"
                            :to="{
                              name: 'feedvisiteur',
                              params: { id: Angry.visiteur.id },
                            }"
                            >{{ Angry.visiteur.prenom }}
                            {{ Angry.visiteur.nom }}
                          </router-link>
                          <span
                            v-if="!Angry.visiteur.isMyProfil"
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >{{ Angry.visiteur.mutualFriend }} mutual
                            friends</span
                          >
                          <span
                            v-else
                            class="d-block font-xssss fw-500 mt-1 lh-3 text-grey-500"
                            >Visit your profil</span
                          >
                        </h4>

                        <a
                          :class="`add-btn-${Angry.visiteur.id}`"
                          v-if="
                            !Angry.visiteur.isFriend &&
                            !Angry.visiteur.isMyProfil &&
                            !Angry.visiteur.isRequestSent
                          "
                          @click="
                            addFriend(
                              Angry.visiteur.id,
                              index,
                              Angry.reaction_type
                            )
                          "
                          class="btn-add btn-round-sm bg-white text-grey-900 fas fa-user-plus font-xss ms-auto mt-2"
                        ></a>
                        <a
                          cancel-data="cancel"
                          :class="`add-btn-${Angry.visiteur.id}`"
                          v-else-if="Angry.visiteur.isRequestSent == 'receiver'"
                          @click="addFriend(Angry.visiteur.id, index, null)"
                          class="btn-decline btn-round-sm bg-white text-grey-900 fas fa-times font-xss ms-auto mt-2"
                        ></a>
                      </div>

                      <div
                        v-if="doneAngrys"
                        class="card w-100 text-center rounded-xxl border-0 p-2 mb-1 mt-1"
                      >
                        No other reactions
                      </div>
                      <Loader v-show="isVisibleAngrys && !doneAngrys" />
                      <div
                        v-if="Angrys.length"
                        v-observe-visibility="{
                          callback: visibilityChangedAngrys,
                          throttle: 500,
                        }"
                      ></div>
                    </div>
                  </div>
                </tab>

                <!-- ************************************************************** -->
              </tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { Tabs, Tab } from "vue3-tabs-component";
import jaimeListAll from "../../pages/Jaimes/allReactions.vue";
import Loader from "../../layouts/loader/loaderImage.vue";
import Skeleton from "../../layouts/skeleton/SkeletonComment.vue";

export default {
  name: "BaseModal",
  components: {
    Loader,
    jaimeListAll,
    Tabs,
    Tab,
    Skeleton,
  },

  data() {
    return {
      //-------(Arrays)-----
      jaimes: [],
      Likes: [],
      Loves: [],
      Wouahs: [],
      hahas: [],
      sads: [],
      Angrys: [],
      //-------(Ready)-----
      isAllReactionsReady: false,
      isLikesReady: false,
      isLovesReady: false,
      isWouahsReady: false,
      isHahasReady: false,
      isSadsReady: false,
      isAngrysReady: false,
      //-------(Total)-----
      totalReactions: 0,
      totalLikes: 0,
      totalLoves: 0,
      totalWouah: 0,
      totalHahas: 0,
      totalSads: 0,
      totalAngrys: 0,
      //-------(Pagination)-----
      //current
      pageAll: 1,
      pageLoves: 1,
      pageWouah: 1,
      pageLikes: 1,
      pageHahas: 1,
      pageSads: 1,
      pageAngrys: 1,
      //last
      lastPageAll: 1,
      lastPageLikes: 1,
      lastPageLoves: 1,
      lastpageWouah: 1,
      lastPageHahas: 1,
      lastPageSads: 1,
      lastpageAngrys: 1,
      //done
      doneAll: false,
      doneWouahs: false,
      doneLikes: false,
      doneLoves: false,
      doneHahas: false,
      doneSads: false,
      doneAngrys: false,
      //visible
      isVisibleLikes: false,
      isVisibleLoves: false,
      isVisibleAll: false,
      isVisibleWouahs: false,
      isVisibleHahas: false,
      isVisibleSads: false,
      isVisibleAngrys: false,
    };
  },
  props: {
    commentID: Number,
  },
  created() {
    document.addEventListener("keyup", this.onClose);

    this.token = localStorage.getItem("token");
  },

  mounted() {
    this.getReactionsList();
    this.getReactionsListByType("1");
    this.getReactionsListByType("2");
    this.getReactionsListByType("3");
    this.getReactionsListByType("4");
    this.getReactionsListByType("5");
    this.getReactionsListByType("6");
  },

  destroyed() {
    document.removeEventListener("keyup", this.onClose);
  },

  methods: {
    //------------------(AddFriend)-------------------

    removeFriend(id, i, type) {
      let btns = document.getElementsByClassName("add-btn-" + id);

      if (btns[0].classList.contains("btn-decline")) {
        for (let j = 0; j < btns.length; j++) {
          btns[j].removeAttribute("cancel-data");
          btns[j].classList.add("fa-user-plus", "btn-add");
          btns[j].classList.remove("fa-times", "btn-decline");
        }

        this.axios
          .post("amis/supprimerAmis", {
            visiteur_id: id,
          })
          .then((res) => {
            //this.getFriendStatus();
          });
      } else {
        this.addFriend(id, i, type);
      }
    },
    addFriend(id, i, type) {
      let btns = document.getElementsByClassName("add-btn-" + id);

      if (btns[0].hasAttribute("cancel-data")) {
        this.removeFriend(id, i, type);
      } else {
        //console.log(this.addFriendALLRef[i]);
        for (let j = 0; j < btns.length; j++) {
          btns[j].setAttribute("cancel-data", "cancel");
          btns[j].classList.remove(
            "fa-user-plus",
            "v-popper--has-tooltip",
            "btn-add"
          );
          btns[j].classList.add("fa-times", "btn-decline");
        }

        this.axios
          .post("amis/ajouterAmis", {
            visiteur_id: this.currentUser.id,
            receiver_id: id,
          })
          .then((res) => {
            //this.getFriendStatus();
          });
      }
    },

    //------------------------------------------

    visibilityChanged(isVisibleAll) {
      //this.addFriendRef=[];

      if (!isVisibleAll) {
        return;
      }
      if (this.pageAll >= this.lastPageAll) {
        return (this.doneAll = true);
      }
      this.isVisibleAll = isVisibleAll;

      this.pageAll++;
      this.getReactionsList();
    },
    visibilityChangedAngrys(isVisibleAngrys) {
      if (!isVisibleAngrys) {
        return;
      }
      if (this.pageAngrys >= this.lastpageAngrys) {
        return (this.doneAngrys = true);
      }
      this.isVisibleAngrys = isVisibleAngrys;

      this.pageAngrys++;
      this.getReactionsListByType("6");
    },
    visibilityChangedSads(isVisibleSads) {
      if (!isVisibleSads) {
        return;
      }
      if (this.pageSads >= this.lastPageSads) {
        return (this.doneSads = true);
      }
      this.isVisibleSads = isVisibleSads;

      this.pageSads++;
      this.getReactionsListByType("5");
    },
    visibilityChangedHahas(isVisibleHahas) {
      if (!isVisibleHahas) {
        return;
      }
      if (this.pageHahas >= this.lastPageHahas) {
        return (this.doneHahas = true);
      }
      this.isVisibleHahas = isVisibleHahas;

      this.pageHahas++;
      this.getReactionsListByType("4");
    },
    visibilityChangedLikes(isVisibleLikes) {
      if (!isVisibleLikes) {
        return;
      }
      if (this.pageLikes >= this.lastPageLikes) {
        return (this.doneLikes = true);
      }
      this.isVisibleLikes = isVisibleLikes;

      this.pageLikes++;
      this.getReactionsListByType("1");
    },
    visibilityChangedLoves(isVisibleLoves) {
      if (!isVisibleLoves) {
        return;
      }
      if (this.pageLoves >= this.lastPageLoves) {
        return (this.doneLoves = true);
      }
      this.isVisibleLoves = isVisibleLoves;

      this.pageLoves++;
      this.getReactionsListByType("2");
    },
    visibilityChangedWouahs(isVisibleWouahs) {
      if (!isVisibleWouahs) {
        return;
      }
      if (this.pageWouah >= this.lastpageWouah) {
        return (this.doneWouahs = true);
      }
      this.isVisibleWouahs = isVisibleWouahs;

      this.pageWouah++;
      this.getReactionsListByType("3");
    },
    getReactionsList() {
      this.axios
        .post(`reactionsComment?page=${this.pageAll}`, {
          commentID: this.commentID,
        })
        .then((res) => {
          this.jaimes.push(...res.data.reactions.data);

          this.isAllReactionsReady = true;
          this.totalReactions = res.data.reactions.total;
          this.lastPageAll = res.data.reactions.last_page;
          console.log(this.jaimes);
        });
    },

    getReactionsListByType(type) {
      switch (type) {
        case "1":
          this.axios
            .post(`getJaimeCommentListByReactType?page=${this.pageLikes}`, {
              commentID: this.commentID,
              ReactionType: type,
            })
            .then((res) => {
              this.Likes.push(...res.data.reactions.data);
              this.isLikesReady = true;
              this.totalLikes = res.data.reactions.total;
              this.lastPageLikes = res.data.reactions.last_page;
            });
          break;
        case "2":
          this.axios
            .post(`getJaimeCommentListByReactType?page=${this.pageLoves}`, {
              commentID: this.commentID,
              ReactionType: type,
            })
            .then((res) => {
              this.Loves.push(...res.data.reactions.data);

              this.isLovesReady = true;
              this.totalLoves = res.data.reactions.total;
              this.lastPageLoves = res.data.reactions.last_page;
            });
          break;

        case "3":
          this.axios
            .post(`getJaimeCommentListByReactType?page=${this.pageWouah}`, {
              commentID: this.commentID,
              ReactionType: type,
            })
            .then((res) => {
              // console.log(res.data.reactions.data);
              this.Wouahs.push(...res.data.reactions.data);

              this.isWouahsReady = true;
              this.totalWouah = res.data.reactions.total;
              this.lastpageWouah = res.data.reactions.last_page;
            });
          break;

        case "6":
          this.axios
            .post(`getJaimeCommentListByReactType?page=${this.pageAngrys}`, {
              commentID: this.commentID,
              ReactionType: type,
            })
            .then((res) => {
              // console.log(res.data.reactions.data);
              this.Angrys.push(...res.data.reactions.data);

              this.isAngrysReady = true;
              this.totalAngrys = res.data.reactions.total;
              this.lastpageAngrys = res.data.reactions.last_page;
            });
          break;
        case "5":
          this.axios
            .post(`getJaimeCommentListByReactType?page=${this.pageSads}`, {
              commentID: this.commentID,
              ReactionType: type,
            })
            .then((res) => {
              // console.log(res.data.reactions.data);
              this.sads.push(...res.data.reactions.data);

              this.isSadsReady = true;
              this.totalSads = res.data.reactions.total;
              this.lastPageSads = res.data.reactions.last_page;
            });
          break;
        case "4":
          this.axios
            .post(`getJaimeCommentListByReactType?page=${this.pageHahas}`, {
              commentID: this.commentID,
              ReactionType: type,
            })
            .then((res) => {
              // console.log(res.data.reactions.data);
              this.hahas.push(...res.data.reactions.data);

              this.isHahasReady = true;
              this.totalHahas = res.data.reactions.total;
              this.lastPageHahas = res.data.reactions.last_page;
            });
          break;
      }
    },
    tabClicked(selectedTab) {
      //console.log("Current tab re-clicked:" + selectedTab.tab.name);
    },
    tabChanged(selectedTab) {},

    handleClose() {
      this.$emit("close");
    },
    handleRefresh() {
      this.$emit("refreshLogo");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
  },

  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.em,
.em-svg {
  height: 1em;
  width: 1em;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
}
.em---1,
.em---1,
.em-thumbsup {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
}
.em---1.em-svg,
.em---1.em-svg,
.em-thumbsup.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44d.svg"),
    none;
}
.em--1,
.em-thumbsdown {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
}
.em--1.em-svg,
.em-thumbsdown.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44e.svg"),
    none;
}
.em-heart {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
}
.em-astonished {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f632.png");
}
.em-heart.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/2764.svg"),
    none;
}
.em-rolling_on_the_floor_laughing {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f923.png");
}
.em-cry {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f622.png");
}
.em-angry {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f620.png");
}
.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;
  }
}
.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.wrapper {
  //width: 40%;
  margin: 0 auto;
  border-radius: 10px;
}
.modal-container {
  margin: 30px 50px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}
.modal-header-txt {
  display: inline-block;
}
.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }
  float: right;
  font-size: 32px;
}
.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}
.loader {
  height: 50px !important;
}
.d-i-b,
.tabs-component-tabs {
  display: inline-block !important;
}
.is-active {
  border-bottom: 2px#333 solid !important;
  //color: #2e58a6 !important;
  //font-size: 32px;
}
.chat-left {
  height: calc(100vh - 300px) !important;
}
.close-btn {
  top: 0px !important;
  right: 0px !important;
  z-index: 9999;
}
.rounded-circle {
  border-radius: 10px !important;
}
.btn-round-sm {
  border-radius: 5px !important;
}
.btn-add,
.btn-decline {
  border-radius: 5px !important;
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;

    color: #fff !important;
  }
}
.btn-add {
  &:hover {
    background-color: #2e58a6 !important;
  }
}
.btn-decline {
  &:hover {
    background-color: #f00 !important;
  }
}
.icon-reaction {
  bottom: -3px;
  right: -3px;
}
.red {
  color: #f00 !important;
}
.blue {
  color: #2e58a6 !important;
}
.orange {
  color: rgb(233, 113, 15) !important;
}
.all-count {
  top: -55px;
  left: 44px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}
.likes-count {
  top: -55px;
  left: 138px;
  font-size: 11px !important;
  // color:#2e58a6 !important;
}
.loves-count {
  top: -55px;
  left: 237px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}
.Wouahs-count {
  top: -55px;
  left: 350px;
  font-size: 11px !important;
  //color:#2e58a6 !important;
}

.rounded-modal {
  border-radius: 10px;
}
.yellow {
  color: #f5f82a !important;
}
.fa-1-5x {
  font-size: 1.5em !important;
}
@media screen and (max-width: 989px) {
.modal-container {

  margin: 0px 10px;
  padding: 0px 20px;
  
}

}
@media (max-width: 767px) {

  .bg-greylight{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
 .card-m{
   padding-left:0px !important;
    padding-right:0px !important;

   
 }
    .modal-container {
    margin: 0px 10px !important;
    
  }
   .wrapper {
  margin: 0 auto;
  height: 100% !important;
  }
  .rounded-modal {
    border-radius: 0px;
    width:100%;
    height: 100% !important;
  }
  .card-footer{
    position: relative;
    bottom: 0;
    border-top:0 ;
    
  }
  .container-publish{
    background: none;

  }
  .card-mm{
       padding-left:0px !important;
    padding-right:0px !important;
  }
}
</style>
