<template>
  <portal to="modal">
    <transition name="fade">
      <div class="modall">
        <div class="wrapper col-md-6 col-sm-12">
          <div class="modal-container col-xs-12">
            <div @click.self.stop.prevent="handleClose" class="absolute inset-0 bg-gray-500 opacity-75"></div>

            <div class="
                                    z-10
                                    bg-white
                                    rounded-modal
                                    overflow-hidden
                                    shadow-xl
                                    transform
                                    transition-all
                                    sm:max-w-lg sm:w-full
                                  " role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div class="bg-white   sm:p-5 sm:pb-5">
                <div class="sm:flex sm:items-start ">
                  <div class="w-full text-center sm:mt-0 sm:ml-4 sm:text-left ">
                    <div class="flex items-center justify-between bg-gray-500 pt-2">
                      <h3 class="
                                              pt-2
                                              text-lg
                                              modal-header-txt
                                              text-gray-900
                                              fw-600
                          
                                            " id="modal-headline">
                        Create a post
                      </h3>
                      <template v-if="isDefaultVisibilityReady">
                        <a href="#" type="button" class="ms-2 visibility-change" id="dropdownMenu4"
                          data-bs-toggle="dropdown" aria-expanded="false" v-tooltip.top-start="'Change visibility'">
                          <i v-if="defaultVisibility == 0" class="fa fa-globe-americas font-xss pt-1"></i>
                          <i v-else-if="defaultVisibility == 1" class="fa fa-user-friends font-xss pt-1"></i>
                        </a>

                        <div class="
                                                dropdown-menu dropdown-menu-start
                                                rounded-xxl
                                                border-0
                                                shadow-lg
                                              " aria-labelledby="dropdownMenu4">
                          <div class="card-body p-0 d-flex v-element v-top" @click="changeVisibility(0)">
                            <i class="
                                                    fa fa-globe-americas
                                                    text-grey-500
                                                    ms-1
                                                    font-xss
                                                  "></i>
                            <h4 class="fw-600 text-gre y-900 font-xssss me-2">
                              Public
                            </h4>
                          </div>
                          <div class="card-body p-0 d-flex v-element" @click="changeVisibility(1)">
                            <i class="fa fa-user-friends text-grey-500 font-xss"></i>
                            <h4 class="fw-600 text-grey-900 font-xssss me-2">
                              Friends
                            </h4>
                          </div>
                        </div>
                      </template>

                      <!-- <template class="d-i-b" v-else>
                 <Loader></Loader>

               </template> -->
                      <i v-if="!isUploading" class="feather-x-square pt-1" @click.prevent="handleClose">
                      </i>
                      <hr />
                    </div>
                    <div class="" :class="{ scrollable: scrollable }">
                      <div class="
                                              px-4
                                            ">
                        <!-- <div>
                      <input type="text" placeholder="Find adresse..." class="bg-grey border-0 lh-32 pt-2 pb-2 ps-3 pe-3 font-xssss fw-500 input-search-adress w-100 theme-dark-bg">
                    </div> -->

                        <div class="autocomplete">
                          <input ref="adresseSearch" type="text" placeholder="Find adresse..." class="
                                                  bg-greylight
                                                  border-0
                                                  lh-32
                                                  pt-2
                                                  pb-2
                                                  ps-3
                                                  pe-3
                                                  font-xssss
                                                  fw-500
                                                  input-search-adress
                                                  w-100
                                                  theme-dark-bg
                                                " v-model="search" @input="onChange" @keydown.down="onArrowDown"
                            @keydown.up="onArrowUp" @keydown.enter="onEnter" />
                          <ul v-show="isOpen" class="autocomplete-results w-100">
                            <template v-if="isLoading">


                              <li class="loading">
                                <!-- <Skeleton v-if="isLoading"></Skeleton> -->
                                Loading...
                              </li>
                            </template>
                            <template v-else>
                              <template v-for="(result, i) in results">

                                <li v-if="i < 10" :key="i" @click="setResult(result.id, result.rs)"
                                  :class="{ 'is-active': i === arrowCounter }" class="autocomplete-result d-flex">
                                  <img :src="`${assetsBaseUrl}${result.logo}`" alt="image" class="w-img" />
                                  <div class="d-flex adresse-info elipsis">
                                    <h4 class="
                                                              fw-700
                                                              font-xss
                                                              mt-0
                                                              lh-28
                                                              mb-0
                                                              elipsis
                                                              d-i-b
                                                            ">
                                      {{ result.rs }}
                                    </h4>
                                    <h6 class="
                                                              font-xssss
                                                              text-grey-500
                                                              fw-600
                                                              mt-0
                                                              mb-0
                                                              elipsis
                                                              d-i-b
                                                            ">
                                      <i class="fas fa-map-marker-alt"></i>
                                      {{ result.adresse }}
                                    </h6>
                                    <h6 class="
                                                              font-xssss
                                                              text-grey-500
                                                              fw-600
                                                              mt-0
                                                              mb-0
                                                              elipsis
                                                              d-i-b
                                                            ">
                                      <i v-if="result.tel" class="fas fa-phone"></i>
                                      {{ result.tel }}
                                    </h6>
                                  </div>

                                </li>

                              </template>

                            </template>

                          </ul>
                        </div>

                        <div class="card-body p-0 mt-2 position-relative">
                          <figure class="avatar position-absolute ms-2 mt-1 b-2 top-5">
                            <img :src="`${assetsBaseUrl}${currentUser.photo}`" alt="image"
                              class="shadow-sm rounded-circle w30" />
                          </figure>
                          <textarea id="text_pub" v-model="textPub" name="message" class="
                                                  h-text-area
                                                  bor-0
                                                  w-100
                                                  rounded-xxl
                                                  p-2
                                                  ps-10
                                                  font-xssss
                                                  text-grey-500
                                                  fw-500
                                                  border-light-md
                                                  theme-dark-bg
                                                " cols="30" rows="3"
                            :placeholder="`What's up ${currentUser.nom} ? `"></textarea>
                        </div>

                        <!-- <input id="adresseId" type="hidden" :value="adresseId"> -->
                        <!-- <input type="hidden" id="currentUserId" :value="currentUser.id">  -->

                        <div ref="dropZoneRef" method="POST" enctype="multipart/form-data" id="myAwesomeDropzone"
                          class="dropzone">
                          <div class="fallback">
                            <input name="file" type="file" multiple />
                          </div>
                        </div>
                      </div>

                      <slot />
                    </div>
                  </div>
                </div>
              </div>
              <div class="
                                      mt-2
                                      card-footer
                                      container-publish
                                      px-4
                                      py-6
                                      sm:px-6 sm:flex sm:flex-row-reverse
                                    " :class="isUploading ? 'loader' : ''">
                <slot name="footer"></slot>
                <span class="
                                        mt-0
                                        flex
                                        w-full
                                        rounded-md
                                        shadow-sm
                                        sm:mt-0 sm:w-auto
                                        spanBtn
                                      ">
                  <button v-if="!isUploading" id="btnAddPub" @click="sending" type="button" class="
                                          btnPub
                                          inline-flex
                                          justify-center
                                          w-full
                                          rounded-md
                                          border border-gray-300
                                          px-3
                                          py-2
                                          bg-white
                                          text-base
                                          leading-6
                                          font-medium
                                          text-gray-700
                                          shadow-sm
                                          hover:text-gray-500
                                          focus:outline-none
                                          focus:border-blue-300
                                          focus:shadow-outline-blue
                                          transition
                                          ease-in-out
                                          duration-150
                                          sm:text-sm sm:leading-5
                                        ">
                    Publish
                  </button>
                  <template class="" v-else>
                    <Loader></Loader>
                  </template>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import { Dropzone } from "dropzone";
import Loader from "../../layouts/loader/loaderImage.vue";
import { useToast } from "vue-toastification";
import LoaderVisibility from "../loader/loaderLogin.vue";
import LoaderAutoComplete from "../../layouts/loader/loader.vue";
import Skeleton from "../../layouts/skeleton/SkeletonComment.vue";
const toast = useToast();

export default {
  name: "BaseModal",
  components: {
    Loader,
    LoaderAutoComplete,
    Skeleton,
    LoaderVisibility,
  },

  data() {
    return {
      locationDone: false,
      ville: null,
      lat: 0,
      lng: 0,
      latMin: 0,
      lngMin: 0,
      latMax: 0,
      lngMax: 0,
      textPub: "",
      token: "",
      isUploading: false,
      defaultVisibility: 1,
      isDefaultVisibilityReady: false,
      search: "",
      results: [],
      isOpen: false,
      arrowCounter: -1,
      page: 1,
      isLoading: false,
      adresses: [],
      adresseID: null,
    };
  },
  props: {
    title: {
      type: String,
      required: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    document.addEventListener("keyup", this.onClose);

    this.token = localStorage.getItem("token");
  },

  mounted() {
    setTimeout(() => {
      this.dropInit(), 500;
    });

    this.getDefaultVisibility();
    this.getAdresses();
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("keyup", this.onClose);
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    getAdresses() {
      this.isLoading = true;
      this.axios.get(`adresses`).then((res) => {
        //console.log(res.data.adresses)
        this.isLoading = false;
        this.adresses = res.data.adresses;
        this.filterResults();
      });
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter = this.arrowCounter + 1;
      } else {
        this.arrowCounter = 0;
      }
    },
    onArrowUp() {
      if (this.arrowCounter >= 1) {
        this.arrowCounter = this.arrowCounter - 1;
      } else {
        this.arrowCounter = this.results.length - 1;
      }
    },
    onEnter() {
      this.search = this.results[this.arrowCounter];
      this.arrowCounter = -1;
      this.isOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.arrowCounter = -1;
        this.isOpen = false;
      }
    },
    setResult(id, rs) {
      this.search = rs;
      this.isOpen = false;
      this.adresseID = id;
      this.$refs.adresseSearch.classList.remove("invalid");
    },
    filterResults() {
      let i = 0;
      // do {
      this.results = this.adresses
        .filter(
          (item) =>
            item.rs.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        )
        .map((item) => {
          item.index = i;
          i++;
          return item;
        });

      // } while (i < 10)

    },
    onChange() {
      this.filterResults();
      this.isOpen = true;
    },
    getDefaultVisibility() {
      this.isDefaultVisibilityReady = false;

      this.axios.get("getDefaultVisibility").then((res) => {
        this.defaultVisibility = parseInt(
          res.data.visibility.default_visibility
        );
        this.isDefaultVisibilityReady = true;
        //console.log(this.defaultVisibility)
      });
    },
    changeVisibility(visib) {
      this.axios
        .post("chaneDefaultVisibility", {
          visibility: visib,
        })
        .then((res) => {
          this.defaultVisibility = visib;
          toast.success("Visibility changed successfully ", {
            position: "bottom-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    dropInit() {
      //setTimeout(() => {,1000})
      Dropzone.autoDiscover = false;
      Dropzone.options.myAwesomeDropzone = {
        paramName: "file", // The name that will be used to transfer the file
        maxFilesize: 25, // MB
        url: `${this.apiBaseUrl}publication`,
        addRemoveLinks: true,
        uploadMultiple: true,
        withCredentials: true,
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        autoProcessQueue: false,
        maxFiles: 8,
        parallelUploads: 22,
        acceptedFiles: "image/* , video/*",
        dictRemoveFile: "Delete",
        dictCancelUploadConfirmation:
          "Are you sure you want to cancel this download?",
        dictDefaultMessage: "Drop files here to upload",
        dictCancelUpload: "Cancel",
        dictInvalidFileType: "This form only accepts images/videos",
        dictFileTooBig: `The file size is too large. {{filesize}} the max accepted is {{maxFilesize}} `,
      };

      var myDropzone = new Dropzone("#myAwesomeDropzone", {
        parallelUploads: 22,
        init: () => {
          var myDropzone = Dropzone.forElement("#myAwesomeDropzone");

          document
            .getElementById("btnAddPub")
            .addEventListener("click", (e) => {
              e.preventDefault();
              e.stopPropagation();
              if (
                myDropzone.files.length &&
                (this.adresseID != null || this.search.length)
              ) {
                //
                if (this.adresseID != null) {
                  //alert("ProcessQueue");
                  myDropzone.processQueue();
                } else if (this.search.length) {
                  this.$nextTick(() => {
                    this.getPosition();
                    this.checkPermission();
                    const interval = setInterval(() => {
                      if (this.locationDone) {
                        myDropzone.processQueue();

                        clearInterval(interval);
                      }
                    }, 10);
                  });
                }
              } else {
                if (!myDropzone.files.length) {
                  this.$refs.dropZoneRef.classList.add("invalid");
                }
                if (this.adresseID == null && !this.search.length) {
                  this.$refs.adresseSearch.classList.add("invalid");
                  console.log(this.$refs.adresseSearch);
                }
              }
            });

          myDropzone.on("sendingmultiple", (data, xhr, formData) => {
            this.isUploading = true;
            if (this.adresseID != null) {
              formData.append("adresse_id", this.adresseID);
              formData.append("visiteur_id", this.currentUser.id);
              formData.append("text_pub", this.textPub);
              formData.append("visibility", this.defaultVisibility);
            } else {
              //formData.append("adresse_id", this.adresseID);
              formData.append("visiteur_id", this.currentUser.id);
              formData.append("text_pub", this.textPub);
              formData.append("visibility", this.defaultVisibility);
              //villePosition
              formData.append("ville", this.ville);
              formData.append("latMin", this.latMin);
              formData.append("latMax", this.latMax);
              formData.append("lngMin", this.lngMin);
              formData.append("lngMax", this.lngMax);
              //userPosition
              formData.append("lng", this.lng);
              formData.append("lat", this.lat);
              //adresse_rs
              formData.append("rs", this.search);
            }
          });
          myDropzone.on("successmultiple", (data, xhr, formData) => {
            this.handleRefresh();
            this.handleClose();
            toast.success("Post created ", {
              position: "bottom-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            this.$router.push(`/feedvisiteur/${this.currentUser.id}`);
          });
          myDropzone.on("error", (file, data) => {
            this.isUploading = false;
          });
        },
      });
    },
    checkPermission() {
      const intervalPermission = setInterval(() => {
        navigator.permissions
          .query({ name: "geolocation" })
          .then((permissionStatus) => {
            //console.log('geolocation permission state is ', permissionStatus.state);
            if (permissionStatus.state == "granted") {
              clearInterval(intervalPermission);
            }

            permissionStatus.onchange = () => {
              if (permissionStatus.state == "granted") {
                //alert("granted");
                clearInterval(intervalPermission);
              } else {
                clearInterval(intervalPermission);
                toast.error("activate position ! ", {
                  position: "bottom-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: false,
                  closeButton: "button",
                  icon: true,
                  rtl: false,
                });
              }
              //
            };
          });
      }, 500);
    },
    getPosition() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        //console.log(this.lat + "," + this.lng);
        fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${this.lat},${this.lng}&result_type=administrative_area_level_1&key=AIzaSyBt0FzE7tTDwZuwkEpiMVXmxs9luDygliI`
        )
          .then((response) => response.json())
          .then((res) => {
            this.ville = res.results[0].formatted_address;
            this.latMin = res.results[0].geometry.bounds.southwest.lat;
            this.latMax = res.results[0].geometry.bounds.northeast.lat;
            this.lngMin = res.results[0].geometry.bounds.southwest.lng;
            this.lngMax = res.results[0].geometry.bounds.northeast.lng;
            this.locationDone = true;
            console.log(res.results[0]);
            console.log(res.results[0].geometry.bounds);
          });
      });
    },
    handleClose() {
      this.$emit("close");
    },
    handleRefresh() {
      this.$emit("refresh");
    },
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    apiBaseUrl() {
      return this.$store.state.apiBaseUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/dropzone.min.css";

.btnPub {
  transition: all 0.4s ease-in-out;
  width: 100%;
  border-radius: 5px;
  background-color: #2e58a6 !important;
  color: #fff;

  &:hover {
    background-color: #1259cc !important;
  }
}

.spanBtn {
  display: inline-block;
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.modall {
  position: fixed;
  z-index: 9998;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.wrapper {
  //width: 50%;
  margin: 5% auto;
  border-radius: 10px;
}

.modal-container {
  margin: 0px 50px;
  padding: 0px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: auto;
}

.modal-header-txt {
  display: inline-block;
}

.feather-x-square {
  &:hover {
    cursor: pointer;
    color: red;
    transition: all 0.4s ease-in-out;
  }

  float: right;
  font-size: 32px;
}

.dropzone {
  border: 2px #f1f1f1 solid;
  max-height: 200px;
  overflow: auto;
}

.loader {
  height: 50px !important;
}

// .h-text-area{
//   height: ;
// }
.invalid {
  border: 2px red solid !important;
}

.visibility-change {
  color: #212529;
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    color: #2e58a6 !important;
  }
}

.dropdown-menu {
  min-width: 30px !important;
  padding: 0px !important;
  border-radius: 10px;
}

.v-element {
  transition: all 0.2s ease-in-out;

  h4,
  i {
    margin-top: 3px;
    padding-top: 3px !important;
  }

  h4 {
    padding-top: 0.3rem !important;
  }

  &:hover {
    cursor: pointer;
    background-color: #eee;
    box-shadow: 1px 1px #ccc inset;
  }

  &:hover h4 {
    color: #2e58a6 !important;
  }

  &:hover i {
    color: #2e58a6 !important;
  }

  i {
    text-align: center;
    padding: 4px 5px;
    margin-left: 2px;
    padding-bottom: 3px !important;
  }
}

.v-top {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.input-search-adress {
  border-radius: 10px !important;
}

//Auto complete
.autocomplete {
  position: relative;
}

.autocomplete-results {
  padding: 0;
  margin: 0;
  border: 1px solid #eeeeee;
  height: 240px;
  min-height: 1em;
  max-height: 20em;
  overflow: auto;
  position: absolute;
  z-index: 999;
  background-color: rgba(255, 255, 255, 1);
}

.autocomplete-result {
  list-style: none;
  text-align: left;
  padding: 4px 2px;
  cursor: pointer;
}

.autocomplete-result.is-active,
.autocomplete-result:hover {
  background-color: #eee;
  color: white !important;
}

.elipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.w-img {
  width: 70px !important;
  height: 70px !important;
  vertical-align: bottom !important;
  border-radius: 10px;
  //text-align:center;
  //left:20%;
}

.d-i-b {
  display: inline-block !important;
}

.adresse-info {
  flex-direction: column;
  padding: 0.25em 1em;
}

.dropzone {
  border-radius: 10px;
  background-color: #eff3fa;
}

.rounded-modal {
  border-radius: 10px;
}

@media(min-width: 989px) {
  .modal-container {
    margin: 40px 10px;
    padding: 0px 20px;
  }
}

@media (max-width: 767px) {
  // .mt-m{
  //   margin-top: 10% ;

  // }
  .modal-container {
    margin: 0;
    padding: 0;
    border-radius: 0;
    height: 100%;
  }

  .wrapper {
    margin: 0 auto;
    height: 100% !important;
  }

  .rounded-modal {
    border-radius: 0px;
    width: 100%;
    height: 100% !important;
  }

  .card-footer {
    position: relative;
    bottom: 0;
    border-top: 0;

  }

  .container-publish {
    background: none;
  }
}

.bg-gray-500 {
  background-color: rgba(0, 0, 0, 0.03);
}

.v-element:last-of-type {
  border-radius: 0 0 10px 10px;
}
</style>