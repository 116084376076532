<template>

  <portal to="modal">
    <transition name="fade">
      <div class="modall" @click.self.stop.prevent="handleClose">
      <div class="absolute">
                  <i
                    v-if="isStoriesReady"
                    class="fas fa-times btn-close-story"
                    @click.self.stop.prevent="handleClose"
                  ></i>
        </div>
        <div
          class="wrapper col-md-6 col-sm-12"
          @click.self.stop.prevent="handleClose"
        >
          <div class="modal-container" @click.self.stop.prevent="handleClose" :class="!isStoriesReady ? 'modal-container-loader':''">
            <div
              class="
                flex flex-col
                h-screen
                w-full
                position-relative
                story-container
              "
            >
              
                
              
              <div
                v-if="!isStoriesReady"
                class="flex flex-row justify-center items-center my-8 h-screen"
              >
                <div
                  class="
                    flex flex-row
                    justify-center
                    items-center
                    my-8
                    h-screen
                  "
                >
                  <div
                    class="
                      position-relative
                      mx-2
                      rounded-slide
                      bg-red-400
                      transition-transform
                      duration-200
                      ease-in
                    "
                    style="width: 322px; height: 495px"
                  >
                    <div class="bg-cover bg-no-repeat h-full rounded-lg">
                      <div class="absolute top-1/2 left-1/2">
                        <Loader></Loader>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                v-else
                class="flex flex-row justify-center items-center my-8 h-screen"
              >
                <div
                  v-for="(story, index) in stories"
                  :key="story.id"
                  class="
                    absolute
                    mx-2
                    mt-5
                    
                    bg-red-400
                    transition-transform
                    duration-200
                    ease-in
                    storie-w
                  "
                  style="height: 550px"
                  :style="
                    index == indexSelected
                      ? `transform: translate(${
                          380 * (index + difference)
                        }px) scale(0.9)`
                      : `transform: translate(${
                          280 * (index + difference)
                        }px) scale(0.4);cursor:pointer;`
                  "
                  @click="index != indexSelected ? selectSlide(index) : ''"
                >
                  <div class="bg-cover bg-no-repeat h-full">
                    <div class="h-full d-flex img-stry-container">
                      <img
                        v-if="story.medias[key] && story.medias[key].type == 1"
                        :src="
                          index == indexSelected
                            ? assetsBaseUrl + story.medias[key].src
                            : assetsBaseUrl + story.medias[0].src
                        "
                        class="img-stry"
                      />
                      <video
                        :id="`${index}|${key}`"
                        v-else
                        autoplay
                        preload="auto"
                        class="
                          d-block
                          blur-up
                          lazyload
                          media-fit
                          img-fluid
                        "
                      >
                        <source
                          :src="
                            index == indexSelected
                              ? assetsBaseUrl + story.medias[key].src
                              : assetsBaseUrl + story.medias[0].src
                          "
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div class="w-full pt-4 absolute bottom-tof">
                      <div class="flex w-12/12 mt-0 pe-2">
                        <div
                          class="
                            flex
                            justify-star
                            items-center
                            w-100
                            align-flex
                          "
                        >
                          <div
                            class="emoji-wrap show p-1 ms-2"
                            v-if="story.nbr_vues > 0"
                          >
                            <ul class="emojis list-inline mb-0">
                              <li
                                role="button"
                                class="emoji list-inline-item text-black"
                                title="Like"
                              >
                                <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    font-14
                                    text-black
                                  "
                                  ><span>{{ story.nbr_vues }}</span> vues</span
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div
                          class="flex justify-end items-center w-100 align-flex"
                          :id="story.id"
                        >
                          <div
                            class="show p-1 pe-2 ps-2"
                            :calss="true ? `${story.id}` : `${story.id}`"
                          >
                            <ul class="emojis list-inline mb-0">
                              <li
                                @click="
                                  like(
                                    story.medias[key].id,
                                    story.id,
                                    key,
                                    index
                                  )
                                "
                                role="button"
                                class="emoji list-inline-item"
                                title="Like"
                              >
                                <i class="em em---1"></i>
                                <!-- <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >(</span
                                >
                                <span
                                  v-if="
                                    story.medias[key] &&
                                    story.medias[
                                      key
                                    ].jaimesStorieCountByType.hasOwnProperty(
                                      '1'
                                    )
                                  "
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >{{
                                    story.medias[key].jaimesStorieCountByType[1]
                                  }}</span
                                >
                                <span
                                  v-else
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >0</span
                                >
                                <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >)</span
                                > -->
                              </li>
                              <li
                                @click="
                                  love(
                                    story.medias[key].id,
                                    story.id,
                                    key,
                                    index
                                  )
                                "
                                role="button"
                                class="emoji list-inline-item"
                                title="Love"
                              >
                                <i class="em em-heart"></i>
                                <!-- <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >(</span
                                >
                                <span
                                  v-if="
                                    story.medias[key] &&
                                    story.medias[
                                      key
                                    ].jaimesStorieCountByType.hasOwnProperty(
                                      '2'
                                    )
                                  "
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >{{
                                    story.medias[key].jaimesStorieCountByType[2]
                                  }}</span
                                >
                                <span
                                  v-else
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >0</span
                                >
                                <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >)</span
                                > -->
                              </li>

                              <li
                                @click="
                                  dislike(
                                    story.medias[key].id,
                                    story.id,
                                    key,
                                    index
                                  )
                                "
                                role="button"
                                class="emoji list-inline-item"
                                title="Dislike"
                              >
                                <i class="em em-thumbsdown"></i>
                                <!-- <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >(</span
                                >
                                <span
                                  :id="story.medias[key].id + key"
                                  v-if="
                                    story.medias[key] &&
                                    story.medias[
                                      key
                                    ].jaimesStorieCountByType.hasOwnProperty(
                                      '7'
                                    )
                                  "
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >{{
                                    story.medias[key].jaimesStorieCountByType[7]
                                  }}</span
                                >
                                <span
                                  v-else
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >0</span
                                >
                                <span
                                  class="
                                    align-items-center
                                    font-xsssss
                                    fw-800
                                    lh-26
                                    mt-count
                                    text-black
                                  "
                                  >)</span
                                > -->
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="w-full pt-4 absolute top-0"
                      v-if="index == indexSelected"
                    >
                      <div class="w-11/12 flex m-auto">
                        <div
                          class="w-full rounded-lg mr-2 relative h-auto"
                          v-for="(elm, index) in story.medias.length"
                          :key="index"
                        >
                          <!-- length slide -->
                          <div
                            class="absolute w-full rounded-lg"
                            style="
                              height: 4px;
                              background-color: rgba(255, 255, 255, 0.35);
                            "
                            :id="`${index}|${key}|${index}`"
                            
                          ></div>
                          <!-- end length -->
                          <div
                            class="absolute w-full rounded-lg"
                            style="height: 4px; background-color: white"
                            :style="
                              index == key
                                ? `width: ${percent}%`
                                : key > index
                                ? `width:100%`
                                : `width:0%`
                            "
                          ></div>
                        </div>
                      </div>

                      <div class="flex w-11/12 mt-4 m-auto">
                        <div class="flex justify-start items-center w-3/4">
                          <div style="width: 35px; height: 35px">
                            <img
                              :src="assetsBaseUrl + story.visiteur.photo"
                              class="rounded-full"
                            />
                          </div>
                          <div class="ml-2">
                            <p class="text-sm text-black user-wrap p-2">
                              <router-link
                                :to="{
                                  name: 'feedvisiteur',
                                  params: { id: story.visiteur.id },
                                }"
                              >
                                {{ story.visiteur.prenom }}
                                {{ story.visiteur.nom }}
                              </router-link>
                            </p>
                          </div>
                        </div>
                        <div class="flex justify-end items-center w-1/4">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-6 w-6 cursor-pointer"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="#fff"
                            @click="isPaused ? playStory(index,key) : pauseStory(index,key)"
                          >
                            <path
                              v-if="!isPaused"
                              d="M9 6a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1zm6 0a1 1 0 0 1 1 1v10a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1z"
                              fill="#fff"
                            />
                            <path
                              v-else
                              d="M6 6.741c0-1.544 1.674-2.505 3.008-1.728l9.015 5.26c1.323.771 1.323 2.683 0 3.455l-9.015 5.258C7.674 19.764 6 18.803 6 17.26V6.741zM17.015 12L8 6.741V17.26L17.015 12z"
                              fill="#fff"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="absolute top-1/2 left-1/2 z-50">
                        <div class="flex flex-col items-center">
                          <div
                            style="
                              width: 50px;
                              height: 50px;
                              transform: translate(-50%, -50%) scale(2.5);
                            "
                            class="
                              rounded-full
                              border-2 border-indigo-400
                              transition-transform
                              duration-100
                            "
                          >
                            <img
                              :src="assetsBaseUrl + story.visiteur.photo"
                              class="rounded-full"
                            />
                          </div>
                          <div class="mt-2">
                            <p class="text-sm text-black font- user-wrap p-1">
                              {{ story.visiteur.prenom }}
                              {{ story.visiteur.nom }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="absolute inset-0 rounded-lg z-10"
                        style="
                          background: -webkit-gradient(
                            linear,
                            left top,
                            left bottom,
                            from(rgba(38, 38, 38, 0.6)),
                            to(rgba(38, 38, 38, 0))
                          );
                        "
                      ></div>
                    </div>
                  </div>
                  <div
                    v-if="index == indexSelected"
                    class="absolute top-1/2 -left-3"
                  >
                    <i
                      @click="prev(index)"
                      class="
                        fas
                        fa-chevron-circle-left
                        text-gray-200
                        cursor-pointer
                        hover:text-gray-300
                        transition-colors
                        duration-150
                        ease-linear
                      "
                    ></i>
                  </div>
                  <div
                    v-if="index == indexSelected"
                    class="absolute top-1/2 -right-3"
                  >
                    <i
                      @click="next(index)"
                      class="
                        fas
                        fa-chevron-circle-right
                        text-gray-200
                        cursor-pointer
                        hover:text-gray-300
                        transition-colors
                        duration-150
                        ease-linear
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
import Loader from "../loader/loaderShowStrory.vue";
import { onMounted, ref, getCurrentInstance  } from "vue";


export default {
  name: "App",
  components: {
    Loader,
  },
  data() {
    return {
      reactionRef: [],
    };
  },

  props: ["indexSelected"],
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
  mounted() {
    document.addEventListener("keyup", this.onClose);
   
  },
  
   destroyed() {
     document.removeEventListener("keyup", this.onClose);
    clearInterval(progress);
    clearInterval(interval);
  },

     
  methods: {
    onClose(event) {
      // Escape key
      if (event.keyCode === 27) {
        this.handleClose();
      }
    },
    //ref---------------------
    setReactionRef(el) {
      if (el && !this.reactionRef.includes(el)) {
        this.reactionRef.push(el);
      }
    },
    //--------------------
    handleClose() {
      this.$emit("closeStory");
      // console.log(window.mediaIdVues);
      this.axios.post('/addStoryVues', {
        media_ids: window.mediaIdVues
        
      }).then()
    },
    like(mediaId, storyId, key, index) {
      this.axios.post("/jaimeStory", {
        reaction_type: "1",
        jaimable_id: mediaId,
        jaimable_type: "App\\Models\\Media",
        visiteur_id: this.currentUser.id,
      });
    },
    love(mediaId, storyId, key, index) {
      this.axios.post("/jaimeStory", {
        reaction_type: "2",
        jaimable_id: mediaId,
        jaimable_type: "App\\Models\\Media",
        visiteur_id: this.currentUser.id,
      });
    },
    dislike(mediaId, storyId, key, index) {
      this.axios.post("/jaimeStory", {
        reaction_type: "7",
        jaimable_id: mediaId,
        jaimable_type: "App\\Models\\Media",
        visiteur_id: this.currentUser.id,
      });
    },
  },

  setup(props) {
    
    const internalInstance = getCurrentInstance();
    const axios = internalInstance.appContext.config.globalProperties.axios;
    // declaration side
    const isStoriesReady = ref(false);
    const indexSelected = ref(0);
    const difference = ref(0);
    const stories = ref([]);
    const key = ref(0);
    const mediaIdVues = ref([]);
    // percent story
    const percent = ref(0);
    const timer = ref(0);
    const progress = ref(0);
    const duration = ref(12000);
    const interval = ref(0);
    const isPaused = ref(false);
    const newDur = ref(0); // duration
    const pausePer = ref(0); // pausePercent
    // ----end declaration
    const selectSlide = (index) => {
      difference.value += indexSelected.value - index;
      indexSelected.value = index;
      key.value = 0;
      reset();
    };
    const fetchStories = () => {
      axios.get("stories").then((res) => {
        stories.value = res.data.stories;
        isStoriesReady.value = true;
        
      });

      play();
    };

    // next story
    const next = (index) => {
      isPaused.value = false;
      if (
        indexSelected.value >= stories.value.length - 1 &&
        key.value >= stories.value[indexSelected.value].medias.length - 1
      ) {
        // Without delay
        setTimeout(() => {
          difference.value = 0;
          indexSelected.value = 0;
          key.value = 0;
        });
      } else if (
        key.value >=
        stories.value[indexSelected.value].medias.length - 1
      ) {
        // Without delay
        setTimeout(() => {
          difference.value += index - (index + 1);
          indexSelected.value++;
          key.value = 0;
        });
      } else {
        key.value++;
        if (!mediaIdVues.value.includes(stories.value[indexSelected.value].medias[key.value].id)) {
        mediaIdVues.value.push(stories.value[indexSelected.value].medias[key.value].id);
        let a = new Set(mediaIdVues.value);
          mediaIdVues.value = [...a];
          window.mediaIdVues = mediaIdVues.value; 
        //console.log(mediaIdVues.value);
      }
       
        
        
        
      }
      reset();
      if (stories.value[indexSelected.value].medias[key.value].type == 2) {
        setTimeout(() => {
          getVideoDuration()
        },50);   
      }
      
    };
    // previous story
    const prev = (index) => {
      isPaused.value = false;
      if (indexSelected.value <= 0 && key.value <= 0) {
        key.value = 0;
      } else if (key.value <= 0) {
        // Without delay
        setTimeout(() => {
          difference.value += index - (index - 1);
          indexSelected.value--;
          key.value = 0;
        });
      } else {
        key.value--;
        if (!mediaIdVues.value.includes(stories.value[indexSelected.value].medias[key.value].id)) {
        mediaIdVues.value.push(stories.value[indexSelected.value].medias[key.value].id);
        let a = new Set(mediaIdVues.value);
        mediaIdVues.value = [...a];
        window.mediaIdVues = mediaIdVues.value; 
        //console.log(mediaIdVues.value);
      }
        
        
      }
      reset();
      
      if (stories.value[indexSelected.value].medias[key.value].type == 2) {
        setTimeout(() => {
          getVideoDuration()
        },50);   
      } 
      
    };
    const autoPlay = () => {

     
       
      if (!mediaIdVues.value.includes(stories.value[indexSelected.value].medias[key.value].id)) {
        mediaIdVues.value.push(stories.value[indexSelected.value].medias[key.value].id);
        let a = new Set(mediaIdVues.value);
        mediaIdVues.value = [...a];
        window.mediaIdVues = mediaIdVues.value; 
        //console.log(mediaIdVues.value);
      }
      
      if (
        indexSelected.value >= stories.value.length - 1 &&
        key.value >= stories.value[indexSelected.value].medias.length - 1
      ) {
        difference.value = 0;
        indexSelected.value = 0;
        key.value = 0;
      } else if (
        key.value >=
        stories.value[indexSelected.value].medias.length - 1
      ) {
        difference.value += indexSelected.value - (indexSelected.value + 1);
        indexSelected.value++;
        key.value = 0;
      } else {
        key.value++;
        if (!mediaIdVues.value.includes(stories.value[indexSelected.value].medias[key.value].id)) {
        mediaIdVues.value.push(stories.value[indexSelected.value].medias[key.value].id);
        let a = new Set(mediaIdVues.value);
          mediaIdVues.value = [...a];
        window.mediaIdVues = mediaIdVues.value; 
        //console.log(mediaIdVues.value);
        }
        
        
        
        
        
      }
      reset();
      if (stories.value[indexSelected.value].medias[key.value].type == 2) {
        
        setTimeout(() => {
          getVideoDuration()
        },50);   
      }
        
      
     
    };
    const getVideoDuration = () => {
      
      let i = indexSelected.value;
      let k = key.value;
    
      if (document.getElementById(`${i}|${k}`)) {
        let vidStoryDuration = document.getElementById(`${i}|${k}`).duration;
        
        let durationMs = vidStoryDuration * 1000;
        if (durationMs) {
          console.log(durationMs);
          //interval.value = setInterval(autoPlay, durationMs);
          duration.value = durationMs;
          //let durationBar = document.getElementById(`${i}|${k}|${i}`);
          //console.log(durationMs / 100);
          //durationBar.style.width = `${Math.floor(durationMs) / 100}%`;
          //percent.value = `${Math.floor(durationMs) / 100}%`;
          
        }
        

      }
      
    };
    const play = () => {
      
      timer.value = new Date().getTime();
      progress.value = setInterval(() => {
        // forward
        let time = new Date().getTime();
        if (newDur.value > 0) {
          percent.value = pausePer.value + Math.floor((100 * (time - timer.value)) / duration.value);
        } else {
          percent.value = Math.floor((100 * (time - timer.value)) / duration.value);
        }
      }, duration.value / 100);
      if (newDur.value > 0) {
        interval.value = setInterval(autoPlay, newDur.value);
      } else {
        interval.value = setInterval(autoPlay, duration.value);
      }
      

    };
    // reset play
    const reset = () => {
      
      percent.value = 0;
      newDur.value = 0;
      
      // duration.value = 12000;
      clearInterval(interval.value);
      clearInterval(progress.value);
      play();
      
    };
    const pauseStory = (i, k) => {
      if (document.getElementById(`${i}|${k}`)) {
        
        document.getElementById(`${i}|${k}`).pause();
      }
      
      isPaused.value = true;
      pausePer.value = percent.value;
      clearInterval(progress.value);
      clearInterval(interval.value);
      //clearTimeout()
      // calcul new duration
      newDur.value = duration.value - (pausePer.value * duration.value) / 100;
      //newDur.value = duration.value - pausePer.value;
    };
    const playStory = (i,k) => {
      isPaused.value = false;
      if (document.getElementById(`${i}|${k}`)) {
        
        document.getElementById(`${i}|${k}`).play();
      }
      play();
    };
    // OnMounted hook
    onMounted(() => {
      fetchStories();
      selectSlide(props.indexSelected);
    });
    return {
      difference,
      indexSelected,
      selectSlide,
      fetchStories,
      stories,
      next,
      prev,
      key,
      percent,
      play,
      timer,
      progress,
      duration,
      reset,
      interval,
      autoPlay,
      isPaused,
      pauseStory,
      playStory,
      newDur,
      pausePer,
      isStoriesReady,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/css/tailwind.css";
/* import fonts */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");
.em,
.em-svg {
  transition: all 0.2s ease-in-out;
  height: 1.5em;
  width: 1.5em;
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  vertical-align: middle;
  &:hover {
    opacity: 1;
    transform: scale(1.2);
  }
}
.em---1,
.em---1,
.em-thumbsup {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
}
.em---1.em-svg,
.em---1.em-svg,
.em-thumbsup.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44d.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44d.svg"),
    none;
}
.em--1,
.em-thumbsdown {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
}
.em--1.em-svg,
.em-thumbsdown.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/1f44e.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/1f44e.svg"),
    none;
}
.em-heart {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
}
.em-astonished {
  background-image: url("https://ressources.trippybook.com/assets/icons/72x72/1f632.png");
}
.em-heart.em-svg {
  background: url("https://ressources.trippybook.com/assets/icons/72x72/2764.png");
  background-image: url("https://ressources.trippybook.com/assets/icons/svg/2764.svg"),
    none;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.fas {
  font-size: 30px;
}
.modall {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: table;
  transition: opacity 0.3s ease;
}
.wrapper {
  //width: 44%;
  margin: 0 auto;
  border-radius: 10px;
}
.modal-container {
  margin: 30px 98px;
  padding: 20px 40px;
  border-radius: 2px;

  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow: hidden;
}
.h-screen {
  height: 77vh !important;
}
.btn-close-story {
   transition: all 0.4s ease-in-out;
  display: block;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  font-size: 25px;
  background-size: contain;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.9;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  -o-transition: opacity 0.2s;
  transition: opacity 0.2s;
  background: #f00 !important;
  color: #fff !important;
  z-index: 999999999 !important;
  &:hover {
    background: rgba(255, 0, 0, 1) !important;
    cursor: pointer;
  }
  &:hover .fa-times {
    color: #fff !important;
  }
}
.story-container {
  overflow: hidden;
}

.rounded-full {
  border-radius: 10px !important;
}
.fa-chevron-circle-right,
.fa-chevron-circle-left {
  &:hover {
    color: #2e58a6 !important;
  }
  transition: all 0.4s ease-in-out;
}
@media screen and (max-width: 989px) {
  .modal-container {
    margin: 0px 10px;
    padding: 0px 20px;
  }
}
.bg-red-400 {
  background-color: #000 !important;
}
// .h-screen {
//   height: 100% !important;
// }

.mt-m {
  margin-top: -24px;
}
.bottom-tof {
  bottom: 1em;
}
.emoji-wrap {
  display: block;
  z-index: 9999999;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.7);
}
.user-wrap {
  display: block;
  z-index: 9999999;
  border-radius: 10px !important;
  background-color: rgba(255, 255, 255, 0.7);
}

.img-stry-container{
  align-items: center;
  
}

@media (max-width: 767px) {

   .storie-w{
    width: 90%;
    margin: 0 auto
  }
  .w-full.story-container{
    width: 100%;
}
.modal-container {
    margin: -45px;
   padding: 10px 38px;
}
.wrapper {
    margin: 11px auto;   
    }


    

}
</style>
