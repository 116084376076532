import { createStore } from 'vuex'
import axios from 'axios';
const store = createStore({
    state: {
        notifications: [],
        notifCount: 0,
        LastPageAdresse: 1,
        LastPagePeople: 1,
        LastPagePub: 1,
        searchResultsIsReady: false,
        NavBarSearchKey: '',
        searchResults: [],
        currentUser: {},
        feeds: {},
        adresseInfoId: null,
        adresseInfo: {},
        range: 1000,
        filterToggle: false,
        continentID: null,
        searchFilter: '',
        paysID: '',
        evalsID: null,
        coutsID: null,
        villeID: '',
        villes: [],
        pays: [],
        catID: [],
        assetsBaseUrl: 'https://ressources.trippybook.com/assets/',

        // baseUrl: 'http://localhost:3000/',
        // apiBaseUrl: 'http://localhost:8000/api/visiteur/'

        apiBaseUrl: 'https://api.trippybook.com/api/visiteur/',
        baseUrl: 'https://www.trippybook.com/'


    },
    actions: {
        getuser({ commit }) {
            axios
                .get("currentUser")
                .then(response => {
                    commit('SET_USER', response.data.visiteur);
                    commit('SET_NOTIFCATIONS', response.data.notifications);
                    commit('SET_NOTIF_COUNT', response.data.unreadNotifCount);
                    //console.log(response.data.notifications)
                })
        },
        getSearchResult({ commit }, { NavBarSearchKey, pageAdresse, pagePeople, pagePub }) {
            commit('SET_SEARCH_RESULTS_IS_READY', false)
            axios
                .post(`searchNavBar`, {
                    searchFilter: NavBarSearchKey

                })
                .then(response => {
                    commit('SET_SEARCH_RESULTS', response.data)
                    commit('SET_SEARCH_RESULTS_IS_READY', true)
                    commit('SET_LAST_PAGE_ADRESSE', response.data.adresses.last_page)
                    commit('SET_LAST_PAGE_PEOPLE', response.data.visiteurs.last_page)
                    commit('SET_LAST_PAGE_PUB', response.data.publications.last_page)




                })
        },
        //---------------------
        getAdresseInfoID({ commit }, idAdresseInfo) {

            commit('SET_ADRESSEINFOID', idAdresseInfo)

        },
        //---------------------
        NavBarSearchKey({ commit, state }, NavBarSearchKey) {
            commit('STORE_NavBarSearchKey', NavBarSearchKey)
        },

        searchFilter({ commit, state }, searchFilter) {
            commit('STORE_SEARCHFILTER', searchFilter)
        },
        evalsID({ commit, state }, evalsID) {
            commit('STORE_EVALSID', evalsID)
        },
        coutsID({ commit, state }, coutsID) {
            commit('STORE_COUTSID', coutsID)
        },
        catID({ commit, state }, catid) {
            commit('STORE_CATID', catid)
        },
        villeID({ commit, state }, villeID) {
            commit('STORE_VILLEID', villeID)
        },
        range({ commit, state }, range) {
            commit('STORE_RANGE', range)
        },
        continentID({ commit, state }, continentID) {
            commit('STORE_CONTINENTID', continentID)
        },
        pays({ commit, state }, pays) {
            commit('STORE_PAYS', pays)
        },
        paysID({ commit, state }, paysID) {
            commit('STORE_PAYSID', paysID)
        },
        villes({ commit, state }, villes) {
            commit('STORE_VILLES', villes)
        },
        filterToggle({ commit, state }, filterToggle) {
            commit('STORE_filterToggle', filterToggle)
        },


    },
    mutations: {
        SET_ADRESSEINFOID(state, idAdresseInfo) {
            state.adresseInfoId = idAdresseInfo
        },
        SET_USER(state, apiGetResUser) {
            state.currentUser = apiGetResUser
        },
        //notif
        SET_NOTIFCATIONS(state, apiGetNotifications) {
            state.notifications = apiGetNotifications
        },
        SET_NOTIF_COUNT(state, apiGetNotifCount) {
            state.notifCount = apiGetNotifCount
        },
        SET_SEARCH_RESULTS(state, apiGetRes) {
            state.searchResults = apiGetRes
        },
        SET_SEARCH_RESULTS_IS_READY(state, ReadyStatus) {
            state.searchResultsIsReady = ReadyStatus
        },
        SET_LAST_PAGE_ADRESSE(state, LastPageAdresse) {
            state.LastPageAdresse = LastPageAdresse
        },
        SET_LAST_PAGE_PEOPLE(state, LastPagePeople) {
            state.LastPagePeople = LastPagePeople
        },

        SET_LAST_PAGE_PUB(state, LastPagePub) {
            state.LastPagePub = LastPagePub
        },


        STORE_SEARCHFILTER(state, searchFilter) {
            state.searchFilter = searchFilter
        },
        STORE_NavBarSearchKey(state, NavBarSearchKey) {
            state.NavBarSearchKey = NavBarSearchKey
        },
        STORE_EVALSID(state, evalsID) {
            state.evalsID = evalsID
        },
        STORE_COUTSID(state, coutsID) {
            state.coutsID = coutsID
        },
        STORE_CATID(state, cat) {
            state.catID = cat
        },
        STORE_VILLEID(state, villeID) {
            state.villeID = villeID
        },
        STORE_RANGE(state, range) {
            state.range = range
        },
        STORE_CONTINENTID(state, continentID) {
            state.continentID = continentID
        },
        STORE_PAYS(state, pays) {
            state.pays = pays
        },
        STORE_PAYSID(state, paysID) {
            state.paysID = paysID
        },
        STORE_VILLES(state, villes) {
            state.villes = villes
        },
        STORE_filterToggle(state, filterToggle) {
            state.filterToggle = filterToggle
        },
    }
})
export default store