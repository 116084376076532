<template lang="">
  <div v-if="loading" class="loading">
      <Loader></Loader>
    </div>
  <div class="color-theme-blue loaded">
    <div class="main-wrap">
      <div
        class="nav-header bg-transparent shadow-none border-0"
        
      >
        <div class="nav-top">
          <a href="#"
          ><img :src="`${assetsBaseUrl}LogoTrippy/logo.png`" class="display1-size me-2 ms-0">
          </a>
        </div>
      </div>

      <div class="row">
        <div
          class="
            col-xl-6
            d-none d-xl-block
            p-0
            vh-100
            bg-image-cover bg-no-repeat
          "
        >
        <Splide
                :options="{
                    rewind: true,
                  perPage: 1,
                  autoWidth: true,
                  arrows: false,
                  pagination: false,
                  wheel: false,
                  type   : 'fade',
                  isNavigation: false,
                  autoplay: true,
                  interval: 5000,
                  speed:5000,
                  autoHeight:true
                  
                  
                }"
              >
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image05.jpg`">
          
              </SplideSlide>
            
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image01.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image02.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image03.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image04.jpg`">
          
              </SplideSlide>
              
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image06.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image07.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image08.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image10.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image11.jpg`">
          
              </SplideSlide>
              <SplideSlide>
              <img class="vh-100"  :src="`${assetsBaseUrl}slider/gallery_image12.jpg`">
          
              </SplideSlide>
        </Splide>
        
        
        
        </div>
        <div
          class="
            col-xl-6
            vh-100
            align-items-center
            d-flex
            bg-white
            rounded-3
            overflow-hidden
          "
        >
          <div class="card shadow-none border-0 ms-auto me-auto login-card">
            <div class="card-body rounded-0 text-left">
              <h2 class="fw-700 display1-size display2-md-size mb-4">
               Create your account

              </h2>
              <form @submit.prevent="register">
                <div class="form-group icon-input mb-2">
                  <i class="font-sm ti-user text-grey-500 pe-0"></i>
                  <input
                      type="text"
                      :class="errors.nom ? 'is-invalid':''"
                      class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xsss
                      fw-600
                    "
                    placeholder="Last name"
                    v-model="nom"
                    required
                  />
                </div>
                 <div v-if="errors.nom" class="alert alert-danger alert-dismissible mt-1" role="alert">
                  {{errors.nom}}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                <div class="form-group icon-input mb-2">
                  <i class="font-sm ti-user text-grey-500 pe-0"></i>
                   
                  <input
                    :class="errors.prenom ? 'is-invalid':''"
                    type="text"
                    class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xsss
                      fw-600   
                    "
                    placeholder="First name"
                    v-model="prenom"
                    required
                  />
                  <div v-if="errors.prenom" class="alert alert-danger alert-dismissible mt-1" role="alert">
                      <strong>{{errors.prenom}}</strong>
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                </div>
                <div class="form-group icon-input mb-2">
                  <i class="font-sm ti-email text-grey-500 pe-0"></i>
                  <input
                    type="email"
                    :class="errors.email ? 'is-invalid':''"
                    class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xsss
                      fw-600
                    "
                    id="email"
                    placeholder="E-mail address
"
                    v-model="email"
                    required
                  />
                </div>
                <div v-if="errors.email" class="alert alert-danger alert-dismissible" role="alert">
                  {{errors.email[0]}}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
                <div class="form-group icon-input mb-2">
                  <input
                    type="Password"
                    :class="errors.password ? 'is-invalid':''"
                    class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xss
                      ls-3
                    "
                    id="password"
                    placeholder="Password"
                    v-model="password"
                    required
                  />
                  <div v-if="errors.password" class="alert alert-danger alert-dismissible mt-1" role="alert">
                  {{errors.password[0]}}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                  <i class="font-sm ti-lock text-grey-500 pe-0"></i>
                </div>
                <div class="form-group icon-input mb-1">
                  <input
                    type="Password"
                    
                    :class="errors.confirm_password ? 'is-invalid':''"
                    class="
                      style2-input
                      ps-5
                      form-control
                      text-grey-900
                      font-xss
                      ls-3
                    "
                    id="confirm_password"
                    placeholder="Confirm password"
                    v-model="confirm_password"
                    required
                  />
                  <div v-if="errors.confirm_password" class="alert alert-danger alert-dismissible mt-1" role="alert">
                  {{errors.confirm_password[0]}}
                  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                  </div>
                  <i class="font-sm ti-lock text-grey-500 pe-0"></i>
                </div>
                <input type="hidden" id="lat" v-model="lat" />
                <input type="hidden" id="long" v-model="long" />
                
                <div class="col-sm-12 p-0 text-left">
                  <div class="form-group mb-1">
                    <button
                      type="submit"
                      class="
                        form-control
                        text-center
                        style2-input
                        text-white
                        fw-600
                        bg-dark
                        border-0
                        p-0
                      "
                    >
                      Sign up
                    </button>
                  </div>
                  <h6 class="text-grey-500 font-xsss fw-500 mt-0 mb-0 lh-32">
                    Already have an account
                    <router-link class="fw-700 ms-1" :to="{ name: 'login' }"
                      >Log in</router-link
                    >
                  </h6>
                </div>
                <!--  {{errors.email}}
                      {{errors.password}}
                      {{errors.nom}}
                      {{errors.prenom}} -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useToast } from 'vue-toastification';
const toast = useToast();
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import Loader from "../layouts/loader/loaderLogin.vue";
export default {
  components:{
    Splide, 
    SplideSlide,
    Loader

  },
  data() {
    return {
      nom: "",
      prenom: "",
      password: "",
      confirm_password: "",
      email: "",
      lat: "",
      long: "",
      errors: {},
      isValid: true,
      loading: false,
    };
  },
  methods: {
    testValidPass(){
      if(this.password == this.confirm_password){
      this.errors.confirm_password="";
      }else if(this.confirm_password.length > 7) {
        this.errors.confirm_password="The confirm password and password must match.";

      }

    },
    register() {
      this.loading = true;
      this.axios
        .post("register", {
          nom: this.nom,
          prenom: this.prenom,
          password: this.password,
          confirm_password: this.confirm_password,
          email: this.email,
        })
        .then((res) => {
          this.loading = true;
          // if(res.response.data.success){
          // console.log(res);
             toast.success("Account created successfully.", {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
          this.$router.push("/");
          // }
        })
        .catch((error) => {
          this.loading = false;
          if (!error.response.data.success) {
              this.errors = error.response.data.errorsValidation;
              console.log(error.response.data.errorsValidation);

              if(this.errors){
              toast.error("Invalid data.", {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false
            });
              }
              
            



          }
        });
    },
  },
  
  mounted() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.lat = position.coords.latitude;
      this.long = position.coords.longitude;
    });
  },
  beforeCreate() {
    if (localStorage.getItem("token")) {
      this.$router.push("/feeds");
    }
  },
  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
};
</script>
<style lang="scss" scoped>
a {
  text-decoration: none !important;
}
.alert{
  font-size: 12px !important;
  margin-bottom:0.5rem !important
}
.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.7875rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(227, 52, 47, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: #e3342f;
  padding-right: calc(1.6em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.1875rem) center;
  background-size: calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 0.75rem);
  background-position: top calc(0.4em + 0.1875rem) right calc(0.4em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid {
  border-color: #e3342f;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23e3342f' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23e3342f' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.8em + 0.375rem) calc(0.8em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label,
.form-check-input.is-invalid ~ .form-check-label {
  color: #e3342f;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip,
.form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label,
.custom-control-input.is-invalid ~ .custom-control-label {
  color: #e3342f;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before,
.custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before,
.custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e9605c;
  background-color: #e9605c;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label,
.custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #e3342f;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label,
.custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #e3342f;
  box-shadow: 0 0 0 0.2rem rgba(227, 52, 47, 0.25);
}
// .alert-dismissible {
//     padding-right: 2rem !important;
//     .btn-close{
//       padding:0.1rem !important
//     }
// }
.alert{
    margin-top:2px !important;
    margin-bottom: 0px  !important;
    padding: 0.5rem 1rem !important;
}
.alert-dismissible .btn-close{
    padding:0.5rem 1rem !important
}
.is-active {
  border-bottom: 0 !important;
}
.splide{
  height: 100vh !important;
}
.image-fit {
  object-fit: cover !important;
}
.loading {
  background-color: transparent;
  position: absolute;
  top: 10%;
  left: 50%;
  z-index: 99;
  margin: 20% auto !important;
}
</style>
