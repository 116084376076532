<template lang=""> 
  <template v-if="!isReady" >
        <Loader/>   
        <Skeleton />
  </template>
 
   <ModalComment v-if="isShowModal" @close="toggleModal" scrollable title="Commentaire" :postId="postIdToCmt" :index="indexCmntedPubMedia" @refreshComment="refreshComment">
      
  </ModalComment>

 <LightBox v-if="lightBox" @close="toggleLightBox" :postId="postId" :mediaId="mediaId" :mediaPos="mediaPos" >
      
  </LightBox>
   
  <div v-if="isReady"
        class="
            ">
        <div v-if="feeds.length" id="data-wrapper">
            <template v-for="(x,index) in feeds" :key="x.id"> 
 
<!-- ----------------------------------------------(Debut Publication)------------------------------------->
                      <div class="card w-100 shadow-xss rounded-xxl border-0 p-3 mb-3">
                     <!-- Test si proprietaire ou pas -->  
                           <div class="card-body p-0 d-flex">
                            <router-link :to="{ name : 'feedadresse' , params : { id : `${x.mediatable.adresse.id}`}}">
                            <figure class="avatar me-3">
                                    <img :src="`${assetsBaseUrl}${x.mediatable.adresse.logo}`" alt="image" class="shadow-md rounded-circle w45">
                               
                            </figure>
                        </router-link>
                           <h4 class="fw-700 text-grey-900 font-14 ">
                                <router-link :to="{ name : 'feedadresse' , params : { id : `${x.mediatable.adresse.id}`}}">
                                   
                                    {{x.mediatable.adresse.rs}} 
                                    
                                </router-link>
                                
                              
                      <!-- Test si proprietaire ou pas -->      
                        <!-- <i class="fas fa-angle-right font-14 mt-0  me-1 ms-1"></i> -->
                        <span class="d-block font-14 fw-500 mt-1 lh-3 text-grey-500">
                           
                                 {{ $filters.timeAgo(x.datetime)}}
                                
 
                                 
                                </span>
                        </h4>
                      
    <!-- ---------------------------------Drop Down menu Posts (à fixer) ------------------------------------------  -->
                            
                            
                        </div>
    <!-- ----------------------------------Fin Drop Down ----------------------------------- --> 
                        <div class="card-body p-0 me-lg-5">
                            <!-- <p class="fw-500 text-grey-500 lh-26 font-14 w-100">{{x.text_pub}} <a href="#" class="fw-600 text-primary ms-2">Voir plus</a></p> -->
                        </div>
                        <div class="card-body d-block p-0">
                           <div class=""> 
<!-- **********************************Debut Container images Dynamic ***************************-->
 
<div class="row"> <!-- Row 1 Start -->


  <div class="col-xs-12 col-sm-12 p-1">
      <a role="button" class="position-relative d-block">
        <img @click="toggleLightBox(x.mediatable.id,x.id,0)" :src="`${assetsBaseUrl}${x.src}`"  class="h-1 rounded-3 w-100" alt="image">
        
     </a>
  </div>


 


</div> <!-- Row 1 End -->








                           
                        
              

                          

                      
                 

<!-- ****************************************Fin Container images Dynamic ***********************-->

                         </div>
                        </div>
                     </div>
<!-- ------------------------------------------------Fin Tof ---------------------------------------->    

      </template> <!-- Fin For -->
           
              <div v-if="done"
        class="
            card
            w-100
            text-center
            shadow-xss
            rounded-xxl
            border-0
            p-4
            mb-3
            mt-3
        "
    >
        
    No other photos
    </div>
        </div> <!-- Fin data-wrapper -->
        <div v-if="feeds.length" v-observe-visibility="{callback: visibilityChanged,throttle: 500,}"></div>
        <div v-else>No photos found</div>
  </div>
  
   
 <Loader v-show="isVisible && !done"/>                    
</template>
<script>
import Skeleton from "../../../layouts/skeleton/Skeleton.vue";
import SkeletonComment from "../../../layouts/skeleton/SkeletonComment.vue";
import Loader from "../../../layouts/loader/loader.vue";
import { watchEffect } from "vue";
import ModalComment from "../../../layouts/modal/modalComment.vue";
import LightBox from "../../../layouts/lightBox.vue";
import loaderJaime from "../../../layouts/loader/loaderJaime.vue";

export default {
  name: "FeedList",
  components: {
    Skeleton,
    Loader,
    SkeletonComment,
    ModalComment,
    LightBox,
    loaderJaime,
  },
  setup() {
    watchEffect(() => {}, {
      flush: "post",
    });
  },
  /* ------------------------------------------------- (DATA)--------------------------------- */

  data() {
    return {
      adresse: [],
      feeds: [],
      jaimes: [],
      comments: [],
      isReady: false,
      isShowModal: false,
      lightBox: false,
      postId: Number,
      mediaId: Number,
      mediaPos: Number,
      postIdToCmt: Number,
      indexCmntedPubMedia: Number,
      commentRefreshKey: false,
      isJaimeGroupReady: true,
      //-------------PAGE-------------
      lastPage: 1,
      lastCommentPage: 1,
      page: 1,
      pageComment: 1,
      isPrevCommentReady: true,

      //-------------REFS-------------
      socialactRef: [],
      commentRef: [],
      jaimesGroup: [],
      btnReactRef: [],
      jaimeCountRef: [],
      commentCountRef: [],
      commentListRef: [],
      annulerCommentBtnRef: [],
      tooltipCommentJaimeRef: [],
      btnJaimeCommentRef: [],
      countJaimeCommentRef: [],
      visibilityRef: [],
      //--------React---------------
      socialClicked: false,
      isCommentReady: true,
      isVisible: false,
      done: false,
      isPhoto: "App\\Models\\Photo",
      isVideo: "App\\Models\\Video",
      excecuted: false,
      show: false,
      //------Hover-----------------
      hovering: false,
      hoveringTwo: false,
      showed: false,
      hoverTimer: "",
      //-------Hover comment-------------------
      hoveringComment: false,
      hoveringTwoComment: false,
      showedComment: false,
      hoverTimerComment: "",
    };
  },
  /* ------------------------------------------------- (LifeCycle Hooks)--------------------------------- */
  destroyed() {
    const controller = new AbortController();
     controller.abort()
  },

  mounted() {
    this.getFeeds();
    this.$store.dispatch("getuser");
  },

  /* ------------------------------------------------- (Computed)----------------------------------------------- */
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
  },
  /*  -------------------------------------------------------(Methods) -------------------------------------------*/
  methods: {
    changeVisibilty(postId, newVisi, oldVisi, i) {
      // alert(postId);
      this.axios
        .put(`publication/${postId}`, { visibility: newVisi })
        .then((res) => {
          //console.log(this.visibilityRef[i]);
        });
    },
    checkvide(event) {
      if (event.target.value == "") {
        // return false
        //console.log('vide');
      } else {
        // return true
        //console.log('non svide');
      }
    },

    toggleModal(postId, index) {
      this.isShowModal = !this.isShowModal;
      this.postIdToCmt = postId;
    },

    getindexCmntedPubMedia(index) {
      this.indexCmntedPubMedia = index;
      //alert(this.indexCmntedPubMedia);
    },

    toggleLightBox(postId, mediaId, mediaPos) {
      this.lightBox = !this.lightBox;
      this.postId = postId;
      this.mediaId = mediaId;
      this.mediaPos = mediaPos;
    },
    isProp(currentUserId, visiteurId) {
      if (currentUserId == visiteurId) {
        return true;
      } else {
        return false;
      }
    },

    /* --------------------------------------------------- (Feeds)----------------------------------------------- */
    getFeeds() {
      this.axios
        .get(`photos/visiteur/${this.$route.params.id}?page=${this.page}`)
        .then((res) => {
          this.feeds.push(...res.data.photos.data);

          this.isReady = true;
          this.lastPage = res.data.photos.last_page;
          console.log(this.feeds);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    visibilityChanged(isVisible) {
      if (!isVisible) {
        return;
      }
      if (this.page >= this.lastPage) {
        return (this.done = true);
      }
      this.isVisible = isVisible;

      this.page++;
      this.getFeeds();
    },
  },
};
</script>


   
<style scoped lang="scss">
@import "../../../../assets/css/emoji.css";
@import "../../../../assets/css/bootstrap-datetimepicker.css";
@import "../../../../assets/css/video-player.css";

@import "https://pro.fontawesome.com/releases/v5.10.0/css/all.css";

.preloader {
  left: 300px !important;
  top: 150px !important;
  background-position: 30% 5% !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.h-3 {
  height: 15em !important;
}
.h-2 {
  height: 12.5em !important;
}
.h-1 {
  height: 25em !important;
}

.h-5 {
  height: 8em !important;
}
.feather-thumbs-upp {
  background-color: blue;
}
.social-action {
  padding: 0px;
  font-size: 15px !important;

  &:hover {
    background-color: rgba(128, 128, 128, 0.2);
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
  }
  .feather-thumbs-up {
    background: none;
  }
}
.social-actions {
  margin-top: 0px;
  margin-bottom: 0px;
  box-shadow: 0px 1px rgba(128, 128, 128, 0.2);

  user-select: none;
}
.jaime-comment {
  box-shadow: 0px 1px rgba(128, 128, 128, 0.2);
  padding-top: 1px;
  padding-bottom: 1px;
}

.emoji .em {
  opacity: 0.9;
  &:hover {
    opacity: 1;
    transform: scale(1.1);
  }
}
.socialClicked {
  // color: blue !important
}
.jaime {
  color: blue !important;
}
.jadore {
  color: red !important;
}

.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.emoji-wrap {
  left: -25px;
  top: 15px;
}

.comment-count {
  user-select: none;
  &:hover {
    cursor: pointer;
    color: #1e74fd !important;
    text-decoration: underline !important;
  }
}
.feather-camera {
  position: absolute;
  right: 5px;
  top: 5px;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
}
.jaimeComment {
  padding: 0px 10px;
  display: inline-block;
  font-weight: bold !important;
  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}
.delete-comment-btn {
  top: -10px;
  right: 0;
}
.del-up {
  padding: 2px;
  &:hover {
    background-color: #eee;
    transition: all 0.3s ease-in-out;
  }
}
.cancelComment {
  padding: 0px 8px;
}
.annulerCommentBtn,
.comment-pagination {
  &:hover {
    text-decoration: underline !important;
    cursor: pointer;
  }
}
.fix-float {
  clear: both;
}
.comment-card {
  padding: 0 1em !important;
  padding-top: 5px !important;
}
.comments-container {
  padding-right: 0 !important;
}

video {
  object-fit: fill !important;
}
.dropdown-menu {
  padding: 0 15px !important;
}
.mediaComment {
  border-radius: 10px;
  margin-top: 5px;
  border: 1px solid rgba(153, 153, 153, 0.3);
  //box-shadow: 1px 1px 1px rgba(153, 153, 153, 0.5);
}
.jaimeComBtn {
  font-weight: bold !important;
}
.emoji-wrap-comment {
  bottom: -50px;
  z-index: 99;
}
.count-jaime-comment {
  float: right;
  display: inline-block;
  background-color: #f5f5f5;
  width: 8%;
  border-radius: 10px;
  text-align: end;
  border: 2px solid #fff;
  padding: 0 2px !important;
  line-height: 1.2em;
}
.rounded-circle {
  border-radius: 10px !important;
}
.comment-focus {
  border: 2px#5869b8b4 solid;
}
.position-absolute {
  position: absolute !important;
}
.change-visibility {
  transition: all 0.4s ease-in-out;
  &:hover {
    cursor: pointer;
    color: #1e74fd;
  }
}
@media (max-width: 767px) {
  .h-1 {
    height: 15em !important;
  }
  .h-3 {
    height: 10em !important;
  }
  .h-2 {
    height: 8em !important;
  }
  .row > * {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .rounded-3 {
    border-radius: 0px !important;
  }
}
</style>
