<template lang="">
    <!-- loader wrapper -->
    <div class="preloader-wrap p-3">
        <div class="box shimmer">
            <div class="lines">
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
            </div>
        </div>
        <div class="box shimmer mb-3">
            <div class="lines">
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
            </div>
        </div>
        <div class="box shimmer">
            <div class="lines">
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
                <div class="line s_shimmer"></div>
            </div>
        </div>
    </div>
    <!-- loader wrapper -->
</template>
<script>
export default {
    name: "skeleton",
};
</script>
<style lang="scss">
.preloader-wrap {
    display: block !important;
}
</style>
