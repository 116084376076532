<template lang="">
  <div>
    <router-view />
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss">
@import "../assets/css/themify-icons.css";
@import "../assets/css/feather.css";
@import "../assets/css/style.css";
.is-active {
  border-bottom: 1px#2e58a6 solid !important;
  color: #2e58a6 !important;

  //font-size:32px
}
.hover-item {
  &:hover {
    border-bottom: 1px#2e58a6 solid !important;
    color: #2e58a6 !important;
  }
}
.red {
  color: #f00 !important;
}
.blue {
  color: #2e58a6 !important;
}
.orange {
  color: rgb(233, 113, 15) !important;
}
.yellow {
  color: rgb(233, 218, 12) !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
}
.gm-style-iw .gm-style-iw-c {
  padding-top: 0 !important;
}
.fa-1-5x {
  font-size: 1.5em !important;
}
</style>
