<template lang="">
 <div class="dropdown-menu dropdown-menu-end p-1 rounded-3 border-0 shadow-lg show ">
                   <ul class="">
                     <li>ddddd</li>
                     
                    </ul>  

                </div>
</template>
<script>
export default {
  
}
</script>
<style lang="scss" scoped>
.dropdown-menu{
  background-color: rgba(0,0,0,0.7);
  color:#fff;
  font-size:12px !important;
  font-weight: 400;
   white-space:nowrap;
  text-overflow:ellipsis;
   overflow: hidden;
}
.rounded-modal{
  border-radius:10px
}
  
</style>