<template lang="">




<!-- -----------------********-----------------------SIDER-----------------------------***************--->



  <Splide
  class="ps-1"
    :options="{
      rewind: true,
      perPage: 3,
      autoWidth: true,
      arrows: false,
      pagination: false,
      wheel: false,
      isNavigation: false,
      drag:'free',
      
    }"
  >
  <SplideSlide>
     <template v-if="!isMyStoriesReady || myStories.length == 0">
          <div class="owl-item d-i-b" style="width: auto; margin-right: 7px">
            <div class="item">
              
              <div 
                class="
                  card
                  w125
                  h200
                  d-block
                  border-0
                  shadow-none
                  rounded-xxxl
                  my-storie
                  overflow-hidden
                  mb-3
                  bg-dark
                  mt-3
                "
              >
                <div
                  class="
                    
                    d-block
                   
                    w-100
                    position-absolute
                    bottom-0
                    text-center
                  "
                >
                  <a @click="toggleModal" class="openModalAddStory">
                    <span class="btn-round-lg bg-white btn-add-story"
                      ><i class="feather-plus font-lg"></i
                    ></span>
                    <div class="clearfix"></div>
                    <h4
                      class="
                        fw-700
                        position-relative
                        z-index-1
                        ls-1
                        font-xssss
                        text-white
                        mt-2
                        mb-1
                      "
                    >
                      Add Story
                    </h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
  <template v-else-if="myStories.length">
  <div class="owl-item" style="width: auto; margin-right: 7px">
    <div
      v-for="myStorie in myStories"
      class="
        card
        w125
        h200
        d-block
        border-0
        shadow-none
        rounded-xxxl
        my-storie
        overflow-hidden
        bg-image-cover
        mb-3
        mt-3
      "
      @click.self="toggleShowModalMy()"
    >
      <img
        v-if="myStorie.medias[myStorie.medias.length - 1].type == 1"
        @click.self="toggleShowModalMy()"
        :src="`${assetsBaseUrl}${
          myStorie.medias[myStorie.medias.length - 1].src
        }`"
        alt="image"
        class="image-fit float-right p-0 bg-white h200 w-100 shadow-xss"
        style="opacity: 1"
      />

      <video
        ref="videoToPlay"
        loop
        v-else
        class="float-right w-100 h200 video-fit"
        @click.self="toggleShowModalMy()"
        muted
        @mouseleave="pauseVideo"
        @mouseover="playVideo"
      >
        <source
          :src="`${assetsBaseUrl}${
            myStorie.medias[myStorie.medias.length - 1].src
          }`"
          type="video/mp4"
        />
      </video>

      <div class="d-block w-100 position-absolute bottom-0 text-center">
        <a @click="toggleModal" class="openModalAddStory">
          <span class="btn-round-lg bg-white btn-add-story"
            ><i class="feather-plus font-lg"></i
          ></span>
          <div class="clearfix"></div>
          <h4
            class="
              fw-700
              position-relative
              z-index-1
              ls-1
              font-xssss
              text-white
              mt-2
              mb-3
            "
          >
            Add Story
          </h4>
        </a>
      </div>
    </div>
  </div>
</template>
  
  </SplideSlide>
  <template v-if="isStoriesReady">
    <SplideSlide v-for="(story, index) in stories" class="item d-i-b" :class="loading && clickedStoryID == story.id ? 'opacity-str' : ''">
      <div class="item d-i-b" @click="toggleShowModal(index,story.id)">
        <div
          class="
            card
            w125
            h200
            d-block
            border-0
            shadow-xss
            rounded-xxxl
            overflow-hidden
            cursor-pointer
            mb-3
            mt-3
            me-1
          "
        >
        <template v-if="loading && clickedStoryID == story.id">
        <LoaderStory></LoaderStory>
        <img
            v-if="story.medias[story.medias.length - 1].type == 1"
            :src="`${assetsBaseUrl}${
              story.medias[story.medias.length - 1].src
            }`"
            alt="image"
            class="image-fit float-right p-0 bg-white h200 w-100 shadow-xss"
            style="opacity: 1"
          />

          <video
            :ref="setVideoRef"
            v-else
            loop
            class="float-right w-100 h200 video-fit"
            muted
            @mouseleave="pauseVideoFriend(index)"
            @mouseover="playVideoFriend(index)"
          >
            <source
              :src="`${assetsBaseUrl}${
                story.medias[story.medias.length - 1].src
              }`"
              type="video/mp4"
            />
          </video>
          <div
            class="
              card-body
              d-block
              p-3
              w-100
              position-absolute
              bottom-0
              text-center
            "
          >
            <a href="#">
              <figure
                class="
                  avatar
                  ms-auto
                  me-auto
                  mb-0
                  position-relative
                  w50
                  z-index-1
                "
              >
                <img
                  :src="`${assetsBaseUrl}${story.visiteur.photo}`"
                  alt="image"
                  class="float-right p-0 bg-white btn-round-lg w-100 shadow-lg"
                  style="opacity: 1"
                />
              </figure>
              <div class="clearfix"></div>
              <h4
                class="
                  fw-600
                  position-relative
                  z-index-1
                  ls-1
                  font-xssss
                  text-white
                  mt-2
                  mb-1
                "
              >
                {{ story.visiteur.nom }}
              </h4>
            </a>
          </div>
        
        
        </template>
         <template v-else>
          <img
            v-if="story.medias[story.medias.length - 1].type == 1"
            :src="`${assetsBaseUrl}${
              story.medias[story.medias.length - 1].src
            }`"
            alt="image"
            class="image-fit float-right p-0 bg-white h200 w-100 shadow-xss"
            style="opacity: 1"
          />

          <video
            :ref="setVideoRef"
            v-else
            loop
            class="float-right w-100 h200 video-fit"
            muted
            @mouseleave="pauseVideoFriend(index)"
            @mouseover="playVideoFriend(index)"
          >
            <source
              :src="`${assetsBaseUrl}${
                story.medias[story.medias.length - 1].src
              }`"
              type="video/mp4"
            />
          </video>
          <div
            class="
              card-body
              d-block
              p-3
              w-100
              position-absolute
              bottom-0
              text-center
            "
          >
            <a href="#">
              <figure
                class="
                  avatar
                  ms-auto
                  me-auto
                  mb-0
                  position-relative
                  w50
                  z-index-1
                "
              >
                <img
                  :src="`${assetsBaseUrl}${story.visiteur.photo}`"
                  alt="image"
                  class="float-right p-0 bg-white btn-round-lg w-100 shadow-lg"
                  style="opacity: 1"
                />
              </figure>
              <div class="clearfix"></div>
              <h4
                class="
                  fw-600
                  position-relative
                  z-index-1
                  ls-1
                  font-xssss
                  text-white
                  mt-2
                  mb-1
                "
              >
                {{ story.visiteur.nom }}
              </h4>
            </a>
          </div>
          </template>
        </div>
      </div>
    </SplideSlide>
    </template>
<template v-else>
  <div class="owl-item" style="width: auto; margin-right: 7px">
    <div class="item">
      <div
        class="
          card
          w125
          h200
          d-block
          border-0
          shadow-xss
          rounded-xxxl
          bg-gradiant-bottom
          overflow-hidden
          cursor-pointer
          mb-3
          mt-3
          me-1
        "
      >
        <Loader></Loader>
      </div>
    </div>
  </div>
</template>
  </Splide>

          


  <ModalStory v-if="isShowModal" @close="toggleModalClose" scrollable title="Story"   @refreshStory="refreshStory">
      
  </ModalStory>

  <ModalShowStory v-if="isStoriesShowModal" @closeStory="toggleShowModalClose" :indexSelected="indexSelected">
      
  </ModalShowStory>

  <ModalShowMyStory v-if="isMyStoriesShowModal" @closeStory="toggleShowModalMyClose" @refreshStory="refreshStory">
      
  </ModalShowMyStory>
  

 
</template>

<script>
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import Loader from "../../layouts/loader/loaderStory.vue";
import LoaderStory from "../../layouts/loader/loaderStoryCheck.vue";
import ModalStory from "../../layouts/modal/modalStory.vue";
import ModalShowStory from "../../layouts/modal/modalShowStory.vue";
import ModalShowMyStory from "../../layouts/modal/modalShowMyStory.vue";
import { useToast } from "vue-toastification";
const toast = useToast();

export default {
  components: {
    ModalStory,
    ModalShowStory,
    Loader,
    ModalShowMyStory,
    Splide,
    SplideSlide,
    LoaderStory
  },
  data() {
    return {
      isShowModal: false,
      isMyStoriesShowModal: false,
      isStoriesShowModal: false,
      stories: [],
      isStoriesReady: false,
      isMyStoriesReady: false,
      indexSelected: 0,
      myStories: [],
      videoRef: [],
      loading: false,
      clickedStoryID : '',
    };
  },
  mounted() {
    this.getStories();
    this.getMyStories();
  },

  methods: {
    setVideoRef(el) {
      if (el && !this.videoRef.includes(el)) {
        this.videoRef.push(el);
      }
    },

    playVideoFriend(i) {
      this.videoRef[i].play();
    },
    pauseVideoFriend(i) {
      this.videoRef[i].pause();
    },
    playVideo() {
      this.$refs.videoToPlay.play();
    },
    pauseVideo() {
      this.$refs.videoToPlay.pause();
    },

    refreshStory() {
      this.getStories();
      this.getMyStories();
    },
    refreshShiwStory() {},
    getStories() {
      this.isStoriesReady = false;
      this.axios.get("stories").then((res) => {
        this.stories = res.data.stories;
        //console.log(res.data);
        this.isStoriesReady = true;
        //console.log(res.data)
      });
    },
    getMyStories() {
      this.isMyStoriesReady = false;
      this.axios.get("myStories").then((res) => {
        this.myStories = res.data.stories;
        //console.log(res.data);
        this.isMyStoriesReady = true;
        //:style="`background-image: url(${assetsBaseUrl}${myStories.medias[0].src});`"
      });
    },
    toggleModal() {
      this.isShowModal = !this.isShowModal;
      
      this.$emit('removeListnerKEyUp');
    },
    
    toggleShowModal(i, idStory) {
       this.clickedStoryID = idStory;
      document.body.style.cursor = "wait";
      this.$emit('removeListnerKEyUp');
     
    
      
      this.loading = true;
      

      this.axios.post('/checkStorieExist', {
        storie_id:idStory
      }).then(res => {

        this.loading = false;
         document.body.style.cursor = "default";
   
        if (res.data.exist == false) {
          this.refreshStory();
          toast.error("This story is no longer available ", {
              position: "bottom-right",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
        } else {
          this.isStoriesShowModal = !this.isStoriesShowModal;
          this.indexSelected = i;
        }
      })
      
    },
    toggleShowModalMy() {
      this.isMyStoriesShowModal = !this.isMyStoriesShowModal;
       this.$emit('removeListnerKEyUp');
    },
    toggleModalClose() {
      this.isShowModal = false;
      this.$emit('addListnerKEyUp');
    },
    toggleShowModalClose(id) {
      
      this.isStoriesShowModal = false;
      this.indexSelected = id;
      this.$emit('addListnerKEyUp');
    },
    toggleShowModalMyClose() {
      this.isMyStoriesShowModal = false;
      this.$emit('addListnerKEyUp');
    },
  },

  computed: {
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
};
</script>
<style lang='scss' scoped>
.openModalAddStory {
  &:hover {
    cursor: pointer;
  }
}
.btn-round-lg {
  border-radius: 10px !important;
}
.btn-add-story {
  &:hover {
    background-color: #2e58a6 !important;
    color: #fff !important;
  }
  transition: all 0.4s ease-in-out;
}

.owl-stage {
  width: 9000px !important;
}
.my-storie {
  &:hover {
    cursor: pointer;
  }
}
.video-fit,
.image-fit {
  object-fit: cover !important;
}
.d-i-b {
  display: inline-block;
}
.is-active {
  border-bottom: 0 !important;
}
@media (max-width: 767px) {
  .h200 {
    height: 145px !important;
  }
  .w125 {
    width: 90px !important;
  }
}
.opacity-str{
  opacity: 0.5;
}
</style>