<template lang="">
   <div class="row">
                <div class="col-xl-12 ">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card w-100 border-0 shadow-none p-5 rounded-xxl bg-lightblue2 mb-3">
                                <div class="row">
                                    <!-- <div class="col-lg-6">
                                        <img :src="`${assetsBaseUrl}images/bg-2.png`"  alt="image" class="w-100" height="200">
                                    </div> -->
                                    <div class="col-lg-6 ps-lg-5">
                                        <h2 class="display1-size d-block mb-2 text-grey-900 fw-700">
                                            <!-- <span class="font-xssss fw-600 text-grey-500 d-block mb-2 ms-1">Welcome back</span>  -->
                                       Statistiques</h2>
                                       
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div class="col-lg-3 pe-2">
                            <div class="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style="background-color: #e5f6ff;">
                                <div class="card-body d-flex p-0">
                                    <i class="btn-round-lg d-inline-block me-3 bg-primary-gradiant feather-users font-md text-white"></i>
                                    <h4 class="text-primary font-xl fw-700">1<span class="fw-500 mt-0 d-block text-grey-500 font-xssss">Friends</span></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 pe-2 ps-2">
                            <div class="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style="background-color: #f6f3ff;">
                                <div class="card-body d-flex p-0">
                                    <i class="btn-round-lg d-inline-block me-3 bg-secondary feather-map-pin font-md text-white"></i>
                                    <h4 class="text-secondary font-xl fw-700">0<span class="fw-500 mt-0 d-block text-grey-500 font-xssss">visited Addresses </span></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 pe-2 ps-2">
                            <div class="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style="background-color: #e2f6e9;">
                                <div class="card-body d-flex p-0">
                                    <i class="btn-round-lg d-inline-block me-3 bg-success feather-thumbs-up font-md text-white"></i>
                                    <h4 class="text-success font-xl fw-700">1<span class="fw-500 mt-0 d-block text-grey-500 font-xssss">Adresses</span></h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 ps-2">
                            <div class="card w-100 border-0 shadow-none p-4 rounded-xxl mb-3" style="background-color: #fff0e9;">
                                <div class="card-body d-flex p-0">
                                    <i class="btn-round-lg d-inline-block me-3 bg-warning feather-award font-md text-white"></i>
                                    <h4 class="text-warning font-xl fw-700">Badge</h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card w-100 p-3 border-0 mb-3 rounded-xxl bg-lightblue2 shadow-none overflow-hidden">
                                   <div class="card-body">
                                       <h4 class="fw-700 font-sm mt-2  mb-4">My addresses that I like</h4>
                                      

                                   </div>
                                   
                            </div>
                        </div>
                       

                    </div>

                </div>               

            </div>
</template>
<script>
export default {
    computed: {
        assetsBaseUrl() {
            return this.$store.state.assetsBaseUrl;
        },
        currentUser() {
            return this.$store.state.currentUser;
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-round-lg {
    border-radius: 10px !important;
}
</style>