<template lang="">
 <ModalMutualFriend v-if="isShowModalMutualFriend" @close="toggleModalCloseMutualFriend" :friendId="friendId"></ModalMutualFriend>
        <div class="col-xl-12 col-xxl-8 col-lg-12 pe-0">

         
          
            <div class="card-body d-block w-100 shadow-none mb-1 p-0 border-bottom-xs">


            <Splide
            class=""
            :options="{
              rewind: true,
              perPage: 3,
              autoWidth: true,
              arrows: false,
              pagination: false,
              wheel: false,
              isNavigation: false,
            }"
          >
              <SplideSlide> <li class=" list-inline-item ms-3 me-3"><router-link :to="{name :'myamisvisiteur', params: { id : this.$route.params.id } }" class="fw-700 font-xsss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs1" data-toggle="tab"><i class="fas fa-users"></i> My  Friends</router-link></li></SplideSlide>
              <SplideSlide><li class="list-inline-item me-3"><router-link :to="{name :'receivedRequests', params: { id : this.$route.params.id } }" class="fw-700 font-xsss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab"><i class="fad fa-user-check"></i>  Received</router-link></li></SplideSlide>
              <SplideSlide><li class="list-inline-item me-3"><router-link :to="{name :'sentRequests', params: { id : this.$route.params.id } }" class="fw-700 font-xsss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab"><i class="fad fa-user-clock"></i>  Sent</router-link></li></SplideSlide>
              <SplideSlide><li class="list-inline-item me-3"><router-link :to="{name :'suggestedFriends', params: { id : this.$route.params.id } }" class="fw-700 font-xsss text-grey-500 pt-3 pb-3 ls-1 d-inline-block" href="#navtabs2" data-toggle="tab"><i class="fad fa-users"></i> Suggested</router-link></li></SplideSlide>
                  

          </Splide>
              
         </div>

                                 
    <router-view />
                           
                                
                               
         
         


         
        </div>
</template>
<script>
import Loader from "../../../../layouts/loader/loader.vue";
import ModalMutualFriend from "../../../../layouts/modal/modalMutualFriend.vue";
import "@splidejs/splide/dist/css/splide.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
export default {
  components: {
    Loader,
    ModalMutualFriend,
    Splide,
    SplideSlide,
  },

  data() {
    return {
      friends: [],
      isFriendsReady: false,
      searchKey: "",
      page: 1,
      lastPage: 1,
      done: false,
      addFriendRef: [],
      isVisible: false,
      totalResults: 0,
      btnShowMutualFriendRef: [],
      tooltipMutualFriendRef: [],
      isShowModalMutualFriend: false,
      friendId: null,
    };
  },
    destroyed() {
    const controller = new AbortController();
     controller.abort()
  },
  mounted() {
    this.getFriends();
  },

  methods: {
    toggleModalCloseMutualFriend(id) {
      this.isShowModalMutualFriend = false;
    },

    toggleModalMutualFriend(id) {
      this.isShowModalMutualFriend = !this.isShowModalMutualFriend;
      this.friendId = id;
    },

    getFriends() {
      this.isFriendsReady = false;
      this.axios.get("/amis/all").then((res) => {
        this.friends = res.data.amis;
        this.isFriendsReady = true;
        //console.log(res);
      });
    },
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    assetsBaseUrl() {
      return this.$store.state.assetsBaseUrl;
    },
    filtredResults() {
      return this.friends.filter(
        (friend) =>
          friend.sender.nom
            .toLowerCase()
            .includes(this.searchKey.toLowerCase()) ||
          friend.receiver.nom
            .toLowerCase()
            .includes(this.searchKey.toLowerCase())
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  flex-direction: row !important;
}
.rounded-circle {
  border-radius: 10px !important;
}

.btn-add-friend {
  &:hover {
    cursor: pointer;
    text-decoration: underline !important;
  }
}
.router-link-active.router-link-exact-active {
  border-bottom: 2px#333 solid !important;
  color: #000 !important;
}
.nav-tabs li a {
  &:hover {
    border-bottom: 2px#aaa solid !important;
    color: #000 !important;
  }
  transition: all 0.1s ease-in-out !important;
}
.text-grey-500 {
  color: #adb5bd !important;
}
.is-active {
  border-bottom: 0 !important;
}
.nav {
  flex-wrap: nowrap;
}
</style>